
import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col
} from "reactstrap";

import { connect } from "react-redux";

function mapStateToProps(state) {
  return {
    project: state.allOtherReducers.project,
    projectId: state.allOtherReducers.projectId,
    projectData: state.allOtherReducers.projectData,
    reportDate: state.allOtherReducers.reportDate
  };
}

class ConnectedSiteReportHeader extends React.Component {
  render() {
    return (
      <>
        <div className="content">
          <Row>
            <Col md="3">
              <Row>
                <Col>
                  <label><b>Project Name</b></label>
                </Col>
              </Row>

              <Row>
                <Col>
                  {this.props.project}
                </Col>
              </Row>
            </Col>
            <Col md="3">
              <Row>
                <Col>
                  <label><b>Date</b></label>
                </Col>
              </Row>
              <Row>
                <Col>
                  {this.props.reportDate}
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

const SiteReportHeader = connect(
  mapStateToProps,
  null
)(ConnectedSiteReportHeader);

export default SiteReportHeader;
