import React from "react";
import axios from "axios"
import AddProjectForm from "views/forms/AddProjectForm"
import Select from "react-select"
import { connect } from "react-redux";
import { setProject, setReportDate, getProjects, getProjectsByUser } from "../../actions/index";
import store from "store";
import { withTranslation } from 'react-i18next';

// reactstrap components
import {
  Card,
  CardBody,
  Row,
  Col,
  FormGroup,
  Form,
} from "reactstrap";
import Button from "reactstrap/lib/Button";

class Initialisation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

      projectData: [],
      currentProject: store.getState().projectName,
      selectedProject: ''
    };
    this.formSubmitSuccess = this.formSubmitSuccess.bind(this)
    this.getSomeProjects = this.getSomeProjects.bind(this)
    this.populateDropDownSelectProject = this.populateDropDownSelectProject.bind(this)
    this.getSomeProjects();


  }



  componentDidMount() {
    this.populateDropDownSelectProject(this.props.projectData)
    var startDate = new Date();
    startDate.setDate(startDate.getDate() - 7);

    var endDate = new Date();
    endDate.setDate(endDate.getDate());
    var getDateArray = function (start, end) {
      var arr = new Array();
      var dt = new Date(start);
      while (dt <= end) {
        arr.push(new Date(dt));
        dt.setDate(dt.getDate() + 1);
      }
      return arr;
    }

    if (this.props.projectData?.length === 1) {
      let p = this.props.projectData[0];

      this.props.setProject({
        label: p.name,
        value: p._id
      })

        this.setState({
            selectedProject: {value: p._id, label: p.name}
        })

      setTimeout(() => {
        this.props.history.push('/admin/instructions');
      }, 200)
    }


    if (this.props.projectId) {
        this.setState({
            selectedProject: {value: this.props.projectId, label: this.props.project}
        })
    }

  }

  componentDidUpdate(prevProps, e) {

    if (this.props.projectData !== prevProps.projectData) {

      this.getSomeProjects()
      this.populateDropDownSelectProject(this.props.projectData)
    }

    if (this.props.projectId !== prevProps.projectId) {

      this.populateDropDownSelectProject(this.props.projectData)
      this.getSomeProjects()
      this.setState({
        currentProject: store.getState().project
      })
    }




  }


  getSomeProjects() {
    axios.get(process.env.REACT_APP_API_URL + "/api/project/", {
      headers: {
        'Authorization': localStorage.jwtToken
      }
    })
      .then(res => {
        this.setState({
          projectData: res.data
        }, () => console.log(this.state.projectData));

        return res.data
      }).catch((error) => {
        ;
      })
  }

  dateSelectedFromDropdownIntervals(dateIn) {
    this.setState({ dateSelected: dateIn }, () => this.state.dateSelected)
  }

  getScheduleClickedOnText = (gotText) => {
    this.setState({ scheduleClickedItem: gotText }, () => this.state.scheduleClickedItem)
  }

  updateProjectData = (data) => {
    this.setState({ projectData: data }, () => this.state.projectData)

  }

  thisTestFunction = () => {
    alert("im in the parent")
  }

  populateDropDownSelectProject(data) {
    var testdata = data
    if (testdata && testdata.length > 0) {
      const options = testdata.map(project => {
        return { value: project._id, label: project.name }
      })
      // this.props.setProject(options[0])
      this.setState({ projectDataFromAPI: options }, () => { console.log('populated') })

    }

  }
  formSubmitSuccess(e) {
    e.preventDefault();

    this.props.setProject(this.state.selectedProject)

    this.props.history.push('/admin/instructions')
  }

  render() {
    return (
      <>
        <div className="content">
          <Row>
            <Col tag="h3">{this.props.t('Select project')}</Col>
          </Row>
          <Row>
            <Col xs="12" md="4">
              <Card>
                <CardBody>
                  <Form className="form-horizontal" onSubmit={(e) => this.formSubmitSuccess(e)}>
                    <Select
                      className="react-select primary"
                      classNamePrefix="react-select"
                      name="projectList"
                      defaultValue={this.props.projectId  ? { value: this.props.projectId, label: this.props.project } : null}
                      onChange={selectedProject => this.setState({
                        selectedProject
                      })}
                      options={this.state.projectDataFromAPI}
                    />
                    <Button color="info" type="submit">
                      {this.props.t('Launch Application')}
                  </Button>
                  </Form>
                </CardBody>
              </Card>
            </Col>
            <Col>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setProject: project => dispatch(setProject(project)),
    getProjects: () => dispatch(getProjects()),
    setReportDate: date => dispatch(setReportDate(date)),
    getProjectsByUser: (userid) => dispatch(getProjectsByUser(userid))

  };
}


const mapStateToProps = state => ({
  auth: state.auth,
  project: state.allOtherReducers.project,
  projectId: state.allOtherReducers.projectId,
  projectData: state.allOtherReducers.projectData,
  reportDate: state.allOtherReducers.reportDate,
});


export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Initialisation))


