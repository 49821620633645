import {
  SET_PROJECT,
  SET_ABSENCE_MODAL_TOGGLE,
  PROJECT_DATA_LOADED,
  SET_REPORT_DATE,
  ADD_PROJECT,
  ADD_GENERAL_COMMENT,
  GENERAL_COMMENTS_LOADED,
  ALL_GENERAL_COMMENTS_LOADED,
  ADD_PROJECT_PERMISSIONS,
  REMOVE_PROJECT_PERMISSIONS,
  EMPTY_ALL_PROJECT_PERMISSIONS,
  ADD_PROJECT_PERMISSIONS_TO_USER,
  UPDATE_GENERAL_COMMENTS,
  SET_CLIENT,
  SET_SELECTED_PHOTOS,
  REFRESH_PHOTO_LIST,
  REFRESH_PHOTOS_WITH_KEEP_SELECTION_MOOD,
  REFRESH_PROJECTS,
  SET_ROLES_LIST,
  SET_RESPONSE, CLEAR_SELECTED_PROJECT,
} from "../constants/action-types";
  import { GET_EMPLOYEES } from "../constants/action-types";
  import {SET_ABSENCE_MODAL} from "../constants/action-types"
  const initialState = {
    projectId:'',
    project: '',
    reportDate:'',
    apiEmployees: [],
    projectData:[],
    generalCommentData:[],
    allGeneralCommentData:[],
    openModal: false,
    toggle:false,
    projectPermissionList: [],
    addedProjectPermissions:[],
    client:'',
    clientId:'',
    selectedPhotos: [],
    refreshPhotoList: false,
    refreshPhotosWithKeepSelectionMood:false,
    rolesList: [],
   };
  export default function (state = initialState, action) {
    if (action.type === SET_RESPONSE) {
      return Object.assign({}, state, {
        response: action.payload,
      })
    }
    if (action.type === ADD_PROJECT) {
      return Object.assign({}, state, {
        // projectData: [...action.payload],
        // project: state.allOtherReducers.project,.project.concat(action.payload.name),
        projectData:state.projectData.concat(action.payload)
      });
    }
    if (action.type === ADD_GENERAL_COMMENT) {
      return Object.assign({}, state, {
        generalCommentData:state.generalCommentData.concat(action.payload)
      });
    }
    if (action.type===GENERAL_COMMENTS_LOADED){
      return Object.assign({}, state, {
        generalCommentData: [...action.payload],
      })
    }
    if (action.type===ALL_GENERAL_COMMENTS_LOADED){
      return Object.assign({}, state, {
        allGeneralCommentData: [...action.payload],
      })
    }
    if (action.type===UPDATE_GENERAL_COMMENTS){
      return Object.assign({}, state, {
        generalCommentData: [...action.payload],
      })
    }
    if (action.type === SET_PROJECT) {
      return Object.assign({}, state, {
        project: action.payload.label,
        projectId: action.payload.value
      });
    }
    if (action.type === CLEAR_SELECTED_PROJECT) {
      return Object.assign({}, state, {
        project: '',
        projectId: ''
      });
    }
    if (action.type === SET_ROLES_LIST) {
      return Object.assign({}, state, {
        rolesList: action.payload
      });
    }
    if (action.type === SET_REPORT_DATE) {
      return Object.assign({}, state, {
        reportDate: action.payload
      });
    }
    if (action.type === SET_CLIENT) {
      return Object.assign({}, state, {
        client:action.payload[0].name,
        clientId:action.payload[0]._id
      });
    }
    if (action.type===PROJECT_DATA_LOADED){
      return Object.assign({}, state, {
        projectData: [...action.payload],
        // project: [{...action.payload}][0].name,
        // projectId: [{...action.payload}][0]._id
      })
    }
    if (action.type === REFRESH_PROJECTS) {
      return Object.assign({}, state, {
        projectData: action.payload,
      })
    }

    if (action.type === GET_EMPLOYEES)
    {
      return Object.assign({}, state, {
        apiEmployees: [...action.payload]
      })
    }
    if (action.type === SET_ABSENCE_MODAL) {
      return Object.assign({}, state, {
        openModal: action.payload
      });
    }
    if (action.type === SET_ABSENCE_MODAL_TOGGLE){
      return Object.assign({}, state, {
        toggle: action.payload
      });
    }
    if (action.type === ADD_PROJECT_PERMISSIONS){

      let shouldPush = true;

        let projectPermissionList = state.projectPermissionList.map(record => {
            if (record.projectId === action.payload.projectId) {
                shouldPush = false;
                return action.payload
            } else {
                return record
            }
        })

        if (shouldPush) {
            projectPermissionList.push(action.payload)
        }

      return Object.assign({}, state, {
        projectPermissionList: projectPermissionList
      });
    }
    if (action.type === REMOVE_PROJECT_PERMISSIONS){
      return Object.assign({}, state, {
        projectPermissionList: state.projectPermissionList.filter(function (record) { return record.projectId !== action.payload })
      });
    }
    if (action.type === EMPTY_ALL_PROJECT_PERMISSIONS){
      return Object.assign({}, state, {
        projectPermissionList: []
      });

    }
    if (action.type === ADD_PROJECT_PERMISSIONS_TO_USER){
      return Object.assign({}, state, {
        addedProjectPermissions: []
      });

    }
    if (action.type === SET_SELECTED_PHOTOS) {
      return Object.assign({}, state, {
        selectedPhotos: action.payload
      })
    }
    if (action.type === REFRESH_PHOTO_LIST) {
      return Object.assign({}, state, {
        refreshPhotoList: action.payload
      })
    }
    if (action.type === REFRESH_PHOTOS_WITH_KEEP_SELECTION_MOOD) {
      return Object.assign({}, state, {
        refreshPhotosWithKeepSelectionMood: action.payload
      })
    }
    return state;
  }


