import React from "react";
import moment from 'moment'
import helper from '../../../utils/general_helper.js'

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col
} from "reactstrap";
import { connect } from "react-redux";
import ReactTable from "react-table";
import { withTranslation } from 'react-i18next';

function mapStateToProps(state) {
  return {
    project: state.allOtherReducers.project,
    projectId: state.allOtherReducers.projectId,
    projectData: state.allOtherReducers.projectData,
    reportDate: state.allOtherReducers.reportDate
  };
}

class ConnectedSiteReportAdditionalWork extends React.Component {

  constructor(props) {
    super(props);
    this.state = {

    }
  }

 


  componentWillReceiveProps({ reportAdditionalWork, formattedAdditionalTableStructure }) {
    this.setState({ ...this.state, reportAdditionalWork, formattedAdditionalTableStructure }, () => this.state)
  }

  render() {
    const NullComponent = () => null;

    return (
      <div>
        <h4 className="pl-3 card-title">{this.props.t('Additional Work')}</h4>
        <ReactTable
            NoDataComponent={() => null}
            data={this.props.formattedAdditionalTableStructure}
            columns={[
              {
                Header: "Id",
                accessor: "_id",
                show: false
              },
              {
                Header: this.props.t("Project Id"),
                accessor: "projectId",
                show: false
              },
              {
                Header: this.props.t("Scheduled Item"),
                accessor: "scheduledItem",
                style: { 'whiteSpace': 'unset' },
                width: 300,
              },
              {
                Header: this.props.t("Detail"),
                accessor: "additionalWorkDetail",
                className: "frozen",
                Cell: (props) => {
                  let multiLineString = props.value
                  let lines = multiLineString.split('\n')
                  return lines.map((line) => <div>{line}</div>);
                },
              },
              {
                Header: this.props.t("Employee Detail"),
                accessor: "timesheetLogDetail",
                // className: "frozen",                
                Cell: (props) => {

                  if (Array.isArray(props.value)) {
                    return props.value.map((timesheetLogDetail) =>
                      <Row striped>
                        <Col>{timesheetLogDetail.user_id.name + ' ' + timesheetLogDetail.user_id.last_name}</Col><Col>{helper.hoursMinutesAllocateTimeFormatted(timesheetLogDetail.allocated)}</Col>
                      </Row>

                    )
                  }
                  else {
                    return []
                  }
                },

              },
              {
                Header: this.props.t("Total employee duration"),
                accessor: "timesheetLogDetail",
                // className: "frozen",                
                Cell: (props) => {
                  return helper.hoursMinutesAllocateTimeFormatted(props.value.reduce((r, d) => r + d.allocated, 0));
                }

              },
              // {
              //   Header: "Work Duration",
              //   accessor: "duration",
              //   width: 150,
              // },
            ]}
            defaultPageSize={1}
            pageSize={this.props.formattedAdditionalTableStructure.length}
            showPaginationTop={false}
            showPaginationBottom={false}
            className="-striped -highlight primary-pagination"
          />
      </div>
    );
  }
}

const SiteReportAdditionalWork = withTranslation()(connect(mapStateToProps, null)(ConnectedSiteReportAdditionalWork));

export default SiteReportAdditionalWork;

