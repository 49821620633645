import {
    HTTP_STATUS_400,
} from "./types";

const requestHeaderInterceptor = (config) => {
    return config;
};

const getErrorResponse = (e) => {
    const dataError = e?.Error || e;
    const errorData = {
        error: true,
        status: HTTP_STATUS_400, // status code
        data: dataError,
        code: dataError?.Code, // error code
        message: dataError?.Message, // error msg
        statusText: HTTP_STATUS_400,
    };
    return Promise.reject(errorData);
};

const responseSuccessInterceptor = (response) => {
    try {
        const { data } = response;

        return data;
    } catch (e) {

        return getErrorResponse(e);
    }
};

const responseErrorInterceptor = (error) => {
    console.log('error', error);
    try {
        if (error && error.response) {
            const {
                response: { status, data, statusText },
            } = error;
            console.warn("Response error", error.response);

            return getErrorResponse(error.response?.data);
        }
        if (error?.request) {
            return Promise.reject({
                error: true,
                message: error?.message,
                code: "request_failed",
            });
        }
    } catch (e) {
        return Promise.reject(e);
    }
};

export {
    requestHeaderInterceptor,
    responseSuccessInterceptor,
    responseErrorInterceptor,
};