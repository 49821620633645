import React from "react";
import {connect} from "react-redux";


// reactstrap components
import {
    Card,
    CardBody,
    Row,
    Col,
} from "reactstrap";
import {userCant} from "../../services/Authorization/authService";
import axios from "axios";
import ReactTable from "react-table";
import moment from "moment";
import {withTranslation} from 'react-i18next';

class ClockInOuts extends React.Component {
    constructor(props) {
        super(props);
        this.fetchData = this.fetchData.bind(this)
        this.state = {
            records: [],
            dateTimeFormat: 'D MMMM YYYY, h:mm a'
        }
        this.fetchData();
    }

    fetchData() {
        if (userCant(this.props.user, 'clockinouts.manage')) {
            return
        }

        axios.get(`${process.env.REACT_APP_API_URL}/api/fetch-clock-in-outs-records?projectId=${this.props.projectId}&date=${this.props.reportDate}`, {
            headers: {
                'Authorization': localStorage.jwtToken
            }
        }).then(res => {
            this.setState({
                records: res.data
            });
            return res.data
        })
    }

    componentDidUpdate(prevProps) {
        if (
            (this.props.reportDate !== prevProps.reportDate)
            ||
            (this.props.projectId !== prevProps.projectId)
        ) {
            this.fetchData()
        }
    }

    renderTimeLogObject(timeObject) {
        if (!timeObject) {
            return '-'
        }

        return timeObject.map((t, index) =>
            <p style={{'fontSize': '12px'}} key={`wtime_${index}`}>
                <b>Start:</b> {moment.utc(t.startTime).format(this.state.dateTimeFormat)}
                <br/>
                <b>End:</b> {moment.utc(t.endTime).format(this.state.dateTimeFormat)}
            </p>)

    }

    render() {
        if (userCant(this.props.user, 'clockinouts.manage')) {
            return
        }

        return (
            <>
                <div className="content">
                    <Row>
                        <Col tag="h3">Clock In/Outs</Col>
                    </Row>
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <ReactTable
                                        data={this.state.records}
                                        columns={[
                                            {
                                                Header: "Id",
                                                accessor: "_id",
                                                show: false
                                            },
                                            {
                                                Header: this.props.t("Employee"),
                                                accessor: "employeeId.name",
                                            },
                                            {
                                                Header: this.props.t("Project"),
                                                accessor: "projectId.name",
                                            },
                                            {
                                                Header: this.props.t("Status"),
                                                accessor: "status",
                                            },
                                            {
                                                Header: this.props.t("Clock In Time"),
                                                accessor: "clockInTime",
                                                Cell: row => moment.utc(row.original.clockInTime).format(this.state.dateTimeFormat)
                                            },
                                            {
                                                Header: this.props.t("Clock Out Time"),
                                                accessor: "clockOutTime",
                                                Cell: row => row.original.clockOutTime ? moment.utc(row.original.clockOutTime).format(this.state.dateTimeFormat) : '-'
                                            },
                                            {
                                                Header: this.props.t("Work time"),
                                                accessor: "workTime",
                                                Cell: row => this.renderTimeLogObject(row.original.workTime)
                                            },
                                            {
                                                Header: this.props.t("Break time"),
                                                accessor: "breakTime",
                                                Cell: row => this.renderTimeLogObject(row.original.breakTime)
                                            },
                                            {
                                                Header: this.props.t("last Update Time"),
                                                accessor: "lastUpdateTime",
                                                Cell: row => new moment(row.original.lastUpdateTime).format(this.state.dateTimeFormat)
                                            },
                                        ]}
                                        defaultPageSize={1}
                                        pageSize={this.state.records.length}
                                        showPaginationTop={false}
                                        showPaginationBottom={false}
                                        className="-striped -highlight primary-pagination"
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {};
}


const mapStateToProps = state => ({
    user: state.auth.user,
    projectId: state.allOtherReducers.projectId,
    reportDate: state.allOtherReducers.reportDate,
});


export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ClockInOuts));


