import React from "react";
import Select from "react-select";
import axios from "axios"
import UpdateTimesheetLogTableContractedWork from 'views/tables/UpdateTimesheetLogTableContractedWork'
import Plan from "views/pages/PlanManagement/PlanManagement"
import {setScheduleItem} from '../../../actions/scheduleActions'
import {withTranslation} from 'react-i18next';

// reactstrap components
import {
    Button,
    Card,
    CardBody,
    CardGroup,
    FormGroup,
    Form,
    Input,
    Row,
    Col,
} from "reactstrap";
import moment from "moment"
import {connect} from "react-redux";
import ContractorWorksFormByEmployee from "./ContractedWorksFormByEmployee";

function mapStateToProps(state) {
    return {
        project: state.allOtherReducers.project,
        projectId: state.allOtherReducers.projectId,
        projectData: state.allOtherReducers.projectData,
        reportDate: state.allOtherReducers.reportDate,
        scheduleItem: state.schedule.scheduleItem,
        scheduleItemId: state.schedule.scheduleItemId
    };
}

function mapDispatchToProps(dispatch) {
    return {

        setScheduleItem: scheduleItem => dispatch(setScheduleItem(scheduleItem)),
    }
}

class UpdateConnectedContractorWorksFormByEmployee extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            singleMaterialSelect: null,
            singleTaskSelect: null,
            singleSiteLocationSelect: null,
            singleHoursWorkedSelect: null,
            singleEmployeesInCompanyValue: null,
            multipleSelect: null,
            callFromAPI: [],
            employeesInCompany: [{}],
            selectedValueCompany: this.props.t('Select Company'),
            matertialFromAPI: [],
            taskFromAPI: [],
            siteLocationFromAPI: [],
            hoursWorkedFromAPI: [],
            contractWorkDate: "",
            defaultDate: "",
            dynamicTreeData: [],
            scheduleClickedItem: '',
            scheduleClickedItemId: [],
            projectStructureBuildingAPI: [],
            sinlgeProjectStructureBuildingAPI: '',
            projectStructureLevelAPI: [],
            sinlgeProjectStructureLevelAPI: '',
            projectStructureElevationAPI: [],
            sinlgeProjectStructureElevationAPI: '',
            projectStructureSystemAPI: [],
            sinlgeProjectStructureSystemAPI: '',
            employeesWorkingToday: [],
            timesheetdatafetch: [],
            selectedEmployeeList: [],
            selectedDuration: '',
            clearChecks: null,
            comment: '',
            timesheetLogDetail: [],
            timesheetLogsAllData: [],
            removeEmployeeList: [],
            errors: {}

        };
        this.formSubmitSuccess = this.formSubmitSuccess.bind(this)
        // this.insertProjectSchedule = this.insertProjectSchedule.bind(this)
        this.getScheduleClickedOnText = this.getScheduleClickedOnText.bind(this)
        this.getProjectSchedule()
        this.getTimesheetLogByDate = this.getTimesheetLogByDate.bind(this)
        this.populateForm = this.populateForm.bind(this)
        this.populateProjectStructureLevelsAndElevations = this.populateProjectStructureLevelsAndElevations.bind(this)
        // this.getTimesheetLogByDate(this.props.reportDate)

    }

    async componentDidMount() {
        console.log('didMount//////xxx');
        this.getTimesheetLogByDate(this.props.reportDate).then((res) => {
            //  this.populateForm()
            // timesheetlogs came from API and were stored in timesheetdatafetch,
            //assign this to new var that we will combine with timesheetlogDetail
            var _timesheetLogsAllData = res
            // Get timesheetLogDetail from the clicked on row through the props additionalWorkRowData
            var timesheetLogDetailToAdd = this.props.contractedWorkRowData.length > 0 ? this.props.contractedWorkRowData[0].timesheetLogDetail : []

            _timesheetLogsAllData.map(v => {
                let g = (timesheetLogDetailToAdd.find(e => `${e.timesheetLogId}` == `${v._id}`) || '')
                if (!g) return v;
                v.timesheetLogDetailId = g._id
                v.allocatedHours = g.allocatedHours;
                v.allocatedMinutes = g.allocatedMinutes;
                v.scheduledItemId = g.scheduledItemId;
                v.projectId = g.projectId
                v.timesheetLogId = g.timesheetLogId
                v.workItemType = g.workItemType
                v.workItemId = g.workItemId
                v.user_id = g.user_id
                if (g.allocatedTime > 0) {
                    v.allocatedTime = g.allocatedTime
                }
                if (g.allocatedHours > 0 | g.allocatedMinutes > 0) {
                    v.checked = true
                }
                return v;
            });
            this.setState({
                timesheetLogsAllData: _timesheetLogsAllData
            }, () => {
            })
        })
        this.getProjectSchedule();

        this.populateDropDownMaterials(this.props.materialTableData);
        this.populateDropDownTasks(this.props.taskTableData)
        this.populateDropDownHoursWorked(this.props.hoursWorked)
        await this.populateProjectStructureBuildings(this.props.projectStructureTableData)

        this.populateProjectStructureLevelsAndElevations(
            this.props.projectStructureTableData,
            this.props.contractedWorkRowData[0]?.building?._id
        )

        this.setState({
            comment: this.props.contractedWorkRowData[0]?.comment,
            sinlgeProjectStructureSystemAPI: [
                {
                    value: this.props.contractedWorkRowData[0]?.system?._id,
                    label: this.props.contractedWorkRowData[0]?.system?.name
                }
            ],

            sinlgeProjectStructureBuildingAPI: {
                value: this.props.contractedWorkRowData[0]?.building?._id,
                label: this.props.contractedWorkRowData[0]?.building?.building
            },
            sinlgeProjectStructureLevelAPI: {
                value: this.props.contractedWorkRowData[0]?.level === null ? '' : this.props.contractedWorkRowData[0]?.level?._id,
                label: this.props.contractedWorkRowData[0]?.level === null ? '' : this.props.contractedWorkRowData[0]?.level?.name
            }
            ,
            sinlgeProjectStructureElevationAPI: {
                value: this.props.contractedWorkRowData[0]?.elevation === null ? '' : this.props.contractedWorkRowData[0]?.elevation?._id,
                label: this.props.contractedWorkRowData[0]?.elevation === null ? '' : this.props.contractedWorkRowData[0]?.elevation?.name
            },
            singleInstructionsFromAPI:
                {
                    value: this.props.contractedWorkRowData[0]?.instruction?._id,
                    label: this.props.contractedWorkRowData[0]?.instruction?.userDefinedInstructionNumber + ' | '
                        + this.props.contractedWorkRowData[0]?.instruction?.instructionTitle
                }
            ,
            singleTaskSelect:
                {
                    value: this.props.contractedWorkRowData[0]?.task?._id,
                    label: this.props.contractedWorkRowData[0]?.task?.taskName
                }
            ,
            singleMaterialSelect:
                {
                    value: this.props.contractedWorkRowData[0]?.material?._id,
                    label: this.props.contractedWorkRowData[0]?.material?.materialName
                }
            ,

        }, async () => {
            this.props.setScheduleItem({
                full_path: this.props.contractedWorkRowData[0]?.scheduledItemId?.full_path,
                _id: this.props.contractedWorkRowData[0]?.scheduledItemId?._id
            })
        })



    }

    componentDidUpdate(prevProps, prevState) {

        if (this.props.projectId !== prevProps.projectId) {
            this.getProjectSchedule();
        }

        if (this.state.sinlgeProjectStructureBuildingAPI?.value !== prevState.sinlgeProjectStructureBuildingAPI?.value) {
            this.populateProjectStructureLevelsAndElevations(this.props.projectStructureTableData, this.state.sinlgeProjectStructureBuildingAPI?.value)
        }
    }

    populateProjectStructureBuildings(data, callback) {
        const options = data.map(projectStructure => {
            return {value: projectStructure._id, label: projectStructure.building}
        })

        this.setState({
            projectStructureBuildingAPI: options,
            sinlgeProjectStructureLevelAPI: ''
        })

    }

    populateProjectStructureLevelsAndElevations(data, BuildingId) {

        console.log('populateProjectStructureLevelsAndElevationspopulateProjectStructureLevelsAndElevationspopulateProjectStructureLevelsAndElevations');
        console.log(data, BuildingId);
        var projectStructureData = data.find((x) => x._id === BuildingId)
        console.log('Buxxx', projectStructureData);
        if (typeof projectStructureData === 'object' && projectStructureData !== null) {

            var optionsLevel = []
            var levelProp = 'levelsx';
            if (projectStructureData.hasOwnProperty(levelProp)) {
                optionsLevel = projectStructureData.levelsx.map(level => {

                    return {value: level._id, label: level.name}
                })
            }

            var optionsElevations = []
            var elevationProp = 'elevationsx';
            if (projectStructureData.hasOwnProperty(elevationProp)) {
                optionsElevations = projectStructureData.elevationsx.map(elevation => {
                    return {value: elevation._id, label: elevation.name}
                })
            }
            var optionsSystem = []
            var systemProp = 'systemsx';
            if (projectStructureData.hasOwnProperty(systemProp)) {
                optionsSystem = projectStructureData.systemsx.map(system => {
                        return {value: system._id, label: system.name}
                    }
                )
            }

            console.log('Response..................................');
            console.log({
                projectStructureLevelAPI: optionsLevel,
                projectStructureElevationAPI: optionsElevations,
                projectStructureSystemAPI: optionsSystem
            });

            this.setState({
                projectStructureLevelAPI: optionsLevel,
                projectStructureElevationAPI: optionsElevations,
                projectStructureSystemAPI: optionsSystem
            })
        }
    }

    populateDropDownMaterials(data) {
        var testdata = data
        const options = testdata.map(material => {
            return {value: material._id, label: material.materialName}
        })
        this.setState({matertialFromAPI: options}, () => console.log(this.state.matertialFromAPI))
    }

    populateDropDownTasks(data) {
        var testdata = data
        const options = testdata.map(task => {
            return {value: task._id, label: task.taskName}
        })
        this.setState({taskFromAPI: options}, () => console.log(this.state.taskFromAPI))
    }

    populateDropDownHoursWorked(data) {
        var testdata = data
        const options = testdata.map(hoursworked => {
            return {value: moment(hoursworked).format("H:mm"), label: moment(hoursworked).format('H [Hr] mm [Min]')}
        })

        this.setState({hoursWorkedFromAPI: options}, () => console.log(this.state.hoursWorkedFromAPI))
    }

    formSubmitSuccess(e) {
        e.preventDefault();

        // let timesheetLogIds = this.state.selectedEmployeeList.map(timesheetLogIds => { return timesheetLogIds._id; });
        let filteredLogIds = this.state.timesheetdatafetch.filter(logIds => {
            if (logIds.allocatedHours > 0 | logIds.allocatedMinutes > 0)
                return logIds._id
        });

        var employeesSelected = []
        for (const selected of filteredLogIds) {
            selected['user_id'] = selected.user_id
            employeesSelected.push(
                selected
            )
        }


        var timesheetLogDetailList = employeesSelected.map(x => x.timesheetLogDetailId)
        console.log(timesheetLogDetailList)

        const data = new FormData(e.target);
        axios.put(process.env.REACT_APP_API_URL + "/api/contractorwork/" + this.props.contractedWorkRowData[0]?._id + "/project/" + this.props.projectId, {
            scheduledItem: data.get('scheduledItem'),
            scheduledItemId: data.get('scheduledItemId'),
            building: data.get('building'),
            level: data.get('level'),
            elevation: data.get('elevation'),
            system: data.get('system'),
            task: data.get('taskName'),
            material: data.get('materialName'),
            employees: employeesSelected,
            duration: data.get('selectedDuration'),
            comment: data.get('comment'),
            timesheetLogDetail: timesheetLogDetailList,
            date: this.props.reportDate
        }, {
            headers: {
                'Authorization': localStorage.jwtToken
            }
        })
            .then(res => {


                this.setState({
                    scheduleClickedItem: '',
                    scheduleClickedItemId: [],
                    selectedValueCompany: '',
                    singleSiteLocationSelect: '',
                    singleTaskSelect: '',
                    singleMaterialSelect: '',
                    singleHoursWorkedSelect: "00:00",
                    employeesInCompany: [{value: "Select No. Workers", label: this.props.t("Select No.Workers")}],
                    employeesInCompanyValue: null,
                    sinlgeProjectStructureLevelAPI: '',
                    sinlgeProjectStructureElevationAPI: null,
                    sinlgeProjectStructureSystemAPI: null,
                    sinlgeProjectStructureBuildingAPI: '',
                    selectedDuration: "00:00",
                    clearChecks: true,
                    selectedEmployeeList: [],
                    comment: '',
                    defaultDate: moment(),
                    errors: {}
                }, () => {
                })
                this.props.getContractorWorkTable();
                this.getTimesheetLogByDate(this.props.reportDate)
                this.props.isUpdateDockVisibleControl()
                this.props.setShouldUpdateWorkingHours();
                this.props.setScheduleItem(null)
                return res.data
            })
            .catch((error) => {
                this.setState({
                    errors: error.response.data
                })
            })
    }

    componentWillReceiveProps(prevProps, {
        scheduleClickedItem,
        scheduleClickedItemId,
        contractorSummaryTableData,
        isDockVisible,
        materialTableData,
        taskTableData,
        siteLocationData,
        hoursWorked,
        projectStructureTableData,
        contractedWorkRowData
    }) {
        this.setState({
                ...this.state,
                scheduleClickedItem,
                scheduleClickedItemId,
                contractorSummaryTableData,
                isDockVisible,
                materialTableData,
                taskTableData,
                siteLocationData,
                hoursWorked,
                projectStructureTableData,
                contractedWorkRowData
            }, () => {
            }
        )
    }

    populateForm() {
    }

    //PROJECT SCHEDULE FUNCTIONS


    getProjectSchedule() {

        axios.get(process.env.REACT_APP_API_URL + '/api/projectschedule/project/' + this.props.projectId, {
            headers: {
                'Authorization': localStorage.jwtToken
            }
        })
            .then(res => {
                this.setState({dynamicTreeData: res.data}, () => console.log(this.state.dynamicTreeData))
                // window.location.reload(true)
            })
            .catch((error) => {
                console.log(error);
            })
    }

    getScheduleClickedOnText = (gotText, gotId) => {
        this.setState({
            scheduleClickedItem: gotText,
            scheduleClickedItemId: gotId

        }, () => this.state.scheduleClickedItem)
    }

    //^^ PROJECT SCHEDULE FUNCTIONS ^^

    async getTimesheetLogByDate(dateToSearch) {
        const timesheetLogByDate = await axios.get(process.env.REACT_APP_API_URL + '/api/timesheetlog/' + dateToSearch + "/projects/" + this.props.projectId, {
            headers: {
                'Authorization': localStorage.jwtToken
            }
        }).catch((error) => {
            ;
        })
        this.setState({
            timesheetdatafetch: timesheetLogByDate.data
        });
        return timesheetLogByDate.data
    }

    render() {
        const divStyle = {
            overflowY: 'scroll',
            width: '500px',
            float: 'left',
            height: '300px',
            position: 'relative'
        };
        var plan = !this.props.isDockVisible ? null : <Plan ref="plan" object={this.props.contractedWorkRowData}/>
        return (
            <>
                <Form className="form-horizontal" onSubmit={(e) => this.formSubmitSuccess(e)}>
                    <Row>

                        <Col>
                            <CardGroup>
                                <Card>
                                    {/* <Plan /> */}
                                    {plan}
                                </Card>

                                <Card>
                                    <CardBody>
                                        <h7>{this.props.t("Scheduled Item")}</h7>
                                        <FormGroup>
                                            <p style={{
                                                color: '#51cbce',
                                                border: '1px #E3E3E3 solid',
                                                padding: '5px',
                                                borderRadius: '3%'
                                            }}>{this.props.scheduleItem}</p>
                                            <Input type="hidden" name="scheduledItemId"
                                                   value={this.props.scheduleItemId}></Input>
                                            <Input type="hidden" name="scheduledItem"
                                                   value={this.props.scheduleItem}></Input>

                                            {this.state.errors.scheduledItem && (
                                                <div
                                                    className="invalid-feedback d-block">{this.state.errors.scheduledItem}</div>
                                            )}
                                        </FormGroup>
                                        <h7>{this.props.t("Building")}</h7>
                                        <FormGroup>
                                            <Select
                                                className="react-select primary"
                                                classNamePrefix="react-select"
                                                name="building"
                                                value={this.state.sinlgeProjectStructureBuildingAPI}
                                                onChange={value =>
                                                    this.setState({
                                                        sinlgeProjectStructureBuildingAPI: value,
                                                        sinlgeProjectStructureLevelAPI: '',
                                                        sinlgeProjectStructureElevationAPI: '',
                                                        sinlgeProjectStructureSystemAPI: ''
                                                    })}
                                                options={this.state.projectStructureBuildingAPI}
                                                placeholder={this.props.t("Choose Building")}
                                            />
                                            {this.state.errors.building && (
                                                <div
                                                    className="invalid-feedback d-block">{this.state.errors.building}</div>
                                            )}
                                        </FormGroup>


                                        <h7>{this.props.t("Level")}</h7>
                                        <FormGroup>
                                            <Select
                                                className="react-select primary"
                                                classNamePrefix="react-select"
                                                name="level"
                                                value={this.state.sinlgeProjectStructureLevelAPI}
                                                onChange={value =>
                                                    this.setState({sinlgeProjectStructureLevelAPI: value})
                                                }
                                                options={this.state.projectStructureLevelAPI}
                                                placeholder={this.props.t("Choose Level")}
                                            />
                                            {this.state.errors.level && (
                                                <div
                                                    className="invalid-feedback d-block">{this.state.errors.level}</div>
                                            )}
                                        </FormGroup>

                                        <h7>{this.props.t("Elevation")}</h7>
                                        <FormGroup>
                                            <Select
                                                className="react-select primary"
                                                classNamePrefix="react-select"
                                                name="elevation"
                                                value={this.state.sinlgeProjectStructureElevationAPI}
                                                onChange={value =>
                                                    this.setState({sinlgeProjectStructureElevationAPI: value})
                                                }
                                                options={this.state.projectStructureElevationAPI}
                                                placeholder={this.props.t("Choose Elevation")}
                                            />
                                            {this.state.errors.elevation && (
                                                <div
                                                    className="invalid-feedback d-block">{this.state.errors.elevation}</div>
                                            )}
                                        </FormGroup>


                                        <h7>{this.props.t("System")}</h7>
                                        <FormGroup>
                                            <Select
                                                className="react-select primary"
                                                classNamePrefix="react-select"
                                                name="system"
                                                value={this.state.sinlgeProjectStructureSystemAPI}
                                                onChange={value =>
                                                    this.setState({sinlgeProjectStructureSystemAPI: value})
                                                }
                                                options={this.state.projectStructureSystemAPI}
                                                placeholder={this.props.t("Choose System")}
                                            />
                                            {this.state.errors.system && (
                                                <div
                                                    className="invalid-feedback d-block">{this.state.errors.system}</div>
                                            )}
                                        </FormGroup>
                                    </CardBody>
                                </Card>

                                <Card>
                                    <CardBody>
                                        <h7>{this.props.t("Task")}</h7>
                                        <FormGroup>
                                            <Select
                                                className="react-select primary"
                                                classNamePrefix="react-select"
                                                name="taskName"
                                                value={this.state.singleTaskSelect}
                                                onChange={value =>
                                                    this.setState({singleTaskSelect: value})
                                                }
                                                options={this.state.taskFromAPI}
                                                placeholder={this.props.t("Choose Task")}
                                            />
                                            {this.state.errors.task && (
                                                <div className="invalid-feedback d-block">{this.state.errors.task}</div>
                                            )}
                                        </FormGroup>

                                        <h7>{this.props.t("Material")}</h7>
                                        <FormGroup>
                                            <Select
                                                className="react-select primary"
                                                classNamePrefix="react-select"
                                                name="materialName"
                                                value={this.state.singleMaterialSelect}
                                                onChange={value =>
                                                    this.setState({singleMaterialSelect: value})
                                                }
                                                options={this.state.matertialFromAPI}
                                                placeholder={this.props.t("Choose Material")}
                                            />
                                            {this.state.errors.material && (
                                                <div
                                                    className="invalid-feedback d-block">{this.state.errors.material}</div>
                                            )}
                                        </FormGroup>

                                        <h7>{this.props.t("Comments")}</h7>
                                        <FormGroup>
                                            <Input
                                                type="textarea"
                                                name="comment"
                                                onChange={(e) => this.setState({comment: `${e.target.value}`})}
                                                value={this.state.comment}
                                                // defaultValue={this.state.comment}

                                            />
                                        </FormGroup>
                                        {/* <h7>Duration (Hours)</h7>
                  <FormGroup>
                    <Select
                      className="react-select primary"
                      classNamePrefix="react-select"
                      name="selectedDuration"
                      value={this.state.selectedDuration}
                      onChange={value =>
                        this.setState({ selectedDuration: value })
                      }
                      options={this.state.hoursWorkedFromAPI}
                      placeholder="Select duration of work."
                    />
                  </FormGroup> */}
                                    </CardBody>
                                </Card>
                            </CardGroup>
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col>
                            <CardGroup>
                                <Card><CardBody>
                                    <UpdateTimesheetLogTableContractedWork
                                        timesheetLogsAllData={this.state.timesheetLogsAllData}
                                        getTimesheetLogByDate={this.getTimesheetLogByDate}
                                        dateSelected={this.props.reportDate}
                                        clearChecks={this.state.clearChecks}
                                        timesheetLogDetail={this.state.timesheetLogDetail}
                                        contractedWorkRowData={this.props.contractedWorkRowData}
                                    />
                                </CardBody></Card>
                            </CardGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <Button color="info" type="submit">
                                {this.props.t("Submit")}
                            </Button>

                        </Col>
                    </Row>
                </Form>
            </>

        );
    }
}

const UpdateContractorWorksFormByEmployee = withTranslation()(connect(mapStateToProps, mapDispatchToProps)(UpdateConnectedContractorWorksFormByEmployee));
export default UpdateContractorWorksFormByEmployee;
