import SortableTree, { getFlatDataFromTree } from '@nosferatu500/react-sortable-tree';
import '@nosferatu500/react-sortable-tree/style.css';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import './PlanManagement.css';
import fetchTools from '../../../services/fetchTools.js';
import  {useHistory}  from 'react-router-dom'
import { sortBy } from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import Button from 'reactstrap/lib/Button';
import {Card} from 'reactstrap'
import BMSTheme from 'react-sortable-tree-theme-bms';
import {setScheduleItem} from '../../../actions/scheduleActions'
import CardBody from 'reactstrap/lib/CardBody';


// import {
//   requestHeaderInterceptor,
//   responseErrorInterceptor,
//   responseSuccessInterceptor,
// } from "./services/interceptors";
// import axios from "axios";

// const fetchTools = require("./services/fetchTools.js")
// const REQUEST_TIMEOUT = 60000;
// console.log("localStorage.jwtToken")
// console.log(localStorage.jwtToken)
// const headers = {
//   Accept: "application/json",
//   "Content-Type": "application/json;charset=UTF-8",
//   'Authorization': localStorage.jwtToken,
// };

// const fetchTools = axios.create({
//   baseURL: process.env.REACT_APP_API_URL,
//   timeout: REQUEST_TIMEOUT,
//   crossDomain: true,
//   headers,
// });

// fetchTools.interceptors.request.use(requestHeaderInterceptor, (error) =>
//   Promise.reject(error)
// );

// fetchTools.interceptors.response.use(
//   responseSuccessInterceptor,
//   responseErrorInterceptor
// );
var objectData = null;
export function PlanManagement({object}) {

  var targetScheduleVersion = null;

  if (object) {
    objectData = object;
  }

  if(objectData){
    targetScheduleVersion = objectData[0]?.scheduledItemId?.scheduleVersion
  }


        const alreadyLoaded = localStorage.getItem('alreadyLoaded');
        if (!alreadyLoaded) {
           localStorage.setItem('alreadyLoaded', true);
           window.location.reload();
        }

  const projectId = useSelector((state) => state.allOtherReducers.projectId)
  const scheduleItemSelected = useSelector((state) => state.schedule.scheduleItem)
  const dispatch = useDispatch()

  const MAX_LEVEL = 5;

  const refTagParrentId = useRef();

  const [tagSystems, setTagSystems] = useState([]);
  const [tag, setTag] = useState(null);
  const [tagSystem, setTagSystem] = useState(null);
  const [treeData, setTreeData] = useState([]);

  const renderTags = useMemo(() => (list) => {
    console.log(list)
    const forest = (xs, build, isChild, root) =>
      sortBy(xs, ['order'])
        .filter((x) => isChild(root, x))
        .map((node) => build(node, (root) => forest(xs, build, isChild, root)))
        .map((v, i) => ({ ...v, title: v.title, key: v._id, id: v._id, expanded: true }));
        console.log(forest)

    const data = forest(
      list,
      ({ _id, parent_id, id, ...rest }, f) => ({
        _id,
        id,
        ...rest,
        children: f(_id),
      }),
      (_id, {parent_id=null}) =>  parent_id === _id,
      null
    );
    console.log(data)
    return data;
  }, [JSON.stringify(treeData)]);


  useEffect(() => {
    getTagSystems(projectId,targetScheduleVersion);

  }, []);

  const getTagSystems = async (projectId, scheduleVersion = null) => {
    try {
      // const response = await fetchTools.get(`api/projectscheduleitems/project/${projectId}`);
      // if(response.data){
      // // setTagSystems(response.data);
      getTags(projectId, scheduleVersion);
      console.log(treeData)
      // dispatch(setScheduleItem(null))
      // setTagSystem(response.data?.[0]?._id)
    // }
    } catch (error) {
      console.log('error', error);
    }
  }

  const getTags = async (projectId, scheduleVersion = null) => {
    try {
      setTreeData([]);

        let url = `api/projectscheduleitems/${projectId}`;

        if (scheduleVersion) {
            url += `?scheduleVersion=${scheduleVersion}`;
        }
        const response = await fetchTools.get(url);
      console.log(response)
      setTreeData(renderTags(response.data))
      console.log('gottreedata')
      console.log(response.data)
    } catch (error) {
      console.log('error', error);
    }
  }

  const addTag = async (_tagName) => {
    try {
      if (!_tagName) {
        return alert('Tag Name is empty');
      }
      const body = {
        name: _tagName,
        tag_system_id: tagSystem,
        parent_id: refTagParrentId.current
      }
      await fetchTools.post('api/tag', body);
      getTags(tagSystem);
      refTagParrentId.current = null;
      setTag(null);
    } catch (error) {
      alert(error?.data?.data);
    }
  }

  const editTag = async (_tagName, curentNode) => {
    try {
      if (!_tagName) {
        return alert('Tag Name is empty');
      }
      const body = {
        name: _tagName,
        id: curentNode._id
      }
      await fetchTools.post('api/tag', body);
      getTags(tagSystem);
      refTagParrentId.current = null;
      setTag(null);
    } catch (error) {
      alert(error?.data?.data);
    }
  }


  const showAddTag = (node) => {
    refTagParrentId.current = node._id;
    setTag(node);
    let text;
    let person = prompt("Tag name:", "");
    if (person == null || person === "") {
      text = "";
    } else {
      text = person;
    }
    addTag(text);
  }

  const showEditTag = (node) => {
    refTagParrentId.current = node._id;
    setTag(node);
    let text;
    let person = prompt("Tag name:", node.title);
    if (person == null || person === "") {
      text = "";
    } else {
      text = person;
    }
    editTag(text, node);
  }

  const removeTag = async () => {
    try {
      const response = await fetchTools.delete(`api/tag/${refTagParrentId.current}`);
      getTags(tagSystem);
      refTagParrentId.current = null;
      setTag(null);
    } catch (error) {
      alert(error?.data?.message);
    }
  }

  const showRemoveTag = (node) => {
    refTagParrentId.current = node._id;
    setTag(node);
    if (window.confirm("Do you want remove this tag")) {
      removeTag()
    }
  }

  const onUpdate = async (moveData, flatData) => {
    try {
      const curentNode = moveData.node;
      const body = {
        parent_id: moveData.nextParentNode?._id || null,
        orders: flatData
      }
      await fetchTools.put(`api/tag/${curentNode._id}`, body);
      getTags(tagSystem);
    } catch (error) {

    }
  }


  const onMoveNode = (moveData) => {
    console.log('onMoveNode', moveData);
    setTreeData(moveData.treeData);

    const flatData = getFlatDataFromTree({
      treeData: moveData.treeData,
      getNodeKey: ({ node }) => node.id, // This ensures your "id" properties are exported in the path
      ignoreCollapsed: false, // Makes sure you traverse every node in the tree, not just the visible ones
    }).map(({ node, path }) => ({
      id: node.id,
      name: node.name,

      // The last entry in the path is this node's key
      // The second to last entry (accessed here) is the parent node's key
      parent_id: path.length > 1 ? path[path.length - 2] : null,
    })).filter(node => node.parent_id === (moveData.nextParentNode?._id || null)).map((node, i) => ({
      _id: node.id,
      order: i
    }));


    onUpdate(moveData, flatData)
  }

  const onChange = (_treeData) => {
    console.log('onChange', _treeData);
    setTreeData(_treeData)
  }

  const alertNodeInfo = ({
    node,
    path,
    treeIndex,
    lowerSiblingCounts: _lowerSiblingCounts,
}) => {
    const objectString = Object.keys(node)
        .map(k => (k === 'children' ? 'children: Array' : `${k}: '${node[k]}'`))
        .join(',\n   ');
        dispatch(setScheduleItem(node))
          alert(scheduleItemSelected)


      //  alert(useSelector(state.scheduleItemSelected))
    // alert( // eslint-disable-line no-alert
    //   node.title
        // 'Info passed to the button generator:\n\n' +
        // `node: {\n   ${objectString}\n},\n` +
        // `path: [${path.join(', ')}],\n` +
        // `treeIndex: ${treeIndex}`
    // );
    // dispatch(setScheduleItem(objectString))
    // alert(useSelector(scheduleItemSelected))
};


  return (

    <div className="PlanManagement">
      {/* <div>Latest Version - Fri 4 Nov 2022 10.28</div>
       <Card><CardBody><div>
        {!scheduleItemSelected?'Selected Item':scheduleItemSelected}
      </div></CardBody></Card> */}
      <SortableTree
        treeData={treeData}
        canDrag={false}
        onMoveNode={onMoveNode}
        onChange={onChange}
        theme={BMSTheme}
        get
        generateNodeProps={rowInfo => ({

      //  onClick:(event) =>  console.log(rowInfo)
                  onClick:(event) => {
                  dispatch(setScheduleItem(rowInfo?.node))
                  }

                })
              }

      />

    </div>
  );
}

export default PlanManagement;
