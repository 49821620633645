import React from "react";
import axios from "axios"
import {Dock} from "react-dock"

// reactstrap components
import {
    Card,
    CardBody,
    Row,
    Col, Button,
} from "reactstrap";
import SimpleWeatherConditionsTable from "views/pages/Weather/SimpleWeatherConditionsTable";
import SimpleWeatherForm from "views/pages/Weather/SimpleWeatherForm";
import {connect} from "react-redux";
import {userCant, userCan} from "../../../services/Authorization/authService";
import {withTranslation} from 'react-i18next';

function mapStateToProps(state) {
    return {
        project: state.allOtherReducers.project,
        projectId: state.allOtherReducers.projectId,
        projectData: state.allOtherReducers.projectData,
        reportDate: state.allOtherReducers.reportDate,
        user: state.auth.user
    };
}


class ConnectedSimpleWeather extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            simpleWeatherTableData: [],
            showSimpleWeatherForm: false
        }

        this.getSimpleWeatherTableData = this.getSimpleWeatherTableData.bind(this)
        this.toggleSimpleWeatherForm = this.toggleSimpleWeatherForm.bind(this)

        this.setEditingSimpleWeatherRecord = this.setEditingSimpleWeatherRecord.bind(this);
    }

    getSimpleWeatherTableData() {

        axios.get(process.env.REACT_APP_API_URL + "/api/weather/simple/" + this.props.reportDate + "/project/" + this.props.projectId, {
            headers: {
                'Authorization': localStorage.jwtToken
            }
        })
            .then(res => {
                this.setState({
                    simpleWeatherTableData: res.data
                }, () => {
                    console.log(this.state.simpleWeatherTableData)
                });
                return res.data
            }).catch((error) => {
            console.log(error)
            ;
        })
    }

    componentWillMount() {
        if (userCant(this.props.user, 'weather.index') &&
            userCant(this.props.user, 'weather.manage')) {
            return this.props.history.push('/admin/instructions')
        }
    }

    componentDidMount() {
        this.getSimpleWeatherTableData()
    }

    componentDidUpdate(prevProps) {
        if (this.props.projectId !== prevProps.projectId) {
            this.getSimpleWeatherTableData()
        }
        if (this.props.reportDate !== prevProps.reportDate) {
            this.getSimpleWeatherTableData()
        }
    }


    toggleSimpleWeatherForm() {
        if (this.state.showSimpleWeatherForm) {
            this.setState({
                showSimpleWeatherForm: false,
                updateSimpleWeatherRecord: null
            })
        } else {
            this.setState({
                showSimpleWeatherForm: true
            })
        }
    }

    setEditingSimpleWeatherRecord(record) {
        this.setState({
            showSimpleWeatherForm: !!record,
            updateSimpleWeatherRecord: record
        })
    }

    render() {

        return (
            <>
                <div className="content">
                    <Row>
                        <Col md="12" tag="h3">{this.props.t('Weather Conditions')}</Col>
                    </Row>
                    <Dock
                        position='right'
                        isVisible={this.state.showSimpleWeatherForm}
                        duration={300}
                        size={window.innerWidth < 600 ? 1 : (window.innerWidth < 900 ? 0.5 : 0.3)}
                    >
                        {this.state.showSimpleWeatherForm && <SimpleWeatherForm
                            toggleSimpleWeatherForm={this.toggleSimpleWeatherForm}
                            getSimpleWeatherTableData={this.getSimpleWeatherTableData}
                            updateSimpleWeatherRecord={this.state.updateSimpleWeatherRecord}
                        />}
                    </Dock>
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    {userCan(this.props.user, 'weather.manage') && <Button color="info" type="submit"
                                                                                           onClick={() => this.setState({showSimpleWeatherForm: !this.state.showSimpleWeatherForm})}>
                                        {this.props.t('Add Simple weather')}
                                    </Button>}
                                    <SimpleWeatherConditionsTable
                                        simpleWeatherTableData={this.state.simpleWeatherTableData}
                                        getSimpleWeatherTableData={this.getSimpleWeatherTableData}
                                        setEditingSimpleWeatherRecord={this.setEditingSimpleWeatherRecord}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

const SimpleWeather = withTranslation()(connect(mapStateToProps, null)(ConnectedSimpleWeather))

export default SimpleWeather;
