
import React from "react";

import {
  Card,
  CardBody,
  CardFooter,
  Row,
  Col,
} from "reactstrap";
import { withTranslation } from 'react-i18next';



class Instructions extends React.Component {


  render() {
    return (
      <>
        <div className="content">
          <Row>
            <Col>
              <Card className="card-stats">
                <CardBody>
                  <h2>{this.props.t('Prerequisites - Configuration')}</h2>
                  <ol>
                    <li>{this.props.t('prerequisites_configuration.point_1')}</li>
                    <li>{this.props.t('prerequisites_configuration.point_2')}</li>
                    <li>{this.props.t('prerequisites_configuration.point_3')}</li>
                    <li>{this.props.t('prerequisites_configuration.point_4')}</li>
                  </ol>
                  <br />
                  <h2>{this.props.t('Site Diary')}</h2>
                  <ol>
                    <li>{this.props.t('site_diary_configuration.point_1')} </li>
                    <li>{this.props.t('site_diary_configuration.point_2')} </li>
                    <li>{this.props.t('site_diary_configuration.point_3')} </li>
                    <li>{this.props.t('site_diary_configuration.point_4')} </li>

                    - {this.props.t('site_diary_configuration.contracted_work_configuration.point_1')}<br />
                    - {this.props.t('site_diary_configuration.contracted_work_configuration.point_2')}<br />
                    - {this.props.t('site_diary_configuration.contracted_work_configuration.point_3')}<br />

                    <li>{this.props.t('site_diary_configuration.point_5')} </li>
                    <li>{this.props.t('site_diary_configuration.point_6')} </li>
                    <li>{this.props.t('site_diary_configuration.point_8')} </li>
                    <li>{this.props.t('site_diary_configuration.point_9')} </li>
                    <li>{this.props.t('site_diary_configuration.point_10')} </li>
                  </ol>
                </CardBody>
                <CardFooter>

                </CardFooter>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default withTranslation()(Instructions);
