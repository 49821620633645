import React from "react";
import {connect} from 'react-redux';

import ReactLanguageSelect from "react-languages-select";

import enFlag from "react-languages-select/flags/gb.svg";
import deFlag from 'react-languages-select/flags/de.svg'


//import css module
import 'react-languages-select/css/react-languages-select.css';
//OR import sass module
import 'react-languages-select/scss/react-languages-select.scss';
import {withTranslation} from "react-i18next";

class LanguageSelector extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            languages: {
                'en': <span><img style={{'maxWidth': '28px', 'marginRight': '5px'}} src={enFlag}/>EN</span>,
                'de': <span><img style={{'maxWidth': '28px', 'marginRight': '5px'}} src={deFlag}/>DE</span>,
            }
        }
    }

    render() {
        return (
           this.props.client?.config?.supportMultipleLanguages && <ReactLanguageSelect
                // showSelectedLabel={false}
                languages={Object.keys(this.state.languages)}
                defaultLanguage={this.props.i18n.language === 'en-US' ? 'en' : this.props.i18n.language}
                selectedSize={13}
                className={this.props.customClass || ''}
                optionsSize={13}
                customLabels={this.state.languages}
                onSelect={(code) => {
                    this.props.i18n.changeLanguage(code)
                }}
            />
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {};
}

const mapStateToProps = state => ({
    client: state.auth.client
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(LanguageSelector));
