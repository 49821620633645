
import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col
} from "reactstrap";
import { connect } from "react-redux";
import ReactTable from "react-table";
import { withTranslation } from 'react-i18next';

function mapStateToProps(state) {
  return {
    project: state.allOtherReducers.project,
    projectId: state.allOtherReducers.projectId,
    projectData: state.allOtherReducers.projectData,
    reportDate: state.allOtherReducers.reportDate
  };
}

class ConnectedSiteReportDelays extends React.Component {

  constructor(props) {
    super(props);
    this.state = {

    }
  }




  componentWillReceiveProps({ reportDelays }) {
    this.setState({ ...this.state, reportDelays }, () => this.state)
  }

  render() {
    const NullComponent = () => null;
    return (
      <div>
        <>
        <h4 className="pl-3 card-title">{`${this.props.t('Delays')} / ${this.props.t('Occurences')} / ${this.props.t('Breaks')}`}</h4>
          <ReactTable
            NoDataComponent={NullComponent}
            data={this.props.reportDelays}
            columns={[
              {
                Header: this.props.t("Delay Id"),
                accessor: "_id",
                show: false
              },
              {
                Header: this.props.t("Description"),
                accessor: "delayTypeDescription"
              },
              {
                Header: this.props.t("Activity"),
                accessor: "scheduledItemId.title"
              }, {
                Header: this.props.t("Comment"),
                accessor: "comment"
              }
            ]}
            loadingText=""
            defaultPageSize={1}
            pageSize={this.props.reportDelays.length}
            showPaginationTop={false}
            showPaginationBottom={false}
            // style={{
            //   height: "200px" // This will force the table body to overflow and scroll, since there is not enough room
            // }}
            // getTrProps={this.onRowClick}
            // getTrProps={onRowClick}
            /*
              You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
            */
            className="-striped -highlight primary-pagination"
          />

        </>
      </div>
    );
  }
}

const SiteReportDelays = withTranslation()(connect(mapStateToProps, null)(ConnectedSiteReportDelays));

export default SiteReportDelays;
