import axios from 'axios';
import setAuthToken from '../utils/setAuthToken';
import jwt_decode from 'jwt-decode';

import { GET_ERRORS, SET_CURRENT_USER } from './types';

// Register User
export const registerUser = (userData, history) => dispatch => {
  return axios
    .post('/user/register', userData)
    .then(res => history.push('/admin/users'))
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};


// Set logged in user
export const setCurrentUser = (decoded) => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded
  };
};

export const changePassword = (userData, history) => dispatch => {

    return axios.post(process.env.REACT_APP_API_URL + "/api/security/user/setpassword/", {
     userData
    },{headers: {
      'Authorization': localStorage.jwtToken}
    })
    .then(res => history.push('/admin/users'))
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};
