import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { loginUser } from '../../actions/authActions';
import {getProjects, getClient, setRolesList} from '../../actions/index'
import TextFieldGroup from '../components/common/TextFieldGroup';
import { Link } from "react-router-dom";

import {
  Card,
  CardBody,
  Container,
  Col,
  Row,

} from "reactstrap";

class Login extends Component {
  constructor() {
    super();
    this.state = {
      email: '',
      password: '',
      errors: {}
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      // this.props.history.push('/admin/instructions');
      this.props.history.push('/app/initialisation')
    }
    document.body.classList.toggle("login-page");
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.auth.isAuthenticated) {
      // this.props.history.push('/admin/instructions');
      this.props.history.push('/app/initialisation')
      this.props.getProjects()
      this.props.getClient()
      this.props.setRolesList()
    }

    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }
  componentWillUnmount() {
    document.body.classList.toggle("login-page");
  }

  onSubmit(e) {
    e.preventDefault();

    const userData = {
      email: this.state.email,
      password: this.state.password
    };

    this.props.loginUser(userData);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }



  render() {
    const { errors } = this.state;

    return (
      <div>
      <Container>
          <Row>
            <Col className="ml-auto mr-auto" lg="4" md="6">
    {/* <p>{process.env.REACT_APP_GIT_SHA}</p> */}
    <div>v1.7.4</div>
              <form onSubmit={this.onSubmit}>
              <Card className="card-login">
                  {/* <CardHeader>
                    <CardHeader>
                      <h3 className="header text-center">Login</h3>
                    </CardHeader>
                  </CardHeader> */}
                  <CardBody>
                <TextFieldGroup
                  placeholder="Email Address"
                  name="email"
                  type="email"
                  value={this.state.email}
                  onChange={this.onChange}
                  error={errors.email}
                />

                <TextFieldGroup
                  placeholder="Password"
                  name="password"
                  type="password"
                  value={this.state.password}
                  onChange={this.onChange}
                  error={errors.password}
                />
                <input type="submit" className="btn btn-info btn-block mt-4" value="Login"/>

                    <Link to={`/auth/forgot-password`}>Forgot Password?</Link>

                </CardBody>
            </Card>
              </form>
           </Col>
           </Row>
           <Row >
           {/* <Col className="ml-auto mr-auto text-center" lg="8" md="6">

           <h1 >
             <div color="white">
              Worksite Site Manager
              </div>
              </h1>

              </Col> */}
           </Row>
           </Container>
           <div
          className="full-page-background"
          style={{
            backgroundImage: `url(${require("assets/img/bg/crane-sky.jpg")?.default})`
          }}
        />
           </div>
    );
  }
}

Login.propTypes = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  getProjects: PropTypes.func.isRequired,
  getClient:PropTypes.func.isRequired,
  setRolesList:PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(mapStateToProps, { loginUser,getProjects, getClient,setRolesList })(Login);
