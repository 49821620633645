import axios from 'axios';
import setAuthToken from '../utils/setAuthToken';
import jwt_decode from 'jwt-decode';

import {GET_ERRORS, SET_CURRENT_USER, UPDATE_CURRENT_USER,SET_CLIENT_OBJECT} from './types';
import {SET_RESPONSE} from "../constants/action-types";

// Register User
export const registerUser = (userData, history, successCallback = null, catchCallback = null) => dispatch => {
 return axios
    .post('/user/register', userData)
    .then(res => {
        if (successCallback) {
            successCallback(res)
        }
        history.push('/admin/users')
    }).catch(err => {
         if (catchCallback) {
             catchCallback(err)
         } else {
             dispatch({
                 type: GET_ERRORS,
                 payload: err.response.data
             });
         }
         }
     );
};

// Login - Get User Token
export const loginUser = userData => dispatch => {
  axios
    .post('/user/login', userData)
    .then(res => {
      // Save to localStorage
        const {token, userData} = res.data;
      // Set token to ls
      localStorage.setItem('jwtToken', token);
      // Set token to Auth header
      setAuthToken(token);

      // Set current user
      dispatch(setCurrentUser(userData));
    })
    .catch(err =>
          dispatch({
            type: GET_ERRORS,
            payload: err.response.data
          })
        );
};

// Set logged in user
export const setCurrentUser = (decoded) => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded
  };
};

export const updateCurrentUser = user => {
  return {
    type: UPDATE_CURRENT_USER,
    payload: user
  };
};

export const setClientObject = client => {
    return {
        type: SET_CLIENT_OBJECT,
        payload: client
    }
}

// Log user out
export const logoutUser = (history) => dispatch => {
  // Remove token from localStorage
  localStorage.removeItem('jwtToken');
  // Remove auth header for future requests
  setAuthToken(false);
  // Set current user to {} which will set isAuthenticated to false
  dispatch(setCurrentUser({}))
  history.push('/auth/login')
};


export const changePassword = (userData, history) => dispatch => {
  axios
    .post('/user/setpassword', userData)
    .then(res => history.push('/admin/users'))
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

export const resetPasswordRequest = (userData) => dispatch => {
    dispatch({
        type: GET_ERRORS,
        payload: {}
    })
    axios
        .post('/user/auth/web/requestResetPassword', userData)
        .then(res => {
            dispatch({
                type: SET_RESPONSE,
                payload: res.data
            })
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        });
};

export const resetPassword = (userData) => dispatch => {
    dispatch({
        type: GET_ERRORS,
        payload: {}
    })
    axios
        .post('/user/auth/web/resetPassword', userData)
        .then(res => {
            dispatch({
                type: SET_RESPONSE,
                payload: res.data
            })
        })
        .catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        );
};

export const updateProfile = (userData, history) => dispatch => {
    axios
        .post('/api/security/user/update-profile', userData, {
            headers: {
                'Authorization': localStorage.jwtToken
            }
        })
        .then(res => {
            // Save to localStorage
            const {token, userData} = res.data;
            // Set token to ls
            localStorage.setItem('jwtToken', token);
            // Set token to Auth header
            setAuthToken(token);

            // Set current user
            dispatch(setCurrentUser(userData));
        })
        .catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        );
};
