import React from "react";
import _ from "lodash"
import axios from "axios"
import moment from "moment"
import AdditionalWorkFormByEmployee from "./AdditionalWorkFormByEmployee"
import UpdateAdditionalWorkFormByEmployee from "./UpdateAdditionalWorkFormByEmployee"
import AddMaterialsTable from "views/tables/AddMaterialsTable"
import AddTasksTable from "views/tables/AddTasksTable"
import {connect} from "react-redux";
import {getProjects} from "../../../actions/index";
import {Dock} from "react-dock"
import additionalWorkhelper from '../../../utils/additionalWork_helper.js'
import {setScheduleItem} from '../../../actions/scheduleActions'
import {withTranslation} from 'react-i18next';

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col,
    FormGroup,
    Button
} from "reactstrap";
import AdditionalWorkTable from "./AdditionalWorkTable";
import AddInstructionForm from "./AddInstructionForm"
import InstructionTable from "./InstructionTable"
import UpdateInstructionForm from "./UpdateInstructionForm"
import {userCant, userCan} from "../../../services/Authorization/authService";


function mapStateToProps(state) {
    return {
        project: state.allOtherReducers.project,
        projectId: state.allOtherReducers.projectId,
        projectData: state.allOtherReducers.projectData,
        reportDate: state.allOtherReducers.reportDate,
        user: state.auth.user
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getProjects: () => dispatch(getProjects()),
        setScheduleItem: scheduleItem => dispatch(setScheduleItem(scheduleItem))
    };
}


class ConnectedAdditionalWork extends React.Component {
    constructor(props) {
        super(props);
        this.getAdditionalWorkTable = this.getAdditionalWorkTable.bind(this)
        this.getContractorSummaryByDate = this.getContractorSummaryByDate.bind(this)
        this.getSomeMaterials = this.getSomeMaterials.bind(this)
        this.getSomeTasks = this.getSomeTasks.bind(this)
        this.getSomeSiteLocations = this.getSomeSiteLocations.bind(this)
        this.getSomeProjectstructures = this.getSomeProjectstructures.bind(this)
        this.isDockVisibleControl = this.isDockVisibleControl.bind(this)
        this.isUpdateDockVisibleControl = this.isUpdateDockVisibleControl.bind(this)
        this.isInstructionDockVisibleControl = this.isInstructionDockVisibleControl.bind(this)
        this.isUpdateInstructionDockVisibleControl = this.isUpdateInstructionDockVisibleControl.bind(this)
        this.setShouldUpdateWorkingHours = this.setShouldUpdateWorkingHours.bind(this)
        this.setShowUpdateWorkingHoursToFalse = this.setShowUpdateWorkingHoursToFalse.bind(this)
        this.getSomeInstructions = this.getSomeInstructions.bind(this)
        this.refreshMaterial = this.refreshMaterial.bind(this)
        this.refreshTask = this.refreshTask.bind(this)
        this.state = {
            dynamicTreeData: [],
            datafetch: [],
            rowsToCopy: [],
            selected: -1,
            employees: [],
            dateList: [],
            today: moment(new Date()),
            dateSelected: '',
            selectedRowList: [],
            resetState: false,
            scheduleClickedItem: '',
            contractorSummaryTableData: [],
            materialData: [],
            taskData: [],
            siteLocationData: [],
            hoursWorked: [],
            additionalWorkTableData: [],
            project: "",
            projectId: "",
            projectStructureTableData: [],
            formattedTableStructure: [],
            isDockVisible: false,
            isUpdateDockVisible: false,
            additionalWorkRowData: [],
            shouldUpdateWorkingHours: false,
            instructionData: [],
            instructionRowData: [],
            isInstructionDockVisible: false,
            isUpdateInstructionDockVisible: false

        };

        this.getAdditionalWorkTable()
        // this.getProjectSchedule();
        this.getContractorSummaryByDate()
        this.getSomeProjectstructures()
        this.getSomeInstructions();
    }

    componentWillMount() {
        if (userCant(this.props.user, 'additionalwork.index') &&
            userCant(this.props.user, 'additionalwork.manage')) {
            return this.props.history.push('/admin/instructions')
        }
    }

    componentDidMount() {

        var startDate = new Date();
        startDate.setDate(startDate.getDate() - 7);

        var endDate = new Date();
        endDate.setDate(endDate.getDate());
        var getDateArray = function (start, end) {
            var arr = [];
            var dt = new Date(start);
            while (dt <= end) {
                arr.push(new Date(dt));
                dt.setDate(dt.getDate() + 1);
            }

            return arr;


        }

        var dateArr = getDateArray(startDate, endDate);
        this.setState({dateList: dateArr}, () => this.state.dateList)
        // Refactor and move to a helper API

        this.getSomeMaterials();
        this.getSomeTasks();
        this.getSomeSiteLocations();
        this.getSomeInstructions();
        var hoursWorkedInDay = this.getTimeStops('00:00', '23:59', 30)
        this.setState({hoursWorked: hoursWorkedInDay}, () => console.log(this.state.hoursWorked))
    }


    componentDidUpdate(prevProps) {

        if (this.props.projectId !== prevProps.projectId) {
            this.getAdditionalWorkTable();

            this.getContractorSummaryByDate();
            this.getSomeTasks();
            // this.getProjectSchedule();
            this.getSomeProjectstructures();
            this.getSomeInstructions();
        } else if (_.isEmpty(this.props.projectData[0])) {
        }

        if (this.props.reportDate !== prevProps.reportDate) {
            this.getAdditionalWorkTable();
            this.getContractorSummaryByDate();
            this.getSomeTasks();
            this.getSomeProjectstructures();
            this.getSomeInstructions();
        }

    }


    getSomeMaterials() {
        axios.get(process.env.REACT_APP_API_URL + "/api/material/project/" + this.props.projectId, {
            headers: {
                'Authorization': localStorage.jwtToken
            }
        })
            .then(res => {
                this.setState({
                    materialData: res.data
                }, () => this.state.materialData);
                return res.data
            }).catch((error) => {
            ;
        })
    }

    getSomeTasks() {
        axios.get(process.env.REACT_APP_API_URL + "/api/task/project/" + this.props.projectId, {
            headers: {
                'Authorization': localStorage.jwtToken
            }
        })
            .then(res => {
                this.setState({
                    taskData: res.data
                }, () => console.log(this.state.taskData));
                return res.data
            }).catch((error) => {
            ;
        })
    }

    getSomeSiteLocations() {
        axios.get(process.env.REACT_APP_API_URL + "/api/sitelocation/" + this.props.projectId, {
            headers: {
                'Authorization': localStorage.jwtToken
            }
        })
            .then(res => {
                this.setState({
                    siteLocationData: res.data
                }, () => console.log(this.state.siteLocationData));
                return res.data
            }).catch((error) => {
            ;
        })
    }

    async getProjectSchedule() {
        const treeData = await axios.get(process.env.REACT_APP_API_URL + '/api/projectschedule/project/' + this.props.projectId, {
            headers: {
                'Authorization': localStorage.jwtToken
            }
        })
            .catch((error) => {
                console.log(error);
            })

        this.setState({dynamicTreeData: treeData.data}, () => {
        })

    }

    getTimeStops(start, end, step) {
        var startTime = moment(start, 'HH:mm');
        var endTime = moment(end, 'HH:mm');

        if (endTime.isBefore(startTime)) {
            endTime.add(1, 'day');
        }

        var timeStops = [];

        while (startTime <= endTime) {
            timeStops.push(new moment(startTime));
            startTime.add(step, 'minutes');
        }
        return timeStops;
    }

    async getAdditionalWorkTable(filtersQueryString = '') {

        if (userCant(this.props.user, 'additionalwork.index') &&
            userCant(this.props.user, 'additionalwork.manage')) {
            return this.props.history.push('/admin/instructions')
        }

        let url = process.env.REACT_APP_API_URL + "/api/additionalwork/" + this.props.reportDate + "/project/" + this.props.projectId;

        if (filtersQueryString) {
            url += '?' + filtersQueryString
        }

        var formattedData = []
        const additionalWorkTableResponse = await axios.get(url, {
            headers: {
                'Authorization': localStorage.jwtToken
            }
        }).catch((error) => {
            ;
            console.log(error)
        })

        this.setState({
            additionalWorkTableData: additionalWorkTableResponse.data
        }, () => {
            console.log(this.state.additionalWorkTableData)
            formattedData = additionalWorkhelper.formatAdditionalWorkDataForTables(this.state.additionalWorkTableData)


            const tableStructure = formattedData.map(x => {
                    return {
                        '_id': x[2],
                        'projectId': x[3],
                        'scheduledItem': x[5],
                        'instruction': x[7],
                        'additionalWorkDetail': x[0],
                        'timesheetLogDetail': x[6],
                        'duration': x[1]
                    }
                }
            )
            this.setState({
                formattedTableStructure: tableStructure
            }, () => {
            });
        });


        return additionalWorkTableResponse.data
    }


    getContractorSummaryByDate() {

        if (userCant(this.props.user, 'additionalwork.index') &&
            userCant(this.props.user, 'additionalwork.manage')) {
            return this.props.history.push('/admin/instructions')
        }

        axios({
            method: 'get',
            url: process.env.REACT_APP_API_URL + '/api/summary/companiesintimesheetlogs/' + this.props.reportDate + '/projects/' + this.props.projectId,
            headers: {
                'Authorization': localStorage.jwtToken
            }
        })
            .then(res => {
                this.setState({contractorSummaryTableData: res.data}, () => {
                })
                return res.data
            })
            .catch((error) => {

                console.log(error);
            })
    }

    getSomeProjectstructures() {
        if (userCant(this.props.user, 'additionalwork.manage')) {
            return
        }

        axios.get(process.env.REACT_APP_API_URL + "/api/projectstructure/project/" + this.props.projectId, {
            headers: {
                'Authorization': localStorage.jwtToken
            }
        })
            .then(res => {
                if (Array.isArray(res.data)) {
                    this.setState({

                        projectStructureTableData: res.data
                    }, () => {
                        console.log(res.data)
                    });
                }
                return []
            }).catch((error) => {
            ;
        })
    }

    isDockVisibleControl() {
        this.setState({isDockVisible: !this.state.isDockVisible}, () => {
        })
    }

    isInstructionDockVisibleControl() {
        this.setState({isInstructionDockVisible: !this.state.isInstructionDockVisible})
    }

    isUpdateInstructionDockVisibleControl(row_id, tableObject) {
        this.setState({isUpdateInstructionDockVisible: !this.state.isUpdateInstructionDockVisible})
        if (tableObject != undefined) {
            var result = tableObject.filter(obj => {
                return obj._id === row_id
            })
            this.setState({instructionRowData: result}, () => {
                console.log(result)
            })
        }
    }

    isUpdateDockVisibleControl(row_id, tableObject) {

        let _additionalWorkRowData = [];

        if (tableObject !== undefined) {
            _additionalWorkRowData = tableObject.filter(obj => `${obj._id}` === `${row_id}`)
        }

        this.setState({
            additionalWorkRowData: _additionalWorkRowData,
            isUpdateDockVisible: !this.state.isUpdateDockVisible
        })

        this.props.setScheduleItem(null)
    }

    setShouldUpdateWorkingHours() {
        this.setState({shouldUpdateWorkingHours: true}, () => {
        })
    }

    setShowUpdateWorkingHoursToFalse() {
        this.setState({shouldUpdateWorkingHours: false}, () => {
        })
    }

    refreshTask(task) {
        let tasksData = this.state.taskData.map(row => {
            return row._id === task._id ? task : row
        })

        this.setState({
            taskData: tasksData
        })
    }


    refreshMaterial(material) {
        let materialData = this.state.materialData.map(row => {
            return row._id === material._id ? material : row
        })

        this.setState({
            materialData: materialData
        })
    }

    getSomeInstructions() {
        if (userCant(this.props.user, 'additionalwork.index')) {
            return
        }

        axios.get(process.env.REACT_APP_API_URL + "/api/instruction/project/" + this.props.projectId, {
            headers: {
                'Authorization': localStorage.jwtToken
            }
        })
            .then(res => {
                this.setState({
                    instructionData: res.data
                });

                return res.data
            }).catch((error) => {
            console.log(error)
            ;
        })
    }

    render() {
        if (userCant(this.props.user, 'additionalwork.index') &&
            userCant(this.props.user, 'additionalwork.manage')) {
            return this.props.history.push('/admin/instructions')
        }

        ++this.childKey;
        return (
            <>
                <div className="content">
                    <Row>
                        <Col md="12" tag="h3">{this.props.t("Additional Work")}</Col>
                    </Row>
                    {userCan(this.props.user, 'additionalwork.manage') &&
                    <Row>
                        <Col>
                            <FormGroup>
                                <Button color="info" type="submit" onClick={() => {
                                    this.isDockVisibleControl()
                                }}>
                                    {this.props.t("Add Additional Work")}
                                </Button>
                            </FormGroup>
                        </Col>
                    </Row>
                    }


                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <CardTitle
                                        tag="h4">{this.props.t("Additional Work Logged for Project")}: {this.props.project}</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <AdditionalWorkTable
                                        isUpdateDockVisibleControl={this.isUpdateDockVisibleControl}
                                        formattedTableStructure={this.state.formattedTableStructure}
                                        additionalWorkTableData={this.state.additionalWorkTableData}
                                        getAdditionalWorkTable={this.getAdditionalWorkTable}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    {this.state.isDockVisible && <Dock
                        position='right'
                        isVisible={this.state.isDockVisible}
                        duration={300}
                        size={window.innerWidth < 600 ? 1 : 0.865}
                    >
                        <Card>
                            <CardHeader>
                                <CardTitle tag="h4">
                                    <Button color="info" className={'mr-2'} onClick={() => {
                                        this.isDockVisibleControl()
                                        this.setShowUpdateWorkingHoursToFalse()
                                    }}
                                    >
                                        {this.props.t("Close")}
                                    </Button>

                                    {this.props.t("Add Additional Work")}

                                </CardTitle>
                                <label>({this.props.t("Time and Attendance must be completed first")})</label>
                            </CardHeader>
                            <CardBody>
                                <AdditionalWorkFormByEmployee
                                    setShowUpdateWorkingHoursToFalse={this.setShowUpdateWorkingHoursToFalse}
                                    setShouldUpdateWorkingHours={this.setShouldUpdateWorkingHours}
                                    shouldUpdateWorkingHours={this.state.shouldUpdateWorkingHours}
                                    additionalWorkRowData={this.state.AdditionalWorkRowData}
                                    isDockVisibleControl={this.isDockVisibleControl}
                                    getAdditionalWorkTable={this.getAdditionalWorkTable}
                                    scheduleClickedItem={this.state.scheduleClickedItem}
                                    contractorSummaryTableData={this.state.contractorSummaryTableData}
                                    materialTableData={this.state.materialData}
                                    taskTableData={this.state.taskData}
                                    instructionData={this.state.instructionData}
                                    siteLocationTableData={this.state.siteLocationData}
                                    hoursWorked={this.state.hoursWorked}
                                    projectStructureTableData={this.state.projectStructureTableData}
                                    isDockVisible={this.state.isDockVisible}
                                />
                            </CardBody>
                        </Card>
                    </Dock>}
                    {this.state.isUpdateDockVisible && <Dock
                        position='right'
                        isVisible={this.state.isUpdateDockVisible}
                        duration={300}
                        size={window.innerWidth < 600 ? 1 : 0.865}
                    >
                        <Card>
                            <CardHeader>
                                <CardTitle tag="h4">
                                    <Button color="info" className={'mr-2'} onClick={() => {
                                        this.isUpdateDockVisibleControl()
                                    }}>
                                        {this.props.t("Close")}
                                    </Button>

                                    {this.props.t("Update Additional Work")}

                                </CardTitle>
                                <label>({this.props.t("Time and Attendance must be completed first")})</label>
                            </CardHeader>
                            <CardBody>
                                <UpdateAdditionalWorkFormByEmployee
                                    isDockVisible={this.state.isUpdateDockVisible}
                                    setShouldUpdateWorkingHours={this.setShouldUpdateWorkingHours}
                                    isUpdateDockVisibleControl={this.isUpdateDockVisibleControl}
                                    additionalWorkRowData={this.state.additionalWorkRowData}
                                    getAdditionalWorkTable={this.getAdditionalWorkTable}
                                    scheduleClickedItem={this.state.scheduleClickedItem}
                                    contractorSummaryTableData={this.state.contractorSummaryTableData}
                                    materialTableData={this.state.materialData}
                                    taskTableData={this.state.taskData}
                                    instructionData={this.state.instructionData}
                                    siteLocationTableData={this.state.siteLocationData}
                                    hoursWorked={this.state.hoursWorked}
                                    projectStructureTableData={this.state.projectStructureTableData}
                                    formattedTableStructure={this.state.formattedTableStructure}
                                />
                            </CardBody>
                        </Card>
                    </Dock>}
                    <Dock
                        position='right'
                        isVisible={this.state.isInstructionDockVisible}
                        duration={300}
                        size={window.innerWidth < 600 ? 1 : (window.innerWidth < 900 ? 0.5 : 0.3)}
                    >
                        <AddInstructionForm
                            instructionData={this.state.instructionData}
                            getSomeInstructions={this.getSomeInstructions}
                            isInstructionDockVisibleControl={this.isInstructionDockVisibleControl}
                        />
                    </Dock>
                    <Dock
                        position='right'
                        isVisible={this.state.isUpdateInstructionDockVisible}
                        duration={300}
                        size={window.innerWidth < 600 ? 1 : 0.865}
                    >
                        <UpdateInstructionForm
                            instructionRowData={this.state.instructionRowData}
                            getSomeInstructions={this.getSomeInstructions}
                            isUpdateInstructionDockVisibleControl={this.isUpdateInstructionDockVisibleControl}
                        />
                    </Dock>
                    {userCan(this.props.user, 'can_manage_instructions') && <Row>
                        <Col>
                            <FormGroup>
                                <Button color="info" type="submit" onClick={() => {
                                    this.isInstructionDockVisibleControl()
                                }}>
                                    {this.props.t("Add Instruction")}
                                </Button>
                            </FormGroup>
                        </Col>
                    </Row>}
                    <Row>
                        <Col>
                            <InstructionTable
                                instructionData={this.state.instructionData}
                                getSomeInstructions={this.getSomeInstructions}
                                isUpdateInstructionDockVisibleControl={this.isUpdateInstructionDockVisibleControl}

                            />
                        </Col>
                    </Row>
                    <Row>

                        <Col md="6">
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h4">{this.props.t("Add Materials")}</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <AddMaterialsTable
                                        materialTableData={this.state.materialData}
                                        getSomeMaterials={this.getSomeMaterials}
                                        refreshMaterial={this.refreshMaterial}
                                    />
                                </CardBody>
                            </Card>
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h4">{this.props.t("Add Tasks")}</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <AddTasksTable
                                        taskData={this.state.taskData}
                                        getSomeTasks={this.getSomeTasks}
                                        refreshTask={this.refreshTask}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

const AdditionalWork = withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ConnectedAdditionalWork));

export default AdditionalWork;
