import React from "react";
// react component for creating dynamic tables
import ReactTable, { ReactTableDefaults } from "react-table";
import axios from "axios";
import { connect } from "react-redux";
import InputMask from 'react-input-mask';
import _, {isNull, uniqueId} from "lodash";
import helper from '../../../utils/general_helper.js'
import {Dock} from "react-dock"
import { withTranslation } from 'react-i18next';



// reactstrap components
import {
  Button,
  Input,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Form,
  UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, FormGroup
} from "reactstrap";
import moment from "moment";
import {userCan, userCant} from "../../../services/Authorization/authService";

function mapStateToProps(state) {
  return {
    project: state.allOtherReducers.project,
    projectId: state.allOtherReducers.projectId,
    projectData: state.allOtherReducers.projectData,
    reportDate: state.allOtherReducers.reportDate,
    user: state.auth.user
   };
}

class ConnectedTimesheetLogTable extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      editContent: false,
      editingRow: -1,
      isDirty: false,
      bulk: '',
      toastMessage: '',
      bulkState: props.t('Bulk Edit'),
      editRecordData: {
        showForm: false,
        record: null,
        updateData: {
          timeIn: '',
          timeOut: '',
          breaks: '',
          regularTime: '',
          overtime: '',
          extraTime: '',
          allocated: ''
        }
      }
    }

    this.renderEditable = this.renderEditable.bind(this);
    this.renderEditableTime = this.renderEditableTime.bind(this);
    this.renderEditableDate = this.renderEditableDate.bind(this);
    this.saveRowData = this.saveRowData.bind(this);
    this.toggleEditable = this.toggleEditable.bind(this);
    this.renderActiveButtons = this.renderActiveButtons.bind(this)
    this.toggleBulkEdit = this.toggleBulkEdit.bind(this)
    this.saveTable = this.saveTable.bind(this)
    this.openEditForm = this.openEditForm.bind(this)

  }



  componentDidMount(props) {

  }


  deleteTimesheetLogByTimesheetLogId(timesheetLogId) {
    axios.delete(process.env.REACT_APP_API_URL + '/api/timesheetlog/' + timesheetLogId, {
      headers: {
        'Authorization': localStorage.jwtToken
      }
    }).then(res => {
      this.props.getTimesheetLogByDate(this.props.reportDate)
      return res
    }).then(res => {
      this.props.getEmployeesCompressedListByDate(this.props.reportDate).catch((error) => {
        ;
      })
    })
      .catch((error) => {
        ;
      })
  }


  componentWillReceiveProps({ timesheetLogs, dateSelected }) {
    this.setState({ ...this.state, timesheetLogs, dateSelected }, () => this.state)
  }

  handleInputChange = (cellInfo,event, then = null) => {
    let data = [...this.props.timesheetLogs];
    data[cellInfo.index][cellInfo.column.id] = event.target.value;

    this.setState({data}, () => {
      if (typeof then === 'function') {
        then()
      }
    });
  };

  handleInputDateTimeChange = (cellInfo,event,then) => {

    let data = [...this.props.timesheetLogs],
        dateTimeValue =  moment.utc(data[cellInfo.index][cellInfo.column.id]),
        value = event.target.value;

    if (event.target.name.includes('date')) {

      let date =  moment.utc(value).format('YYYY-MM-DD').split('-');
      dateTimeValue.set('year', date[0])
      dateTimeValue.set('month', date[1] - 1)
      dateTimeValue.set('date', date[2])
    } else {

      let hoursAndMinutes = value.split(':');

      dateTimeValue.set('hour', hoursAndMinutes[0])
      dateTimeValue.set('minute', hoursAndMinutes[1])
    }

    data[cellInfo.index][cellInfo.column.id] = dateTimeValue.utc();

    this.setState({ data },()=>{
      if (typeof then === 'function') {
        then()
      }
    });
  };

  renderEditableDate(cellInfo) {
    let v =  moment.utc(this.props.timesheetLogs[cellInfo.index][cellInfo.column.id])
        .format('YYYY-MM-DD')

    if (!this.state.bulk) {
      return (<div> {v}</div>)
    } else {
      return (
          <Input
              name="date"
              type="date"
              className={'inputs'}
              tabIndex={uniqueId()}
              onBlur={this.onBlur.bind(this,cellInfo,this.handleInputDateTimeChange)}
              onKeyDown={this.handleKeyEnter.bind(this, cellInfo, this.handleInputDateTimeChange)}
              onChange={this.handleInputDateTimeChange.bind(null, cellInfo)}
              value={v}
          />

      )
    }

  }
  renderEditableTime(cellInfo) {
    if (this.state.bulk) {
      let v = moment.utc(this.props.timesheetLogs[cellInfo.index][cellInfo.column.id])
          .format('HH:mm')

      return (
        <Input
          name="time"
          type="time"
          className={'inputs'}
          tabIndex={uniqueId()}
          onBlur={this.onBlur.bind(this,cellInfo,this.handleInputDateTimeChange)}
          onKeyDown={this.handleKeyEnter.bind(this, cellInfo, this.handleInputDateTimeChange)}
          onChange={this.handleInputDateTimeChange.bind(null, cellInfo)}
          value={v}
        />

      )
    }else{
      return  (<div >
        { moment.utc(cellInfo.value).format('h:mm a')}
      </div>)
    }
  }

  saveRowData(e) {
    e.preventDefault();
    let cellInfo = this.state.editRecordData.record

    this.setState({ editContent: false }, () => { })

    let data = this.state.editRecordData.updateData;
    data.timeOut = moment.utc(data.timeOut)
    data.timeIn = moment.utc(data.timeIn)

    axios.put(process.env.REACT_APP_API_URL + '/api/timesheetlog/', {
      _id: cellInfo.original._id,
      ...data
    }, {
      headers: {
        'Authorization': localStorage.jwtToken
      }
    }).then(res => {
      this.props.getTimesheetLogByDate(this.props.reportDate)
     this.closeEditForm()
      return res.data
    }).catch((error) => {
      if (error?.response?.data) {
        this.setState({
          toastMessage: error.response.data
        })
        setTimeout(() => {
          this.setState({
            toastMessage: ''
          })
        }, 2500)
      }
    })
  }


  closeEditForm(){
    this.setState({
      editRecordData: {
        showForm: false,
        record: null,
        updateData: {
          timeIn: '',
          timeOut: '',
          breaks: '',
          regularTime: '',
          overtime: '',
          extraTime: '',
          allocated: ''
        }
      }
    })
  }

  openEditForm(record) {

    this.setState({
      editRecordData: {
        title:`Edit ${record.original.username} Timesheet`,
        showForm: true,
        record: record,
        updateData: {
          timeIn: moment.utc(record.original.timeIn).format('YYYY-MM-DD HH:mm'),
          timeOut: moment.utc(record.original.timeOut).format('YYYY-MM-DD HH:mm'),
          breaks: record.original.breaks,
          regularTime: record.original.regularTime,
          overtime:   record.original.overtime,
          extraTime: record.original.extraTime,
          allocated: record.original.allocated
        }
      }
    })
  }
  toggleBulkEdit() {
    const { bulk } = this.state;
    if (!bulk) {
      this.setState({
        bulk: true,
        bulkState: this.props.t('Finish')
      });
    }
    else {
      this.setState({
        bulk: false,
        bulkState: this.props.t('Bulk Edit')
      })
    }
  }


  toggleEditable(index) {
    const { isDirty } = this.state;
    if (!isDirty) {
      this.setState({
        editingRow: index,
        isDirty: true
      });
    }
    else {
      this.setState({
        editingRow: -1,
        isDirty: false
      })
    }
  }

  renderActiveButtons(row) {
    if (userCant(this.props.user, 'timesheets.manage')) {
      return '';
    }

    if (this.state.bulk) {
      return (<div />)
    }
    else {
      return (<div>
        <Row>
          <Col>
            <Button
                onClick={() => this.openEditForm(row)}
              size="xs"
              className="btn-icon btn-neutral btn btn-info btn-sm"
            ><i className="fa fa-edit"
              /></Button>


            <Button
              color="danger"
              size="sm"
              className="btn-icon btn-neutral btn btn-info btn-sm"
              onClick={() => {
                this.deleteTimesheetLogByTimesheetLogId(row.original._id)
              }
              }
            ><i
                className="fa fa-remove"
              />
            </Button>

          </Col>
        </Row>
      </div>)
    }

  }

  toMinutes(duration) {
    var ary = duration.split(':');
    return +ary[0] * 60 + ary[1];
  }

  sum(a, b) {
    return a + b;
  }
  renderHoursMinutes() {
    //
  }

  /**
   *
   * @param cellInfo
   * @param onChangeHandler
   * @param e
   */
  handleKeyEnter(cellInfo, onChangeHandler, e) {
    onChangeHandler.call(this, cellInfo, e, () => {
      if (['Enter', 'Tab'].includes(e.key)) {
        this.updateRecord(this.state.data[cellInfo.index]).then(r => {
          if (e.key !== 'Tab') {
            this.focusNextInput(e.target.tabIndex)
          }
        })
      }
    })
  }

  /**
   *
   * @param cellInfo
   * @param onChangeHandler
   * @param e
   */
  onBlur(cellInfo, onChangeHandler, e) {
    onChangeHandler.call(this, cellInfo, e, () => {
      this.updateRecord(this.state.data[cellInfo.index])
    })
  }

  /**
   *
   * @param cellInfo
   * @returns {Promise<AxiosResponse<any>>}
   */
  updateRecord(cellInfo){

   return axios.put(process.env.REACT_APP_API_URL + '/api/timesheetlog/', {
      ...cellInfo
    }, {
      headers: {
        'Authorization': localStorage.jwtToken
      }
    }).then(res => {
      this.props.getTimesheetLogByDate(this.props.reportDate)
    }).catch((error) => {
      if (error?.response?.data) {
        this.setState({
          toastMessage: error.response.data
        })
        setTimeout(() => {
          this.setState({
            toastMessage: ''
          })
        }, 2500)
      }
    })

  }

  /**
   *
   * @param currentIndex
   */
  focusNextInput(currentIndex) {

    let inputList = document.querySelectorAll('.inputs');

    for (let i = 0; i < inputList.length; i++) {

      if (inputList[i].tabIndex != (currentIndex + 1)) {
        continue;
      }

      inputList[i].focus();
      break;
    }
  }

  renderEditable(cellInfo) {
    if (this.state.bulk) {
      return (
          <Input
              contentEditable
              suppressContentEditableWarning
              tag={InputMask}
              type={'Input'}
              maskChar=""
              mask="999"
              className={'inputs'}
              tabIndex={uniqueId()}
              onBlur={this.onBlur.bind(this, cellInfo, this.handleInputChange)}
              onKeyDown={this.handleKeyEnter.bind(this,cellInfo,this.handleInputChange)}
              onChange={this.handleInputChange.bind(null, cellInfo)}
              value={this.props.timesheetLogs[cellInfo.index][cellInfo.column.id]}
          />

      )
    }
    else {
      const cellValue = this.props.timesheetLogs[cellInfo.index][cellInfo.column.id];

      const { editingRow } = this.state;
      return (
        <div>
          {editingRow === cellInfo.index ? (
            <Input
              maskChar=""
              mask="999"
              placeholder=''
              onChange={this.handleInputChange.bind(null, cellInfo)}
              value={cellValue}
              tag={InputMask}
            />
          ) : (<div >
            {(cellInfo.value == undefined) ? 0 : cellInfo.value}
          </div>)}
        </div>
      );
    }
  }

  /**
   *
   * @param cancelBulkUpdate
   */
  saveTable(cancelBulkUpdate = true) {
    axios.post(process.env.REACT_APP_API_URL + '/api/timesheetlog/bulkupdate', {
      timesheetdata: this.state.data
    }, {
      headers: {
        'Authorization': localStorage.jwtToken
      }
    })
      .then(res => {
        this.props.getTimesheetLogByDate(this.props.reportDate)
        if (cancelBulkUpdate) {

          this.setState({
            bulk: false,
            bulkState: this.props.t("Bulk Edit")
          })

        }

        return res.data
      })
      .catch((error) => {
        if (error?.response?.data) {
          this.setState({
            toastMessage: error.response.data
          })
          setTimeout(() => {
            this.setState({
              toastMessage: ''
            })
          }, 2500)
        }

      })
  }

  render() {
    var sumRegularTime = 0

    function toMinutes(duration) {
      if (duration!=null){
      var ary = duration.split('.');
      return +Number(ary[0]) * 60 + Number(ary[1]);}
      else{
        return ''
      }

    }

    function sum(a, b) {
      return a + b;
    }

    var regularTimeSummary = helper.convertMinutesToHoursMinutes(this.props.timesheetLogs.map((d) => toMinutes(d.regularTime)).reduce(sum, 0))
    var totalRegularTime = regularTimeSummary
    var sumOvertime = 0
    var overTimeSummary = _.sum((_.map(this.props.timesheetLogs, d => d.overtime)))
    var extraTimeSummary = _.sum((_.map(this.props.timesheetLogs, d => d.extraTime)))
    var allocatedSummary = _.sum((_.map(this.props.timesheetLogs, d => d.allocatedTime)))
    var allocatedSummaryHm = helper.hoursMinutesAllocateTimeFormatted(allocatedSummary)

    const columnDefaults = {
      ...ReactTableDefaults.column,

    }
    let { bulk } = this.state;

    const renderSaveButton = () => {
      if (bulk) {
        return (<Button color="info" onClick={() => this.saveTable()}>{this.props.t('Save')}</Button>);
      }
    }


    return (
      <>
        <div className="content">

          <Dock
              position='right'
              isVisible={this.state.editRecordData.showForm}
              duration={300}
          >
            <Card>
              <CardHeader>
                <CardTitle tag="h4">{this.state.editRecordData.title}</CardTitle>
              </CardHeader>
              <CardBody>


                <Row>
                  <Col>
                    <Form className="form-horizontal" onSubmit={(e) => this.saveRowData(e)}>

                      <Row>
                        <Col>

                          <FormGroup>
                            <label>{this.props.t('Time In')}</label>

                            <Input
                                placeholder={this.props.t("type here")}
                                name="time"
                                type="datetime-local"
                                onChange={e => this.setState({
                                  editRecordData: {
                                    ...this.state.editRecordData,
                                    updateData: {
                                      ...this.state.editRecordData.updateData,
                                      timeIn: e.target.value
                                    }
                                  }
                                })}
                                value={this.state.editRecordData.updateData.timeIn}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col>

                          <FormGroup>
                            <label>{this.props.t("Time Out")}</label>

                            <Input
                                placeholder={this.props.t("type here")}
                                name="timeout"
                                type="datetime-local"
                                onChange={e => this.setState({
                                  editRecordData: {
                                    ...this.state.editRecordData,
                                    updateData: {
                                      ...this.state.editRecordData.updateData,
                                      timeOut: e.target.value
                                    }
                                  }
                                })}
                                value={this.state.editRecordData.updateData.timeOut}
                            />
                          </FormGroup>
                        </Col>
                      </Row>


                      <Row>
                        <Col>

                          <FormGroup>
                            <label>{this.props.t("Breaks(Mins)")}</label>

                            <Input
                                maskChar=""
                                mask="999"
                                placeholder=''
                                tag={InputMask}
                                onChange={e => this.setState({
                                  editRecordData: {
                                    ...this.state.editRecordData,
                                    updateData: {
                                      ...this.state.editRecordData.updateData,
                                      breaks: e.target.value
                                    }
                                  }
                                })}
                                value={this.state.editRecordData.updateData.breaks}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>

                          <FormGroup>
                            <label>Overtime(Mins)</label>

                            <Input
                                maskChar=""
                                mask="999"
                                placeholder=''
                                tag={InputMask}
                                onChange={e => this.setState({
                                  editRecordData: {
                                    ...this.state.editRecordData,
                                    updateData: {
                                      ...this.state.editRecordData.updateData,
                                      overtime: e.target.value
                                    }
                                  }
                                })}
                                value={this.state.editRecordData.updateData.overtime}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col>

                          <FormGroup>
                            <label>Extra Time(Mins)</label>

                            <Input
                                maskChar=""
                                mask="999"
                                placeholder=''
                                tag={InputMask}
                                onChange={e => this.setState({
                                  editRecordData: {
                                    ...this.state.editRecordData,
                                    updateData: {
                                      ...this.state.editRecordData.updateData,
                                      extraTime: e.target.value
                                    }
                                  }
                                })}
                                value={this.state.editRecordData.updateData.extraTime}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <Button color="info" type="submit">
                            {this.props.t("Submit")}
                          </Button>
                          <Button color="info" onClick={() => {
                            this.closeEditForm()
                          }}
                          >
                            {this.props.t("Close")}
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                </Row>


              </CardBody>
            </Card>
          </Dock>

          <ReactTable
            NoDataComponent={() => null}
            data={this.props.timesheetLogs}
            columns={[
              {
                Header: "TimesheetLogs Id",
                accessor: "_id",
                show: false
              },
              {
                Header: this.props.t("Name"),
                accessor: "username",
                style: {
                  fontSize: 10,
                },
                Footer: <span>{this.props.timesheetLogs.length} {this.props.t("Selected")}</span>,
              },
              {
                Header: this.props.t("Date In"),
                accessor: "timeIn",
                Cell: this.renderEditableDate,
                style: {
                  fontSize: 10,
                },
              },
              {
                Header: this.props.t("Time In"),
                accessor: "timeIn",
                Cell: this.renderEditableTime,
                style: {
                  fontSize: 10,
                },
              },
              {
                Header: this.props.t("Date Out"),
                accessor: "timeOut",
                Cell: this.renderEditableDate,
                style: {
                  fontSize: 10,
                },
              },
              {
                Header: this.props.t("Time out"),
                accessor: "timeOut",
                Cell: this.renderEditableTime,
                style: {
                  fontSize: 10,
                },
              },
              {
                Header: this.props.t("Breaks (Mins)"),
                accessor: "breaks",
                Cell: this.renderEditable,
                style: {
                  fontSize: 10,
                },
              },
              {
                Header: this.props.t("Regular Time"),
                accessor: "regularTime",
                Cell: ((value) => helper.hoursMinutesFormatted(value)),
                style: {
                  fontSize: 10,
                },
                Footer: <span>{totalRegularTime}</span>,
              },
              {
                Header: this.props.t("Overtime (Mins)"),
                accessor: "overtime",
                Cell: this.renderEditable,
                style: {
                  fontSize: 10,
                },
                Footer: <span>{overTimeSummary}</span>
              },
              {
                Header: this.props.t("Extra Time (Mins)"),
                accessor: "extraTime",
                Cell: this.renderEditable,
                style: {
                  fontSize: 10,
                },
                Footer: <span>{extraTimeSummary}</span>
              },
              {
                Header: this.props.t("Allocated Time"),
                accessor: "allocatedTime",
                Cell: ((value) => helper.hoursMinutesAllocateTimeFormatted(value.value)),
                style: {
                  fontSize: 10,
                },
                Footer: <span>{allocatedSummaryHm}</span>
              },
              {
                Header: this.props.t("Actions"),
                id: "activateToggle",
                width: 150,
                style: {
                  fontSize: 10,
                },
                Cell: ((value) => (this.renderActiveButtons(value)))
              }
            ]
            }

            columnDefaults
            defaultPageSize={1}
            minRows={1}
            pageSize={this.props.timesheetLogs.length}
            showPaginationTop={false}
            showPaginationBottom={false}
            className="-striped -highlight primary-pagination"
          />
          <Row>

            {userCan(this.props.user, 'timesheets.manage')  &&  <Col md="5">
              <Button color="info" onClick={() => this.toggleBulkEdit()}>{this.state.bulkState}</Button>
              {/*{renderSaveButton()}*/}
            </Col> }
          </Row>

          {this.state.toastMessage && (
              <div className="photo__options__toast bg-danger">
               <i className={'fa fa-times'}></i>
                <span>{this.state.toastMessage}</span>
              </div>
          )}
        </div>
      </>
    );
  }
}

const TimesheetLogTable = withTranslation()(connect(mapStateToProps, null)(ConnectedTimesheetLogTable));

export default TimesheetLogTable;
