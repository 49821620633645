import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
import axios from "axios";
import InputMask from 'react-input-mask';
import Plan from "views/pages/PlanManagement/PlanManagement"
import {setScheduleItem} from '../../../actions/scheduleActions'

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Input,
  Form,
  Row,
  Col,
  FormGroup,
  Modal
} from "reactstrap";
import { Dock } from "react-dock"

import { connect } from "react-redux";
import classnames from "classnames";
import {withTranslation} from "react-i18next";
import {userCant, userCan} from "../../../services/Authorization/authService";

function mapStateToProps(state) {
  return {
    project: state.allOtherReducers.project,
    projectId: state.allOtherReducers.projectId,
    projectData: state.allOtherReducers.projectData,
    reportDate: state.allOtherReducers.reportDate,
    scheduleItem: state.schedule.scheduleItem,
    scheduleItemId: state.schedule.scheduleItemId,
    user: state.auth.user
  };
}

function mapDispatchToProps(dispatch) {
  return {

    setScheduleItem: scheduleItem  => dispatch(setScheduleItem(scheduleItem)),
  }
}

class ConnectedAddDelayTypeTable extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      data: [{ "_id": "zx3", "material_name": "LED Detector" }, { "_id": "zx4", "material_name": "Hand Torch" }],
      delayTypeData: '',
      delayTypeDescriptionToAddValue: '',
      delayTypeActivityToAddValue: '',
      editContent: false,
      editingRow: -1,
      isDirty: false,
      modalClassic: false,
      dynamicTreeData: [],
      scheduleClickedItem: '',
      description: '',
      scheduleClickedItemId: '',
      showForm:false,
      errors:{},
      editRecord: null
    }
    this.formSubmitSuccess = this.formSubmitSuccess.bind(this)
    this.renderEditable = this.renderEditable.bind(this);
    this.saveRowData = this.saveRowData.bind(this);
    this.toggleEditable = this.toggleEditable.bind(this);
    this.renderActiveButtons = this.renderActiveButtons.bind(this);
    this.deleteDelayTypeById = this.deleteDelayTypeById.bind(this)
    this.getScheduleClickedOnText = this.getScheduleClickedOnText.bind(this)
    this.getProjectSchedule();
    this.props.setScheduleItem(null)
  }



  componentDidMount(props) {
   this.props.setScheduleItem(null)
  }

  componentDidUpdate(props){
   this.props.setScheduleItem(null)
  }


  deleteDelayTypeById(delayTypeId) {
    axios.delete(process.env.REACT_APP_API_URL + '/api/delaytype/' + delayTypeId, {
      headers: {
        'Authorization': localStorage.jwtToken
      }
    }).then(res => {
      this.props.getSomeDelayTypes()
      return res
    })
      .catch((error) => {
        ;
      })
  }


  formSubmitSuccess(e) {
    e.preventDefault();

    this.setState({
      errors: {}
    })

    if (this.state.editRecord) {
      return this.saveRowData(e)
    }

    const data = new FormData(e.target);
    axios.post(process.env.REACT_APP_API_URL + "/api/delaytype/", {
      delayTypeDescription: data.get("delayTypeDescription"),
      scheduledItemId: data.get('scheduledItemId'),
      delayTypeActivity: data.get("scheduledItem"),
      projectId: this.props.projectId
    }, {
      headers: {
        'Authorization': localStorage.jwtToken
      }
    }).then(
      res => {
        axios.get(process.env.REACT_APP_API_URL + "/api/delaytype/project/" + this.props.projectId, {
          headers: {
            'Authorization': localStorage.jwtToken
          }
        })
          .then(res => {
            this.setState({
              data: res.data
            }, () => this.props.getSomeDelayTypes());
            this.clearForm()
            return res.data
          })
      })
      .catch((error) => {
        this.setState({
          errors: error.response.data
        })
      })

  }

  clearForm(then = null) {
    this.setState({
      singleDelayValueSelect: null,
      commentInput: '',
      description: '',
      scheduleClickedItemId: '',
      showForm: false,
      errors: {},
      editRecord: null
    }, () =>{
      this.props.setScheduleItem(null)

      if (then) {
        then();
      }
    })
  }



  handleDelayTypeToAddDescription = (e) => {

    this.setState({ delayTypeDescriptionToAddValue: e.target.value }, () => this.state.delayTypeDescriptionToAddValue);
  }

  handleDelayTypeToAddActivity = (e) => {

    this.setState({ delayTypeActivityToAddValue: e.target.value }, () => this.state.delayTypeActivityToAddValue);
  }


  componentDidUpdate(prevProps) {
    if (this.props.projectId !== prevProps.projectId) {
      this.props.getSomeDelayTypes()
      this.getProjectSchedule()
      // this.props.updateDelayTypeTableData(this.state.data)
    }

  }

  componentWillReceiveProps({ timesheetLogs, dateSelected, delayTypeData, scheduleClickedItem }) {
    this.setState({ ...this.state, timesheetLogs, dateSelected, delayTypeData, scheduleClickedItem }, () => this.state)
  }

  handleInputChange = (cellInfo, event) => {
    let data = [...this.props.delayTypeData];
    data[cellInfo.index][cellInfo.column.id] = event.target.value;

    this.setState({ data });
  };

  renderEditable(cellInfo) {
    return (
        <div >
          {cellInfo.value}
        </div>
    );
  }
  saveRowData(e) {

    const data = new FormData(e.target);

    axios.put(process.env.REACT_APP_API_URL + '/api/delayType/', {
      _id: this.state.editRecord._id,
      delayTypeDescription: data.get("delayTypeDescription"),
      scheduledItemId: data.get('scheduledItemId'),
      delayTypeActivity: data.get("scheduledItem"),
    }, {
      headers: {
        'Authorization': localStorage.jwtToken
      }
    }).then(res => {

      this.clearForm( ()=>{
        this.props.refreshDelayType(res.data)
      } )
      return res.data
    })
      .catch((error) => {
        this.setState({
          errors: error.response.data
        })
      })
  }

  toggleEditable(record) {
      this.setState({
          editRecord: record,
          showForm: true,
          errors: {},
          description: record.delayTypeDescription
      }, () => {
          this.props.setScheduleItem(record.scheduledItemId)
      })
  }

  renderActiveButtons(row) {
    if (userCant(this.props.user, 'delaysandevents.manage')) {
      return '';
    }
    return (<div>
      <Button
        onClick={() => this.toggleEditable(row.original)}
        size="xs"
        className="btn-icon btn-neutral btn btn-info"
      ><i className="fa fa-edit"
        /></Button>
      <Button
        onClick={() => this.deleteDelayTypeById(row.original._id)}
        color="danger"
        size="sm"
        className="btn-icon btn-neutral btn btn-info"
      ><i
          className="fa fa-remove"
        /></Button>
    </div>)


  }

  //PROJECT SCHEDULE FUNCTIONS

  toggleModalClassic = () => {
    this.setState({
      modalClassic: !this.state.modalClassic,
    });

  };

  getProjectSchedule() {
    axios.get(process.env.REACT_APP_API_URL + '/api/projectschedule/project/' + this.props.projectId, {
      headers: {
        'Authorization': localStorage.jwtToken
      }
    })
      .then(res => {
        this.setState({ dynamicTreeData: res.data }, () => this.state.dynamicTreeData)
        // window.location.reload(true)
      })
      .catch((error) => {
        console.log(error);
      })
  }

  getScheduleClickedOnText = (gotText, gotId) => {
    this.setState({
      scheduleClickedItem: gotText,
      scheduleClickedItemId: gotId

    }, () => this.state.scheduleClickedItem)
  }
  //^^ PROJECT SCHEDULE FUNCTIONS ^^

  render() {
    const {errors} = this.state
    return (
      <>

        <Card>
          <CardHeader>
            <CardTitle tag="h4">{this.props.t('Add Delay Type')}</CardTitle>
          </CardHeader>
          <CardBody>

            <Dock
                position='right'
                isVisible={this.state.showForm}
                duration={300}
                size={window.innerWidth < 600?1:(window.innerWidth < 900?0.5:0.3)}
                zIndex={2000}
            >
              {this.state.showForm &&
              <Row>
                <Col>
                  <CardBody>
                    <h3>
                      {this.props.t(`${this.state.editRecord ? 'Edit' : 'Add'} Delay type`)}
                    </h3>
                  <Form className="form-horizontal" onSubmit={(e) => this.formSubmitSuccess(e)}>

                    <Row>
                      <Col>
                        <FormGroup>
                          <label>{this.props.t("Description")}</label>
                          <Input
                              type="text"
                              name="delayTypeDescription"
                              value={this.state.description}
                              className={classnames('form-control', {
                                'is-invalid': errors.description
                              })}
                              onChange={(e) => this.setState({description: `${e.target.value}`})}
                          />

                          {errors.description && (
                              <div className="invalid-feedback d-block">{errors.description}</div>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                     <Row>
                      <Col>
                        <label>{this.props.t('Scheduled Activity')}</label>
                        <FormGroup>
                          <Input type="text"
                                 name="scheduledItem"
                                 value={this.props.scheduleItem}
                                 className={classnames('form-control', {
                                   'is-invalid': errors.delayTypeActivity
                                 })}
                                 onClick={() => this.toggleModalClassic()}
                                 onChange={() => this.props.scheduleItem}
                          />
                          {errors.delayTypeActivity && (
                              <div className="invalid-feedback d-block">{errors.delayTypeActivity}</div>
                          )}
                          <Input type="hidden" name="scheduledItemId" value={this.props.scheduleItemId}></Input>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Button className='w-100' color="info" type="submit">
                          {this.props.t(`${this.state.editRecord ? 'Edit' : 'Add'} Delay type`)}
                        </Button>
                      </Col>

                      <Col>
                        <Button color="info"
                                className="w-100"
                                onClick={() => this.clearForm()}>{this.props.t("Cancel")}</Button>
                      </Col>
                    </Row>
                  </Form>
                  </CardBody>

                </Col>
              </Row>
              }
            </Dock>
            <Row>
              <Col>
                {userCan(this.props.user, 'delaysandevents.manage') && <Button color="info" onClick={() => this.setState({ showForm: !this.state.showForm })}>
                  {this.props.t('Add Delay Type')}
                </Button> }
<br/>
                <label>{this.props.t('Data')}</label>
                <ReactTable
                  data={this.props.delayTypeData}
                  columns={[
                    {
                      Header: this.props.t("Delay Type Id"),
                      accessor: "_id",
                      show: false
                    },
                    // {
                    //   Header: "Number",
                    //   accessor: "delayTypeNumber",
                    // },
                    {
                      Header: this.props.t("Description"),
                      accessor: "delayTypeDescription",
                      Cell: this.renderEditable
                    },
                    {
                      Header: this.props.t("Schedule Activity"),
                      accessor: "delayTypeActivity",
                      Cell: c => c.original?.scheduledItemId?.full_path || c.value
                    },
                    {
                      Header: this.props.t("Actions"),
                      accessor: "actions",
                      sortable: false,
                      filterable: false,
                      Cell: ((value) => (this.renderActiveButtons(value)))
                    },

                  ]}
                  defaultPageSize={1}
                  pageSize={this.props.delayTypeData.length}
                  showPaginationTop={false}
                  showPaginationBottom={false}
                  // style={{
                  //   height: "200px" // This will force the table body to overflow and scroll, since there is not enough room
                  // }}
                  // getTrProps={this.onRowClick}
                  // getTrProps={onRowClick}
                  /*
                    You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
                  */
                  className="-striped -highlight primary-pagination"
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Modal
          isOpen={this.state.modalClassic}
          toggle={this.toggleModalClassic}
          zIndex={10000}
        // onOpened={this.props.emptyProjectIdsFromoPermissionsList}
        // onClosed={this.setState({userId:''})}
        >
          <Row>
            <Col md="12">
              <Plan />
            </Col>
            {/* <Col md="12" > {

                      this.state.dynamicTreeData.map((element) => {

                        return <TreeNode
                          node={element}
                          prevName={element.title}
                          getScheduleClickedOnText={this.getScheduleClickedOnText}
                          thisTestProp={this.thisTestFunction}
                          thisTestProp={this.state.today} />
                      }
                      )
                    }


                    </Col> */}
          </Row>
          <Row>
            <Col md="5" />
            <Col>
              <Button
                className="btn-link"
                color="default"
                data-dismiss="modal"
                type="button"
                onClick={() => this.toggleModalClassic('')}
              >
                {this.props.t("Done")}
              </Button>
            </Col>
            <Col md="5" />
          </Row>
        </Modal>
      </>
    );
  }
}

const AddDelayTypeTable = withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ConnectedAddDelayTypeTable));

export default AddDelayTypeTable;
