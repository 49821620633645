import React from "react";
import {
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Col,
  Row,
  Container,
  Card,
  CardBody,
  CardImgOverlay,
  CardImg,
  Button,
  Input,
  Form,
  FormGroup,
  CardHeader
} from "reactstrap";

import { withTranslation } from 'react-i18next';

import ImageCarousel from "./ImageCarousel";
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import Label from "reactstrap/lib/Label";
import axios from "axios"
import ReactBSAlert from "react-bootstrap-sweetalert";
import {Dock} from "react-dock"

class ImageGalleryUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false, // state for the modal popup
      images: [], // images array we receive from the parent
      imagesToShow: 0, // limit
      currentIndex: 0, // used for the carousel
      alert: null,
      isDockVisible: false,
      editImage: {}
    }

    this.warningWithConfirmAndCancelMessage = this.warningWithConfirmAndCancelMessage.bind(this)
    this.hideAlert = this.hideAlert.bind(this)
    this.isDockVisibleControl = this.isDockVisibleControl.bind(this)
    this.formSubmitSuccess = this.formSubmitSuccess.bind(this)
  }

  isDockVisibleControl(image) {
    this.setState({
      isDockVisible: !this.state.isDockVisible,
      editImage: image
    }, () => { })
  }

  static getDerivedStateFromProps(props, state) {
    const { images, limit, type } = props;
    const imagesToShow = props.hasOwnProperty("limit") ? limit : 6;
    return { images, imagesToShow, type };
  }

  componentWillReceiveProps({ }) {

  }


  // for toggling the modal state
  toggleModal = () => {
    this.setState({
      isModalOpen: !this.state.isModalOpen
    })
  }

  // used to set the current index of the carousel
  showModalImage = imageId => {
    this.toggleModal();
    this.setState({
      currentIndex: imageId
    })
  }

  deleteImageById(imageId) {
    var apiEndPoint;
    if (this.props.type==='logo')
    {
       apiEndPoint='logo'
    }
    else if (this.props.type==='image')
    {
      apiEndPoint='image'
    }
    else{
      return apiEndPoint
    }

    axios.delete(process.env.REACT_APP_API_URL + '/api/' + apiEndPoint + '/' + imageId, {
      headers: {
        'Authorization': localStorage.jwtToken
      }
    }).then(res => {
      this.setState({
        alert: null
      }, () => { });
      this.props.getImages()
      return res
    })
      .catch((error) => {
        ;
      })


  }
  //Alerts
  warningWithConfirmAndCancelMessage = (image) => {

    this.setState({
      alert: (
        <ReactBSAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title={this.props.t("Are you sure?")}
          onConfirm={() => this.deleteImageById(image._id)}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          confirmBtnText={`${this.props.t('Yes')}, ${this.props.t('delete it')}`}
          cancelBtnText="Cancel"
          showCancel
        >
          {this.props.t('You will not be able to recover this image')}.
        </ReactBSAlert>
      )
    });
  };

  successDelete = () => {
    this.setState({
      alert: (
        <ReactBSAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title={this.props.t("Deleted!")}
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="info"
        >
          {this.props.t('The image has been deleted')}.
        </ReactBSAlert>
      )
    });
  };

  hideAlert = () => {
    this.setState({
      alert: null
    }, () => { });
  };

  formSubmitSuccess(e) {
    e.preventDefault();
    const data = new FormData(e.target);


      let url = process.env.REACT_APP_API_URL + "/api/image/";

      if (this.props.updateEndpoint) {
          url = this.props.updateEndpoint;
      }

      axios.put(url + this.state.editImage._id, {
      imageComment: data.get('imageComment'),
      title: data.get('title'),
      imageId: data.get('imageId')
    }, {
      headers: {
        'Authorization': localStorage.jwtToken
      }
    })
      .then(res => {
        this.isDockVisibleControl({})
        this.props.getImages()
        return res.data
      })
      .catch((error) => {
        console.log(error)
      })
  }

  render() {
    const { isModalOpen, images, imagesToShow, currentIndex } = this.state;
    const tempImagesArray = images.slice(0, imagesToShow); //temporary array
    const hasMore = images.length !== 0 ? images.length - imagesToShow : 0;
    // <img src={'data:image/jpg;base64', image} />;
    return <>
      {this.state.alert}

      <Row>
        {tempImagesArray.map((image, index) => (

          <Col
            md="3"
            className="my-2"
            key={index}
          // onClick={()=>alert("hellyeah")}
          // onClick={() => this.showModalImage(index)}
          >
            <Card >
              <CardBody>
                <CardHeader>
                <Row>
                  <Col>
                    <Row>
                      <Col>
                        <Label>{this.props.t('Description')}: {image.imageComment}</Label>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Label>{this.props.t('File Name')}: {image.name}</Label>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={{ align: 'right' }}>
                  <Button
                      color="info"
                      size="sm"
                      className="btn-icon btn-neutral btn btn-edit" onClick={() => { this.isDockVisibleControl(image) }}>
                      <i
                        className="fa fa-edit"
                      />
                    </Button>
                    <Button
                      color="danger"
                      size="sm"
                      className="btn-icon btn-neutral btn btn-danger"
                      onClick={() => {
                        this.warningWithConfirmAndCancelMessage(image)

                      }
                      }
                    ><i
                        className="fa fa-remove"
                      />
                    </Button>

                  </Col>
                </Row>
                </CardHeader>
                <CardImg src={image.imageLocation} />
                {hasMore !== 0 && index === imagesToShow - 1 ? (
                  <CardImgOverlay className="overlay">
                    <h2 className="mb-0">{hasMore}</h2>
                    <small> {this.props.t('More')} </small>
                  </CardImgOverlay>
                ) : null}

              </CardBody>
            </Card>

          </Col>
        ))}

        <Dock
          position='right'
          isVisible={this.state.isDockVisible}
          duration={300}
          size={window.innerWidth < 600?1:0.865}

        >
          <div style={{margin:25}}>
          <Form className="form-horizontal" onSubmit={(e) => this.formSubmitSuccess(e)}>
            <Row>
              <Col md={6}>
                <Card style={{ width: "50%" }}>
                  <CardBody>
                    <Row>
                      <Col>

                        <FormGroup>
                          <label>{this.props.t('Description')}</label>
                          <Input
                            type="text"
                            name="imageComment"
                            value={this.state.editImage.imageComment}
                            // defaultValue={this.state.description}
                            onChange={(e) => {
                              var newComment = `${e.target.value}`
                              this.setState(
                                prevState => (
                                  {
                                    editImage:
                                    {
                                      ...prevState.editImage,
                                      imageComment: newComment

                                    }
                                  }
                                )
                              )
                            }
                            }
                          />
                        </FormGroup>
                        <Input type="hidden" name="imageId" value={this.state.editImage._id} ></Input>

                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <label>{this.props.t('Title')}</label>
                          <Input
                              type="text"
                              name="title"
                              value={this.state.editImage.name}
                              // defaultValue={this.state.description}
                              onChange={(e) => {
                                var newTitle = `${e.target.value}`
                                this.setState(
                                    prevState => (
                                        {
                                          editImage:
                                              {
                                                ...prevState.editImage,
                                                name: newTitle

                                              }
                                        }
                                    )
                                )
                              }
                              }
                          />
                        </FormGroup>

                      </Col>
                    </Row>
                    <CardImg src={this.state.editImage.imageLocation} />
                  </CardBody>
                </Card>
              </Col>
              <Col md={6}></Col>



            </Row>

            <Row>
              <Col>
                <Button type='submit' color="info" >
                  {this.props.t('Save')}
                </Button>
                <Button color="info" onClick={() => {
                  this.isDockVisibleControl({})
                }}
                >
                  {this.props.t('Close')}
                </Button>

              </Col>
            </Row>
          </Form>
          </div>
        </Dock>
      </Row>

      <Modal
        className="modal-xl"
        isOpen={isModalOpen}
        toggle={this.toggleModal}
      >
        <ModalHeader>{this.props.t('Image Gallery')}</ModalHeader>
        <ModalBody>
          <Row>
            <Col md="12">
              <ImageCarousel images={images} currentIndex={currentIndex} />
            </Col>
          </Row>
        </ModalBody>
      </Modal>


    </>;
  }
}

export default withTranslation()(ImageGalleryUpload);
