export const HTTP_STATUS_OK = 200;
export const HTTP_STATUS_400 = 400;
export const HTTP_STATUS_401 = 401;
export const HTTP_STATUS_403 = 403;
export const HTTP_STATUS_404 = 404;
export const HTTP_STATUS_500 = 500;
export const HTTP_STATUS_503 = 503;

export const METHOD_GET = 'GET';
export const METHOD_POST = 'POST';
export const METHOD_PUT = 'PUT';
export const METHOD_DELETE = 'DELETE';