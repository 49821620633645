import SortableTree, {getFlatDataFromTree} from '@nosferatu500/react-sortable-tree';
import '@nosferatu500/react-sortable-tree/style.css';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import './TagManagement.css';
import fetchTools from './services/fetchTools.js';
import {useHistory} from 'react-router-dom'
import {sortBy} from 'lodash'
import {useSelector} from 'react-redux'
import Button from 'reactstrap/lib/Button';
import {Dock} from "react-dock"
import {Col, Form, FormGroup, Input, Row} from "reactstrap";
import close from "../../../assets/img/close.svg";
import Datetime from "react-datetime";
import date from "../../../assets/img/date.svg";
import closeTag from "../../../assets/img/close-tag.svg";
import {TagList} from "../PhotoGallery/TagList";
import {GET_ERRORS} from "../../../actions/types";
import TextFieldGroup from "../../components/common/TextFieldGroup";
import ReactBSAlert from "react-bootstrap-sweetalert";
import {isSuperuser, userCant, userCan} from "../../../services/Authorization/authService";
import {useTranslation} from 'react-i18next';
import Axios from "axios";
// import {
//   requestHeaderInterceptor,
//   responseErrorInterceptor,
//   responseSuccessInterceptor,
// } from "./services/interceptors";
// import axios from "axios";

// const fetchTools = require("./services/fetchTools.js")
// const REQUEST_TIMEOUT = 60000;
// console.log("localStorage.jwtToken")
// console.log(localStorage.jwtToken)
// const headers = {
//   Accept: "application/json",
//   "Content-Type": "application/json;charset=UTF-8",
//   'Authorization': localStorage.jwtToken,
// };

// const fetchTools = axios.create({
//   baseURL: process.env.REACT_APP_API_URL,
//   timeout: REQUEST_TIMEOUT,
//   crossDomain: true,
//   headers,
// });

// fetchTools.interceptors.request.use(requestHeaderInterceptor, (error) =>
//   Promise.reject(error)
// );

// fetchTools.interceptors.response.use(
//   responseSuccessInterceptor,
//   responseErrorInterceptor
// );

export function TagManagement(props) {
    // alert(this.props.history)
    const user = useSelector((state) => state.auth.user)
    const projectId = useSelector((state) => state.allOtherReducers.projectId)
    const {t, i18n} = useTranslation();

    const MAX_LEVEL = 3;

    const refTagParrentId = useRef();

    const [validationErrors, setValidationErrors] = useState({});

    const [tagSystems, setTagSystems] = useState([]);
    const [tag, setTag] = useState(null);
    const [tagSystem, setTagSystem] = useState(null);
    const [treeData, setTreeData] = useState([]);
    const [showTagModal, setShowTagModal] = useState(false);
    const [tagName, setTagName] = useState('');
    const [actionType, setActionType] = useState(null);
    const [deleteAlert, setDeleteAlert] = useState(null);
    const renderTags = useMemo(() => (list) => {
        const forest = (xs, build, isChild, root) =>
            sortBy(xs, ['order'])
                .filter((x) => isChild(root, x))
                .map((node) => build(node, (root) => forest(xs, build, isChild, root)))
                .map((v, i) => ({...v, title: v.name, key: v._id, id: v._id, expanded: true}));
        const data = forest(
            list,
            ({_id, parent_id, id, ...rest}, f) => ({
                _id,
                id,
                ...rest,
                children: f(_id),
            }),
            (_id, {parent_id}) => parent_id === _id,
            null
        );
        return data;
    }, [JSON.stringify(treeData)]);


    useEffect(() => {
        getTagSystems(projectId);
    }, [projectId]);

    useEffect(() => {
        if (userCant(user, 'tags.manage') && userCant(user, 'tags.index')) {
            props.history.push('/admin/instructions')
        }
    });

    const getTagSystems = async (projectId) => {
        if (userCant(user, 'tags.manage') && userCant(user, 'tags.index')) {
            return props.history.push('/admin/instructions')
        }

        await Axios.get(`${process.env.REACT_APP_API_URL}/api/tag-systems/project/${projectId}`, {
            headers: {
                'Authorization': localStorage.jwtToken
            }
        }).then(response => {
            if (response.data && response.data.data) {
                let data = response.data.data;
                setTagSystems(data);
                getTags(data?.[0]?._id);
                setTagSystem(data?.[0]?._id)
            }
        }).catch(error => {
            console.log('error', error);
        });

    }

    const getTags = async (tagSystemid) => {
        if (userCant(user, 'tags.manage') && userCant(user, 'tags.index')) {
            props.history.push('/admin/instructions')
        }
        try {
            setTreeData([]);
            await Axios.get(`${process.env.REACT_APP_API_URL}/api/tags/${tagSystemid}`, {
                headers: {
                    'Authorization': localStorage.jwtToken
                }
            }).then(response => {
                setTreeData(renderTags(response.data.data))
            });

        } catch (error) {
            console.log('error', error);
        }
    }

    const addTag = async () => {
        if (userCant(user, 'tags.manage')) {
            props.history.push('/admin/instructions')
        }
        try {
            const body = {
                name: tagName,
                tag_system_id: tagSystem,
                parent_id: refTagParrentId.current
            }
            await Axios.post(`${process.env.REACT_APP_API_URL}/api/tag/`, body, {
                headers: {
                    'Authorization': localStorage.jwtToken
                }
            })

            getTags(tagSystem);
            hideTagModal();
        } catch (error) {
            setValidationErrors(error.response.data)
        }
    }

    const hideTagModal = () => {
        setTagName('');
        setShowTagModal(false)
        refTagParrentId.current = null;
        setTag(null);
        setActionType(null);
        setValidationErrors({});
    }
    const editTag = async () => {

        try {

            const body = {
                name: tagName,
                id: refTagParrentId.current
            }
            await fetchTools.post('api/tag', body);
            getTags(tagSystem);
            hideTagModal()
        } catch (error) {
            setValidationErrors(error.data)
        }
    }


    const showAddTag = (node) => {
        if (userCant(user, 'tags.manage')) {
            return;
        }
        refTagParrentId.current = node._id;
        setTag(node)
        setActionType('add')
        setShowTagModal(true)
    }

    const showEditTag = (node) => {
        if (userCant(user, 'tags.manage')) {
            return;
        }

        refTagParrentId.current = node._id;
        setTagName(node.name);
        setActionType('edit')
        setTag(node)
        setShowTagModal(true)
    }

    const handleCreatEditTag = () => {
        if (userCant(user, 'tags.manage')) {
            return;
        }

        switch (actionType) {
            case 'edit':
                editTag();
                break;
            default:
                addTag();
                break;
        }
    }

    const removeTag = async () => {
        try {
            const response = await fetchTools.delete(`api/tag/${refTagParrentId.current}`);
            getTags(tagSystem);
            refTagParrentId.current = null;
            setTag(null);
            showSuccessAlert()
        } catch (error) {
            showDeleteAlertError()
        }
    }

    const showRemoveTag = (node) => {
        refTagParrentId.current = node._id;
        setTag(node);
        showDeleteAlertConfirmation(node);
    }

    const hideDeleteAlert = () => {
        refTagParrentId.current = null;
        setTag(null);
        setDeleteAlert(null);
    };
    const onUpdate = async (moveData, flatData) => {
        try {
            const curentNode = moveData.node;
            const body = {
                parent_id: moveData.nextParentNode?._id || null,
                orders: flatData
            }

            await Axios.put(process.env.REACT_APP_API_URL + '/api/tag/' + curentNode._id, body, {
                headers: {
                    'Authorization': localStorage.jwtToken
                }
            })

            getTags(tagSystem);
        } catch (error) {

        }
    }


    const onMoveNode = (moveData) => {
        console.log('onMoveNode', moveData);
        setTreeData(moveData.treeData);

        const flatData = getFlatDataFromTree({
            treeData: moveData.treeData,
            getNodeKey: ({node}) => node.id, // This ensures your "id" properties are exported in the path
            ignoreCollapsed: false, // Makes sure you traverse every node in the tree, not just the visible ones
        }).map(({node, path}) => ({
            id: node.id,
            name: node.name,

            // The last entry in the path is this node's key
            // The second to last entry (accessed here) is the parent node's key
            parent_id: path.length > 1 ? path[path.length - 2] : null,
        })).filter(node => node.parent_id === (moveData.nextParentNode?._id || null)).map((node, i) => ({
            _id: node.id,
            order: i
        }));


        onUpdate(moveData, flatData)
    }

    const onChange = (_treeData) => {
        console.log('onChange', _treeData);
        setTreeData(_treeData)
    }
    /**
     *
     * @param tagSystemid
     * @returns {Promise<void>}
     */
    const refreshTagsLevel = async tagSystemid => {
        if (!isSuperuser(user)) {
            return;
        }

        setDeleteAlert(<ReactBSAlert
            info
            style={{display: "block", marginTop: "-100px"}}
            title={t(`refreshing tags level ...`)}
            onConfirm={() => {
            }}
            showConfirm={false}
            timeout={1500}
        >
            {t('Please wait while refreshing tags level ...')}
        </ReactBSAlert>)


        await Axios.get(`${process.env.REACT_APP_API_URL}/api/tags/refresh-tags-level/${tagSystemid}`, {
            headers: {
                'Authorization': localStorage.jwtToken
            }
        }).then(response => {
            setTreeData(renderTags(response.data.data))

            setDeleteAlert(<ReactBSAlert
                success
                style={{display: "block", marginTop: "-100px"}}
                title={t(`tags level successfully refreshed!`)}
                onConfirm={() => {
                }}
                showConfirm={false}
                timeout={1500}
            >
                {t('tags level successfully refreshed!')}
            </ReactBSAlert>)

            setTimeout(() => {
                setDeleteAlert('')
            }, 1500)
        }).catch(err => setDeleteAlert(''));
    }

    const showDeleteAlertConfirmation = (node) => {
        setDeleteAlert(
            <ReactBSAlert
                warning
                style={{display: "block", marginTop: "-100px"}}
                title={t("Are you sure?")}
                onConfirm={() => removeTag()}
                onCancel={() => hideDeleteAlert()}
                confirmBtnBsStyle="info"
                cancelBtnBsStyle="danger"
                confirmBtnText={`${t('Yes')}, ${t('delete it')}!`}
                cancelBtnText="Cancel"
                showCancel
            >
                {t('You will not be able to recover this imaginary file')}!
            </ReactBSAlert>
        );
    };

    const showDeleteAlertError = () => {
        setDeleteAlert(
            <ReactBSAlert
                danger
                style={{display: "block", marginTop: "-100px"}}
                title={t("You can't remove the tag")}
                onConfirm={() => hideDeleteAlert()}
                onCancel={() => hideDeleteAlert()}
                confirmBtnBsStyle="info"
            >
                {t('it is already attached with images')}.
            </ReactBSAlert>
        );
    };


    const showSuccessAlert = () => {
        setDeleteAlert(
            <ReactBSAlert
                success
                style={{display: "block", marginTop: "-100px"}}
                title={t("Tag deleted") + '!'}
                onConfirm={() => hideDeleteAlert()}
                onCancel={() => hideDeleteAlert()}
                confirmBtnBsStyle="info"
            >
                {t('the tag successfully deleted')}
            </ReactBSAlert>
        );
    }

    return (
        <div className="content">
            <div className="TagManagement">
                {
                    tagSystems.map(tagSystem => <div key={tagSystem?._id} className='wrap-title'>
                        {/* <h2>{tagSystem.name}</h2> */}
                        {
                            userCan(user, 'tags.manage') && (!tag || tag?.level < MAX_LEVEL) &&
                            <Button className="btn btn-primary" onClick={() => setShowTagModal(true)}>
                                {t('Add root tag')}
                            </Button>
                        }

                        {tagSystem?._id && isSuperuser(user) &&
                        <Button className="btn btn-primary" onClick={() => refreshTagsLevel(tagSystem?._id)}>
                            {t('Refresh tags level')}
                        </Button>}
                    </div>)
                }
                <SortableTree
                    treeData={treeData}
                    onMoveNode={onMoveNode}
                    onChange={onChange}
                    get
                    generateNodeProps={({node, path}) => {
                        let buttons = [];
                        if (userCan(user, 'tags.manage')) {
                            buttons = [
                                <Button className="btn btn-primary btn-link"
                                        onClick={() => showAddTag(node)}
                                >
                                    {t('Add Child')}
                                </Button>,
                                <Button className="btn btn-primary btn-link"
                                        onClick={() => showEditTag(node)}
                                >
                                    {t('Edit')}
                                </Button>,
                                <Button className="btn btn-primary btn-link"
                                        onClick={() => showRemoveTag(node)}
                                >
                                    {t('Remove')}
                                </Button>,
                            ]
                        }

                        if (node.level === 3) {
                            buttons.splice(0, 1);
                        }
                        return {
                            buttons
                        }
                    }}
                />
            </div>
            {deleteAlert}
            <Dock
                position='right'
                isVisible={showTagModal}
                duration={300}
                size={window.innerWidth < 600 ? 1 : (window.innerWidth < 900 ? 0.5 : 0.3)}
            >
                <Form className="show_edit_tag_modal">
                    <Row className="photo__form__header">
                        <span>
                            {tag ? actionType === 'edit' ? `${t('Edit')} [${tag.name}] Tag` : `${t('Add Child tag for ')} [${tag.name}] ${t('Tag')}` : t('Add Root Tag')}
                        </span>
                        <img className="pointer" src={close} onClick={hideTagModal}/>
                    </Row>
                    <Row className="p-3">
                        <Col>
                            <FormGroup>
                                <label>{t('Tag name')}</label>
                                <TextFieldGroup
                                    placeholder={t("Tag name")}
                                    name="name"
                                    type="text"
                                    value={tagName}
                                    onChange={e => setTagName(e.target.value)}
                                    error={validationErrors.name}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <div className="photo__filter__actions">
                        <Button color="info" onClick={handleCreatEditTag}>{t('Apply')}</Button>
                        <Button color="info" onClick={hideTagModal}>{t('Close')}</Button>
                    </div>
                </Form>
            </Dock>
        </div>
    );
}

export default TagManagement;
