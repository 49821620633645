import React from "react";
import {Dock} from "react-dock"

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col, Button
} from "reactstrap";
import {setRolesList} from 'actions/index'
import { withTranslation } from 'react-i18next';

import RolesTable from "./RolesTable"
import RegisterRoleForm from "./RegisterRoleForm"
import {connect} from "react-redux";
import {withRouter} from 'react-router-dom';
import {userCant} from "../../../services/Authorization/authService";

function mapStateToProps(state) {
    return {
        rolesList: state.allOtherReducers.rolesList,
        loggedInUser: state.auth.user
    };
}

function mapDispatchToProps(dispatch) {
    return {
        fetchRoles: () => dispatch(setRolesList())
    }
}

class RolesDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            rolesTableData: [],
            projectTableData: [],
            showRolesForm: false
        };

        this.setEditingRole = this.setEditingRole.bind(this)
        this.toggleRolesForm = this.toggleRolesForm.bind(this)
    }

    componentWillMount() {
        if (userCant(this.props.loggedInUser, 'roles.manage')) {
            return this.props.history.push('/admin/instructions')
        }
    }

    setEditingRole(role) {
        this.setState({
            showRolesForm: !!role,
            updateRole: role
        })
    }

    toggleRolesForm() {
        if (this.state.showRolesForm) {
            this.setState({
                showRolesForm: false,
                updateRole: null
            })
        } else {
            this.setState({
                showRolesForm: true
            })
        }
    }

    render() {
        if (userCant(this.props.loggedInUser, 'roles.manage')) {
            return
        }
        return (
            <>
                <div className="content">
                    <Dock
                        position='right'
                        isVisible={this.state.showRolesForm}
                        duration={300}
                        size={window.innerWidth < 600?1:(window.innerWidth < 900?0.5:0.4)}
                    >
                        {this.state.showRolesForm &&
                        <RegisterRoleForm
                            updateRole={this.state.updateRole}
                            toggleRolesForm={this.toggleRolesForm}
                            canUpdatePermissions={this.state.updateRole?.name !== 'superuser'}
                        />
                        }
                    </Dock>

                    <Row>
                        <Col>
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h4">{this.props.t('Roles')}</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <Button color="info" type="submit"
                                            onClick={() => this.setState({showRolesForm: !this.state.showRolesForm})}>
                                        {this.props.t('Add Role')}
                                    </Button>
                                    <RolesTable
                                        setEditingRole={this.setEditingRole}/>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}


export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(RolesDetails)));

