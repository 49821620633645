import React from "react";
import {
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Col,
  Row,
  Container,
  Card,
  CardBody,
  CardImgOverlay,
  CardImg,
  Button,
  Input,
  Label
} from "reactstrap";

import ImageCarousel from "./ImageCarousel";
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

class ImageGallery extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false, // state for the modal popup
      images: [], // images array we receive from the parent
      imagesToShow: 0, // limit 
      currentIndex: 0 // used for the carousel
    }
  }
  static getDerivedStateFromProps(props, state) {
    const { images, limit } = props;
    const imagesToShow = props.hasOwnProperty("limit") ? limit : 6;
    return { images, imagesToShow };
  }

  // for toggling the modal state
  toggleModal = () => {
    this.setState({
      isModalOpen: !this.state.isModalOpen
    })
  }

  // used to set the current index of the carousel
  showModalImage = imageId => {
    this.toggleModal();
    this.setState({
      currentIndex: imageId
    })
  }

  updateStateList(e, value) {

    //TODO: Completely remove hoursWorked, at the moment we are removing temporarily the hours worked from the input table. Its quite significant work removing completely
    //so we choose to just disable the addition by sending through dummy data, in this case 0, we need to change the display of this data in contracted work as well. i.e. we
    // should not display the hours field 
    //HACK
    if (e.target.checked) {
      this.props.addToSelectedImagesList(value)
    }
    else {
      this.props.removeFromSelectedImagesList(value)
    }



  }

   makeid(length) {
    var result           = [];
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
      result.push(characters.charAt(Math.floor(Math.random() * 
 charactersLength)));
   }
   return result.join('');
}


  render() {
    const { isModalOpen, images, imagesToShow, currentIndex } = this.state;
    const tempImagesArray = images.slice(0, imagesToShow); //temporary array
    const hasMore = images.length !== 0 ? images.length - imagesToShow : 0;
    // <img src={'data:image/jpg;base64', image} />;
    return <Container>
      <Row>
        <Col>
          <Row>
            {tempImagesArray.map((image, index) => (

              <Col
                md="6"
                className={"my-2" + index}
                key={index}
              // onClick={()=>alert("hellyeah")}
              // onClick={() => this.showModalImage(index)}
              >
                <Card >
                  <CardBody>
                    <div  > <Label>Description: {image.imageComment}</Label>
                      <br />
                      <Label>File Name: {image.name}</Label>
                      <CardImg src={image.imageLocation + '?x-request=html' +  '&test='+ this.makeid(15)} id={"img-" + image._id} />
                    </div>
                    {hasMore !== 0 && index === imagesToShow - 1 ? (
                      <CardImgOverlay className="overlay">
                        <h2 className="mb-0">{hasMore}</h2>
                        <small> More </small>
                      </CardImgOverlay>
                    ) : null}

                  </CardBody>
                </Card>

              </Col>
            ))}
          </Row>
        </Col>
      </Row>

      <Modal
        className="modal-xl"
        isOpen={isModalOpen}
        toggle={this.toggleModal}
      >
        <ModalHeader>Image Gallery</ModalHeader>
        <ModalBody>
          <Row>
            <Col md="12">
              <ImageCarousel images={images} currentIndex={currentIndex} />
            </Col>
          </Row>
        </ModalBody>
      </Modal>


    </Container>;
  }
}

export default ImageGallery;