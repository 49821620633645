
import React from "react";
import Select from "react-select";
import axios from "axios";
import _ from "lodash"
import { Dock } from "react-dock"

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col
} from "reactstrap";
import AddRentalCompanyTable from "views/pages/Equipment/AddRentalCompanyTable";
import AddRentalEquipmentTable from "views/pages/Equipment/AddRentalEquipmentTable";
import AddRental from "views/pages/Equipment/AddRental";
import { connect } from "react-redux";
import RentalTable from "views/pages/Equipment/RentalTable";
import {userCant, userCan} from "../../../services/Authorization/authService";
import { withTranslation } from 'react-i18next';

function mapStateToProps(state) {
  return {
    project: state.allOtherReducers.project,
    projectId: state.allOtherReducers.projectId,
    projectData: state.allOtherReducers.projectData,
    reportDate: state.allOtherReducers.reportDate,
    user: state.auth.user
  };
}

class Equipment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rentalCompanyTableData: [],
      rentalEquipmentTableData: [],
      rentalTableData: [],
      companyTableData: [],
      rentalTableDataRowCount: null,
      rentalsTableData: [],
      isAddRentalDockVisible: false,
      isRentalCompanyDockVisible: false,
      isRentalEquipmentDockVisible: false
    };
    this.getSomeRentalCompanies();
    this.getSomeRentalEquipment();
    this.getSomeCompanies();
    this.getRentalsByProjectAndReportDate()
    this.getSomeRentalEquipment = this.getSomeRentalEquipment.bind(this)
    this.getSomeCompanies = this.getSomeCompanies.bind(this)
    this.getSomeRentalCompanies = this.getSomeRentalCompanies.bind(this)
    this.getRentalsByProjectAndReportDate = this.getRentalsByProjectAndReportDate.bind(this)
    this.isAddRentalDockVisible = this.isAddRentalDockVisible.bind(this)
    this.isRentalCompanyDockVisible = this.isRentalCompanyDockVisible.bind(this)
    this.isRentalEquipmentDockVisible = this.isRentalEquipmentDockVisible.bind(this)

  }

  componentWillMount() {
    if (userCant(this.props.user, 'equipment.index') &&
        userCant(this.props.user, 'equipment.manage')) {
      return this.props.history.push('/admin/instructions')
    }
  }



  componentDidUpdate(prevProps) {

    if (this.props.projectId !== prevProps.projectId) {

      if (this.props.projectId === undefined) {
        return {}
      } else {
        this.getRentalsByProjectAndReportDate()
        this.getSomeCompanies();
      }
    }

    if (this.props.reportDate !== prevProps.reportDate) {
      this.getRentalsByProjectAndReportDate()
    }

  }

  getSomeRentalCompanies() {
    if (userCant(this.props.user, 'equipment.index') &&
        userCant(this.props.user, 'equipment.manage')) {
      return this.props.history.push('/admin/instructions')
    }
    axios.get(process.env.REACT_APP_API_URL + "/api/rentalcompany/", {
      headers: {
        'Authorization': localStorage.jwtToken
      }
    })
      .then(res => {
        this.setState({
          rentalCompanyTableData: res.data
        }, () => this.state.rentalCompanyTableData);
        return res.data
      }).catch((error) => {
        ;
      })
  }

  updateRentalCompanyTableData = (data) => {
    this.setState({ rentalCompanyTableData: data }, () => this.state.rentalCompanyTableData)
  }


  getSomeRentalEquipment() {
    if (userCant(this.props.user, 'equipment.index') &&
        userCant(this.props.user, 'equipment.manage')) {
      return this.props.history.push('/admin/instructions')
    }

    axios.get(process.env.REACT_APP_API_URL + "/api/rentalequipment/project/" + this.props.projectId, {
      headers: {
        'Authorization': localStorage.jwtToken
      }
    })
      .then(res => {
        this.setState({
          rentalEquipmentTableData: res.data
        }, () => this.state.rentalEquipmentTableData);
        return res.data
      }).catch((error) => {
        ;
      })
  }

  getRentalsByProjectAndReportDate() {

    if (userCant(this.props.user, 'equipment.index') &&
        userCant(this.props.user, 'equipment.manage')) {
      return this.props.history.push('/admin/instructions')
    }

    axios.get(process.env.REACT_APP_API_URL + "/api/rentals/open/project/" + this.props.projectId + "/date/" + this.props.reportDate, {
      headers: {
        'Authorization': localStorage.jwtToken
      }
    })
      .then(res => {
        this.setState({
          rentalsTableData: res.data
        }, () => { });
        return res.data
      }).catch((error) => {
        ;
      })
  }

  getSomeCompanies() {
    if (userCant(this.props.user, 'equipment.index') &&
        userCant(this.props.user, 'equipment.manage')) {
      return this.props.history.push('/admin/instructions')
    }

    axios.get(process.env.REACT_APP_API_URL + "/api/get-active-project-companies?projectId=" + this.props.projectId, {
      headers: {
        'Authorization': localStorage.jwtToken
      }
    })
      .then(res => {
        this.setState({
          companyTableData: res.data
        }, () => this.state.companyTableData);
        return res.data
      }).catch((error) => {
        ;
      })
  }

  updateRentalEquipmentTableData = (data) => {
    this.setState({ rentalEquipmentTableData: data }, () => this.state.rentalEquipmentTableData)
  }

  updateRentalTableData = (data) => {
    this.setState({ rentalTableData: data }, () => this.state.rentalTableData)
  }


  getRowCount(data, tableRowCount) {

    var rowCount = _.size(data)
    this.setState({ rentalTableDataRowCount: rowCount }, () => console.log(this.state.rentalTableDataRowCount))
    return rowCount
  }

  componentDidMount() {
    if (this.props.projectId === undefined) {
      return {}
    } else {
      this.getRentalsByProjectAndReportDate()
    }

    this.getRowCount(this.state.rentalTableData, "rentalTableData")
  }

  isAddRentalDockVisible() {
    this.setState({ isAddRentalDockVisible: !this.state.isAddRentalDockVisible }, () => { })
  }

  isRentalCompanyDockVisible() {
    this.setState({ isRentalCompanyDockVisible: !this.state.isRentalCompanyDockVisible }, () => { })
  }

  isRentalEquipmentDockVisible() {
    this.setState({ isRentalEquipmentDockVisible: !this.state.isRentalEquipmentDockVisible }, () => { })
  }

  render() {
    return (
      <>
        <div className="content">
          <Row>
            <Col md={6}>
                {userCan(this.props.user, 'equipment.manage') &&
                <Button color="info" onClick={() => {
                    this.isAddRentalDockVisible()
                }}>{this.props.t("Add Rental")}</Button>}

                <Button color="info" onClick={() => {
                    this.isRentalCompanyDockVisible()
                }}>{this.props.t("Rental Companies")}</Button>

                <Button color="info" onClick={() => {
                    this.isRentalEquipmentDockVisible()
                }}>{this.props.t("Rental Equipment")}</Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <RentalTable
                rentalsTableData={this.state.rentalsTableData}
                rentalTableDataRowCount={this.state.rentalTableDataRowCount}
                getRentalsByProjectAndReportDate={this.getRentalsByProjectAndReportDate}
              />
            </Col>
          </Row>
          <Dock
            position='right'
            isVisible={this.state.isAddRentalDockVisible}
            duration={300}
            size={window.innerWidth < 600?1:(window.innerWidth < 900?0.5:0.3)}
            zIndex={99999}
          >
            <CardBody>
            <Row>
              <Col md={2}>
                <Button color="warning" onClick={() => { this.isAddRentalDockVisible() }}>{this.props.t("Close")}</Button>
              </Col>
            </Row>
            </CardBody>
            <Row >
              <Col >
                <CardBody >
                  <AddRental
                    companyTableData={this.state.companyTableData}
                    rentalCompanyTableData={this.state.rentalCompanyTableData}
                    rentalEquipmentTableData={this.state.rentalEquipmentTableData}
                    rentalsTableData={this.state.rentalsTableData}
                    rentalTableDataRowCount={this.state.rentalTableDataRowCount}
                    getRentalsByProjectAndReportDate={this.getRentalsByProjectAndReportDate}
                   isAddRentalDockVisible={this.isAddRentalDockVisible}
                  />
                </CardBody>
              </Col>
            </Row>
          </Dock>
          <Dock
            position='right'
            isVisible={this.state.isRentalCompanyDockVisible}
            duration={300}
            size={window.innerWidth < 600?1:(window.innerWidth < 900?0.5:0.3)}
            zIndex={99999}
          >
            <CardBody>
              <Row>
                <Col md={3}>
                  <Button color="warning" onClick={() => { this.isRentalCompanyDockVisible() }}>{this.props.t("Close")}</Button>
                </Col>
              </Row>
            </CardBody>
            <Row >
              <Col >
                <CardBody >
                  <AddRentalCompanyTable
                    rentalCompanyTableData={this.state.rentalCompanyTableData}
                    getSomeRentalCompanies={this.getSomeRentalCompanies}
                  />
                </CardBody>
              </Col>
            </Row>
          </Dock>
          <Dock
            position='right'
            isVisible={this.state.isRentalEquipmentDockVisible}
            duration={300}
            size={window.innerWidth < 600?1:(window.innerWidth < 900?0.5:0.3)}
            zIndex={99999}
          ><Row>
              <Col>
                <CardBody>
                  <Row>
                    <Col md={2}>
                      <Button color="warning" onClick={() => { this.isRentalEquipmentDockVisible() }}>{"Close"}</Button>
                    </Col>
                  </Row>
                </CardBody>
              </Col>
            </Row>
            <Row >
              <Col >
                <CardBody >
                  <AddRentalEquipmentTable
                    rentalEquipmentTableData={this.state.rentalEquipmentTableData}
                    updateRentalEquipmentTableData={this.updateRentalEquipmentTableData}
                    getSomeRentalEquipment={this.getSomeRentalEquipment}
                    rentalCompanyTableData={this.state.rentalCompanyTableData}
                    updateRentalCompanyTableData={this.updateRentalCompanyTableData}
                  />
                </CardBody>
              </Col>
            </Row>

          </Dock>
        </div>
      </>
    );
  }
}

export default withTranslation()(connect(mapStateToProps, null)(Equipment))
