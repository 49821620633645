export function authHeader() {
    let token = localStorage.getItem('jwtToken');
    
    if (token !== null && token !== "" && token !== undefined) {
        return {
            'Content-Type': 'application/json',
            // 'Authorization': token
        };
    } else {
        return {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        };
    }
}