import { combineReducers } from 'redux';
import authReducer from './authReducer';
import errorReducer from './errorReducer';
import allOtherReducers from './allOtherReducers'
import scheduleReducer from './scheduleReducer'
import dashboardReducers from "./FileManager/dashboard";
import filemanagerReducers from "./FileManager/filemanager";

export default combineReducers({
  auth: authReducer,
  errors: errorReducer,
  allOtherReducers,
  schedule: scheduleReducer,
  dashboard: dashboardReducers,
  filemanager: filemanagerReducers
});