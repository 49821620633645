import React from "react";

export const onColumnFilter = (filter, row, column) => {
    const columnId = column.id || filter.id;


    if (!row[columnId]) {
        return false;
    }


    if (typeof (row[columnId]) == 'object' && !Array.isArray(row[columnId])) {

        if (Array.isArray(column.filterBy)) {
            return column.filterBy.find(f => row[columnId][f].toLowerCase().includes(filter.value.toLowerCase()) ?? false)
        }

        return row[columnId][column.filterBy].toLowerCase().includes(filter.value.toLowerCase()) ?? false;
    }
    if (Array.isArray(row[columnId])) {
        let count = 0;

        for (let i = 0; i < row[columnId].length; i++) {
            let rowValue = row[columnId][i];

            if (columnId == 'projects') {
                rowValue = rowValue.projectId
            }

            if (Array.isArray(column.filterBy)) {
                if (column.filterBy.find(f => rowValue[f].toLowerCase().includes(filter.value.toLowerCase()))) {
                    count++;
                }
            } else {

                if (rowValue[column.filterBy].toLowerCase().includes(filter.value.toLowerCase())) {
                    count++;
                }
            }
        }
        if (count != 0) {
            return true;
        }
    } else {
        if (Array.isArray(column.filterBy)) {
            return column.filterBy.find(f => {
                if (!row._original[f]) {
                    return false;
                }
                return row._original[f].toLowerCase().includes(filter.value.toLowerCase()) ?? false
            })
        }

        return row[columnId].toLowerCase().includes(filter.value.toLowerCase()) ?? false;
    }
}