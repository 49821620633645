import React from "react";
import axios from "axios"
import image_helper from "../../utils/image_helper.js"
import ImageGalleryUpload from "views/components/imageDisplay/ImageGalleryUpload"
import EXIF from 'exif-js'
import moment from 'moment'
import _ from "lodash"
import { withTranslation } from 'react-i18next';



// reactstrap components
import {
  Card,
  CardBody,
  Row,
  Col,
  Alert,
  Form,
  Input,
  Button
} from "reactstrap";

import { connect } from "react-redux";
import { ADD_GENERAL_COMMENT } from "constants/action-types.js";
import {userCant} from "../../services/Authorization/authService";

// import ImageGallery from "views/components/imageDisplay/ImageGallery";

function mapStateToProps(state) {
  return {
    clientId: state.allOtherReducers.clientId,
    project: state.allOtherReducers.project,
    projectId: state.allOtherReducers.projectId,
    projectData: state.allOtherReducers.projectData,
    reportDate: state.allOtherReducers.reportDate,
    user: state.auth.user
  };
}

class ConnectedUploadCompanyLogo extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      file: null,
      gotFile: null,
      images: [],
      retreivedImages: [],
      imageDate: moment().format("DD MMM YYYY"),
      imageReportDate: this.props.reportDate,
      visible: false,
      visibleNoImage: false,
      imageComment:null
    };
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.getImages = this.getImages.bind(this)
    this.removeImageFromPage = this.removeImageFromPage.bind(this)
    this.onDismiss = this.onDismiss.bind(this)
    this.uploadSuccessfulAlert = this.uploadSuccessfulAlert.bind(this)
    this.noImageAlert = this.noImageAlert.bind(this)
    this.noImageAlertDismiss = this.noImageAlertDismiss.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)

  }
  //Lifecycle
  componentDidUpdate(prevProps) {
    var projectId = this.props.projectId
    var date = this.props.reportDate

    if (this.props.reportDate !== prevProps.reportDate) {

      this.getImages()
      // this.props.getGeneralCommentsByDateAndByProject({ date, projectId });
    }
  }
  componentDidMount() {
    this.getImages()
  }
  componentWillMount() {
    if (userCant(this.props.user, 'logo.manage')) {
      return this.props.history.push('/admin/instructions')
    }
  }
  //Alerts
  onDismiss() {
    this.setState({ visible: !this.state.visible })
  }

  uploadSuccessfulAlert() {
    this.setState({ visible: !this.state.visible })
  }

  noImageAlert() {
    this.setState({ visibleNoImage: !this.state.visibleNoImage })
  }

  noImageAlertDismiss() {
    this.setState({ visibleNoImage: !this.state.visibleNoImage })
  }


  /////NEW WAY////
  async pushPhotoToS3(presignedUploadUrl, uploadPhoto) {
    const myHeaders = new Headers({ 'Content-Type': 'image/*' });
    const response = await fetch(presignedUploadUrl, {
      method: 'PUT',
      headers: myHeaders,
      body: uploadPhoto
    });
  }

  getS3SignUrl(filename, filetype) {

    return axios.post(process.env.REACT_APP_API_URL + "/api/logomanagement/presigned/", {
      filename: filename,
      imageComment: this.state.imageComment,
      projectid: this.props.projectId,
      date: this.props.reportDate,
      companyId: this.props.clientId
    }, {
      headers: {
        'Authorization': localStorage.jwtToken,
      }

    })
      .then(res => {
        console.log(res)
        return res.data
      }).catch((error) => {
        console.log(error)
        if (axios.isCancel(error)) {
          console.log(error)
        }
        ;
      })



    // const presignedUrl = await response.json();

  }

  async getS3SignUrlGetObject(filename, filetype) {
    // {
    //   filename: filename,
    // },
    return axios.get(process.env.REACT_APP_API_URL + "/api/logomanagement/presigned/" + filename, {
      headers: {
        'Authorization': localStorage.jwtToken,
      }
    })
      .then(res => {
        console.log(res)
        return res.data
      }).catch((error) => {
        console.log(error)
        if (axios.isCancel(error)) {
          console.log(error)
        }
        ;
      })

    // const presignedUrl = await response.json();

  }

  async handleSubmit(e) {
    e.preventDefault()
    try {
      const data = await this.getS3SignUrl(this.state.file.name,
        this.state.file.type).then(async (x) => {
          await this.pushPhotoToS3(x, this.state.file).then(() => {
            this.uploadSuccessfulAlert()
            this.removeImageFromPage()
            this.setState({
              imageComment: null,
              chooseFileVisible: true,
              uploadAndCancelVisible: false,
              fileName: '',
              fileLink: null
            }, ()=>this.getImages())
          });
        });
    }
    catch (err) {
      console.log(err)
    }
    // if(data.url){
    // // const photoConvertedToBlob = dataURIToBlob(profilePhotoResized);

    // }
  }

  async getImages() {
    if (userCant(this.props.user, 'logo.manage')) {
      return;
    }
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
        'Authorization': localStorage.jwtToken
      }
    };

    this.setState({ retreivedImages: [] })
    const image = axios.get(process.env.REACT_APP_API_URL + "/api/logo/company/" + this.props.clientId, config).then(
      (response) => {

       console.log(response)
          if (Array.isArray(response.data)) {
            console.log(response)
            response.data.map(async (imageItem)  =>  {
              console.log(imageItem)
              imageItem.imageLocation=  await this.getS3SignUrlGetObject(imageItem.imageKey)
              this.setState(prevState => ({
                retreivedImages: [...prevState.retreivedImages, imageItem]
              }), () => { console.log(this.state.retreivedImages) })

            })

          }
       
      }
    )
    console.log(this.state.retreivedImages)
  }

  onFormSubmit(e) {
    e.preventDefault();
    let reportDate = this.state.imageReportDate;
    if (this.state.imageReportDate == null) {
      reportDate = this.props.reportDate
    }
    if (this.state.file != null) {
      const formData = new FormData();
      formData.append('image', this.state.file);
      formData.append('imageComment', this.state.imageComment)
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
          'Authorization': localStorage.jwtToken
        }
      };
      axios.post(process.env.REACT_APP_API_URL + "/api/logo/company/" + this.props.clientId, formData, config
      ).then((response) => {
        this.uploadSuccessfulAlert()
        this.removeImageFromPage()
        this.getImages();
      }).catch((error) => {
      });
    }
    else {
      this.noImageAlert()
    }
  }
  onChange(e) {
    var self = this
    this.setState({ file: e.target.files[0] });

    let img_arr = [];
    for (let i = 0; i < e.target.files.length; i++) {
      const file = e.target.files[i];
      const test = e.target.files[i]
      EXIF.getData(e.target.files[i], function () {

        if (_.get(this, 'exifdata.DateTime') != undefined) {
          let photoTakenTime = moment(this.exifdata.DateTime, "YYYY:MM:DD h:mm:ss").format("DD MMM YYYY")
          let imageReportDate = moment(this.exifdata.DateTime, "YYYY:MM:DD h:mm:ss").format("YYYY-MM-DD")
          self.setState({
            imageDate: photoTakenTime,
            imageReportDate: imageReportDate
          }, () => { })

        }
        else {
          self.setState({
            imageDate: self.props.t('No taken date found with image, the current selected report date will be used in the upload. You can change the date by using the report date selector above.'),
            imageReportDate: null
          }, () => { })
        }

        var output = document.getElementById('output');
        output.src = URL.createObjectURL(test);
        output.onload = function () {
          URL.revokeObjectURL(output.src) // free memory
        }
        output.onerror = () => {
          document.getElementById('imageUpload').reset();
        }
        //  return this.exifdata.DateTime
      })
    }

  }

  removeImageFromPage() {
    var output = document.getElementById('output');
    output.removeAttribute('src')
    var imageFileUploadElement = document.getElementById('image')
    imageFileUploadElement.value = ""
    document.getElementById("imageUpload").reset();
    this.setState({
      file: null,
      imageDate: null
    })
  }

  handleImageCommentText = (e) => {

    this.setState({ imageComment: e.target.value }, () => this.state.imageComment);
  }


  render() {
    if (userCant(this.props.user, 'logo.manage')) {
      return
    }
    const images = this.state.retreivedImages
    console.log(images)

    const renderUploadForm = () => {
      if (this.state.retreivedImages<1) {
        return (<Form onSubmit={this.handleSubmit} id='imageUpload'>
        <Row>
          <Col>
            <h1>{this.props.t('File Upload')}</h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <Input type="file" id="image" name='image' accept="image/*" onChange={this.onChange} />
            <Button type="submit">{this.props.t('Upload')}</Button>
        &nbsp;&nbsp;&nbsp;
        <Button onClick={this.removeImageFromPage} type="reset">{this.props.t('Cancel')}</Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <Input type="text" id="imageComment" name='imageComment' onChange={this.handleImageCommentText}/>
          </Col>
        </Row>
      </Form>);
      }
    }

    return (
      <div className="content">
        <Alert
          color="success"

          isOpen={this.state.visible}
          toggle={this.onDismiss}
        >
          <span>
            <b> {this.props.t('Info')} - </b> {this.props.t('Image uploaded successfully')}.
          </span>
        </Alert>
        <Alert
          color="warning"
          isOpen={this.state.visibleNoImage}
          toggle={this.noImageAlertDismiss}
        >
          <span>
            <b> {this.props.t('Warning')} - </b> {this.props.t('No image selected')}.
          </span>
        </Alert>
        <Row>

          <Col>
            {renderUploadForm()}
          </Col>
          <Col></Col>
        </Row>
        <Row>
          <Col>
            <br />
            <label>{this.props.t('Image taken on')}:</label>
            <br />
            <label>{this.state.imageDate}</label>
            <br />
          </Col>
        </Row>
        <Row>
          <Col>
            <img id="output" />
          </Col>
        </Row>
        <Row>
          <Col>
            <ImageGalleryUpload
                updateEndpoint={`${process.env.REACT_APP_API_URL}/api/logo/`}
                images={images} limit={12} getImages={this.getImages} type="logo"/>
          </Col>
        </Row>
      </div>

    )
  }
}


const UploadCompanyLogo = withTranslation()(connect(
  mapStateToProps,
  null
)(ConnectedUploadCompanyLogo));
export default UploadCompanyLogo;
