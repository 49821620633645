
import React from "react";
import Select from "react-select";
import axios from "axios"
import Datetime from 'react-datetime';
import ReactTable from "react-table";
// import { addProject } from 'actions/index'
import moment from 'moment'
import InputMask from 'react-input-mask';
import _ from 'lodash'
import { Dock } from "react-dock"

// reactstrap components
import {
  Button,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  InputGroup,
  CardBody
} from "reactstrap";
import { connect } from "react-redux";
import classnames from "classnames";
import { withTranslation } from 'react-i18next';
import ReactBSAlert from "react-bootstrap-sweetalert";

// function mapDispatchToProps(dispatch) {
//   return {
//     addProject: project => dispatch(addProject(project)),

//   };
// }

function mapStateToProps(state) {
  return {
    project: state.allOtherReducers.project,
    projectId: state.allOtherReducers.projectId,
    projectData: state.allOtherReducers.projectData,
    reportDate: state.allOtherReducers.reportDate
  };
}


class ConnectedProjectStructure extends React.Component {
  constructor() {
    super();
    this.state = {
      singleProjectValueSelect: '',
      startDate: "",
      data: '',
      name: '',
      isDirty: false,
      buildingsTableData: [],
      elevations: [],
      systems:[],
      levels:[],
      showForm:false,
      errors:{},
      alert: null
    };
    this.formSubmitSuccess = this.formSubmitSuccess.bind(this)
    this.renderEditable = this.renderEditable.bind(this);
    this.saveRowData = this.saveRowData.bind(this);
    this.toggleEditable = this.toggleEditable.bind(this);
    this.renderActiveButtons = this.renderActiveButtons.bind(this);
    this.deleteProjectStructureByProjectId = this.deleteProjectStructureByProjectId.bind(this);
    this.deleteConfirmation = this.deleteConfirmation.bind(this);
  }


  // componentDidUpdate(prevProps) {
  //   // if (this.props.projectData !== prevProps.projectData) {
  //   //   this.populateDropDownDelayType(this.props.delayTypeData);
  //   // }


  // }
  handleTagsinput = tagsinput => {
    this.setState({ tagsinput });

  };


  handleOnChange(value) {
    alert(value)
  }


  formSubmitSuccess(e) {

    this.setState({
      errors:{}
    })

    e.preventDefault();

    if(this.state.editingRecord){
      return this.saveRowData()
    }

    axios.post(process.env.REACT_APP_API_URL + "/api/projectstructure", {
      building: this.state.name,
      projectId: this.props.projectId,
      levels: this.state.levels,
      elevations: this.state.elevations,
      systems: this.state.systems
    }, {
      headers: {
        'Authorization': localStorage.jwtToken
      }
    }).then(res => {
      this.props.getSomeProjectstructures()
      this.clearForm();

      return res.data
    }).catch((error) => {
      this.setState({
        errors: error.response.data
      })
    })
  }

  clearForm() {
    this.setState({
      name: '',
      levels:[],
      elevations:[],
      systems:[],
      errors: {},
      showForm: false
    }, () => this.state.name)
  }



  handleInputChange = (cellInfo, event) => {
    let data = [...this.props.projectStructureTableData];
    data[cellInfo.index][cellInfo.column.id] = event.target.value;

    this.setState({ data });
  };
  renderEditable(cellInfo) {

    const cellValue = this.props.projectStructureTableData[cellInfo.index][cellInfo.column.id];

    const { editingRow } = this.state;
    return (
      <div>
        {editingRow === cellInfo.index ? (
          <Input
            maskChar=""
            mask=""
            placeholder=""
            onChange={this.handleInputChange.bind(null, cellInfo)}
            value={cellValue}
            tag={InputMask}
          />
        ) : (<div >
          {cellInfo.value}
        </div>)}
      </div>
    );
  }

  saveRowData() {

    axios.put(process.env.REACT_APP_API_URL + "/api/projectstructure", {
      _id:this.state.editingRecord._id,
      building: this.state.name,
      projectId: this.props.projectId,
      levels: this.state.levels,
      elevations: this.state.elevations,
      systems: this.state.systems
    }, {
      headers: {
        'Authorization': localStorage.jwtToken
      }
    }).then(res => {
      this.props.getSomeProjectstructures()
      this.clearForm();

      this.setState({
        editingRecord: null
      })
      return res.data
    }).catch((error) => {
      this.setState({
        errors: error.response.data
      })
    })
  }

  toggleEditable(projectStructure = null) {
    this.setState({
      editingRecord: projectStructure,
      showForm: !!projectStructure,
      name: projectStructure?.building,
      elevations: projectStructure?.elevationsx || [],
      systems: projectStructure?.systemsx || [],
      levels: projectStructure?.levelsx || [],
      errors: {}
    })
  }

  deleteProjectStructureByProjectId(recordId){
    axios.delete(process.env.REACT_APP_API_URL + '/api/projectstructure/' + recordId, {
      headers: {
        'Authorization': localStorage.jwtToken
      }
    }).then(res => {
      this.props.getSomeProjectstructures()
      return res
    }).finally(() => this.hideAlert())
  }
  deleteConfirmation = (recordId) => {
    this.setState({
      alert: (
          <ReactBSAlert
              warning
              style={{ display: "block", marginTop: "-100px" }}
              title={this.props.t("Are you sure?")}
              onConfirm={() => this.deleteProjectStructureByProjectId(recordId)}
              onCancel={() => this.hideAlert()}
              confirmBtnBsStyle="info"
              cancelBtnBsStyle="danger"
              confirmBtnText={this.props.t("OK")}
              cancelBtnText={this.props.t("Cancel")}
              showCancel
          >
          </ReactBSAlert>
      )
    });
  }

  hideAlert = () => {
    this.setState({
      alert: null
    }, () => { });
  };

  renderActiveButtons(row) {
    return (<div>
      <Button
        onClick={() => this.toggleEditable(row)}
        size="xs"
        className="btn-icon btn-neutral btn btn-info"
      ><i className="fa fa-edit"
        /></Button>

      {row.can_be_removed &&
      <Button
          color="info"
          size="xs"
          className="btn-icon btn-neutral btn btn-remove"
          onClick={() => {
            this.deleteConfirmation(row._id)

          }}>
        <i className="fa fa-times"/>
      </Button>}
    </div>)

  }


  componentDidMount() {

  }
  componentDidUpdate() {

  }
  componentWillReceiveProps(prevProps, { projectStructureTableData }) {
    this.setState({
      ...this.state, projectStructureTableData
    })

  }



  /////////////////////
  createUI() {
    return this.state.elevations.map((el, i) =>
      <div key={i}>
        <InputGroup>
          <Input type="text" value={el.name || ''} onChange={this.handleChange.bind(this, i)} />
          {
            el.can_be_removed &&
            <Button type='button' value='remove' onClick={this.removeClick.bind(this, i)}
                    className="btn-linkall btn-info nc-icon nc-simple-delete"/>
          }
        </InputGroup>

      </div>
    )
  }

  createUISystems() {
    return this.state.systems.map((el, i) =>
      <div key={i}>
        <InputGroup>
          <Input type="text" value={el.name || ''} onChange={this.handleChangeSystems.bind(this, i)} />
          {
           el.can_be_removed &&
          <Button type='button' value='remove' onClick={this.removeClickSystems.bind(this, i)} className="btn-linkall btn-info nc-icon nc-simple-delete" />
          }
        </InputGroup>

      </div>
    )
  }

  createUILevel() {
    return this.state.levels.map((el, i) =>
      <div key={i}>
        <InputGroup>
          <Input type="text" value={el.name || ''} onChange={this.handleChangeLevels.bind(this, i)}/>
          {
            el.can_be_removed &&
            <Button type='button' value='remove' onClick={this.removeClickLevels.bind(this, i)}
                    className="btn-linkall btn-info nc-icon nc-simple-delete"/>
          }
        </InputGroup>

      </div>
    )
  }

  handleChange(i, event) {
    let elevations = [...this.state.elevations];
    elevations[i] = {
      ...elevations[i],
      name:event.target.value
    };
    this.setState({ elevations });
  }

  handleChangeSystems(i, event) {
    let systems = [...this.state.systems];
    systems[i] = {
      ...systems[i],
      name: event.target.value
    };
    this.setState({ systems });
  }

  handleChangeLevels(i, event) {
    let levels = [...this.state.levels];
    levels[i] = {
      ...levels[i],
      name: event.target.value
    };
    this.setState({ levels });
  }

  addClick() {
    this.setState(prevState => ({ elevations: [...prevState.elevations, {
      name:'',
        can_be_removed:true
      }] }))
  }
  addClickSystems() {
    this.setState(prevState => ({ systems: [...prevState.systems, {
        name:'',
        can_be_removed:true
      }] }))
  }

  addClickLevels() {
    this.setState(prevState => ({ levels: [...prevState.levels, {
        name:'',
        can_be_removed:true
      }] }))
  }

  removeClick(i) {
    let elevations = [...this.state.elevations];
    elevations.splice(i, 1);
    this.setState({ elevations });
  }

  removeClickSystems(i) {
    let systems = [...this.state.systems];
    systems.splice(i, 1);
    this.setState({ systems });
  }

  removeClickLevels(i) {
    let levels = [...this.state.levels];
    levels.splice(i, 1);
    this.setState({ levels });
  }

  render() {
    const { name, description, startDate, customerId,errors } = this.state;
    return (
      <>
        {this.state.alert}
        <Dock
            position='right'
            isVisible={this.state.showForm}
            duration={300}
            size={window.innerWidth < 600?1:(window.innerWidth < 900?0.5:0.3)}
        >

          {this.state.showForm &&
          <Row>
            <Col>
              <CardBody>
                <h3>
                  <Button className="btn-square mr-2" color="info" onClick={() => {
                    this.toggleEditable()
                  }}>
                    {this.props.t('Close')}
                  </Button>
                  {this.props.t('Add New Project Structure')}
                </h3>
                {/* onSubmit={this.formSubmitSuccess} */}
                <Form className="form-horizontal" onSubmit={(e) => this.formSubmitSuccess(e)}>
                  <Row><Col>
                    <FormGroup>
                      <label>{this.props.t('Building Name')}</label>
                      <Input
                          name="name"
                          type="text"
                          className={classnames('form-control form-control-lg', {
                            'is-invalid': errors.building
                          })}
                          onChange={(e) => {
                            this.setState({name: `${e.target.value}`})
                          }
                          }
                          value={this.state.name}

                      />

                      {errors.building && (
                          <div className="invalid-feedback d-block">{errors.building}</div>
                      )}
                    </FormGroup>
                  </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <label>{this.props.t('Levels')}</label>
                        <br/>
                        {this.createUILevel()}

                        <Button type='button' value='add more' onClick={this.addClickLevels.bind(this)}
                                className="btn-link btn btn-info nc-icon nc-simple-add"/>
                        <label>{this.props.t('Add Level')}</label>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <label>{this.props.t('Elevations')}</label>
                        <br/>
                        {this.createUI()}

                        <Button type='button' value='add more' onClick={this.addClick.bind(this)}
                                className="btn-link btn btn-info nc-icon nc-simple-add"/>
                        <label>{this.props.t('Add Elevations')}</label>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <label>{this.props.t('System')}</label>
                        <br/>
                        {this.createUISystems()}

                        <Button type='button' value='add more' onClick={this.addClickSystems.bind(this)}
                                className="btn-link btn btn-info nc-icon nc-simple-add"/>
                        <label>{this.props.t('Add System')}</label>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={6}>
                      <Button className="btn-square w-100" color="info" type="submit">
                        {this.props.t('Submit')}
                      </Button>
                    </Col>


                  </Row>


                </Form>

              </CardBody>
            </Col>
          </Row>
          }
        </Dock>

        <Button color="info" type="submit"
                onClick={() => this.setState({ showForm: !this.state.showForm })}>
          {this.props.t('Add Building')}
        </Button>
        <ReactTable
          data={this.props.projectStructureTableData}
          columns={[
            {
              Header: "Building Id",
              accessor: "_id",
              show: false
            },
            {
              Header: this.props.t("Building"),
              accessor: "building",
              Cell: this.renderEditable
            },
            {
              Header: this.props.t("Levels"),
                id: 'levelsx',
              accessor: data => {
                let output = [];
                _.map(data.levelsx, level => {
                    output.push(level.name);
                });
                return output.join(', ');},
              Cell: this.renderEditable
            },
            {
              Header: this.props.t("Elevations"),
                id: 'elevationsx',
              accessor: data => {
                let output = [];
                _.map(data.elevationsx, elevation => {
                    output.push(elevation.name);
                });
                return output.join(', ');},
              Cell: this.renderEditable
            },
            {
              Header: this.props.t("Systems"),
                id: 'systemsx',
              accessor: data => {
                let output = [];
                _.map(data.systemsx, system => {
                    output.push(system.name);
                });
                return output.join(', ');},
              Cell: this.renderEditable
            },
            {
              Header: "Actions",
              id: "activateToggle",
              // width: Math.round(window.innerWidth * 0.25),
              Cell: ((row) => (this.renderActiveButtons(row.original)))
            },

          ]}
          defaultPageSize={1}
          pageSize={this.props.projectStructureTableData.length}
          showPaginationTop={false}
          showPaginationBottom={false}
          // getTrProps={this.onRowClick}
          // getTrProps={onRowClick}
          /*
            You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
          */
          className="-striped -highlight primary-pagination"
        />
      </>
    );
  }
}

const AddProjectStructure = withTranslation()(connect(mapStateToProps, null)(ConnectedProjectStructure));
export default AddProjectStructure;
