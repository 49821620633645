import React from "react";
import AddGeneralCommentsForm from "views/forms/AddGeneralCommentsForm"
import GeneralCommentsTable from "views/tables/GeneralCommentsTable"
import GeneralCommentsPreviousTable from 'views/tables/GeneralCommentsPreviousTable'

// reactstrap components
import {
    Card,
    CardTitle,
    CardBody,
    Row,
    Col, Button,
} from "reactstrap";
import {connect} from "react-redux";
import {getGeneralComments, getGeneralCommentsByDateAndByProject} from "../../actions/index";
import {userCant, userCan} from "../../services/Authorization/authService";
import {Dock} from "react-dock"
import {withTranslation} from 'react-i18next';


function mapStateToProps(state) {
    return {
        project: state.allOtherReducers.project,
        projectId: state.allOtherReducers.projectId,
        projectData: state.allOtherReducers.projectData,
        reportDate: state.allOtherReducers.reportDate,
        generalCommentData: state.allOtherReducers.generalCommentData,
        allGeneralCommentData: state.allOtherReducers.allGeneralCommentData,
        user: state.auth.user,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getGeneralComments: projectId => dispatch(getGeneralComments(projectId)),
        getGeneralCommentsByDateAndByProject: (projectId, date) => dispatch(getGeneralCommentsByDateAndByProject(projectId, date))
    };
}

class ConnectedGeneralComments extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showForm: false,
            editComment: null,
            generalCommentData: props.generalCommentData
        };
        var projectId = this.props.projectId
        var reportDate = this.props.reportDate
        this.props.getGeneralComments({projectId})
        this.props.getGeneralCommentsByDateAndByProject({projectId, reportDate})

        this.hideCommentForm = this.hideCommentForm.bind(this)
        this.setEditingComment = this.setEditingComment.bind(this)
        // this.getSomeGeneralComments();
    }

    componentWillMount() {
        if (userCant(this.props.user, 'generalComments.index') &&
            userCant(this.props.user, 'generalComments.manage')) {
            return this.props.history.push('/admin/instructions')
        }
    }

    componentDidMount() {

        var startDate = new Date();
        startDate.setDate(startDate.getDate() - 7);

        var endDate = new Date();
        endDate.setDate(endDate.getDate());
        var getDateArray = function (start, end) {
            var arr = new Array();
            var dt = new Date(start);
            while (dt <= end) {
                arr.push(new Date(dt));
                dt.setDate(dt.getDate() + 1);
            }
            return arr;
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.reportDate !== prevProps.reportDate || this.props.projectId !== prevProps.projectId) {
            this.props.getGeneralComments({projectId: this.props.projectId});

            this.props.getGeneralCommentsByDateAndByProject({
                projectId: this.props.projectId,
                reportDate: this.props.reportDate
            })
        }
    }


    dateSelectedFromDropdownIntervals(dateIn) {
        this.setState({dateSelected: dateIn}, () => this.state.dateSelected)
    }

    getScheduleClickedOnText = (gotText) => {
        this.setState({scheduleClickedItem: gotText}, () => this.state.scheduleClickedItem)
    }


    updateGeneralCommentData = (data) => {
        this.setState({generalCommentData: data}, () => this.state.generalCommentData)

    }

    hideCommentForm(comment = null, newComment = null) {
        this.setState({
            showForm: false,
            editComment: null
        })

        if (comment) {
            this.refreshComment(comment);
        }

        if (newComment) {
            this.state.generalCommentData.push(newComment)

            this.props.getGeneralComments({
                projectId: this.props.projectId
            })
            this.updateGeneralCommentData(
                this.state.generalCommentData
            )
        }
    }

    refreshComment(comment) {
        let commentsData = this.state.generalCommentData.map(r => r._id === comment._id ? comment : r)
        this.updateGeneralCommentData(commentsData)
    }

    setEditingComment(comment) {
        this.setState({
            editComment: comment,
            showForm: true
        })
    }


    render() {
        if (userCant(this.props.user, 'generalComments.index') &&
            userCant(this.props.user, 'generalComments.manage')) {
            return;
        }

        return (
            <>
                <div className="content">
                    <Row>
                        <Col tag="h3">{this.props.t("General Comments")}</Col>
                    </Row>

                    <Dock
                        position='right'
                        isVisible={this.state.showForm}
                        duration={300}
                        size={window.innerWidth < 600 ? 1 : (window.innerWidth < 900 ? 0.5 : 0.3)}
                    >
                        {this.state.showForm && <AddGeneralCommentsForm
                            editComment={this.state.editComment}
                            hideCommentForm={this.hideCommentForm}/>}
                    </Dock>
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>

                                    {userCan(this.props.user, 'generalComments.manage') &&
                                    <Button color="info"
                                            onClick={() => this.setState({showForm: !this.state.showForm})}>
                                        {this.props.t("Add Comment")}
                                    </Button>}

                                    <GeneralCommentsTable
                                        setEditingComment={this.setEditingComment}
                                        generalCommentData={this.props.generalCommentData}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Card>

                                <CardBody>
                                    <GeneralCommentsPreviousTable/>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}


const GeneralComments = withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ConnectedGeneralComments));
export default GeneralComments;
