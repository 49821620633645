const helper = {

  formatContractedWorkDataForTables(dataToFormat) {
    if (dataToFormat) {
      var compositeScheduledItemId;
      const formattedData = dataToFormat.map(elt => {
        console.log(elt)
        if (elt.elevation) { var compositeElevation = elt.elevation.name } else { var compositeElevation = '' }
        if (elt.level) { var compositeLevel = elt.level.name } else { var compositeLevel = '' }
        // if (elt.system) { var compositeSystem = elt.system.name } else { var compositeSystem = '' }
        if (elt.hasOwnProperty('system')) {
          if (elt.system != null) {
            console.log(elt.system.name)
            var compositeSystem =  elt.system.name
          }
          else
          {
            var compositeSystem = 'test'
          }
        }
        if (elt.comment.length > 0) { var compositeComment = elt.comment } else { var compositeComment = '' }
        if (elt.hasOwnProperty('task')) {
          if (elt.task != null) {
            console.log(elt.task.taskName)
            var compositeTaskName = elt.task.taskName
          }
          else
          {
            var compositeTaskName = ''
          }
        }
        else {
          var compositeTaskName = ''
        }
        if (elt.hasOwnProperty('material')) {
          if (elt.material !== null) {
            var compositeMaterialName = elt.material.materialName
          }
          else
          {
            var compositeMaterialName = ''
          }
        }
        else {
          var compositeMaterialName = ''
        }
        if (typeof elt.scheduledItemId === null) {
          compositeScheduledItemId = elt.scheduledItem
        } else {
          console.log(elt.scheduledItemId)
          compositeScheduledItemId = elt.scheduledItemId?.full_path }
        var nameComma = ''
        return [
          // format output
          elt.building?.building
          + ' '
          + compositeSystem
          + ': '
          + compositeTaskName
          + ' '
          + compositeMaterialName
          + ' '
          + compositeLevel
          + ' '
          + compositeElevation
          + '\n'
          + 'Comments: ' + compositeComment,
          elt.duration,
          elt._id,
          elt.projectId,
          elt.scheduledItem,
          compositeScheduledItemId,
          elt.timesheetLogDetail
        ]
      }
      )
      const tableStructure = formattedData.map(x => {
        return {
          '_id': x[2],
          'projectId': x[3],
          'scheduledItem': x[5],
          'contractedWorkDetail': x[0],
          'timesheetLogDetail': x[6],
          'duration': x[1]
        }
      }
      )
      console.log(tableStructure)
      return formattedData
    }
    else {
      var str = 'ERR:No contracted work data provide'
      console.log(str)
      return []
    }
  }
}

module.exports = helper;
