import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
import axios from "axios";
import InputMask from 'react-input-mask';
import helper from '../../../utils/general_helper.js'
import { withTranslation } from 'react-i18next';

// reactstrap components
import {
  Button,
  Input,
  Col,
  Row,
  Table
} from "reactstrap";
import {connect} from "react-redux";
import {userCant} from "../../../services/Authorization/authService";

class ContractorWorkTable extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      editContent: false,
      editingRow: -1,
      isDirty: false
    }
    this.deleteContractorWorkItemById = this.deleteContractorWorkItemById.bind(this)
    this.saveRowData = this.saveRowData.bind(this)
    this.toggleEditable = this.toggleEditable.bind(this)
    this.renderActiveButtons = this.renderActiveButtons.bind(this)
    this.renderEditable = this.renderEditable.bind(this)

  }

  componentWillReceiveProps(prevProps, { contractorWorkTableData, formattedTableStructure }) {
    this.setState({ ...this.state, contractorWorkTableData, formattedTableStructure }, () => {
    })

  }

  handleInputChange = (cellInfo, event) => {
    let data = [...this.props.contractorWorkTableData];
    data[cellInfo.index][cellInfo.column.id] = event.target.value;

    this.setState({ data });
  };
  renderEditable(cellInfo) {

    const cellValue = this.props.contractorWorkTableData[cellInfo.index][cellInfo.column.id];

    const { editingRow } = this.state;
    return (
      <div>
        {editingRow === cellInfo.index ? (
          <Input
            maskChar=""
            mask=""
            placeholder=""
            onChange={this.handleInputChange.bind(null, cellInfo)}
            value={cellValue}
            tag={InputMask}
          />
        ) : (<div >
          {cellInfo.value}
        </div>)}
      </div>
    );
  }


  saveRowData(cellInfo) {
    this.setState({ editContent: false }, () => {})

    axios.put(process.env.REACT_APP_API_URL + '/api/contractorwork/', {
      _id: cellInfo.original._id,
      numberHoursAllocated: cellInfo.original.numberHoursAllocated,
      numberEmployeesWorking: cellInfo.original.numberEmployeesWorking,
    }, {
      headers: {
        'Authorization': localStorage.jwtToken
      }
    }).then(res => {
      this.setState({
        editingRow: -1,
        isDirty: false
      })
      return res.data
    })
      .catch((error) => {
        ;
      })
  }


  toggleEditable(index) {
    console.log(index);
    const { isDirty } = this.state;
    if (!isDirty) {
      this.setState({
        editingRow: index,
        isDirty: true
      });
    }
    else {
      this.setState({
        editingRow: -1,
        isDirty: false
      })
    }
  }

  renderActiveButtons(row) {
    if (userCant(this.props.user, 'contractwork.manage')) {
      return '';
    }

    return (<div>
      <Button
        color="danger"
        size="sm"
        className="btn-icon btn-neutral btn btn-info"
        onClick={() => {
          this.deleteContractorWorkItemById(row.original._id)
        }
        }
      ><i
          className="fa fa-remove"
        />
      </Button>
       <Button
        color="danger"
        size="sm"
        className="btn-icon btn-neutral btn btn-info"
        onClick={() => {
          this.props.isUpdateDockVisibleControl(row.original._id, this.props.contractorWorkTableData)
        }
        }
      ><i
          className="fa fa-edit"
        />
      </Button>
    </div>)


  }

  deleteContractorWorkItemById(contractorWorkItemId) {
    axios.delete(process.env.REACT_APP_API_URL + '/api/contractorwork/' + contractorWorkItemId, {
      headers: {
        'Authorization': localStorage.jwtToken
      }
    }).then(res => {
      this.props.getContractorWorkTable()
      return res
    })
      .catch((error) => {
        ;
      })
  }



  render() {

    return (
      <>
        <div className="content">
          <ReactTable
            data={this.props.formattedTableStructure}
            columns={[
              {
                Header: "Id",
                accessor: "_id",
                show: false
              },
              {
                Header: this.props.t("Project Id"),
                accessor: "projectId",
                show: false
              },
              {
                Header: this.props.t("Scheduled Item"),
                accessor: "scheduledItem",
                style: { 'whiteSpace': 'unset' },
                width: 300,
              },
              {
                Header: this.props.t("Detail"),
                accessor: "contractedWorkDetail",
                className: "frozen",
                Cell: (props) => {
                  let multiLineString = props.value
                  let lines = multiLineString.split('\n')
                  return lines.map((line) => <div>{line}</div>);
                },
              },
              {
                Header: this.props.t("Employee Detail"),
                accessor: "timesheetLogDetail",
                // className: "frozen",
                Cell: (props) => {


                  return props.value.map((timesheetLogDetail) =>
                      <Row striped>
                        <Col>{timesheetLogDetail.user_id.name + ' ' + timesheetLogDetail.user_id.last_name}</Col><Col>{helper.hoursMinutesAllocateTimeFormatted(timesheetLogDetail.allocated)}</Col>
                      </Row>

                  )
                },

              },
              {
                Header: this.props.t("Total employee duration"),
                accessor: "timesheetLogDetail",
                // className: "frozen",
                Cell: (props) => {

                  return helper.hoursMinutesAllocateTimeFormatted(props.value.reduce((r, d) => r + d.allocated, 0));

                },

              },
              // {
              //   Header: "Work Duration",
              //   accessor: "duration",
              //   width: 150,
              // },
              {
                Header: this.props.t("Actions"),
                id: "activateToggle",
                Cell: ((value) => (this.renderActiveButtons(value))),
                className: "frozen",
                width: 200,
              }
            ]}
            defaultPageSize={1}
            pageSize={this.props.contractorWorkTableData.length}
            showPaginationTop={false}
            showPaginationBottom={false}
            className="-striped -highlight primary-pagination"
          />
        </div>
      </>
    );
  }
}


function mapStateToProps(state) {
  return {
    project: state.allOtherReducers.project,
    projectId: state.allOtherReducers.projectId,
    projectData: state.allOtherReducers.projectData,
    reportDate: state.allOtherReducers.reportDate,
    user: state.auth.user
  };
}

export default withTranslation()(connect(mapStateToProps)(ContractorWorkTable));

