import React from "react";
import Select from "react-select";
import axios from "axios"
import TreeNode from "views/components/TreeNode";
import TimesheetLogTableContractedWork from 'views/tables/TimesheetLogTableContractedWork'
import Plan from "views/pages/PlanManagement/PlanManagement"
import {setScheduleItem} from '../../../actions/scheduleActions'
import {withTranslation} from 'react-i18next';


// reactstrap components
import {
    Button,
    CardGroup,
    Card,
    CardBody,
    FormGroup,
    Form,
    Input,
    Row,
    Col,
} from "reactstrap";

import moment from "moment"
import {connect} from "react-redux";

function mapStateToProps(state) {
    return {
        project: state.allOtherReducers.project,
        projectId: state.allOtherReducers.projectId,
        projectData: state.allOtherReducers.projectData,
        reportDate: state.allOtherReducers.reportDate,
        scheduleItem: state.schedule.scheduleItem,
        scheduleItemId: state.schedule.scheduleItemId
    };
}

function mapDispatchToProps(dispatch) {
    return {

        setScheduleItem: scheduleItem => dispatch(setScheduleItem(scheduleItem)),
    }
}


class ConnectedContractorWorksFormByEmployee extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            singleMaterialSelect: null,
            singleTaskSelect: null,
            singleSiteLocationSelect: null,
            singleHoursWorkedSelect: null,
            singleEmployeesInCompanyValue: null,
            singleSelect: 'test',
            multipleSelect: null,
            callFromAPI: [],
            employeesInCompany: [{}],
            selectedValueCompany: this.props.t('Select Company'),
            matertialFromAPI: [],
            taskFromAPI: [],
            siteLocationFromAPI: [],
            hoursWorkedFromAPI: [],
            contractWorkDate: "",
            defaultDate: "",
            modalClassic: false,
            dynamicTreeData: [],
            scheduleClickedItem: '',
            scheduleClickedItemId: '',
            projectStructureBuildingAPI: [],
            sinlgeProjectStructureBuildingAPI: '',
            projectStructureLevelAPI: [],
            sinlgeProjectStructureLevelAPI: null,
            projectStructureElevationAPI: [],
            sinlgeProjectStructureElevationAPI: null,
            projectStructureSystemAPI: [],
            sinlgeProjectStructureSystemAPI: null,
            employeesWorkingToday: [],
            timesheetdatafetch: [],
            selectedEmployeeList: [],
            selectedDuration: '',
            clearChecks: null,
            comment: '',
            errors: {}

        };
        this.formSubmitSuccess = this.formSubmitSuccess.bind(this)
        this.insertProjectSchedule = this.insertProjectSchedule.bind(this)
        this.getScheduleClickedOnText = this.getScheduleClickedOnText.bind(this)

    }

    componentDidMount() {
        this.populateProjectStructureBuildings(this.props.projectStructureTableData)
        this.getProjectSchedule()
        this.getTimesheetLogByDate(this.props.reportDate)
        this.populateDropDownCompany(this.props.contractorSummaryTableData);
        this.populateDropDownMaterials(this.props.materialTableData);
        this.populateDropDownTasks(this.props.taskTableData)
        this.populateDropDownSiteLocations(this.props.siteLocationTableData)
        this.populateDropDownHoursWorked(this.props.hoursWorked)
        this.populateProjectStructureLevelsAndElevations(this.props.projectStructureTableData)
    }
    componentDidUpdate(prevProps, prevState) {


        if (this.props.isDockVisible !== prevProps.isDockVisible) {
            this.getTimesheetLogByDate(this.props.reportDate)
            this.getProjectSchedule();
        }

        if (this.props.contractorSummaryTableData !== prevProps.contractorSummaryTableData) {
            this.populateDropDownCompany(this.props.contractorSummaryTableData);

        }

        if (this.props.materialTableData !== prevProps.materialTableData) {
            this.populateDropDownMaterials(this.props.materialTableData);
        }

        if (this.props.taskTableData !== prevProps.taskTableData) {
            this.populateDropDownTasks(this.props.taskTableData)
        }

        if (this.props.siteLocationTableData !== prevProps.siteLocationTableData) {
            this.populateDropDownSiteLocations(this.props.siteLocationTableData)
        }

        if (this.props.hoursWorked !== prevProps.hoursWorked) {
            this.populateDropDownHoursWorked(this.props.hoursWorked)
        }

        if (this.props.projectId !== prevProps.projectId) {
            this.getProjectSchedule();
        }
        if (this.props.projectStructureTableData !== prevProps.projectStructureTableData) {
            this.populateProjectStructureBuildings(this.props.projectStructureTableData)
        }

        if (this.state.sinlgeProjectStructureBuildingAPI?.value !== prevState.sinlgeProjectStructureBuildingAPI?.value) {
            this.populateProjectStructureLevelsAndElevations(this.props.projectStructureTableData)
            this.setState({sinlgeProjectStructureLevelAPI: ''})
        }

        // if (this.props.projectId !== prevProps.projectId) {
        //   this.getTimesheetLogByDate(this.props.reportDate)
        // }

        // if (this.props.reportDate !== prevProps.reportDate) {
        //   this.getTimesheetLogByDate(this.props.reportDate)
        // }

        if (this.props.contractedWorkRowData !== prevProps.contractedWorkRowData) {

            this.getTimesheetLogByDate(this.props.reportDate)
        }
        if (this.props.shouldUpdateWorkingHours === true) {
            this.getTimesheetLogByDate(this.props.reportDate).then(() => {
                    this.props.setShowUpdateWorkingHoursToFalse()
                }
            )
        }


    }

    populateProjectStructureBuildings(data) {
        var testdata = data
        const options = testdata.map(projectStructure => {
            return {value: projectStructure._id, label: projectStructure.building}
        })

        this.setState({
            projectStructureBuildingAPI: options,
            sinlgeProjectStructureLevelAPI: ''
        }, () => {
        })
    }

    populateProjectStructureLevelsAndElevations(data) {
        var projectStructureData = data.find((x) => x._id === this.state.sinlgeProjectStructureBuildingAPI?.value)
        if (typeof projectStructureData === 'object' && projectStructureData !== null) {

            var optionsLevel = []
            var levelProp = 'levelsx';
            if (projectStructureData.hasOwnProperty(levelProp)) {
                optionsLevel = projectStructureData.levelsx.map(level => {

                    return {value: level._id, label: level.name}
                })
            }

            var optionsElevations = []
            var elevationProp = 'elevationsx';
            if (projectStructureData.hasOwnProperty(elevationProp)) {
                optionsElevations = projectStructureData.elevationsx.map(elevation => {
                    return {value: elevation._id, label: elevation.name}
                })
            }
            var optionsSystem = []
            var systemProp = 'systemsx';
            if (projectStructureData.hasOwnProperty(systemProp)) {
                optionsSystem = projectStructureData.systemsx.map(system => {
                        return {value: system._id, label: system.name}
                    }
                )
            }
            this.setState({
                projectStructureLevelAPI: optionsLevel,
                projectStructureElevationAPI: optionsElevations,
                projectStructureSystemAPI: optionsSystem
            }, () => {
            })
        }
    }

    // populateProjectStructureElevations(data) {
    //   var testdata = data
    // }


    populateDropDownCompany(data) {
        var testdata = data
        const options = testdata.map(company => {
            return {value: company.company, label: company.company}
        })
        this.setState({callFromAPI: options}, () => {
        })
    }

    populateDropDownMaterials(data) {
        var testdata = data
        const options = testdata.map(material => {
            return {value: material._id, label: material.materialName}
        })
        this.setState({matertialFromAPI: options}, () => {
        })
    }

    populateDropDownTasks(data) {
        var testdata = data
        const options = testdata.map(task => {
            return {value: task._id, label: task.taskName}
        })
        this.setState({taskFromAPI: options}, () => {
        })
    }

    populateDropDownSiteLocations(data) {
        var testdata = data
        const options = testdata.map(sitelocation => {
            return {value: sitelocation.siteLocationName, label: sitelocation.siteLocationName}
        })
        this.setState({siteLocationFromAPI: options}, () => {
        })
    }

    populateDropDownHoursWorked(data) {
        var testdata = data
        const options = testdata.map(hoursworked => {
            return {value: moment(hoursworked).format("H:mm"), label: moment(hoursworked).format('H [Hr] mm [Min]')}
        })

        this.setState({hoursWorkedFromAPI: options}, () => {
        })
    }

    handleCompanyChange(value, arr) {
        this.setState({selectedValueCompany: value}, () => {
        })
        let found = arr.find(element => element.company === value.value);
        let employeeCountList = Array.from(Array(found.count + 1).keys()).slice(1)
        const options = employeeCountList.map(employeeCL => {
            return {value: employeeCL, label: employeeCL}
        })

        this.setState({employeesInCompany: options}, () => {
        })

    }

    insertProjectSchedule() {
        axios.post(process.env.REACT_APP_API_URL + '/api/projectschedule/insert/project/' + this.props.projectId, {}, {
            headers: {
                'Authorization': localStorage.jwtToken
            }
        })
            .then(res => {
            })
    }

    formSubmitSuccess(e) {
        e.preventDefault();
        let filteredLogIds = this.state.timesheetdatafetch.filter(logIds => {
            if (logIds.allocatedHours > 0 | logIds.allocatedMinutes > 0)
                return logIds._id
        });

        var employeesSelected = []
        for (const selected of filteredLogIds) {
            employeesSelected.push(
                {
                    ['_id']: selected._id,
                    ['user_id']: selected.user_id,
                    ['allocatedHours']: selected.allocatedHours,
                    ['allocatedMinutes']: selected.allocatedMinutes
                }
            )
        }

        const data = new FormData(e.target);
        axios.post(process.env.REACT_APP_API_URL + "/api/contractorwork/" + this.props.projectId, {
            scheduledItem: data.get('scheduledItem'),
            scheduledItemId: data.get('scheduledItemId'),
            building: data.get('building'),
            level: data.get('level'),
            elevation: data.get('elevation'),
            system: data.get('system'),
            task: data.get('taskName'),
            material: data.get('materialName'),
            employees: employeesSelected,
            duration: data.get('selectedDuration'),
            comment: data.get('comment'),
            date: this.props.reportDate
        }, {
            headers: {
                'Authorization': localStorage.jwtToken
            }
        })
            .then(res => {
                this.setState({
                    timesheetdatafetch: []
                }, () => {
                })

                this.setState({
                    scheduleClickedItem: '',
                    scheduleClickedItemId: '',
                    selectedValueCompany: '',
                    singleSiteLocationSelect: '',
                    singleTaskSelect: '',
                    singleMaterialSelect: '',
                    singleHoursWorkedSelect: "00:00",
                    employeesInCompany: [{value: "Select No. Workers", label: this.props.t("Select No.Workers")}],
                    employeesInCompanyValue: null,
                    sinlgeProjectStructureLevelAPI: null,
                    sinlgeProjectStructureElevationAPI: null,
                    sinlgeProjectStructureSystemAPI: null,
                    sinlgeProjectStructureBuildingAPI: '',
                    selectedDuration: "00:00",
                    clearChecks: true,
                    selectedEmployeeList: [],
                    comment: '',
                    defaultDate: moment(),
                    errors: {}
                }, () => {
                    this.props.getContractorWorkTable();
                    this.getTimesheetLogByDate(this.props.reportDate)
                    this.props.setScheduleItem(null)
                })
                return res.data
            })
            .catch((error) => {
                this.setState({
                    errors: error.response.data
                })
            })

    }

    componentWillReceiveProps(prevProps, {
        scheduleClickedItem,
        scheduleClickedItemId,
        contractorSummaryTableData,
        isDockVisible,
        materialTableData,
        taskTableData,
        siteLocationData,
        hoursWorked,
        projectStructureTableData,
        contractedWorkRowData,
        shouldUpdateWorkingHours
    }) {
        this.setState({
            ...this.state,
            scheduleClickedItem,
            scheduleClickedItemId,
            contractorSummaryTableData,
            isDockVisible,
            materialTableData,
            taskTableData,
            siteLocationData,
            hoursWorked,
            projectStructureTableData,
            contractedWorkRowData,
            shouldUpdateWorkingHours
        }, () => {
        })

    }

    //PROJECT SCHEDULE FUNCTIONS
    async getProjectSchedule() {
        const treeData = await axios.get(process.env.REACT_APP_API_URL + '/api/projectschedule/project/' + this.props.projectId, {
            headers: {
                'Authorization': localStorage.jwtToken
            }
        })
            .catch((error) => {

            })
        this.setState({dynamicTreeData: treeData.data})

    }

    getScheduleClickedOnText = (gotText, gotId) => {
        this.setState({
            scheduleClickedItem: gotText,
            scheduleClickedItemId: gotId

        }, () => {
        })
    }

    //^^ PROJECT SCHEDULE FUNCTIONS ^^

    async getTimesheetLogByDate(dateToSearch) {
        const timesheetLogByDate = await axios.get(process.env.REACT_APP_API_URL + '/api/timesheetlog/' + dateToSearch + "/projects/" + this.props.projectId, {
            headers: {
                'Authorization': localStorage.jwtToken
            }
        }).catch((error) => {
            ;
        })

        this.setState({
            timesheetdatafetch: timesheetLogByDate.data
        }, () => {
        });
        return timesheetLogByDate.data
    }


    render() {
        const divStyle = {
            overflowY: 'scroll',
            width: '500px',
            float: 'left',
            height: '300px',
            position: 'relative'
        };
        // var plan = this.props.isDockVisible ? null : <Plan ref="plan" />
        return (
            <>
                <Form className="form-horizontal" onSubmit={(e) => this.formSubmitSuccess(e)}>
                    <Row>

                        <Col>
                            <CardGroup>
                                <Card>
                                    {/* {plan} */}
                                    <Plan
                                        object={this.props.contractedWorkRowData}
                                    />
                                </Card>
                                <Card>
                                    <CardBody>
                                        <h7>{this.props.t("Scheduled Item")}</h7>
                                        <FormGroup>
                                            <p style={{
                                                color: '#51cbce',
                                                border: '1px #E3E3E3 solid',
                                                padding: '5px',
                                                borderRadius: '3%'
                                            }}>{this.props.scheduleItem}</p>
                                            <Input type="hidden" name="scheduledItemId"
                                                   value={this.props.scheduleItemId}></Input>
                                            <Input type="hidden" name="scheduledItem"
                                                   value={this.props.scheduleItem}></Input>

                                            {this.state.errors.scheduledItem && (
                                                <div
                                                    className="invalid-feedback d-block">{this.state.errors.scheduledItem}</div>
                                            )}
                                        </FormGroup>


                                        <h7>{this.props.t("Building")}</h7>
                                        <FormGroup>
                                            <Select
                                                className="react-select primary"
                                                classNamePrefix="react-select"
                                                name="building"
                                                value={this.state.sinlgeProjectStructureBuildingAPI}
                                                onChange={value =>
                                                    this.setState({sinlgeProjectStructureBuildingAPI: value})}
                                                options={this.state.projectStructureBuildingAPI}
                                                placeholder={this.props.t("Choose Building")}
                                            />
                                            {this.state.errors.building && (
                                                <div
                                                    className="invalid-feedback d-block">{this.state.errors.building}</div>
                                            )}
                                        </FormGroup>


                                        <h7>{this.props.t("Level")}</h7>
                                        <FormGroup>
                                            <Select
                                                className="react-select primary"
                                                classNamePrefix="react-select"
                                                name="level"
                                                value={this.state.sinlgeProjectStructureLevelAPI}
                                                onChange={value =>
                                                    this.setState({sinlgeProjectStructureLevelAPI: value}, () => {
                                                    })
                                                }
                                                options={this.state.projectStructureLevelAPI}
                                                placeholder={this.props.t("Choose Level")}
                                            />
                                            {this.state.errors.level && (
                                                <div
                                                    className="invalid-feedback d-block">{this.state.errors.level}</div>
                                            )}
                                        </FormGroup>

                                        <h7>{this.props.t("Elevation")}</h7>
                                        <FormGroup>
                                            <Select
                                                className="react-select primary"
                                                classNamePrefix="react-select"
                                                name="elevation"
                                                value={this.state.sinlgeProjectStructureElevationAPI}
                                                onChange={value =>
                                                    this.setState({sinlgeProjectStructureElevationAPI: value}, () => {
                                                    })
                                                }
                                                options={this.state.projectStructureElevationAPI}
                                                placeholder={this.props.t("Choose Elevation")}
                                            />

                                            {this.state.errors.elevation && (
                                                <div
                                                    className="invalid-feedback d-block">{this.state.errors.elevation}</div>
                                            )}
                                        </FormGroup>


                                        <h7>{this.props.t("System")}</h7>
                                        <FormGroup>
                                            <Select
                                                className="react-select primary"
                                                classNamePrefix="react-select"
                                                name="system"
                                                value={this.state.sinlgeProjectStructureSystemAPI}
                                                onChange={value =>
                                                    this.setState({sinlgeProjectStructureSystemAPI: value})
                                                }
                                                options={this.state.projectStructureSystemAPI}
                                                placeholder={this.props.t("Choose System")}
                                            />

                                            {this.state.errors.system && (
                                                <div
                                                    className="invalid-feedback d-block">{this.state.errors.system}</div>
                                            )}
                                        </FormGroup>
                                    </CardBody>
                                </Card>

                                <Card>
                                    <CardBody>
                                        <h7>{this.props.t("Task")}</h7>
                                        <FormGroup>
                                            <Select
                                                className="react-select primary"
                                                classNamePrefix="react-select"
                                                name="taskName"
                                                value={this.state.singleTaskSelect}
                                                onChange={value =>
                                                    this.setState({singleTaskSelect: value})
                                                }
                                                options={this.state.taskFromAPI}
                                                placeholder={this.props.t("Choose Task")}
                                            />

                                            {this.state.errors.task && (
                                                <div className="invalid-feedback d-block">{this.state.errors.task}</div>
                                            )}
                                        </FormGroup>

                                        <h7>Material</h7>
                                        <FormGroup>
                                            <Select
                                                className="react-select primary"
                                                classNamePrefix="react-select"
                                                name="materialName"
                                                value={this.state.singleMaterialSelect}
                                                onChange={value =>
                                                    this.setState({singleMaterialSelect: value})
                                                }
                                                options={this.state.matertialFromAPI}
                                                placeholder={this.props.t("Choose Material")}
                                            />

                                            {this.state.errors.material && (
                                                <div
                                                    className="invalid-feedback d-block">{this.state.errors.material}</div>
                                            )}
                                        </FormGroup>

                                        <h7>{this.props.t("Comments")}</h7>
                                        <FormGroup>
                                            <Input
                                                type="textarea"
                                                name="comment"
                                                onChange={(e) => this.setState({comment: `${e.target.value}`})}
                                                value={this.state.comment}
                                                defaultValue={this.state.comment}

                                            />
                                        </FormGroup>
                                        {/* <h7>Duration (Hours)</h7>
                  <FormGroup>
                    <Select
                      className="react-select primary"
                      classNamePrefix="react-select"
                      name="selectedDuration"
                      value={this.state.selectedDuration}
                      onChange={value =>
                        this.setState({ selectedDuration: value })
                      }
                      options={this.state.hoursWorkedFromAPI}
                      placeholder="Select duration of work."
                    />
                  </FormGroup> */}
                                    </CardBody>
                                </Card>
                            </CardGroup>
                        </Col>
                    </Row>
                    <Row>
                        <br/>
                    </Row>
                    <Row>
                        <Col>
                            <CardGroup>
                                <Card>
                                    <CardBody>
                                        <TimesheetLogTableContractedWork
                                            timesheetLogs={this.state.timesheetdatafetch}
                                            getTimesheetLogByDate={this.getTimesheetLogByDate}
                                            dateSelected={this.props.reportDate}
                                        />
                                    </CardBody>
                                </Card>
                            </CardGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <Button color="info" type="submit">
                                {this.props.t("Submit")}
                            </Button>
                           </Col>
                    </Row>
                </Form>
            </>

        );
    }
}

const ContractorWorksFormByEmployee = withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ConnectedContractorWorksFormByEmployee));
export default ContractorWorksFormByEmployee;
