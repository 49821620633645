import Buttons from "views/components/Buttons.jsx";
import ExtendedForms from "views/forms/ExtendedForms.jsx";
import ExtendedTables from "views/tables/ExtendedTables.jsx";
import GridSystem from "views/components/GridSystem.jsx";
import Icons from "views/components/Icons.jsx";
import LockScreen from "views/pages/LockScreen.jsx";
import Login from "views/pages/Login.jsx";
import ResetPasswordRequest from "views/pages/ResetPasswordRequest.jsx";
import ResetPassword from "views/pages/ResetPassword.jsx";
import Notifications from "views/components/Notifications.jsx";
import Panels from "views/components/Panels.jsx";
import ReactTables from "views/tables/ReactTables.jsx";
import Register from "views/pages/Register.jsx";
import RegularForms from "views/forms/RegularForms.jsx";
import RegularTables from "views/tables/RegularTables.jsx";
import SweetAlert from "views/components/SweetAlert.jsx";
import Typography from "views/components/Typography.jsx";
import ValidationForms from "views/forms/ValidationForms.jsx";
import Widgets from "views/Widgets.jsx";
// import TimesheetDayView from "views/tables/TimesheetDayView.jsx";
import Timesheets from "views/pages/DailyWorkRecord/Timesheets.jsx";
import ContractedWork from "views/pages/ContractedWork/ContractedWork";
import AdditionalWork from "views/pages/AdditionalWork/AdditionalWork";
import SimpleWeather from "views/pages/Weather/SimpleWeather"
import UserDetails from "views/pages/Users/UserDetails";
import CustomFields from "views/pages/CustomFields/CustomFieldsIndex";
import UserProfile from "views/pages/Users/UserProfile";
import Equipment from "views/pages/Equipment/Equipment"
import Delays from "views/pages/Delays/Delays";
import Project from "views/pages/Projects";
import ProjectStructure from "views/pages/ProjectStructure/ProjectStructure";
import Absences from "views/pages/Absences"
import SiteReportNextGenFour from "views/pages/SiteReport/SiteReportNextGenFour"
import GeneralComments from "views/pages/GeneralComments"
import Company from "views/pages/Companies/Company"
import Instructions from "views/Instructions";
import Initialisation from "views/pages/Initialisation";
import ImageManagement from "views/pages/ImageManagement/UploadImages";
import UploadCompanyLogo from "views/pages/UploadCompanyLogo";
import ClientConfiguration from "views/pages/ClientConfiguration";
import GeneralReport from "views/pages/Reporting/GeneralReport"
import TagManagement from "views/pages/TagManagement/TagManagement"
import PlanManagement from "views/pages/PlanManagement/PlanManagement"
import {PhotoGallery} from "views/pages/PhotoGallery/PhotoGallery";
// import { userCan } from './services/Authorization/authService';
import store from './store/index'
import RolesDetails from "./views/pages/Roles/Roles";
import FileManager from "./views/pages/FileManager/FileManager";
import ClockInOuts from "./views/pages/ClockInOuts";
import UserWizard from "./views/pages/UserWizard/WizardSteps";
// const {user} = store.getState().auth

let clientConfig = store?.getState()?.auth?.client?.config || {};

let routes = [
    {
        path: "/instructions",
        name: "Instructions",
        icon: "nc-icon nc-alert-circle-i",
        component: Instructions,
        layout: "/admin"
    },
    {
        path: "/user-profile",
        name: "Profile",
        mini: "UP",
        component: UserProfile,
        layout: "/admin",
        show: "false",
        dontRequireProject: true
    },
    {
        collapse: true,
        name: "Site Diary",
        icon: "nc-icon nc-book-bookmark",
        state: "pagesCollapse",
        views: [

            {
                path: "/login",
                name: "Login",
                mini: "L",
                component: Login,
                layout: "/auth",
                show: "false"
            },
            {
                path: "/forgot-password",
                name: "Forgot Password",
                mini: "L",
                component: ResetPasswordRequest,
                layout: "/auth",
                show: "false"
            },
            {
                path: "/reset-password",
                name: "Reset Password",
                mini: "L",
                component: ResetPassword,
                layout: "/auth",
                show: "false"
            },
            {
                path: "/simpleweather",
                name: "Weather",
                mini: "W",
                component: SimpleWeather,
                layout: "/admin",
                permission: ['weather.index', 'weather.manage'],
            },
            {
                path: "/timesheets",
                name: "Time and Attendance",
                mini: "TAA",
                component: Timesheets,
                layout: "/admin",
                permission: ['timesheets.index', 'timesheets.manage'],
            },
            {
                path: "/clock-in-outs",
                name: "Clock In Outs",
                mini: "CIO",
                component: ClockInOuts,
                layout: "/admin",
                permission: 'clockinouts.manage',
            },
            {
                path: "/equipment",
                name: "Equipment",
                mini: "E",
                component: Equipment,
                layout: "/admin",
                permission: ['equipment.index', 'equipment.manage']
            },
            {
                path: "/contractwork",
                name: "Contract Work",
                mini: "CAW",
                component: ContractedWork,
                layout: "/admin",
                permission: ['contractwork.index', 'contractwork.manage']
            },
            {
                path: "/additionalwork",
                name: "Additional Work",
                mini: "AW",
                component: AdditionalWork,
                layout: "/admin",
                permission: ['additionalwork.index', 'additionalwork.manage']
            },
            {
                path: "/delaysandevents",
                name: "Delays and Events",
                mini: "DE",
                component: Delays,
                layout: "/admin",
                permission: ['delaysandevents.index', 'delaysandevents.manage']
            },
            {
                path: "/general-comments",
                name: "General Comments",
                mini: "GC",
                component: GeneralComments,
                layout: "/admin",
                permission: ['generalComments.index', 'generalComments.manage']
            },
            {
                path: "/register",
                name: "Register",
                mini: "R",
                component: Register,
                layout: "/auth",
                show: "false"
            },
            {
                path: "/site-report",
                name: "Site Report",
                mini: "SR",
                component: SiteReportNextGenFour,
                layout: "/admin",
                show: "true",
                permission: 'siteReport.manage'
            },
            {
                path: "/lock-screen",
                name: "LockScreen",
                mini: "LS",
                component: LockScreen,
                layout: "/app",
                show: "false"
            },
            {
                path: "/initialisation",
                name: "Initialisation",
                mini: "I",
                component: Initialisation,
                layout: "/app",
                show: "false",
                dontRequireProject: true
            },
            {
                path: "/complete-user-registration",
                name: "Wizard",
                mini: "W",
                component: UserWizard,
                layout: "/app",
                show: "false",
                dontRequireProject: true
            },
        ]
    },
    {
        collapse: true,
        name: "Images",
        icon: "nc-icon nc-book-bookmark",
        state: "imagesCollapse",
        views: [
            {
                path: "/photogallery",
                name: "Photo gallery",
                mini: "PG",
                component: PhotoGallery,
                layout: "/admin",
                show: "true",
                permission: 'images.manage'
            },
            {
                path: "/images",
                name: "Image Upload",
                mini: "IMG",
                component: ImageManagement,
                layout: "/admin",
                show: "true",
                permission: 'images.manage'
            },
            {
                path: "/tagmanagement",
                name: "Tag Management",
                mini: "TM",
                component: TagManagement,
                layout: "/admin",
                show: "true",
                permission: ['tags.index', 'tags.manage']
            },
            {
                path: "/planmanagement",
                name: "Plan Management",
                mini: "PM",
                component: PlanManagement,
                layout: "/admin",
                show: "false"
            },]
    },
    {
        collapse: true,
        name: "Reporting",
        icon: "nc-icon nc-book-bookmark",
        state: "reportingCollapse",
        views: [

            {
                path: "/general-report",
                name: "General Reporting",
                mini: "GR",
                component: GeneralReport,
                layout: "/report",
                show: "true",
                permission: 'generalReport.manage'
            },
        ]
    },
    {
        collapse: true,
        name: "Configuration",
        icon: "nc-icon nc-layout-11",
        state: "configurationCollapse",
        show: "true",
        views: [
            {
                path: "/projects",
                name: "Projects",
                mini: "P",
                component: Project,
                layout: "/admin",
                permission: 'projects.manage',
                dontRequireProject: true
            },
            {
                path: "/project-structure",
                name: "Project Structure",
                mini: "PS",
                component: ProjectStructure,
                layout: "/admin",
                permission: 'projectStructure.manage'
            },
            {
                path: "/companies",
                name: "Companies",
                mini: "C",
                component: Company,
                layout: "/admin",
                permission: 'companies.index',
                dontRequireProject: true
            },
            {
                path: "/users",
                name: "Users",
                mini: "UD",
                component: UserDetails,
                layout: "/admin",
                permission: 'users.index',
                dontRequireProject: true
            },
            {
                path: "/custom-fields",
                name: "Custom Fields",
                mini: "CF",
                component: CustomFields,
                layout: "/admin",
                permission: 'custom_fields.manage',
                dontRequireProject: true,
                show: clientConfig.enableCustomFields ? "true" : "false"
            },
            {
                path: "/roles",
                name: "Roles",
                mini: "R",
                component: RolesDetails,
                layout: "/admin",
                permission: 'roles.manage',
                dontRequireProject: true
            },
            {
                path: "/companylogo",
                name: "Logo",
                mini: "CL",
                component: UploadCompanyLogo,
                layout: "/admin",
                permission: 'logo.manage'
            },
            {
                path: "/client-configuration",
                name: "Client Configuration",
                mini: "CL",
                component: ClientConfiguration,
                layout: "/admin",
                permission: 'configuration.manage',
                dontRequireProject: true
            },
            {
                path: "/file-manager",
                name: "File manager",
                mini: "F",
                component: FileManager,
                layout: "/admin",
                show: clientConfig.enableFilesManager ? "true" : "false",
                permission: 'can_access_files_manager',
            },
        ]
    },
    {
        collapse: true,
        name: "Components",
        icon: "nc-icon nc-layout-11",
        state: "componentsCollapse",
        show: "false",
        views: [
            {
                path: "/buttons",
                name: "Buttons",
                mini: "B",
                component: Buttons,
                layout: "/admin"
            },
            {
                path: "/grid-system",
                name: "Grid System",
                mini: "GS",
                component: GridSystem,
                layout: "/admin"
            },
            {
                path: "/panels",
                name: "Panels",
                mini: "P",
                component: Panels,
                layout: "/admin"
            },
            {
                path: "/sweet-alert",
                name: "Sweet Alert",
                mini: "SA",
                component: SweetAlert,
                layout: "/admin"
            },
            {
                path: "/notifications",
                name: "Notifications",
                mini: "N",
                component: Notifications,
                layout: "/admin"
            },
            {
                path: "/icons",
                name: "Icons",
                mini: "I",
                component: Icons,
                layout: "/admin"
            },
            {
                path: "/typography",
                name: "Typography",
                mini: "T",
                component: Typography,
                layout: "/admin"
            },
            {
                path: "/test",
                name: "Absences",
                mini: "ABS",
                component: Absences,
                layout: "/admin"
            }
        ]
    },
    {
        collapse: true,
        name: "Forms",
        icon: "nc-icon nc-ruler-pencil",
        state: "formsCollapse",
        show: "false",
        views: [
            {
                path: "/regular-forms",
                name: "Regular Forms",
                mini: "RF",
                component: RegularForms,
                layout: "/admin"
            },
            {
                path: "/extended-forms",
                name: "Extended Forms",
                mini: "EF",
                component: ExtendedForms,
                layout: "/admin"
            },
            {
                path: "/validation-forms",
                name: "Validation Forms",
                mini: "VF",
                component: ValidationForms,
                layout: "/admin"
            },
        ]
    },
    {
        collapse: true,
        name: "Tables",
        icon: "nc-icon nc-single-copy-04",
        state: "tablesCollapse",
        show: "false",
        views: [
            {
                path: "/regular-tables",
                name: "Regular Tables",
                mini: "RT",
                component: RegularTables,
                layout: "/admin"
            },
            {
                path: "/extended-tables",
                name: "Extended Tables",
                mini: "ET",
                component: ExtendedTables,
                layout: "/admin"
            },
            {
                path: "/react-tables",
                name: "React Tables",
                mini: "RT",
                component: ReactTables,
                layout: "/admin"
            }
        ]
    },
    {
        path: "/widgets",
        name: "Widgets",
        icon: "nc-icon nc-box",
        component: Widgets,
        layout: "/admin",
        show: "false"
    },
];


export default routes;
