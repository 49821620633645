export default {
    selectedFiles: [],
    selectedFolder: '',
    bufferedItems: {
        type: '',
        files: []
    },
    foldersList: [],
    history: {
        currentIndex: 0,
        steps: []
    },
    filesList: [],
    showImages: 'icons',
    orderFiles: {
        field: 'date',
        orderBy: 'desc'
    },
    itemsView: 'list',
    pagination: {
        hasMore: true,
        nextPage: 2
    }
}