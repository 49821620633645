import {
    requestHeaderInterceptor,
    responseErrorInterceptor,
    responseSuccessInterceptor,
} from "./interceptors";
import axios from "axios";
import { authHeader } from './auth-header';


const REQUEST_TIMEOUT = 60000;

// const headers = {
//     Accept: "application/json",
//     "Content-Type": "application/json;charset=UTF-8",
//     'Authorization': localStorage.getItem('jwtToken'),
// };

const fetchTools = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: REQUEST_TIMEOUT,
    crossDomain: true,
    headers: authHeader(),
});

// fetchTools.interceptors.request.use(requestHeaderInterceptor, (error) =>{
//     console.log(localStorage.jwtToken)
//     Promise.reject(error)}
// );

fetchTools.interceptors.request.use(requestHeaderInterceptor, (error) => {
  
        Promise.reject(error)
}
);

fetchTools.interceptors.response.use(
    responseSuccessInterceptor,
    responseErrorInterceptor
);

export default fetchTools;