 
import React from "react";
import { createRoot } from 'react-dom/client';
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { Provider } from "react-redux";
import store from "../src/store/index";
import { setReportDate, getProjects } from "./actions/index";
import AuthLayout from "layouts/Auth.jsx";
import AdminLayout from "layouts/Admin.jsx";
import AppLayout from "layouts/App.jsx";
import ReportLayout from "layouts/Report.jsx";

import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/paper-dashboard.scss?v=1.1.0";
import "assets/demo/demo.css";

import "perfect-scrollbar/css/perfect-scrollbar.css";
import { unregister } from './registerServiceWorker';

import ReactGA from 'react-ga';
import moment from 'moment';
import { logoutUser } from './actions/authActions';
import CacheBuster from 'react-cache-buster';
import { version } from '../package.json';
import './i18n';

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript


ReactGA.initialize('UA-178440371-1');
ReactGA.pageview(window.location.pathname + window.location.search);

const history = createBrowserHistory();
console.log("STORECONTENTS")
// store.dispatch(getProjects())
store.dispatch(setReportDate(moment().format("YYYY-MM-DD")))
// registerServiceWorker();
if(localStorage.hasOwnProperty('state')){
  var authstate = localStorage.state
  var parsedAuthState = JSON.parse(authstate)
  if(parsedAuthState.hasOwnProperty('auth')){
    var expiredDate = parsedAuthState.auth.user.exp
    if (Date.now() >= expiredDate * 1000) {
      store.dispatch(logoutUser(history ))
    }

  }
}



unregister();
root.render(
  <CacheBuster
      currentVersion={version}
      isEnabled={true} //If false, the library is disabled.
      isVerboseMode={false} //If true, the library writes verbose logs to console.
      // loadingComponent={<Loading />} //If not pass, nothing appears at the time of new version check.
      metaFileDirectory={'.'} //If public assets are hosted somewhere other than root on your server.
    >
  <Provider store={store}>
  <Router history={history}>
    <Switch>
      {/* <Route exact path="/" component={Login} />   */}
      <Route path="/auth" render={props => <AuthLayout {...props} />} />
      <Route path="/admin" render={props => <AdminLayout {...props} />} />
      <Route path="/app" render={props => <AppLayout {...props} />} />
      <Route path="/report" render={props => <ReportLayout {...props} />} />
      <Redirect to="/auth/login"/>
    </Switch>
  </Router>
  </Provider>
  </CacheBuster>,
  // document.getElementById("root")
 
);



