
import React from "react";
import ReactTable from "react-table";
import { addProject, addProjectIdToPermissionsList, removeProjectIdToPermissionsList } from 'actions/index'
import moment from 'moment'
import { withRouter } from 'react-router-dom';

// reactstrap components
import {
  Input,
  InputGroup
} from "reactstrap";
import { connect } from "react-redux";
import Select from "react-select";
import {userCan} from "../../services/Authorization/authService";
import { withTranslation } from 'react-i18next';

function mapDispatchToProps(dispatch) {
  return {
    addProject: project => dispatch(addProject(project)),
    addProjectIdToPermissionsList: projectId => dispatch(addProjectIdToPermissionsList(projectId)),
    removeProjectIdToPermissionsList: projectId => dispatch(removeProjectIdToPermissionsList(projectId))
  }
}

function mapStateToProps(state) {
  return {
    project: state.allOtherReducers.project,
    projectId: state.allOtherReducers.projectId,
    projectData: state.allOtherReducers.projectData,
    reportDate: state.allOtherReducers.reportDate,
    projectPermissionsList: state.allOtherReducers.projectPermissionList,
    loggedInUser: state.auth.user
  };
}


class ProjectTable extends React.Component {
  constructor(props) {
    super(props);

      const {projects, disabledProjects} = this.getProjectsCanManage()

    this.state = {
      selected_ids: [],
      keyGen: [],
      checked: {},
        projectData: projects,
        disabledProjects : disabledProjects
    };
    this.updateStateList = this.updateStateList.bind(this)
    this.populateProjectPermissionsListWithCurrentUsersExistingPermissions = this.populateProjectPermissionsListWithCurrentUsersExistingPermissions.bind(this)
    this.filterProjectsByLocation = this.filterProjectsByLocation.bind(this)
  }

  componentDidMount() {
    this.populateProjectPermissionsListWithCurrentUsersExistingPermissions(this.props.rowData)

      const {projects, disabledProjects} = this.getProjectsCanManage()

      this.setState({
          projectData: projects,
          disabledProjects : disabledProjects
      })
  }
    getDisabledProjects(projectsCanManage){

        if (userCan(this.props.loggedInUser, 'can_access_all_projects')) {
            return []
        }

        //from target user
        if (!this.props.rowData.original.projects) {
            return []
        }

        return this.props.rowData.original.projects
            .filter(p => {
                if (!p.projectId) {
                    return false
                }
                return !projectsCanManage.includes(p.projectId._id)
            }).map(p => p.projectId);
    }

    getProjectsCanManage() {
        let accessibleProjects = this.filterProjectsByLocation();

        if (userCan(this.props.loggedInUser, 'can_access_all_projects')) {
            return {
                projects: accessibleProjects,
                disabledProjects: []
            }
        }

        let projectsCanManageIds = this.props.loggedInUser.projects
            .filter(p => !!p.is_admin)
            .map(p => p.projectId)

        let disabledProjects = this.getDisabledProjects(projectsCanManageIds);

        let projects = accessibleProjects
            .filter(p => projectsCanManageIds.includes(p._id))
            .concat(disabledProjects)

        return {
            projects,
            disabledProjects: disabledProjects.map(d => d._id)
        }
    }


    filterProjectsByLocation() {
        const userLocations = this.props.rowData.original.locations;

        if (!userLocations.length) {
            return [];
        }

        return this.props.projectData.filter(project => {
            return project.locations?.some(projectLocation => userLocations.find(userLocation => userLocation._id == projectLocation._id))
        });
    }

  componentDidUpdate(prevProps) {

  }
  handleTagsinput = tagsinput => {
    this.setState({ tagsinput });
  };
  componentWillReceiveProps(prevProps, { rowData }) {
    this.setState({
      ...this.state,  rowData
    }, () => { })

  }

 populateProjectPermissionsListWithCurrentUsersExistingPermissions(rowData)
 {

     var rowProp = 'row';
     if (rowData.hasOwnProperty(rowProp)) {

         if(!rowData.row._original.projects){
             return;
         }

         rowData.row._original.projects.forEach(p => {
             if (!p.projectId) {
                 return;
             }

             let shouldBeManageable = this.state.projectData.find(project => {
                 return project._id == p.projectId._id;
             });

             if (!shouldBeManageable) {
                 return;
             }
             this.props.addProjectIdToPermissionsList({
                 projectId: p.projectId._id,
                 is_admin: p.is_admin
             })
         })
     }
 }


  updateStateList(e, value) {
    if (e.target.checked) {
      const newSelected = Object.assign({}, this.state.checked);
      newSelected[value] = !this.state.checked[value];
      this.setState({
        keyGen: this.state.keyGen.concat([value]),
        checked: newSelected

      }, () =>
          this.props.addProjectIdToPermissionsList({
              projectId: value,
              is_admin: false
          })
      )
    } else {
      const newSelected = Object.assign({}, this.state.checked);
      newSelected[value] = !this.state.checked[value];
      this.setState({
        keyGen: this.state.keyGen.filter(function (val) { return val !== value }),
        checked: newSelected
      }, () => this.props.removeProjectIdToPermissionsList(value))
    }
  }

    setIsAdmin(isChecked,projectId){

      let record = this.findProjectRecord(projectId)

      if(record){
          record.is_admin = !!isChecked
          this.props.addProjectIdToPermissionsList(record)
      }

  }

    findProjectRecord(projectId, propertyToFind = null) {
        let record = this.props.projectPermissionsList.find(r => r.projectId === projectId)

        if (!record) {
            return null
        }

        if(propertyToFind){
            return  record[propertyToFind];
        }

        return record;
    }


  render() {
    return (
      <>
        <ReactTable
          data={this.state.projectData}
          columns={[
            {
              Header: this.props.t("Project Id"),
              accessor: "_id",
              show: false
            },
            {
              Header: this.props.t("Select Projects"),
              Cell: (rowInfo) => {
                return (
                  <InputGroup>
                    <Input
                        disabled={this.state.disabledProjects.includes(rowInfo.original._id)}
                      addon type="checkbox" aria-label="Checkbox for following text input"
                      checked={this.findProjectRecord(rowInfo.original._id)}
                      onChange={(e) => this.updateStateList(e, rowInfo.original._id)}
                    />
                  </InputGroup>
                );
              },
            },
            {
              Header:this.props.t( "Name"),
              accessor: "name",
            },
              {
                  Header:this.props.t( "Project admin"),
                  Cell: (rowInfo) => {
                      return this.state.disabledProjects.includes(rowInfo.original._id) ? '' : <div style={{position: 'absolute', width: '145px'}}>
                          <Input
                              disabled={!this.findProjectRecord(rowInfo.original._id)}
                              checked={!!this.findProjectRecord(rowInfo.original._id,'is_admin')}
                              id={`project_admin_${rowInfo.index}`}
                              addon type="checkbox"
                              onChange={(e) => this.setIsAdmin(e.target.checked, rowInfo.original._id)}
                          />
                      </div>
                  },
              },
              {
              Header: this.props.t("Description"),
              accessor: "description",
            },
            {
              Header: this.props.t("Start Date"),
              accessor: "startDate",
              Cell: (props) => {
                //props.value will contain your date
                //you can convert your date here
                const custom_date = moment.utc(props.value).format('DD MMM YYYY')
                return <span>{custom_date}</span>
              }
            },
          ]}
          defaultPageSize={1}
          pageSize={this.state.projectData.length}
          showPaginationTop={false}
          showPaginationBottom={false}
          // getTrProps={this.onRowClick}
          // getTrProps={onRowClick}
          /*
            You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
          */
          className="-striped -highlight primary-pagination"
        />
      </>
    );
  }
}


export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(ProjectTable)));
