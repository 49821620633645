import React from "react";
import axios from "axios"

import ImageGalleryUpload from "views/components/imageDisplay/ImageGalleryUpload"
import EXIF from 'exif-js'
import moment from 'moment'
import _ from "lodash"


// reactstrap components
import {
    Card,
    CardBody,
    Row,
    Col,
    Alert,
    Form,
    Input,
    Button
} from "reactstrap";

import {connect} from "react-redux";
import {ADD_GENERAL_COMMENT} from "constants/action-types.js";
import {userCant} from "../../../services/Authorization/authService";
// import FontProvider from "../../../../pdfmake/src/fontProvider.js";

// import ImageGallery from "views/components/imageDisplay/ImageGallery";
import {withTranslation} from 'react-i18next';
import Dropzone from 'react-dropzone';

import "react-dropzone/examples/theme.css";

function mapStateToProps(state) {
    return {
        project: state.allOtherReducers.project,
        projectId: state.allOtherReducers.projectId,
        projectData: state.allOtherReducers.projectData,
        reportDate: state.allOtherReducers.reportDate,
        user: state.auth.user
    };
}

class ConnectedUploadImage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            file: null,
            gotFile: null,
            images: [],
            retreivedImages: [],
            imageDate: moment().format("DD MMM YYYY"),
            imageReportDate: this.props.reportDate,
            visible: false,
            visibleNoImage: false,
            imageComment: null,
            title: null,
            signedImages: [],
            files: []
        };
        this.onDrop = files => {
            files = files.map(file => {
                let img = new Image()
                img.src = window.URL.createObjectURL(file)
                file.preview = img.src;
                img.onload = () => {
                    file.height = img.naturalHeight
                    file.width = img.naturalWidth

                }
                return file;
            })

            this.setState({files})
        };

        this.getImages = this.getImages.bind(this)
        this.onDismiss = this.onDismiss.bind(this)
        this.uploadSuccessfulAlert = this.uploadSuccessfulAlert.bind(this)
        this.noImageAlert = this.noImageAlert.bind(this)
        this.noImageAlertDismiss = this.noImageAlertDismiss.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.getS3SignUrlGetObject = this.getS3SignUrlGetObject.bind(this)
        this.removeFile = this.removeFile.bind(this);
    }

    componentWillMount() {
        if (userCant(this.props.user, 'images.manage')) {
            return this.props.history.push('/admin/instructions')
        }
    }

    //Lifecycle
    componentDidUpdate(prevProps) {
        var projectId = this.props.projectId
        var date = this.props.reportDate

        if (this.props.reportDate !== prevProps.reportDate) {

            this.getImages()
            // this.props.getGeneralCommentsByDateAndByProject({ date, projectId });
        }
    }

    componentDidMount() {
        this.getImages()
    }

    //Alerts
    onDismiss() {
        this.setState({visible: !this.state.visible})
    }

    uploadSuccessfulAlert() {
        this.setState({visible: !this.state.visible})
    }

    noImageAlert() {
        this.setState({visibleNoImage: !this.state.visibleNoImage})
    }

    noImageAlertDismiss() {
        this.setState({visibleNoImage: !this.state.visibleNoImage})
    }

    handleImageCommentText = (e) => {

        this.setState({imageComment: e.target.value}, () => this.state.imageComment);
    }

    async handleSubmit(e) {
        e.preventDefault()
        try {
            await Promise.all(this.state.files.map(file => this.doUploadFile(file))).then(() => {
                this.uploadSuccessfulAlert()

                this.setState({
                    imageComment: null,
                    title: null,
                    files: []
                }, () => this.getImages())
            })
        } catch (err) {
            console.log(err)
        }
    }

    async doUploadFile(file) {
        return await this.retrieveS3SignUrl(file)
            .then(async (responseData) => {
                await this.pushPhotoToS3(responseData, file).then(() => {
                    this.generateThumbnail(responseData.image._id).then(() => {
                    })
                });
            });
    }

    /**
     *
     * @param imageId
     * @returns {Promise<AxiosResponse<any>>}
     */
    generateThumbnail(imageId) {
        return axios.post(`${process.env.REACT_APP_API_URL}/api/imagemanagement/generate-thumbnail/${imageId}`, {}, {
            headers: {
                'Authorization': localStorage.jwtToken,
            }
        }).then(response => {
            return response.data;
        })
    }

    retrieveS3SignUrl(file) {

        return axios.post(process.env.REACT_APP_API_URL + "/api/imagemanagement/presigned/", {
            filename: file.name,
            imageComment: this.state.imageComment,
            name: this.state.title,
            projectid: this.props.projectId,
            date: this.props.reportDate,
            imageHeight: file.height,
            imageWidth: file.width
        }, {
            headers: {
                'Authorization': localStorage.jwtToken,
            }
        }).then(res => {
            return res.data
        }).catch((error) => {
        })
    }


    async getS3SignUrlGetObject(filename, filetype) {

        return axios.get(process.env.REACT_APP_API_URL + "/api/imagemanagement/presigned/" + filename, {
            headers: {
                'Authorization': localStorage.jwtToken,
            }
        }).then(res => {
            return res.data
        }).catch((error) => {
        })
    }

    async pushPhotoToS3(presignedUploadUrl, uploadPhoto) {
        const myHeaders = new Headers({'Content-Type': 'image/*'});
        try {
            const response = await fetch(presignedUploadUrl.url, {
                method: 'PUT',
                headers: myHeaders,
                body: uploadPhoto
            });
        } catch (err) {
            console.log(err)
        }
    }

    async getImages() {
        if (userCant(this.props.user, 'images.manage')) {
            return;
        }
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                'Authorization': localStorage.jwtToken
            },
        };

        this.setState({retreivedImages: []})

        const image = await axios.get(process.env.REACT_APP_API_URL + "/api/images/project/" + this.props.projectId + "/date/" + this.props.reportDate, config).then(
            (response) => {
                if (Array.isArray(response.data)) {
                    response.data.map(async (imageItem) => {
                        var checkTags = imageItem.tags !== null ? imageItem.tags : []
                        if (!checkTags.includes('weatherreport')) {
                            imageItem.imageLocation = await this.getS3SignUrlGetObject(imageItem.imageKey)
                            this.setState(prevState => ({
                                retreivedImages: [...prevState.retreivedImages, imageItem]
                            }), () => {
                                console.log(this.state.retreivedImages)
                            })
                        }
                    })
                }
            }
        )
    }

    removeFile(fileIndex) {
        this.setState({
            files: this.state.files.filter((file, index) => index !== fileIndex)
        })
    }


    renderFiles() {
        return <div className={'photo__list__group'}>
            <div className={'photo__list'}>
                {this.state.files.map((file, index) => {
                    return <div key={index} className={'relative'}>
                        <img src={file.preview} alt="  "/>
                    </div>
                })}
            </div>
        </div>
    }

    render() {
        var
            images = []

        images = this.state.retreivedImages


        return (

            <div className="content">
                <Alert color="success" isOpen={this.state.visible} toggle={this.onDismiss}>
                    <span>
            <b> {this.props.t('Info')} - </b> {this.props.t('Image uploaded successfully')}.
          </span>
                </Alert>
                <Alert
                    color="warning"
                    isOpen={this.state.visibleNoImage}
                    toggle={this.noImageAlertDismiss}
                >
          <span>
            <b> {this.props.t('Warning')} - </b> {this.props.t('No image selected')}.
          </span>
                </Alert>
                <Row>

                    <Col md={12}>
                        <Form id='uploadImage' onSubmit={(e) => this.handleSubmit(e)}>

                            <Dropzone onDrop={this.onDrop}>
                                {({getRootProps, getInputProps}) => (
                                    <section className="container">
                                        <div {...getRootProps({className: 'dropzone'})}>
                                            <input {...getInputProps()} />
                                            <p>Drag 'n' drop some files here, or click to select files</p>
                                        </div>
                                        <aside>
                                            <h4>Files</h4>
                                            <div className={'row'}>
                                                <div className={'col'}>
                                                    {this.renderFiles()}
                                                </div>
                                            </div>
                                        </aside>
                                    </section>
                                )}
                            </Dropzone>

                            {this.state.files.length ?
                                <div>
                                    <Row>
                                        <Col>
                                            <label>{this.props.t('Images taken on')}:</label>
                                            <br/>
                                            <label>{moment(this.props.reportDate).format("DD MMM YYYY")}</label>
                                            <br/>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <label>Title</label>
                                            <Input placeholder={this.props.t('Write a title of the image to upload')}
                                                   type="text" id="title"
                                                   name='title'
                                                   onChange={(e) => this.setState({title: e.target.value})}/>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <label>{this.props.t('Description')}</label>
                                            <Input
                                                placeholder={this.props.t('Write a description of the image to upload')}
                                                type="text" id="imageComment" name='imageComment'
                                                onChange={this.handleImageCommentText}/>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <Button type="submit" text="Submit">{this.props.t('Upload')}</Button>

                                            <Button type="reset" onClick={() => {
                                                this.setState({
                                                    files: []
                                                })
                                            }
                                            }>{this.props.t('Cancel')}</Button>
                                        </Col>
                                    </Row>

                                </div>
                                : null
                            }
                        </Form>
                    </Col>
                    <Col></Col>
                </Row>

                <Row>
                    <Col md={3}>
                        <img id="output"/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ImageGalleryUpload images={images} limit={12} getImages={this.getImages} type="image"/>
                    </Col>
                </Row>
            </div>

        )
    }
}


const UploadImage = withTranslation()(connect(
    mapStateToProps,
    null
)(ConnectedUploadImage));
export default UploadImage;
