
import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col
} from "reactstrap";
import { connect } from "react-redux";
import ReactTable from "react-table";
import helper from '../../../utils/general_helper.js'
import { withTranslation } from 'react-i18next';

function mapStateToProps(state) {
  return {
    project: state.allOtherReducers.project,
    projectId: state.allOtherReducers.projectId,
    projectData: state.allOtherReducers.projectData,
    reportDate: state.allOtherReducers.reportDate
  };
}

class ConnectedSiteReportContractorSummary extends React.Component {

  constructor(props) {
    super(props);
    this.state = {

    }
  }




  componentWillReceiveProps({ reportContractorSummary }) {
    this.setState({ ...this.state, reportContractorSummary }, () => this.state)
  }

  render() {
    const NullComponent = () => null;

    function toMinutes(duration) {
      var ary = duration.split('.');
      return +Number(ary[0]) * 60 + Number(ary[1]);

    }

    function sum(a, b) {
      return a + b;
    }
console.log(this.props.reportContractorSummary)
var totalRegularTime =0
if (this.props.reportContractorSummary.length>0){
  console.log(this.props.reportContractorSummary[0].employeesWithTimesheet)
    // var regularTimeSummary = helper.convertMinutesToHoursMinutes(this.props.reportContractorSummary[0].employeesWithTimesheet.map((d) => toMinutes(d.regularTime)).reduce(sum, 0))
    // totalRegularTime = regularTimeSummary
}
    return (
      <div>
        <>
        <h4 className="pl-3 card-title">{this.props.t('Contractors / Companies on site')}</h4>
          <ReactTable
            NoDataComponent={NullComponent}
            data={this.props.reportContractorSummary}
            columns={[
              {
                Header: this.props.t("Contractor / Company"),
                accessor: "_id.name"
              },
              {
                Header:this.props.t( "Number of Workers"),
                accessor: "count"
              },
              {
                Header: this.props.t("Hours on Site"),
                // accessor: "count"
                Cell:((props)=>{
                  // const items = this.props.reportContractorSummary[0].employeesWithTimesheet.filter(item => item.companyid[0] === props.original._id._id);
                  var regularTimeSummary = helper.convertMinutesToHoursMinutes(this.props.reportContractorSummary[0].employeesWithTimesheet.filter(item => item.companyid[0] === props.original._id._id).map((d) => toMinutes(d.lengthTime)).reduce(sum, 0))
                  return regularTimeSummary
                })
              }
            ]}
            loadingText=""
            defaultPageSize={1}
            pageSize={this.props.reportContractorSummary.length}
            showPaginationTop={false}
            showPaginationBottom={false}

            // getTrProps={this.onRowClick}
            // getTrProps={onRowClick}
            /*
              You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
            */
            className="-striped -highlight primary-pagination"
          />

        </>
      </div>
    );
  }
}

const SiteReportContractorSummary = withTranslation()(connect(mapStateToProps, null)(ConnectedSiteReportContractorSummary));

export default SiteReportContractorSummary;
