import {
  SET_CLIENT,
  ADD_PROJECT,
  SET_PROJECT,
  CLEAR_SELECTED_PROJECT,
  SET_REPORT_DATE,
  PROJECT_DATA_LOADED,
  GET_EMPLOYEES,
  SET_ABSENCE_MODAL,
  SET_ABSENCE_MODAL_TOGGLE,
  ADD_GENERAL_COMMENT,
  GENERAL_COMMENTS_LOADED,
  ALL_GENERAL_COMMENTS_LOADED,
  ADD_PROJECT_PERMISSIONS,
  REMOVE_PROJECT_PERMISSIONS,
  EMPTY_ALL_PROJECT_PERMISSIONS,
  ADD_PROJECT_PERMISSIONS_TO_USER,
  UPDATE_GENERAL_COMMENTS,
  SET_SELECTED_PHOTOS,
  REFRESH_PHOTO_LIST,
  REFRESH_PHOTOS_WITH_KEEP_SELECTION_MOOD,
  REFRESH_PROJECTS,
  SET_ROLES_LIST
} from "../constants/action-types";

import Axios from "axios";

export * from './FileManager/dashboard';
export * from './FileManager/filemanager';

export function setProject(payload) {
  return { type: SET_PROJECT, payload }
};

export function clearSelectedProject() {
  return {
    type: CLEAR_SELECTED_PROJECT
  }
}
export function setRolesList() {
  return function (dispatch) {
    return Axios.get(process.env.REACT_APP_API_URL + "/api/roles/get-roles-list/", {
          headers: {
            'Authorization': localStorage.jwtToken
          }
        }
    ).then(response => response.data)
        .then(json => {
          dispatch({type: SET_ROLES_LIST, payload: json});
        });

  };
}

export function setReportDate(payload) {
  return { type: SET_REPORT_DATE, payload }
};

export function getClient() {
  return function (dispatch) {
    return Axios.get(process.env.REACT_APP_API_URL + "/api/client/", {headers: {
      'Authorization': localStorage.jwtToken}
    }
    )
      .then(response => response.data)
      .then(json => {
        dispatch({ type: SET_CLIENT, payload: json });
      });

  };
}


export function getProjects() {
  return function (dispatch) {
    return Axios.get(process.env.REACT_APP_API_URL + "/api/project/", {headers: {
      'Authorization': localStorage.jwtToken}
    }
    )
      .then(response => response.data)
      .then(json => {
        dispatch({ type: PROJECT_DATA_LOADED, payload: json });
      });

  };
}

export function refreshProjects() {
  return function (dispatch) {
    return Axios.get(process.env.REACT_APP_API_URL + "/api/project/", {headers: {
            'Authorization': localStorage.jwtToken}
        }
    )
        .then(response => response.data)
        .then(json => {
          dispatch({ type: REFRESH_PROJECTS, payload: json });
        });

  };
}

export function addProject(payload) {
  return function (dispatch) {
    return Axios.post(process.env.REACT_APP_API_URL + "/api/project/", payload, {headers: {
      'Authorization': localStorage.jwtToken}
    }).then((response) => dispatch({ type: ADD_PROJECT, payload: response.data }))
  }
};

export function getProjectsByUser(payload) {

  return function (dispatch) {
    return Axios.get(process.env.REACT_APP_API_URL + "/api/project/user/" + payload, {headers: {
      'Authorization': localStorage.jwtToken}
    }
    )
      .then(response => response.data)
      .then(json => {
        dispatch({ type: PROJECT_DATA_LOADED, payload: json });
      });

  };
}

export function addGeneralComment(payload) {
  return function (dispatch) {
    return Axios.post(process.env.REACT_APP_API_URL + "/api/generalcomment/", {
      generalComment: payload.generalComment,
      date: payload.reportDate,
      projectId: payload.projectId
    },{headers: {
      'Authorization': localStorage.jwtToken}
    }).then((response) => dispatch({ type: ADD_GENERAL_COMMENT, payload: response.data }))
  }
};

export function getGeneralComments(payload) {
  return function (dispatch) {
    return Axios.get(process.env.REACT_APP_API_URL + "/api/generalcomment/project/" + payload.projectId, {headers: {
      'Authorization': localStorage.jwtToken}
    })
      .then(json => {
        dispatch({ type: ALL_GENERAL_COMMENTS_LOADED, payload: json.data });
      });

  };
}

export function updateGeneralComments(payload) {
  return function (dispatch) {
    return Axios.put(process.env.REACT_APP_API_URL + "/api/generalcomment/project/" + payload.projectId, {headers: {
      'Authorization': localStorage.jwtToken}
    })
      .then(json => {

        dispatch({ type: UPDATE_GENERAL_COMMENTS, payload: json.data });
      });

  };
}

export function getGeneralCommentsByDateAndByProject(payload) {
  return function (dispatch) {
    return Axios.get(process.env.REACT_APP_API_URL + "/api/generalcomment/"
    + payload.reportDate + "/project/" + payload.projectId, {headers: {
      'Authorization': localStorage.jwtToken}
    })
      .then(response => response.data)
      .then(json => {
  
        dispatch({ type: GENERAL_COMMENTS_LOADED, payload: json });
      });

  };
}

export function getEmployees() {
  return function (dispatch) {
    return Axios.get(process.env.REACT_APP_API_URL + "/api/employee/", {headers: {
      'Authorization': localStorage.jwtToken}
    })
      .then((response) => {

        dispatch({ type: GET_EMPLOYEES, payload: response.data });
      });

  };
}

export function setAbsenceModalBolean(payload) {
  return { type: SET_ABSENCE_MODAL, payload }
};


export function setAbsenceModalToggle(payload) {
  return { type: SET_ABSENCE_MODAL_TOGGLE, payload }
};


export function addProjectIdToPermissionsList(payload) {
  return function (dispatch){
   dispatch({ type: ADD_PROJECT_PERMISSIONS, payload: payload });}
  // return { type: ADD_PROJECT_PERMISSIONS, payload }
};

export function removeProjectIdToPermissionsList(payload) {
  return function (dispatch){
   dispatch({ type: REMOVE_PROJECT_PERMISSIONS, payload: payload });}
};

export function emptyProjectIdsFromoPermissionsList(payload) {
  return function (dispatch){
   dispatch({ type: EMPTY_ALL_PROJECT_PERMISSIONS });}
};

export function addProjectPermissionsToUser(payload) {

  return function (dispatch) {
    return Axios.put(process.env.REACT_APP_API_URL + "/api/user/permissions", {
      _id:payload._id,
      projects: payload.projects
    },{headers: {
      'Authorization': localStorage.jwtToken}
    }).then((response) => dispatch({ type: ADD_PROJECT_PERMISSIONS_TO_USER, payload: payload }))
  }
};

export function setSelectedPhotos(payload) {
  return { type: SET_SELECTED_PHOTOS, payload }
};

export function refreshPhotoList(payload) {
  return { type: REFRESH_PHOTO_LIST, payload }
};

export function refreshPhotosWithKeepSelectionMood(payload) {
  return { type: REFRESH_PHOTOS_WITH_KEEP_SELECTION_MOOD, payload }
};