import {
    requestHeaderInterceptor,
    responseErrorInterceptor,
    responseSuccessInterceptor,
} from "./interceptors";
import axios from "axios";


const REQUEST_TIMEOUT = 60000;
// console.log("localStorage.jwtToken")
// console.log(localStorage.jwtToken)

const headers = {
    Accept: "application/json",
    "Content-Type": "application/json;charset=UTF-8",
    'Authorization': localStorage.jwtToken,
};

const fetchTools = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: REQUEST_TIMEOUT,
    crossDomain: true,
    headers,
});

fetchTools.interceptors.request.use(requestHeaderInterceptor, (error) =>
    Promise.reject(error)
);

fetchTools.interceptors.response.use(
    responseSuccessInterceptor,
    responseErrorInterceptor
);

export default fetchTools;