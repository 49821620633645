export const SET_PROJECT = "SET_PROJECT";
export const SET_REPORT_DATE = "SET_REPORT_DATE";
export const PROJECT_DATA_LOADED = "PROJECT_DATA_LOADED";
export const GET_EMPLOYEES = "GET_EMPLOYEES";
export const SET_ABSENCE_MODAL="SET_ABSENCE_MODAL";
export const SET_ABSENCE_MODAL_TOGGLE="SET_ABSENCE_MODAL_TOGGLE";
export const ADD_PROJECT ="ADD_PROJECT";
export const ADD_GENERAL_COMMENT = "ADD_GENERAL_COMMENT";
export const GENERAL_COMMENTS_LOADED = "GENERAL_COMMENTS_LOADED";
export const ALL_GENERAL_COMMENTS_LOADED = "ALL_GENERAL_COMMENTS_LOADED";
export const ADD_PROJECT_PERMISSIONS = "ADD_PROJECT_PERMISSIONS";
export const REMOVE_PROJECT_PERMISSIONS = "REMOVE_PROJECT_PERMISSIONS";
export const EMPTY_ALL_PROJECT_PERMISSIONS = "EMPTY_ALL_PROJECT_PERMISSIONS";
export const ADD_PROJECT_PERMISSIONS_TO_USER = "ADD_PROJECT_PERMISSIONS_TO_USER";
export const UPDATE_GENERAL_COMMENTS = "UPDATE_GENERAL_COMMENTS"
export const SET_CLIENT = "SET_CLIENT"
export const SET_SELECTED_PHOTOS = "SET_SELECTED_PHOTOS"
export const REFRESH_PHOTO_LIST = "REFRESH_PHOTO_LIST"
export const REFRESH_PHOTOS_WITH_KEEP_SELECTION_MOOD = "REFRESH_PHOTOS_WITH_KEEP_SELECTION_MOOD"

export const REFRESH_PROJECTS = "REFRESH_PROJECTS"

export const SET_ROLES_LIST = "SET_ROLES_LIST"

export const SET_RESPONSE = "SET_RESPONSE"

export const CLEAR_SELECTED_PROJECT = "CLEAR_SELECTED_PROJECT"