import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
import axios from "axios";
import moment from "moment"
import InputMask from 'react-input-mask';
import TreeNode from "views/components/TreeNode";
import { withTranslation } from 'react-i18next';

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Input,
  Form,
  Row,
  Col,
  FormGroup,
  Modal
} from "reactstrap";
import { connect } from "react-redux";
import { userCant} from "../../../services/Authorization/authService";

function mapStateToProps(state) {
  return {
    project: state.allOtherReducers.project,
    projectId: state.allOtherReducers.projectId,
    projectData: state.allOtherReducers.projectData,
    reportDate: state.allOtherReducers.reportDate,
    user: state.auth.user
  };
}

class ConnectedInstructionTable extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      data: [{ "_id": "zx3", "material_name": "LED Detector" }, { "_id": "zx4", "material_name": "Hand Torch" }],
      instructionData: '',
      instructionTitle: '',
      instructionDescriptionToAddValue: '',
      description: '',
      title:''

    }
    this.renderActiveButtons = this.renderActiveButtons.bind(this);
    this.deleteInstructionById = this.deleteInstructionById.bind(this)

  }



  componentDidMount(props) {

  }


  deleteInstructionById(instructionId) {
    axios.delete(process.env.REACT_APP_API_URL + '/api/instruction/' + instructionId, {
      headers: {
        'Authorization': localStorage.jwtToken
      }
    }).then(res => {
      this.props.getSomeInstructions()
      return res
    })
      .catch((error) => {
        ;
      })
  }



  clearForm() {
    console.log("clearForm")
    this.setState({
      description: '',
      title: ''
    }, () => this.state.description)
  }



  handleInstructionToAddDescription = (e) => {

    this.setState({ instructionDescriptionToAddValue: e.target.value }, () => this.state.instructionDescriptionToAddValue);
  }



  componentDidUpdate(prevProps) {
    if (this.props.projectId !== prevProps.projectId) {
      this.props.getSomeInstructions()
    }

  }

  componentWillReceiveProps({ dateSelected, instructionData }) {
    this.setState({ ...this.state,dateSelected, instructionData }, () => this.state)
  }






  renderActiveButtons(row) {

    if (userCant(this.props.user, 'can_manage_instructions')) {
      return;
    }

    return (<div>
     <Button
        color="danger"
        size="sm"
        className="btn-icon btn-neutral btn btn-info"
        onClick={() => {
          this.props.isUpdateInstructionDockVisibleControl(row.original._id, this.props.instructionData)
        }
        }
      ><i
          className="fa fa-edit"
        />
      </Button>
      {/* <Button
        onClick={() => this.saveRowData(row)}
        size="xs"
        className="btn-icon btn-neutral btn btn-info"
      ><i className="fa fa-save"
        /></Button> */}
      <Button
        onClick={() => this.deleteInstructionById(row.original._id)}
        color="danger"
        size="sm"
        className="btn-icon btn-neutral btn btn-info"
      ><i
          className="fa fa-remove"
        /></Button>
    </div>)


  }

  render() {



    return (
      <>

        <Card>
          <CardHeader>
            <CardTitle tag="h4">{this.props.t("Instructions")}</CardTitle>
          </CardHeader>
          <CardBody>
            <Row>
              <Col>
                <ReactTable
                  data={this.props.instructionData}
                  columns={[
                    // {
                    //   Header: "Instruction Id",
                    //   accessor: "_id",
                    //   show: true
                    // },
                    {
                      Header: this.props.t("Number"),
                      accessor: "userDefinedInstructionNumber",
                      // Cell: this.renderEditable
                    },
                    {
                      Header: this.props.t("Title"),
                      accessor: "instructionTitle",
                      // Cell: this.renderEditable
                    },
                    {
                      Header: this.props.t("Description"),
                      accessor: "instructionDescription",
                      // Cell: this.renderEditable
                    },
                    {
                      Header: this.props.t("Actions"),
                      accessor: "actions",
                      sortable: false,
                      filterable: false,
                      Cell: ((value) => (this.renderActiveButtons(value)))
                    },

                  ]}
                  defaultPageSize={1}
                  pageSize={this.props.instructionData.length}
                  showPaginationTop={false}
                  showPaginationBottom={false}
                  // style={{
                  //   height: "200px" // This will force the table body to overflow and scroll, since there is not enough room
                  // }}
                  // getTrProps={this.onRowClick}
                  // getTrProps={onRowClick}
                  /*
                    You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
                  */
                  className="-striped -highlight primary-pagination"
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </>
    );
  }
}

const InstructionTable = withTranslation()(connect(mapStateToProps, null)(ConnectedInstructionTable));

export default InstructionTable;
