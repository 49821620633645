import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
import { connect } from "react-redux";
import _ from "lodash";
import helper from '../../../utils/general_helper.js'
import { withTranslation } from 'react-i18next';


// reactstrap components
import {
  Input,
  Alert
} from "reactstrap";

function mapStateToProps(state) {
  return {
    project: state.allOtherReducers.project,
    projectId: state.allOtherReducers.projectId,
    projectData: state.allOtherReducers.projectData,
    reportDate: state.allOtherReducers.reportDate
  };
}

class ConnectedTimesheetLogTable extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      editContent: false,
      editingRow: -1,
      isDirty: false,
      selectedEmployeeList: [],
      isAlerted: false

    }

    this.renderEditableMinutes = this.renderEditableMinutes.bind(this);
    this.renderEditableHours = this.renderEditableHours.bind(this);
    this.toggleEditable = this.toggleEditable.bind(this);
    this.renderActiveButtons = this.renderActiveButtons.bind(this)
    this.hhMmToMinutes = this.hhMmToMinutes.bind(this)
    this.minutesToHhMm = this.minutesToHhMm.bind(this)
    this.hoursMinutesFormatted = this.hoursMinutesFormatted.bind(this)
    this.onDismiss = this.onDismiss.bind(this)


  }


  componentDidMount(props) {

  }

  componentDidUpdate(prevProps) {

    if (this.props.contractorSummaryTableData !== prevProps.contractorSummaryTableData) {
      this.populateDropDownCompany(this.props.contractorSummaryTableData);

    }

  }


  clearCheckBoxesOnTimesheetLogsTable() {

  }
  deleteTimesheetLogByTimesheetLogId(timesheetLogId) {

  }


  componentWillReceiveProps({ timesheetLogs, dateSelected, clearChecks }) {
    this.setState({ ...this.state, timesheetLogs, dateSelected, clearChecks }, () => this.state)
  }

  handleInputChange = (cellInfo, event) => {

    let data = [...this.props.timesheetLogs];
    data[cellInfo.index][cellInfo.column.id] = event.target.value;
    this.setState({ data }, () => { });
  };
  renderEditable(cellInfo) {


  }
  renderEditable2(cellInfo) {

  }
  renderEditableTime(cellInfo) {

  }

  saveRowData(cellInfo) {

  }


  toggleEditable(index) {
    const { isDirty } = this.state;
    if (!isDirty) {
      this.setState({
        editingRow: index,
        isDirty: true
      });
    }
    else {
      this.setState({
        editingRow: -1,
        isDirty: false
      })
    }
  }



  renderActiveButtons(row) {

    return ({})


  }

  hhMmToMinutes(x) {
    const hhmm = Math.round(100 * x)  // convert hh.mm -> hhmm
    return 60 * Math.trunc(hhmm / 100) + (hhmm % 100)
  }

  // convert minutes back to hh.mm pseudo-float format
  // use minutesToHhMm(minutes).toFixed(2) if you want trailing zeros
  minutesToHhMm(minutes) {
    return (Math.trunc(minutes / 60) + (minutes % 60) / 100).toFixed(2)
  }

  hoursMinutesFormatted(cellData) {
    var splitData = cellData.original.regularTime.toString()
    var stringToSplit = splitData.split('.')
    return stringToSplit[0] + ' Hrs' + ' ' + stringToSplit[1] + ' Mins'
  }


  addCheckBoxes(rowInfo, rowData) {

    var rowProp = 'row';
    if (rowData.hasOwnProperty(rowProp)) {
      var x = rowData.row._original.projects.includes(rowInfo)
      return x
    }
  }

  filterCaseInsensitive = (filter, row) => {
    const id = filter.pivotId || filter.id;
    const content = row[id];
    if (typeof content !== 'undefined') {
      // filter by text in the table or if it's a object, filter by key
      if (typeof content === 'object' && content !== null && content.key) {
        return String(content.key).toLowerCase().includes(filter.value.toLowerCase());
      } else {
        return String(content).toLowerCase().includes(filter.value.toLowerCase());
      }
    }

    return true;
  };



  onDismiss() {
    if (this.state.isAlerted) {
      this.setState({ isAlerted: false })
    }

  }

  forEachItemInChecked() {
    let checkeds = this.state.checked
    Object.keys(this.state.checked).map(item => {

      var stateCopy = Object.assign({}, this.state);
      stateCopy.checked[item] = false;
      this.setState(stateCopy);
    })
  }


  renderEditableHours(cellInfo) {
    const cellValue = this.props.timesheetLogs[cellInfo.index][cellInfo.column.id];

    return (
      <div>

        <Input
          type='number'
          // placeholder='0'
          min="0"
          max="24"
          defaultValue="0"
          onChange={this.handleInputChange.bind(null, cellInfo)}
          value={cellValue}
        />


      </div>
    );
  }

  renderEditableMinutes(cellInfo) {
    const cellValue = this.props.timesheetLogs[cellInfo.index][cellInfo.column.id];
    return (
      <div>

        <Input
          type='number'
          // placeholder='0'
          min="0"
          max="59"
          defaultValue="0"
          onChange={this.handleInputChange.bind(null, cellInfo)}
          value={cellValue}
        />


      </div>
    );
  }

  render() {

    var sumRegularTime = 0
    var regularTimeSummary = ([_.sum((_.map(this.props.timesheetLogs, d => Number(Number(d.regularTime).toFixed(2)))))].map(this.hhMmToMinutes).map(x => sumRegularTime += x).map(this.minutesToHhMm)).toString().split('.')
    var totalRegularTime = regularTimeSummary[0] + ' Hrs' + ' ' + regularTimeSummary[1] + ' Mins'
    var sumOvertime = 0
    var overTimeSummary = _.sum((_.map(this.props.timesheetLogs, d => d.overtime)))
    var extraTimeSummary = _.sum((_.map(this.props.timesheetLogs, d => d.extraTime)))
    // .map(this.hhMmToMinutes).map(x => sumOvertime += x).map(this.minutesToHhMm)).toString().split('.')
    // var totalOverTime = overTimeSummary[0]+ ' Hrs' + ' ' + overTimeSummary[1]+' Mins'
    var totalOverTime


    return (
      <>
        <div className="content">
          <div>
            <Alert color="primary" isOpen={this.state.isAlerted} toggle={this.onDismiss} fade={true}>
              {this.props.t("Add hours before selecting employee")}.
            </Alert>
          </div>

          <ReactTable
            NoDataComponent={() => null}
            data={this.props.timesheetLogs}
            columns={[
              {
                Header: this.props.t("TimesheetLogs Id"),
                accessor: "_id",
                show: false
              },
              {
                Header: this.props.t("Name"),
                accessor: "username",
                width: 500
              },
              {
                Header: this.props.t("Allocation Hours"),
                headerStyle: { 'whiteSpace': 'unset' },
                accessor: "allocatedHours",
                Cell: this.renderEditableHours,
                width: 120
                // Footer: <span>{overTimeSummary}</span>
              },
              {
                Header: this.props.t("Allocation Mins"),
                headerStyle: { 'whiteSpace': 'unset' },
                accessor: "allocatedMinutes",
                Cell: this.renderEditableMinutes,
                width: 120
                // Footer: <span>{overTimeSummary}</span>
              },
              {
                Header: this.props.t("Current Allocation"),
                headerStyle: { 'whiteSpace': 'unset' },
                accessor: "allocatedTime",
                className: "frozen",
                Cell: (props) => {

                  return helper.hoursMinutesAllocateTimeFormatted(props.value);

                }
              },
              {
                Header: this.props.t("Hours Worked"),
                accessor: "regularTime",
                Cell: ((value) => helper.hoursMinutesFormatted(value)),
              },
            ]}
            defaultPageSize={1}
            minRows={0}
            pageSize={this.props.timesheetLogs.length}
            showPaginationTop={false}
            showPaginationBottom={false}
            className="-striped -highlight primary-pagination"
            filterable
            defaultFilterMethod={this.filterCaseInsensitive}
          />
        </div>

      </>
    );
  }
}

const TimesheetLogTable = withTranslation()(connect(mapStateToProps, null)(ConnectedTimesheetLogTable));

export default TimesheetLogTable;
