import React from "react";
import TimesheetsPeopleList from './TimesheetsPeopleList'
import axios from "axios"
import TimesheetLog from "./TimesheetLog"
import TimesheetLog_bulk from "./TimesheetLog_Bulk"
import moment from "moment"
import {connect} from "react-redux";
import Datetime from 'react-datetime';
import ReactTable from "react-table";
import {CSVLink} from "react-csv"
import {Dock} from "react-dock"
import {withTranslation} from 'react-i18next';


// reactstrap components
import {
    Alert,
    Button,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Row,
    Col,
} from "reactstrap";
import {userCant, userCan} from "../../../services/Authorization/authService";
// function mapDispatchToProps(dispatch) {
//   return {
//     setProject: project => dispatch(setProject(project))
//   };
// }

function mapStateToProps(state) {
    return {
        project: state.allOtherReducers.project,
        projectId: state.allOtherReducers.projectId,
        projectData: state.allOtherReducers.projectData,
        reportDate: state.allOtherReducers.reportDate,
        user: state.auth.user,
        client: state.auth.client
    };
}

class ConnectedTimesheets extends React.Component {
    constructor(props) {
        super(props);

        this.addPerson = this.addPerson.bind(this);
        this.getTimesheetLogByDate = this.getTimesheetLogByDate.bind(this)
        this.dateSelectedFromDropdownIntervals = this.dateSelectedFromDropdownIntervals.bind(this)
        this.getEmployeesCompressedListByDate = this.getEmployeesCompressedListByDate.bind(this)
        this.copyTimesheetLogFromSelectedDateToToday = this.copyTimesheetLogFromSelectedDateToToday.bind(this)
        this.logsheetCopyVisibleAlertDismiss = this.logsheetCopyVisibleAlertDismiss.bind(this)
        this.copyTimesheetLogFromSelectedDateToAnotherSelectedDate = this.copyTimesheetLogFromSelectedDateToAnotherSelectedDate.bind(this)
        this.generateTimesheetsOverview = this.generateTimesheetsOverview.bind(this)
        this.download = this.download.bind(this);
        this.WhichDock = this.WhichDock.bind(this)

        this.state = {
            timesheetdatafetch: [],
            datafetch: [],
            rowsToCopy: [],
            selected: -1,
            employees: [],
            dateList: [],
            today: moment(new Date()),
            dateSelected: '',
            selectedRowList: [],
            resetState: false,
            logsheetCopyVisibleAlert: false,
            copyFromDate: '',
            copyToDate: '',
            timesheetReportHeader: [],
            columns: [],
            timesheetReportData: [],
            dataToDownload: [],
            startDate: '',
            endDate: '',
            isDockVisible: false,
            isCopyTimeSheetDockVisible: false,
            isTimeSheetReportDockVisible: false,
            isPeopleListDockVisible: false,
            dockZIndex: -1
        };
    }


    componentWillMount() {
        if (
            userCant(this.props.user, 'timesheets.index') &&
            userCant(this.props.user, 'timesheets.manage')) {
            return this.props.history.push('/admin/instructions')
        }
    }

    componentDidMount() {
        var startDate = new Date();
        startDate.setDate(startDate.getDate() - 7);

        var endDate = new Date();
        endDate.setDate(endDate.getDate());
        var getDateArray = function (start, end) {
            var arr = new Array();
            var dt = new Date(start);
            while (dt <= end) {
                arr.push(new Date(dt));
                dt.setDate(dt.getDate() + 1);
            }
            return arr;
        }

        var dateArr = getDateArray(startDate, endDate);
        this.setState({dateList: dateArr}, () => this.state.dateList)
        // Refactor and move to a helper API
        this.getTimesheetLogByDate(this.props.reportDate)
        this.getEmployeesCompressedListByDate(this.props.reportDate)
    }

    componentDidUpdate(prevProps) {


        if (this.props.projectId !== prevProps.projectId) {
            this.getTimesheetLogByDate(this.props.reportDate)
        }

        if (this.props.reportDate !== prevProps.reportDate) {
            this.getTimesheetLogByDate(this.props.reportDate)
        }

        if ((this.props.projectId !== prevProps.projectId) || (this.props.reportDate !== prevProps.reportDate)) {
            this.getEmployeesCompressedListByDate(this.props.reportDate)
        }
    }


    getTimesheetLogByDate(dateToSearch) {
        if (
            userCant(this.props.user, 'timesheets.index') &&
            userCant(this.props.user, 'timesheets.manage')) {
            return
        }

        axios.get(process.env.REACT_APP_API_URL + '/api/timesheetlog/' + dateToSearch + "/projects/" + this.props.projectId, {
            headers: {
                'Authorization': localStorage.jwtToken
            }
        }).then(res => {
            this.setState({
                timesheetdatafetch: res.data
            }, () => {
            });

            return res.data
        })
            .catch((error) => {
                ;
            })
    }

    getEmployeesCompressedListByDate(dateToSearch) {

        if (
            userCant(this.props.user, 'timesheets.index') &&
            userCant(this.props.user, 'timesheets.manage')) {
            return
        }

        axios.get(process.env.REACT_APP_API_URL + '/api/employeescompressedlist/' + dateToSearch + '/projects/' + this.props.projectId, {
            headers: {
                'Authorization': localStorage.jwtToken
            }
        }).then(res => {
            this.setState({
                datafetch: res.data
            }, () => {
            });

            return res.data
        })
            .catch((error) => {
                ;
            })
    }


    dateSelectedFromDropdownIntervals(dateIn) {
        this.setState({dateSelected: dateIn}, () => this.state.dateSelected)
    }

    selectedRowState(resetValue) {
        this.setState({resetState: resetValue}, () => this.state.resetState)

    }

    ////// EXPERIMENTAL TIMESHEET REPORT ////////////
    generateTimesheetsOverview(start, end) {
        if (
            userCant(this.props.user, 'timesheets.index') &&
            userCant(this.props.user, 'timesheets.manage')) {
            return
        }
        axios.get(process.env.REACT_APP_API_URL + "/api/reports/timesheets/overview?projectid=" + this.props.projectId + "&start=" + start + "&end=" + end, {
            headers: {
                'Authorization': localStorage.jwtToken
            }
        }).then(
            res => {
                // var x = []
                // x.push(res.data);

                this.setState({
                    timesheetReportData: res.data
                }, () => {
                    console.log(this.state.timesheetReportData)
                })
                console.log(res.data)
            })
    }

    generateTimesheetsColumns(start, end) {
        axios.get(process.env.REACT_APP_API_URL + "/api/reports/timesheets/overview/columns?start=" + start + "&end=" + end, {
            headers: {
                'Authorization': localStorage.jwtToken
            }
        }).then(
            res => {
                this.setState({
                    timesheetReportHeader: res.data
                }, () => {
                    console.log(this.state.timesheetReportHeader)
                    const columns = Object.values(this.state.timesheetReportHeader).map((value, id) => {
                        console.log(value)
                        console.log(id)
                        return {
                            Header: value,
                            accessor: value
                        }
                    })
                    this.setState({
                        columns: columns
                    })
                })
                this.generateTimesheetsOverview(start, end)
                // console.log(res.data)
            })
    }

    download = async (event) => {
        const currentRecords = this.reactTable.getResolvedState().sortedData;
        console.log("currentRecords")
        console.log(currentRecords)
        var data_to_download = []
        for (var index = 0; index < currentRecords.length; index++) {
            console.log("currentRecords.length")
            console.log(currentRecords.length)
            let record_to_download = {}
            let columns = this.state.columns
            console.log("columns.length")
            console.log(columns.length)
            for (var colIndex = 0; colIndex < columns.length; colIndex++) {
                record_to_download[columns[colIndex].Header] = currentRecords[index][columns[colIndex].accessor]
                console.log(record_to_download)

            }
            data_to_download.push(record_to_download)
        }
        this.setState({dataToDownload: data_to_download}, () => {
            }
        )
        setTimeout(() => {
            this.csvLink.link.click()
        }, 3000);
    }


    renderTableHeader() {
        let header = Object.keys(this.state.timesheetReportHeader)
        return header.map((key, index) => {
            return <th key={index}>{key.toUpperCase()}</th>
        })
    }

    ////////////////////////////////////////////

    addTimesheetLog(element) {
        const {timeIn, timeOut, breakTime} = this.getTimeInTimeOut();

        axios.post(process.env.REACT_APP_API_URL + "/api/timesheetlog/", {
            createdDate: this.props.reportDate,
            user_id: element,
            timeIn: timeIn,
            timeOut: timeOut,
            breaks: breakTime,
            projectId: this.props.projectId
        }, {
            headers: {
                'Authorization': localStorage.jwtToken
            }
        })
            .then(() => this.setState({
                resetState: true
            }, () => this.state.resetState))
            .then(response =>
                axios.get(process.env.REACT_APP_API_URL + '/api/timesheetlog/' + this.props.reportDate + "/projects/" + this.props.projectId, {
                    headers: {
                        'Authorization': localStorage.jwtToken
                    }
                })
                    .then(res => {
                        this.setState({
                            timesheetdatafetch: res.data
                        }, () => this.state.timesheetdatafetch);
                        return res.data
                    })
                    .catch((error) => {
                        ;
                    })
            ).then(
            () => this.setState({rowsToCopy: []}, () => this.state.rowsToCopy))
            .then(
                () => axios.get(process.env.REACT_APP_API_URL + '/api/employeescompressedlist/' + this.props.reportDate + '/projects/' + this.props.projectId, {
                    headers: {
                        'Authorization': localStorage.jwtToken
                    }
                }).then(res => {
                    this.setState({
                        datafetch: res.data
                    }, () => this.state.datafetch);
                    this.setState({resetState: false}, () => {
                    })
                    return res.data
                })
                    .catch((error) => {

                    })
            )
            .catch((error) => {

            })
    }

    /**
     *
     * @returns {{timeIn: Date, timeOut: Date}}
     */
    getTimeInTimeOut() {

        const project = this.props.projectData.find(p => `${p._id}` === `${this.props.projectId}`);

        let timeIn = moment.utc(this.props.reportDate),
            timeOut = moment.utc(this.props.reportDate),
            config = project?.configuration || {},
            [startHours, startMinutes] = config.startOfDay.split(':'),
            [endHours, endMinutes] = config.endOfDay.split(':');


        timeIn.set('hour', startHours)
        timeIn.set('minute', startMinutes)

        timeOut.set('hours', endHours)
        timeOut.set('minute', endMinutes)

        let breakTime = config?.breakTime || 0;

        return {
            timeIn,
            timeOut,
            //prevent negative value from config.
            breakTime: breakTime < 0 ? 0 : breakTime
        }
    }

    addPerson() {
        var array1 = this.state.rowsToCopy;
        this.setState({
            rowsToCopy: []
        }, () => this.state.rowsToCopy)
        var counter = array1.length;
        array1.forEach((element) => {
            counter -= 1;
            this.addTimesheetLog(element)
        })
    }

    rowsToCopyFromPeopleToTimesheet(rowsToCopyFromPeopleList) {
        this.setState({
            rowsToCopy: rowsToCopyFromPeopleList
        }, () => this.state.rowsToCopy)

    }

    copyTimesheetLogFromSelectedDateToToday(dateTo) {

        // 1. Select Date to copy from interval drop down
        var dateSelectedToCopy = this.props.reportDate
        var dateSelectedToCopyTo = moment(dateTo).format("YYYY-MM-DD")
        axios({
            method: 'post',
            url: process.env.REACT_APP_API_URL + '/api/timesheetlogstocopy/project/' + this.props.projectId + '/' + dateSelectedToCopy + "/" + dateSelectedToCopyTo,
            headers: {
                'Authorization': localStorage.jwtToken
            }
        }).then(res => {
            this.setState({logsheetCopyVisibleAlert: true}, () => {
            })
        })
            .catch((error) => {

                console.log(error);
            })

    }

    copyTimesheetLogFromSelectedDateToAnotherSelectedDate(dateFrom, dateTo) {

        // 1. Select Date to copy from interval drop down

        axios({
            method: 'post',
            url: process.env.REACT_APP_API_URL + '/api/timesheetlogstocopy/project/' + this.props.projectId + '/' + dateFrom + "/" + this.props.reportDate,
            headers: {
                'Authorization': localStorage.jwtToken
            }
        }).then(res => {
            this.setState({logsheetCopyVisibleAlert: false, isDockVisible: false})
            this.getTimesheetLogByDate(this.props.reportDate)
        })
            .catch((error) => {

                console.log(error);
            })
        // 2.  Copy selected date of timesheetlog to array variable1
        // 3. Copy todays timesheetlog to array variable2
        // 4. Compare array variable1 contents to array variable2
        // 5. Remove from array variable 1 any items that are in array variable2
        // 6. Copy array variable1 to timesheetlog with today's date

    }


    logsheetCopyVisibleAlertDismiss() {
        this.setState({logsheetCopyVisibleAlert: false}, () => {
        })
    }

    WhichDock() {


        if (this.state.isPeopleListDockVisible) {
            return <CardBody>
                <Button color="info" onClick={() => this.addPerson()}>{this.props.t("Add People")}</Button>
                <Button color="default" onClick={() => this.setState({
                    isPeopleListDockVisible: false,
                    isDockVisible: !this.state.isDockVisible,
                    dockZIndex: -1
                })}>{this.props.t("Close")}</Button>

                <TimesheetsPeopleList
                    rowsToCopyFromPeopleToTimesheet={this.rowsToCopyFromPeopleToTimesheet.bind(this)}
                    timesheet={this.state.timesheetdatafetch}
                    employees={this.state.datafetch}
                    resetState={this.state.resetState}
                    selectedRowState={this.selectedRowState.bind(this)}
                    getEmployeesCompressedListByDate={this.getEmployeesCompressedListByDate}
                    projectId={this.props.projectId}
                />
            </CardBody>
        } else if (this.state.isCopyTimeSheetDockVisible) {
            return <Card>
                <CardHeader>
                    <CardTitle tag="h4">
                        <Button color="default" className={'mr-2'} onClick={() => this.setState({
                            isCopyTimeSheetDockVisible: false,
                            isDockVisible: !this.state.isDockVisible,
                            dockZIndex: -1
                        })}>{this.props.t("Close")}
                        </Button>

                        {this.props.t("Copy Timesheets")}

                    </CardTitle>
                </CardHeader>
                <CardBody>


                    <Alert color="success" isOpen={this.state.logsheetCopyVisibleAlert}
                           toggle={this.logsheetCopyVisibleAlertDismiss}>
                        {this.props.t("Timesheet entries copied")}.
                    </Alert>

                    <Row>
                        <Col>
                            <Row>
                                <Col md={3}>{this.props.t("Copy from")}:</Col>
                                <Col>
                                    <Datetime
                                        onChange={value =>
                                            this.setState({copyFromDate: moment(value).format('YYYY-MM-DD')})
                                        }
                                        closeOnSelect={true}
                                        timeFormat={false}
                                        utc={true}
                                        dateFormat={'DD MMM YYYY'}
                                        inputProps={{placeholder: this.props.t("Select copy from")}}
                                    />
                                </Col>
                            </Row>
                            <br/>
                            <Row>
                                <Col md={3}>{this.props.t("Copy to")}: </Col>
                                <Col>{this.props.reportDate}</Col>
                            </Row>
                            <br/>
                            <Row>

                                <Col>
                                    <Button color="info"
                                            onClick={() => this.copyTimesheetLogFromSelectedDateToAnotherSelectedDate(this.state.copyFromDate, this.state.copyToDate)}>{this.props.t("Copy from")} {moment(this.state.copyFromDate).format('DD MMM YYYY')} {this.props.t("to")} {moment(this.props.reportDate).format('DD MMM YYYY')}</Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </CardBody>
            </Card>

        } else if (this.state.isTimeSheetReportDockVisible) {
            return <Row>
                <Col>
                    <Card>
                        <CardHeader>
                            <CardTitle tag="h3">Export Timesheets</CardTitle>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col md={3}>{this.props.t("From")}:</Col>
                                <Col>
                                    <Datetime
                                        onChange={value =>
                                            this.setState({startDate: moment(value).format('YYYY-MM-DD')}, () => {
                                            })
                                        }
                                        closeOnSelect={true}
                                        timeFormat={false}
                                        utc={true}
                                        dateFormat={'DD MMM YYYY'}
                                        inputProps={{placeholder: this.props.t("Select start date")}}
                                    />
                                </Col>
                            </Row>
                            <br/>
                            <Row>
                                <Col md={3}>To:</Col>
                                <Col>
                                    <Datetime
                                        onChange={value =>
                                            this.setState({endDate: moment(value).format('YYYY-MM-DD')}, () => {
                                            })
                                        }
                                        closeOnSelect={true}
                                        timeFormat={false}
                                        utc={true}
                                        dateFormat={'DD MMM YYYY'}
                                        inputProps={{placeholder: this.props.t("Select end date")}}
                                    />
                                </Col>
                            </Row>
                            <br/>
                            <Row>
                                <Col>

                                    <Button color="success"
                                            onClick={() => this.generateTimesheetsColumns(this.state.startDate, this.state.endDate)}>{this.props.t("Search")}</Button>
                                    <Button color="info" onClick={this.download}>{this.props.t("Download")}</Button>
                                    <Button color="default" onClick={() => this.setState({
                                        isTimeSheetReportDockVisible: false,
                                        isDockVisible: !this.state.isDockVisible,
                                        dockZIndex: -1
                                    })}>{this.props.t("Close")}</Button>
                                </Col>
                            </Row>
                            <div>

                            </div>
                            <div>
                                <CSVLink
                                    data={this.state.dataToDownload}
                                    filename="data.csv"
                                    className="hidden"
                                    ref={(r) => this.csvLink = r}
                                    target="_blank"/>

                            </div>
                            <div>

                                <ReactTable
                                    NoDataComponent={() => null}
                                    ref={(r) => this.reactTable = r}
                                    data={this.state.timesheetReportData}
                                    columns={this.state.columns}
                                    showPaginationTop={false}
                                    showPaginationBottom={false}
                                    className="-striped -highlight primary-pagination"
                                />
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        } else {
            return <div/>
        }
    }

    render() {

        return (
            <>
                <div className="content">

                    <Row>
                        <Col md="12"
                             tag="h3">-{this.props.t("Time and Attendance for")} {moment(this.props.reportDate).format("DD MMM YYYY")}</Col>
                    </Row>
                    <Row>


                        <Col md="12">

                            <Row>
                                <Col>
                                    <Card>
                                        {/* <CardHeader>
                      <CardTitle tag="h4">Select Available People</CardTitle>
                    </CardHeader> */}
                                        <CardBody>
                                            <Row>

                                                <Col>
                                                    {userCan(this.props.user, 'timesheets.manage') &&
                                                    <Button color="success" onClick={() => this.setState({
                                                        isPeopleListDockVisible: true,
                                                        isDockVisible: !this.state.isDockVisible,
                                                        dockZIndex: 999999,
                                                        isCopyTimeSheetDockVisible: false,
                                                        isTimeSheetReportDockVisible: false
                                                    })}>{this.props.t("Select People")}</Button>}

                                                    {userCan(this.props.user, 'timesheets.manage') &&
                                                    <Button color="success" onClick={() => this.setState({
                                                        isCopyTimeSheetDockVisible: true,
                                                        isDockVisible: !this.state.isDockVisible,
                                                        dockZIndex: 999999,
                                                        isPeopleListDockVisible: false,
                                                        isTimeSheetReportDockVisible: false
                                                    })}>{this.props.t("Copy Timesheets")}</Button>}

                                                    <Button color="success" onClick={() => this.setState({
                                                        isTimeSheetReportDockVisible: true,
                                                        isDockVisible: !this.state.isDockVisible,
                                                        dockZIndex: 999999,
                                                        isCopyTimeSheetDockVisible: false,
                                                        isPeopleListDockVisible: false
                                                    })}>{this.props.t("Download Timesheets")}</Button>

                                                </Col>


                                            </Row>
                                            <Row>


                                                <Col>
                                                    <TimesheetLog_bulk
                                                        timesheetLogs={this.state.timesheetdatafetch}
                                                        getTimesheetLogByDate={this.getTimesheetLogByDate}
                                                        dateSelected={this.props.reportDate}
                                                        getEmployeesCompressedListByDate={this.getEmployeesCompressedListByDate}
                                                    />
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>

                            </Row>


                        </Col>
                    </Row>

                    <Dock
                        position='right'
                        isVisible={this.state.isDockVisible}
                        duration={300}
                        size={window.innerWidth < 600 ? 1 : (window.innerWidth < 900 ? 0.5 : 0.3)}
                        zIndex={this.state.dockZIndex}
                    >
                        <this.WhichDock/>

                    </Dock>


                </div>
            </>
        );
    }
}

const Timesheets = withTranslation()(connect(mapStateToProps, null)(ConnectedTimesheets));

export default Timesheets;
