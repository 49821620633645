
import React from "react";
import axios from "axios";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,

  CardTitle,
  Row,
  Col
} from "reactstrap";
import AddCompanyTable from "views/pages/Companies/AddCompanyTable";
import { connect } from "react-redux";
import {userCant} from "../../../services/Authorization/authService";
import { withTranslation } from 'react-i18next';

function mapStateToProps(state) {
  return {
    project: state.allOtherReducers.project,
    projectId: state.allOtherReducers.projectId,
    projectData: state.allOtherReducers.projectData,
    reportDate: state.allOtherReducers.reportDate,
    user : state.auth.user
  };
}

class ConnectedCompany extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      companyTableData: [],
    };
    // this.formSubmitSuccess=this.formSubmitSuccess.bind(this)
    this.getSomeCompanies=this.getSomeCompanies.bind(this)
    this.getSomeCompanies();
    this.refreshCompany = this.refreshCompany.bind(this)
  }


  componentDidUpdate(prevProps) {

    if (prevProps.projectId !== this.props.projectId) {
      this.getSomeCompanies();
    }
  }

  componentWillMount() {
    if (userCant(this.props.user, 'companies.index')) {
      return this.props.history.push('/admin/instructions')
    }
  }


  getSomeCompanies() {
    if (userCant(this.props.user, 'companies.index')) {
      return
    }

    axios.get(process.env.REACT_APP_API_URL + "/api/company?projectId=" + this.props.projectId, {
      headers: {
      'Authorization': localStorage.jwtToken}
    })
      .then(res => {
        this.setState({
          companyTableData: res.data
        }, () => this.state.companyTableData);
        return res.data
      }).catch((error) => {
        ;
      })
  }



  updateCompanyTableData = (data)=>{
    this.setState({companyTableData:data},()=>this.state.companyTableData)

  }

  refreshCompany(company) {
   let companies = this.state.companyTableData.map(row => {
      if (row._id === company._id) {
        return company
      }
      return row
    })

    this.updateCompanyTableData(companies)
  }

  render() {
    if (userCant(this.props.user, 'companies.index')) {
      return
    }

    return (
      <>
        <div className="content">
          <Row>
            <Col>
            <Card>
              <CardHeader>
                <CardTitle tag="h4">{this.props.t('Company Administration')}</CardTitle>
              </CardHeader>
              <CardBody>
                <AddCompanyTable
                  companyData={this.state.companyTableData}
                  updateCompanyTableData={this.updateCompanyTableData}
                  refreshCompany={this.refreshCompany}
                />
              </CardBody>
            </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

const Company = withTranslation()(connect(
  mapStateToProps,
  null
)(ConnectedCompany));

export default Company;
