import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {resetPasswordRequest} from '../../actions/authActions';
import TextFieldGroup from '../components/common/TextFieldGroup';
import {Card, CardBody, CardHeader, Col, Container, Row,} from "reactstrap";
import {Link} from "react-router-dom";
import store from "../../store";
import {SET_RESPONSE} from "../../constants/action-types";

class ResetPasswordRequest extends Component {
    constructor() {
        super();
        this.state = {
            email: '',
            message: '',
            errors: {},
        };

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() {
        if (this.props.auth.isAuthenticated) {
            this.props.history.push('/app/initialisation')
        }
        store.dispatch({
            type: SET_RESPONSE,
            payload: {}
        })
        document.body.classList.toggle("login-page");
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let stateProps = {};

        stateProps.errors = nextProps.errors || {};
        stateProps.message = nextProps.message || '';

        if (stateProps.message) {
            stateProps.email = ''
        }

        return stateProps;
    }

    componentWillUnmount() {
        document.body.classList.toggle("login-page");
    }

    onSubmit(e) {
        e.preventDefault();

        const userData = {
            email: this.state.email,
        };

        this.props.resetPasswordRequest(userData);
    }

    onChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }

    render() {
        const {errors} = this.state;
        let {message} = this.state;
        return (
            <div>
                <Container>
                    <Row>
                        <Col className="ml-auto mr-auto" lg="4" md="6">
                            <form onSubmit={this.onSubmit}>
                                <Card className="card-login">
                                    <CardHeader>
                                        Enter you email to send the reset link
                                    </CardHeader>
                                    <CardBody>
                                        <TextFieldGroup
                                            placeholder="Email Address"
                                            name="email"
                                            type="email"
                                            value={this.state.email}
                                            onChange={this.onChange}
                                            error={errors.email}
                                        />
                                        <input type="submit" className="btn btn-info btn-block mt-4"
                                               value="Reset Password"/>
                                        <Link to={`/auth/login`}>Already have account, login here?</Link>
                                    </CardBody>
                                </Card>
                            </form>
                            {
                                message && message.length &&
                                <div className="alert alert-success">
                                    {message}
                                </div>
                            }
                        </Col>
                    </Row>
                </Container>
                <div
                    className="full-page-background"
                    style={{
                        backgroundImage: `url(${require("assets/img/bg/crane-sky.jpg")?.default})`
                    }}
                />
            </div>
        );
    }
}

ResetPasswordRequest.propTypes = {
    resetPasswordRequest: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        errors: state.errors,
        message: state.allOtherReducers.response ? state.allOtherReducers.response.message : ""
    }
};

export default connect(mapStateToProps, {resetPasswordRequest})(ResetPasswordRequest);
