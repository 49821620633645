import React from "react";
import ReactTable from "react-table";
import axios from "axios";
import InputMask from 'react-input-mask';
import ReactBSAlert from "react-bootstrap-sweetalert";
import {onColumnFilter} from '../../filters/ColumnFilter'

// reactstrap components
import {
  Button,
  Input,
  Row,
  Col,
  CardBody,
  FormGroup,
  Label,
  Modal,
  Card, InputGroup, NavItem, NavLink, TabContent, TabPane, Nav
} from "reactstrap";
import { Dock } from "react-dock"

import { connect } from "react-redux";
import classnames from "classnames";
import moment from "moment";
import { withTranslation } from 'react-i18next';
import {userCan} from "../../../services/Authorization/authService";
import {getProjects} from "../../../actions";

function mapStateToProps(state) {
  return {
    project: state.allOtherReducers.project,
    projectId: state.allOtherReducers.projectId,
    projectData: state.allOtherReducers.projectData,
    reportDate: state.allOtherReducers.reportDate,
    user: state.auth.user
  };
}


class ConnectedAddCompanyTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      companyToAddValue: '',
      alert: null,
      visible: null,
      showCompanyForm: false,
      errors: {},
      editCompany: null,
      locations: [{
        name: '',
        address: '',
        assignToCurrentProject: !!this.props.projectId
      }],
      positions: [{
        name: ''
      }],
      showProjectModal: false,
      selectedCompany: null,
      assignedProjects: {},
      unassignedProjects: {},
      activeFormTab: 'locations'
    }
    this.createNewCompany = this.createNewCompany.bind(this)
    this.renderEditable = this.renderEditable.bind(this);
    this.saveRowData = this.saveRowData.bind(this);
    this.toggleEditable = this.toggleEditable.bind(this);
    this.renderActiveButtons = this.renderActiveButtons.bind(this);
    this.warningWithConfirmAndCancelMessage = this.warningWithConfirmAndCancelMessage.bind(this);
    this.deleteCompanyById = this.deleteCompanyById.bind(this);
    this.warningCompanyCantBeDeleted = this.warningCompanyCantBeDeleted.bind(this)

    this.addMoreLocations = this.addMoreLocations.bind(this);
    this.addMorePositions = this.addMorePositions.bind(this);

    this.removeLocation = this.removeLocation.bind(this);
    this.removePosition = this.removePosition.bind(this);

    this.handleUpdateLocation = this.handleUpdateLocation.bind(this)
    this.handleUpdatePosition = this.handleUpdatePosition.bind(this)

    this.handleCheckedAssignedProjectsLocations = this.handleCheckedAssignedProjectsLocations.bind(this)
    this.toggleProjectsModal = this.toggleProjectsModal.bind(this);
    this.updateCompanyProjects = this.updateCompanyProjects.bind(this);
    this.isProjectLocationAssignedToCompany = this.isProjectLocationAssignedToCompany.bind(this);
  }

  createNewCompany(element) {
    this.setState({
      errors: {}
    })

    if(this.state.editCompany){
       this.saveRowData()
    }else{
      axios.post(process.env.REACT_APP_API_URL + "/api/company/", {
        name: element,
        locations: this.state.locations,
        positions: this.state.positions,
        projectId: this.props.projectId
      }, {
        headers: {
          'Authorization': localStorage.jwtToken
        }
      })
          .then(res => {
            axios.get(process.env.REACT_APP_API_URL + "/api/company?projectId="+this.props.projectId, {
              headers: {
                'Authorization': localStorage.jwtToken
              }
            })
                .then(res => {
                  this.setState({
                    companyToAddValue: '',
                    errors: {},
                    showCompanyForm: false,
                    locations: [{
                      name: '',
                      address: '',
                      assignToCurrentProject: !!this.props.projectId
                    }],
                    positions: [{
                      name: ''
                    }]
                  })

                  this.setState({
                    data: res.data
                  }, () => {
                    this.props.updateCompanyTableData(this.state.data)
                  });

                  return res.data
                })

          })
          .catch((error) => {
            this.setState({
              errors: error.response.data
            })
          })
    }


  }

  toggleActiveTabForm(tabId) {
    if (this.state.activeFormTab !== tabId) {
      this.setState({
        activeFormTab: tabId
      });
    }
  }

  handleCompanyToAddText = (e) => {

    this.setState({ companyToAddValue: e.target.value }, () => this.state.companyToAddValue);
  }


  componentWillReceiveProps({ dateSelected, companyData }) {
    this.setState({ ...this.state, dateSelected, companyData }, () => this.state)
  }

  warningWithConfirmAndCancelMessage = (companyId) => {
    this.setState({
      alert: (
        <ReactBSAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title={this.props.t("Are you sure?")}
          onConfirm={() => this.deleteCompanyById(companyId)}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          confirmBtnText={this.props.t("OK")}
          cancelBtnText={this.props.t("Cancel")}
          showCancel
        >
        </ReactBSAlert>
      )
    });
  }

  hideAlert = () => {
    this.setState({
      alert: null
    }, () => { });
  };

  deleteCompanyById(companyId) {
    var self = this
    axios.delete(process.env.REACT_APP_API_URL + '/api/company/' + companyId, {
      headers: {
        'Authorization': localStorage.jwtToken
      }
    }).then(res => {

      axios.get(process.env.REACT_APP_API_URL + "/api/company?projectId=" + this.props.projectId, {
        headers: {
          'Authorization': localStorage.jwtToken
        }
      }).then(response =>
        {this.setState({
          data: response.data,
          alert: null
        }, () => {

          this.props.updateCompanyTableData(this.state.data)

        })});


    })
      .catch((error) => {

        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          if (error.response.status === 403 || error.response.status === 409) {
            self.warningCompanyCantBeDeleted(error.response)
            self.setState({ alert: null }, () => { })
            console.log(error.response);
            //  return self.setState({responseOnDeleteRequest:'Delete associated rental equipment first'},()=>{})
          }
          console.log(error.response.status);

        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
        console.log(error.config);
      })
  }


  warningCompanyCantBeDeleted(response) {
    this.setState({
      visible:
        <ReactBSAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          onConfirm={() => this.setState({ visible: null }, () => { })}
          // onCancel={() => this.setState({ deleteRentalCompanyAlert: null }, () => { })}
          confirmBtnBsStyle="info"
          // cancelBtnBsStyle="danger"
          confirmBtnText={this.props.t("Close")}
        // cancelBtnText="Cancel"
        // showCancel
        >
          <p>{this.props.t('Unable to delete company as its associated with one or more employees')}.</p>
          {/* <p>Detail:</p> */}
          {/*
         <p>{response?.data?.rentalEquipment?.company }</p>
         <p>{response?.data?.rentalEquipment?.description}</p>
         <p>{response?.data?.rentalEquipment?.typeModel}</p> */}
        </ReactBSAlert>
    }, () => { })
  }

  renderProjects(company) {
    if (!(company.projects || []).length) {
      return ''
    }

    return company.projects.map(project => <label
        key={`company_${project._id}`}
        className="p-1 mr-1 rounded bg-success text-white">
      {project.name} <span
        style={{'fontSize': '10px'}}>{project.name}</span>
    </label>)
  }

  renderLocations(company) {
    if (!(company.locations || []).length) {
      return ''
    }

    return company.locations.map(location => <label
        key={`company_${location._id}`}
        className="p-1 mr-1 rounded bg-success text-white">
      <span style={{'fontSize': '10px'}}>{location.name} ({location.address})</span>
    </label>)
  }

  renderPositions(company) {
    if (!(company.positions || []).length) {
      return ''
    }

    return company.positions.map(position => <label
        key={`company_${position._id}`}
        className="p-1 mr-1 rounded bg-success text-white">
      <span style={{'fontSize': '10px'}}>{position.name}</span>
    </label>)
  }

  toggleProjectsModal(company) {

    let assignedProjects = {};

    company?.projects?.forEach(project => {
      assignedProjects[project._id] = project.locations.filter(l => company.locations.find(companyLocation => `${companyLocation._id}` == `${l._id}`));
    })

    this.setState({
      showProjectModal: company ? true : false,
      selectedCompany: company,
      assignedProjects: assignedProjects,
      unassignedProjects: {}
    });
  }

  updateCompanyProjects() {
    axios.patch(process.env.REACT_APP_API_URL + '/api/update-company-projects/', {
      companyId: this.state.selectedCompany._id,
      projects: this.state.assignedProjects,
      unassignedProjects: this.state.unassignedProjects
    }, {
      headers: {
        'Authorization': localStorage.jwtToken
      }
    }).then(res => {
      this.props.refreshCompany(res.data);
      this.props.getProjects()
      this.setState({
        showProjectModal: false
      }, () => {
        this.toggleProjectsModal(null);
      })

    })
  }

  isProjectLocationAssignedToCompany(projectId, locationId) {
    const projectLocations = this.state.assignedProjects[projectId]


    if(!projectLocations) {
      return false
    }

    return projectLocations.find(pL => {
      return `${pL._id}` == `${locationId}`
    });
  }

  handleCheckedAssignedProjectsLocations(e, projectId,location) {

    let assignedProjects = this.state.assignedProjects,
        unassignedProjects = this.state.unassignedProjects;

    if (e.target.checked) {

      if (assignedProjects[projectId]) {
        assignedProjects[projectId].push(location);

      } else {
        assignedProjects = Object.assign(assignedProjects, {
          [projectId]: [
            location
          ]
        })
      }

      if (unassignedProjects[projectId]) {
        unassignedProjects[projectId] = unassignedProjects[projectId].filter(l => `${l._id}` != `${location._id}`);

        if (!unassignedProjects[projectId].length) {
          delete unassignedProjects[projectId];
        }
      }

      this.setState({
        assignedProjects,
        unassignedProjects,
      })
    } else {

      let projectLocations = assignedProjects[projectId] || [];

      projectLocations = projectLocations?.filter(l => `${l._id}` != `${location._id}`) || [];


      if (!projectLocations.length) {
        delete assignedProjects[projectId];
      } else {
        assignedProjects[projectId] = projectLocations;
      }

      if (unassignedProjects[projectId]) {
        unassignedProjects[projectId] = unassignedProjects[projectId].concat(location)
      } else {
        unassignedProjects = Object.assign(unassignedProjects, {
          [projectId]: [
            location
          ]
        })
      }
    }


    this.setState({
      assignedProjects,
      unassignedProjects
    })

  }

  renderActiveButtons(row) {



    return (<div>
        {userCan(this.props.user, 'companies.update') && <Button
            onClick={() => this.toggleEditable(row.original)}
            size="xs"
            className="btn-icon btn-neutral btn btn-info"
        ><i className="fa fa-edit"
        /></Button>}
      {userCan(this.props.user, 'companies.delete') && <Button
        color="danger"
        size="xs"
        className="btn-icon btn-neutral btn btn-info"
        onClick={() => this.warningWithConfirmAndCancelMessage(row.original._id)}
      ><i
          className="fa fa-remove"
        />
      </Button> }

      {userCan(this.props.user, 'companies.assign_to_projects') &&
      <Button
          onClick={() => this.toggleProjectsModal(row.original)}
          size="xs"
          className="btn-icon btn-neutral btn btn-info"
      ><i className="fa fa-list"
      /></Button>}
    </div>)


  }
  handleInputChange = (cellInfo, event) => {
    let data = [...this.props.companyData];
    data[cellInfo.index][cellInfo.column.id] = event.target.value;

    this.setState({ data });
  };
  renderEditable(cellInfo) {

    return (
        <div>
          {cellInfo.value}
        </div>
    );
  }
  saveRowData() {

    axios.put(process.env.REACT_APP_API_URL + '/api/company/', {
      _id: this.state.editCompany._id,
      name: this.state.companyToAddValue,
      locations: this.state.locations,
      positions: this.state.positions,
    }, {
      headers: {
        'Authorization': localStorage.jwtToken
      }
    }).then(res => {

      this.setState({
        editCompany: null,
        errors:{},
        showCompanyForm: false,
        companyToAddValue:'',
        locations: [{
          name: '',
          address: '',
        assignToCurrentProject: !!this.props.projectId
        }],
        positions: [{
          name: '',
        }]
      }, () => {
        this.props.refreshCompany(res.data);
      })


      return res.data
    })
      .catch((error) => {
        this.setState({
          errors: error.response.data
        })
      })
  }

  toggleEditable(company) {
    this.setState({
      editCompany: company,
      showCompanyForm: true,
      companyToAddValue: company.name,
      locations: company.locations.length ? company.locations : [{
        name: '',
        address: ''
      }],
      positions: company.positions.length ? company.positions : [{
        name: ''
      }]
    })
  }

  addMoreLocations(e){
    e.preventDefault();
    this.setState({
      locations: this.state.locations.concat({
        name: '',
        address: ''
      })
    })
  }

  addMorePositions(e){
    e.preventDefault();
    this.setState({
      positions: this.state.positions.concat({
        name: ''
      })
    })
  }

  removeLocation(indexToRemove, e) {
    e.preventDefault();

    this.setState({
      locations: this.state.locations.filter((location, index) => index !== indexToRemove)
    })
  }

  removePosition(indexToRemove, e) {
    e.preventDefault();

    this.setState({
      positions: this.state.positions.filter((location, index) => index !== indexToRemove)
    })
  }

  handleUpdateLocation(e,targetIndex,fieldName){
      this.setState({
          locations: this.state.locations.map((location, index) => {
              if (index !== targetIndex) {
                  return location;
              }

              if (fieldName === 'assignToCurrentProject') {
                location[fieldName] = !!e.target.checked;
              } else {
                location[fieldName] = e.target.value;
              }
              return location;
          })
      })
  }

  handleUpdatePosition(e,targetIndex,fieldName){
    this.setState({
      positions: this.state.positions.map((location, index) => {
        if (index !== targetIndex) {
          return location;
        }

        location[fieldName] = e.target.value;

        return location;
      })
    })
  }

  renderCompanyLocationsList(company) {

    if(!company) {
      return [];
    }

    let locations = [];

    company.locations.forEach((location,index) => {

      locations.push({
        Header: `${location.name} (${location.address})`,
        Cell: (rowInfo) => {

          const assignedLocation = this.isProjectLocationAssignedToCompany(rowInfo.original._id, location._id);

          let canBeUnassigned = assignedLocation?.can_be_unassigned;

          if (canBeUnassigned === undefined) {
            canBeUnassigned = true;
          }

          return (
              <InputGroup key={`${location._id}_${company._id}_${index}`}>
                <Input
                    addon type="checkbox" aria-label="Checkbox for following text input"
                    readOnly={!canBeUnassigned}
                    disabled={!canBeUnassigned}
                    checked={!!assignedLocation}
                    onChange={(e) => this.handleCheckedAssignedProjectsLocations(e, rowInfo.original._id,location)}
                />
              </InputGroup>
          );
        },
      });
    })

    return locations;
  }
  render() {

    const taskdat = [{ "_id": "5e1cfa47c4baf7b27c5b6776", "siteLocationName": "test", "projectId": "ettest", "createDate": "2020-01-13T23:16:23.512Z", "__v": 0 }, { "_id": "5e1cfa7bc4baf7b27c5b6777", "siteLocationName": "test", "projectId": "ettest", "createDate": "2020-01-13T23:17:15.588Z", "__v": 0 }, { "_id": "5e1cfbd3c4baf7b27c5b6778", "siteLocationName": "test", "projectId": "ettest", "createDate": "2020-01-13T23:22:59.798Z", "__v": 0 }, { "_id": "5e1cfc47c4baf7b27c5b6779", "siteLocationName": "test", "projectId": "ettest", "createDate": "2020-01-13T23:24:55.329Z", "__v": 0 }, { "_id": "5e1cfc4cc4baf7b27c5b677a", "siteLocationName": "test", "projectId": "ettest", "createDate": "2020-01-13T23:25:00.589Z", "__v": 0 }, { "_id": "5e1cfc4dc4baf7b27c5b677b", "siteLocationName": "test", "projectId": "ettest", "createDate": "2020-01-13T23:25:01.077Z", "__v": 0 }, { "_id": "5e1cfc4dc4baf7b27c5b677c", "siteLocationName": "test", "projectId": "ettest", "createDate": "2020-01-13T23:25:01.247Z", "__v": 0 }, { "_id": "5e1cfc4dc4baf7b27c5b677d", "siteLocationName": "test", "projectId": "ettest", "createDate": "2020-01-13T23:25:01.444Z", "__v": 0 }]

    const { errors } = this.state
    return (
      <>
        {this.state.alert}
        {this.state.visible}
        <div className="content">
          <Dock
              position='right'
              isVisible={this.state.showCompanyForm}
              duration={300}
              size={window.innerWidth < 600?1:(window.innerWidth < 900?0.5:0.3)}
          >
            {this.state.showCompanyForm && <Row>
              <Col>
                <CardBody>
                  <h5>
                    {this.props.t(`${this.state.editCompany ? 'Edit' : 'Add'} Company`)}</h5>
                  <Row>
                    <Col md={12}>
                      <Input type="text"
                             className={classnames('form-control', {
                               'is-invalid': errors.name
                             })}
                             onChange={this.handleCompanyToAddText} value={this.state.companyToAddValue}/>
                      {errors.name && (
                          <div className="invalid-feedback d-block">{errors.name}</div>
                      )}
                    </Col>
                  </Row>

                  <hr/>

                  <Nav tabs>

                    <NavItem>
                      <NavLink
                          className={classnames({
                            active: this.state.activeFormTab === 'locations'
                          })}
                          onClick={() => { this.toggleActiveTabForm('locations') }}
                      >
                        {this.props.t('Locations')}
                      </NavLink>
                    </NavItem>

                    <NavItem>
                      <NavLink
                          className={classnames({
                            active: this.state.activeFormTab === 'positions'
                          })}
                          onClick={() => { this.toggleActiveTabForm('positions') }}
                      >
                        {this.props.t('Positions')}
                      </NavLink>
                    </NavItem>
                  </Nav>

                  <TabContent activeTab={this.state.activeFormTab}>
                    <TabPane tabId="locations">
                      <Row className={'mt-2'}>
                        <Col md={6}>
                          { errors.locations_required && <div className="invalid-feedback d-block">{errors.locations_required}</div> }
                        </Col>

                        <Col md={6} className="text-right">
                          <a href='#' style={{"fontSize":"14px"}} onClick={this.addMoreLocations}>
                            <i className="fa fa-plus text-success"></i> {this.props.t('Add more locations')}
                          </a>
                        </Col>
                      </Row>
                      <Row className="mt-2">
                        <Col>
                          <div>
                            {this.state.locations.map((location,locationIndex) =>
                                <fieldset key={`location_${locationIndex}`} style={{"border": "1px solid gray","padding":"0 10px","marginTop":'5px'}}>
                                  <legend style={{"fontSize":"16px"}}>
                                    {this.props.t('Location')} ({locationIndex+1})
                                    {this.state.locations.length > 1 &&   <a href="#"
                                                                             onClick={this.removeLocation.bind(null,locationIndex)}
                                                                             className="text-danger pull-right mr-2" style={{"fontSize":"18px"}}>
                                      <i className="fa fa-trash"> </i>
                                    </a> }
                                  </legend>
                                  <FormGroup>
                                    <Label>{this.props.t('Name')} </Label>
                                    <Input type="text"
                                           className={classnames({
                                             'is-invalid': errors.locations && errors?.locations[locationIndex]?.name
                                           })}
                                           onChange={e=>this.handleUpdateLocation(e,locationIndex,'name')}
                                           value={this.state.locations[locationIndex].name}/>
                                    {
                                      errors.locations && <div className="invalid-feedback d-block">{errors.locations[locationIndex]?.name}</div>
                                    }
                                  </FormGroup>
                                  <FormGroup>
                                    <Label>{this.props.t('Address')}</Label>
                                    <Input type="text"
                                           className={classnames({
                                             'is-invalid': errors.locations && errors?.locations[locationIndex]?.address
                                           })}
                                           onChange={e=>this.handleUpdateLocation(e,locationIndex,'address')}
                                           value={this.state.locations[locationIndex].address}/>
                                    {
                                      errors.locations && <div className="invalid-feedback d-block">{errors.locations[locationIndex]?.address}</div>
                                    }
                                  </FormGroup>

                                  {this.props.projectId && !this.state.editCompany  && <FormGroup className="d-inline mr-2"
                                                                                                  style={{"fontSize": "16px"}}
                                  >
                                    <Input
                                        checked={!!location.assignToCurrentProject}
                                        id={`location_${locationIndex}_assign_to_current_project`}
                                        addon type="checkbox"
                                        onChange={(e) => this.handleUpdateLocation(e,locationIndex,'assignToCurrentProject')}
                                    />
                                    <Label style={{
                                      "whiteSpace": "nowrap",
                                      'position': 'absolute',
                                      'fontSize':'12px'
                                    }}
                                           for={`location_${locationIndex}_assign_to_current_project`} className="ml-1">
                                      {this.props.t('Assign to current project')} <b>{this.props.project} </b>
                                    </Label>
                                  </FormGroup> }
                                </fieldset>)
                            }

                          </div>
                        </Col>
                      </Row>
                    </TabPane>

                    <TabPane tabId="positions">

                      <Row className={'mt-2'}>
                        <Col md={6}>
                          { errors.positions_required && <div className="invalid-feedback d-block">{errors.positions_required}</div> }
                        </Col>

                        <Col md={6} className="text-right">
                          <a href='#' style={{"fontSize":"14px"}} onClick={this.addMorePositions}>
                            <i className="fa fa-plus text-success"></i> {this.props.t('Add more positions')}
                          </a>
                        </Col>
                      </Row>
                      <Row className="mt-2">
                        <Col>
                          <div>
                            {this.state.positions.map((position,positionIndex) =>
                                <fieldset key={`position_${positionIndex}`} style={{"border": "1px solid gray","padding":"0 10px","marginTop":'5px'}}>
                                  <legend style={{"fontSize":"16px"}}>
                                    {this.props.t('Position')} ({positionIndex+1})
                                    {this.state.positions.length > 1 &&   <a href="#"
                                                                             onClick={this.removePosition.bind(null,positionIndex)}
                                                                             className="text-danger pull-right mr-2" style={{"fontSize":"18px"}}>
                                      <i className="fa fa-trash"> </i>
                                    </a> }
                                  </legend>
                                  <FormGroup>
                                    <Label>{this.props.t('Name')} </Label>
                                    <Input type="text"
                                           className={classnames({
                                             'is-invalid': errors.positions && errors?.positions[positionIndex]?.name
                                           })}
                                           onChange={e=>this.handleUpdatePosition(e,positionIndex,'name')}
                                           value={this.state.positions[positionIndex].name}/>
                                    {
                                      errors.positions && <div className="invalid-feedback d-block">{errors.positions[positionIndex]?.name}</div>
                                    }
                                  </FormGroup>
                                </fieldset>)
                            }

                          </div>
                        </Col>
                      </Row>

                    </TabPane>
                  </TabContent>



                  <Row>
                    <Col md={6}>
                      <Button color="info"
                              className="w-100"
                              onClick={() => this.createNewCompany(this.state.companyToAddValue)}>

                        {this.props.t(`${this.state.editCompany ? 'Edit' : 'Add'} company`)}
                      </Button>
                    </Col>

                    <Col md={6}>
                      <Button color="info"
                              className="w-100"
                              onClick={() => this.setState({
                                showCompanyForm: false,
                                errors: {},
                                companyToAddValue: '',
                                editCompany: null,
                                locations: [{
                                  name: '',
                                  address: '',
                                  assignToCurrentProject: !!this.props.projectId
                                }],
                                positions: [{
                                  name: ''
                                }]
                              })}>
                        Close
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Col>
            </Row>
            }
          </Dock>

          {userCan(this.props.user, 'companies.create') && <Button color="info" type="submit"
                                                                   onClick={() => this.setState({showCompanyForm: !this.state.showCompanyForm})}>
            {this.props.t('Add Company')}
          </Button>}
          <Row>
            <Col>
              <h5>{this.props.t('All Companies')}</h5>
              <ReactTable
                data={this.props.companyData}
                columns={[
                  {
                    Header: this.props.t("Company Id"),
                    accessor: "_id",
                    show: false,
                    filterable:false
                  }, {
                    Header: this.props.t("Company Name"),
                    accessor: "name",
                    Cell: this.renderEditable
                  },
                  {
                    Header: this.props.t('Projects'),
                    filterBy:"name",
                    Cell: row => this.renderProjects(row.original)
                  },
                  {
                    Header: this.props.t('Locations'),
                    filterBy:"name",
                    Cell: row => this.renderLocations(row.original)
                  },
                  {
                    Header: this.props.t('Positions'),
                    filterBy: "name",
                    Cell: row => this.renderPositions(row.original)
                  },
                  {
                    Header: this.props.t("Actions"),
                    id: "activateToggle",
                    width: 150,
                    Cell: ((value) => (this.renderActiveButtons(value))),
                    filterable:false
                  }

                ]}
                defaultPageSize={1}
                pageSize={this.props.companyData.length}
                showPaginationTop={false}
                showPaginationBottom={false}
                // style={{
                //   height: "200px" // This will force the table body to overflow and scroll, since there is not enough room
                // }}
                // getTrProps={this.onRowClick}
                // getTrProps={onRowClick}
                /*
                  You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
                */
                className="-striped -highlight primary-pagination"
                filterable
                defaultFilterMethod={(filter, row, column) => {
                  return onColumnFilter(filter, row, column);}}
              />
            </Col>
          </Row>


          <Modal isOpen={this.state.showProjectModal}
                 size='xl'>
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <ReactTable
                        data={this.props.projectData}
                        columns={[
                          {
                            Header: this.props.t("Project Id"),
                            accessor: "_id",
                            show: false
                          },
                          {
                            Header: this.props.t("Name"),
                            accessor: "name",
                          },
                            ...this.renderCompanyLocationsList(this.state.selectedCompany),
                          {
                            Header: this.props.t("Description"),
                            accessor: "description",
                          },
                          {
                            Header: this.props.t("Start Date"),
                            accessor: "startDate",
                            Cell: (props) => {
                              //props.value will contain your date
                              //you can convert your date here
                              const custom_date = moment.utc(props.value).format('DD MMM YYYY')
                              return <span>{custom_date}</span>
                            }
                          },
                        ]}
                        defaultPageSize={1}
                        pageSize={this.props.projectData.length}
                        showPaginationTop={false}
                        showPaginationBottom={false}
                        className="-striped -highlight primary-pagination"
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col md="3"/>
              <Col md="3">
                <Button
                    className="btn-link"
                    color="default"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => this.toggleProjectsModal(null)}
                >
                  {this.props.t('Never mind')}
                </Button>
              </Col>

              <Col md="3">
                <Button
                    className="btn-link"
                    color="default"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => this.updateCompanyProjects()}
                >
                  {this.props.t('Save')}
                </Button>
              </Col>
              <Col md="3"/>
            </Row>
          </Modal>
        </div>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getProjects: () => dispatch(getProjects()),
  };
}

const AddCompanyTable = withTranslation()(connect(
    mapStateToProps,
    mapDispatchToProps
)(ConnectedAddCompanyTable));
export default AddCompanyTable;
