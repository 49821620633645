
import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col
} from "reactstrap";
import { connect } from "react-redux";
import ReactTable from "react-table";
import { withTranslation } from 'react-i18next';

function mapStateToProps(state) {
  return {
    project: state.allOtherReducers.project,
    projectId: state.allOtherReducers.projectId,
    projectData: state.allOtherReducers.projectData,
    reportDate: state.allOtherReducers.reportDate
  };
}

class ConnectedSiteReportGeneralComments extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      tableLength:[]
    }
  }

  componentWillReceiveProps({ reportGeneralComments }) {
    this.setState({ ...this.state, reportGeneralComments }, () => this.state)
  }

  render() {
    const NullComponent = () => null;
    return (
      <div>
        <>
        <h4 className="pl-3 card-title">{this.props.t("General Comments")}</h4>

<ReactTable
            data={this.props.reportGeneralComments}
            columns={[
              {
                Header: this.props.t("General Comments Id"),
                accessor: "_id",
                show: false
              }, {
                Header: this.props.t("General Comments"),
                accessor: "generalComment",

              }

            ]}
            loadingText=""
            defaultPageSize={1}
            pageSize={this.props.reportGeneralComments.length}
            showPaginationTop={false}
            showPaginationBottom={false}
            className="primary-pagination"
          />

        </>
      </div>
    );
  }
}

const SiteReportGeneralComments = withTranslation()(connect(mapStateToProps, null)(ConnectedSiteReportGeneralComments));

export default SiteReportGeneralComments;
