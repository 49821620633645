import React from "react";
import axios from "axios"
import {connect} from "react-redux";
import Select from "react-select";
import WeatherImageUpload from "./WeatherImageUpload";
import moment from 'moment'

import {withTranslation} from 'react-i18next';


// reactstrap components
import {
    Button,
    Card,
    CardBody,
    Input,
    Label,
    Form,
    FormGroup,
    Row,
    Col,
    Alert
} from "reactstrap";
import ContractorWorksFormByEmployee from "../ContractedWork/ContractedWorksFormByEmployee";
import classnames from "classnames";

function mapStateToProps(state) {
    return {
        project: state.allOtherReducers.project,
        projectId: state.allOtherReducers.projectId,
        projectData: state.allOtherReducers.projectData,
        reportDate: state.allOtherReducers.reportDate
    };
}

class ConnectedSimpleWeatherForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            wind: '',
            temperature: '',
            multiValue: [],
            filterOptions: [
                {value: "sun", label: "Sun"},
                {value: "rain", label: "Rain"},
                {value: "cloud", label: "Cloud"},
                {value: "freezing", label: "Freezing"},
                {value: "storm", label: "Storm"},
                {value: "snow", label: "Snow"},
            ],
            imageList: [],
            file: null,
            gotFile: null,
            retreivedImages: [],
            imageDate: moment().format("DD MMM YYYY"),
            imageReportDate: this.props.reportDate,
            visible: false,
            visibleNoImage: false,
            imageComment: null,
            chooseFileVisible: true,
            uploadAndCancelVisible: false,
            signedImages: [],
            fileName: '',
            disabledSubmit: false,
            incompatibleImage: false,
            errors: {},
        }
        this.handleMultiChange = this.handleMultiChange.bind(this);
        this.addImageToForm = this.addImageToForm.bind(this)
        this.getImages = this.getImages.bind(this)
        this.removeImageFromPage = this.removeImageFromPage.bind(this)
        this.onDismiss = this.onDismiss.bind(this)
        this.uploadSuccessfulAlert = this.uploadSuccessfulAlert.bind(this)
        this.noImageAlert = this.noImageAlert.bind(this)
        this.incompatibleImageAlertDismiss = this.incompatibleImageAlertDismiss.bind(this)
        this.noImageAlertDismiss = this.noImageAlertDismiss.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleUploadChange = this.handleUploadChange.bind(this)
        this.getS3SignUrlGetObject = this.getS3SignUrlGetObject.bind(this)
    }

    handleMultiChange(option) {
        this.setState(state => {
            return {
                multiValue: option
            };
        });
    }

    getColor(key) {
        return this.state.selected[key] ? 'primary' : 'default';
    }

    componentDidMount() {
        const simpleWeatherRecord = this.props.updateSimpleWeatherRecord

        if (simpleWeatherRecord) {
            simpleWeatherRecord.weatherType = simpleWeatherRecord.weatherType.map(t => t.toLowerCase())

            let weatherType = this.state.filterOptions
                .filter(o => simpleWeatherRecord.weatherType.includes(o.value.toLowerCase()));

            let img,
                imageList = []

            if (simpleWeatherRecord.images.length) {
                img = simpleWeatherRecord.images[0]
                imageList = [
                    img._id
                ]
            }

            this.setState({
                multiValue: weatherType,
                temperature: simpleWeatherRecord.temperature,
                wind: simpleWeatherRecord.wind,
                comment: simpleWeatherRecord.comment,
                fileLink: img?.url,
                uploadAndCancelVisible: !!img,
                chooseFileVisible: !img,
                fileName: img?.name,
                imageList: imageList,
            })
        }
    }

    formSubmitSuccess(e) {
        e.preventDefault();
        this.setState({
            disabledSubmit: true
        })
        // this.handleSubmit(e).then(() => {

        let weatherItems = [];
        this.state.multiValue.map(item => {
            return weatherItems.push(item.value)
        })

        let dataToUpdate = {
            weatherType: weatherItems,
            temperature: this.state.temperature,
            wind: this.state.wind,
            weatherDate: this.props.reportDate,
            comment: this.state.comment,
            projectId: this.props.projectId,
            images: this.state.imageList
        };

        let actionMethod = 'post';

        if (this.props.updateSimpleWeatherRecord) {
            actionMethod = 'put'
            dataToUpdate._id = this.props.updateSimpleWeatherRecord._id
        }

        axios[actionMethod](process.env.REACT_APP_API_URL + "/api/weather/simple", dataToUpdate, {
            headers: {
                'Authorization': localStorage.jwtToken
            }
        }).then(async res => {
            this.handleSubmit(res.data._id, () => {
                this.props.getSimpleWeatherTableData()
                this.setState({
                    wind: '',
                    temperature: '',
                    multiValue: [],
                    comment: '',
                    imageList: [],
                    disabledSubmit: false,
                    fileLink: null,
                    fileName: null,
                    errors: {}
                }, () => {
                })
                this.props.toggleSimpleWeatherForm()
            })

            return res.send
        }).catch((error) => {
            this.setState({
                errors: error.response.data,
                disabledSubmit: false,
            })
        })
    }

    addImageToForm(imageId) {
        this.setState(prevState => ({
            imageList: [...prevState.imageList, imageId]
        }), () => {
            console.log(this.state.imageList)
        })
    }


    //Alerts
    onDismiss() {
        this.setState({visible: !this.state.visible})
    }

    uploadSuccessfulAlert() {
        this.setState({visible: !this.state.visible})
    }

    noImageAlert() {
        this.setState({visibleNoImage: !this.state.visibleNoImage})
    }

    noImageAlertDismiss() {
        this.setState({visibleNoImage: !this.state.visibleNoImage})
    }

    incompatibleImageAlertDismiss() {
        this.setState({incompatibleImage: !this.state.incompatibleImage})
    }

    removeImageFromPage() {
        var output = document.getElementById('output');
        output.removeAttribute('src')
        var imageFileUploadElement = document.getElementById('image')
        imageFileUploadElement.value = ""
        document.getElementById("imageUpload").reset();
        this.setState({
            file: null,
            imageDate: null
        })
    }

    handleImageCommentText = (e) => {

        this.setState({imageComment: e.target.value}, () => this.state.imageComment);
    }

    async handleUploadChange(e) {
        let img = new Image()

        if (e.target.files) {
            const file = e.target.files[0];
            const fileLink = URL.createObjectURL(file);
            img.src = window.URL.createObjectURL(file)
            img.onload = () => {
                if (img.width) {
                    try {
                        this.setState({
                            imageHeight: img.naturalHeight,
                            imageWidth: img.naturalWidth
                        }, () => {
                            this.setState({
                                file: file,
                                fileLink: fileLink,
                                fileName: file.name,
                                chooseFileVisible: false,
                                uploadAndCancelVisible: true
                            }, () => {
                            })
                        })
                    } catch (err) {
                        console.log(err)
                    }
                }
            }
            img.onerror = () => {
                document.getElementById('uploadImage').reset();
            }
        }
    }

    async handleSubmit(weatherRecordId, successCallback) {
        let typeDataAvailable = (this.state.file || {}).type
        if (typeDataAvailable) {
            if (this.state.file.type.includes('svg')) {
                this.incompatibleImageAlertDismiss()
            } else {
                try {
                    const data = await this.getS3SignUrl(this.state.file.name,
                        this.state.file.type, weatherRecordId).then(async (x) => {

                        await this.pushPhotoToS3(x, this.state.file).then(() => {

                            this.uploadSuccessfulAlert()
                            // this.removeImageFromPage()

                            this.setState({
                                    imageComment: null,
                                    chooseFileVisible: true,
                                    uploadAndCancelVisible: false,
                                    // fileName: '',
                                    fileLink: null,
                                    imageHeight: null,
                                    imageWidth: null,
                                    imageList: []
                                }, () => {
                                    successCallback();
                                }
                                // this.getImages()
                            )
                        });
                    });
                } catch (err) {
                    console.log(err)
                }
                // if(data.url){
                // // const photoConvertedToBlob = dataURIToBlob(profilePhotoResized);

                // }
            }
        } else {
            successCallback();
        }

    }

    getS3SignUrl(filename, filetype, weatherRecordId) {
        console.log(weatherRecordId)
        return axios.post(process.env.REACT_APP_API_URL + "/api/imagemanagement/weather/presigned/", {
            filename: filename,
            imageComment: this.state.imageComment,
            projectid: this.props.projectId,
            date: this.props.reportDate,
            imageHeight: this.state.imageHeight,
            imageWidth: this.state.imageWidth,
            tags: ['weatherreport'],
            weatherRecordId: weatherRecordId
        }, {
            headers: {
                'Authorization': localStorage.jwtToken,
            }

        })
            .then(res => {
                console.log(res)
                return res.data
            }).catch((error) => {
                console.log(error)
                if (axios.isCancel(error)) {
                    console.log(error)
                }
                ;
            })


        // const presignedUrl = await response.json();

    }

    async getS3SignUrlGetObject(filename, filetype) {
        // {
        //   filename: filename,
        // },
        return axios.get(process.env.REACT_APP_API_URL + "/api/imagemanagement/weather/presigned/" + filename, {
            headers: {
                'Authorization': localStorage.jwtToken,
            }
        })
            .then(res => {
                console.log(res)
                return res.data
            }).catch((error) => {
                console.log(error)
                if (axios.isCancel(error)) {
                    console.log(error)
                }
                ;
            })

        // const presignedUrl = await response.json();

    }

    async pushPhotoToS3(presignedUploadUrl, uploadPhoto) {
        const myHeaders = new Headers({'Content-Type': 'image/*'});
        const response = await fetch(presignedUploadUrl.url, {
            method: 'PUT',
            headers: myHeaders,
            body: uploadPhoto
        });
    }


    async getImages() {
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                'Authorization': localStorage.jwtToken
            },
            params: {
                tags: "weatherreport"
            }

        };

        this.setState({
            retreivedImages: [],
        }, () => {
        })

        const image = await axios.get(process.env.REACT_APP_API_URL + "/api/images/project/" + this.props.projectId + "/date/" + this.props.reportDate + "/weatherreport", config).then(
            (response) => {

                console.log(response)
                if (Array.isArray(response.data)) {
                    console.log(response)
                    response.data.map(async (imageItem) => {
                        imageItem.imageLocation = await this.getS3SignUrlGetObject(imageItem.imageKey)
                        this.setState(prevState => ({
                            retreivedImages: [...prevState.retreivedImages, imageItem]
                        }), () => {
                            console.log(this.state.retreivedImages)
                            this.addImageToForm(imageItem._id)
                        })

                    })

                }

            }
        )
        console.log(this.state.retreivedImages)
    }


    render() {
        const {errors = {}} = this.state;

        var images = []

        // images = this.state.retreivedImages

        console.log(images)
        const {chooseFileVisible, uploadAndCancelVisible} = this.state
        return (
            <Row>
                <Col>
                    <CardBody>
                        <h3>
                            <Button color="info"
                                    onClick={() => this.props.toggleSimpleWeatherForm()}>
                                {this.props.t("Close")}
                            </Button>
                        </h3>
                        <Form method={"post"} onSubmit={(e) => this.formSubmitSuccess(e)}>
                            <Row>
                                <Col md={12}>
                                    <label>{this.props.t("Weather Type")}</label>
                                    <FormGroup>
                                        <Select
                                            className={classnames('form-control', {
                                                'is-invalid': errors.weatherType
                                            })}
                                            name="weather"
                                            placeholder="Weather"
                                            value={this.state.multiValue}
                                            options={this.state.filterOptions}
                                            onChange={this.handleMultiChange}
                                            isMulti
                                        />

                                        {errors.weatherType && (
                                            <div className="invalid-feedback">{errors.weatherType}</div>
                                        )}
                                    </FormGroup>
                                </Col>

                            </Row>

                            <Row>
                                <Col md={6}>
                                    <FormGroup>
                                        <label>{this.props.t("Temperature")}</label>
                                        <Input name={"temperature"} type="text"
                                               className={classnames('form-control form-control-lg', {
                                                   'is-invalid': errors.temperature
                                               })}
                                               value={this.state.temperature}
                                               onChange={(e) => {
                                                   this.setState({temperature: e.target.value})
                                               }}
                                        />

                                        {errors.temperature && (
                                            <div className="invalid-feedback">{errors.temperature}</div>
                                        )}
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <label>{this.props.t("Wind")}</label>
                                        <Input name={"wind"} type="text"
                                               value={this.state.wind}
                                               className={classnames('form-control form-control-lg', {
                                                   'is-invalid': errors.wind
                                               })}
                                               onChange={(e) => {
                                                   this.setState({wind: e.target.value})
                                               }}
                                        />
                                        {errors.wind && (
                                            <div className="invalid-feedback">{errors.wind}</div>
                                        )}
                                    </FormGroup>
                                </Col>
                            </Row>


                            <Row>
                                <Col>
                                    <FormGroup>
                                        <label>{this.props.t("Comment")}</label>
                                        <Input name={"comment"} type="textarea"
                                               value={this.state.comment}
                                               onChange={(e) => {
                                                   this.setState({comment: e.target.value})
                                               }}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <label>{this.props.t("Weather Image")}</label>
                                </Col>
                            </Row>
                            <Row>

                                <Col>
                                    {/* IMAGE STUFF BEGINS */}
                                    <div>
                                        <Alert
                                            color="success"

                                            isOpen={this.state.visible}
                                            toggle={this.onDismiss}
                                            autoDismiss={3}
                                        >
                        <span>
                          <b> {this.props.t("Info")} - </b> {this.props.t("Image uploaded successfully")}.
                        </span>
                                        </Alert>
                                        <Alert
                                            color="warning"
                                            isOpen={this.state.visibleNoImage}
                                            toggle={this.noImageAlertDismiss}
                                        >
                        <span>
                          <b> {this.props.t("Warning")} - </b> {this.props.t("No image selected")}.
                        </span>
                                        </Alert>
                                        <Alert
                                            color="warning"
                                            isOpen={this.state.incompatibleImage}
                                            toggle={this.incompatibleImageAlertDismiss}
                                        >
                        <span>
                          <b> {this.props.t("Warning")} - </b> {this.props.t("Unsupported image type")}.
                        </span>
                                        </Alert>
                                        <Row>

                                            <Col>
                                                {/* <Form id='uploadImage' onSubmit={(e) => this.handleSubmit(e)}> */}

                                                {chooseFileVisible ?
                                                    <Input type="file" onChange={(e) => this.handleUploadChange(e)}
                                                           accept="image/*"/>
                                                    : null}

                                                {uploadAndCancelVisible ?

                                                    <div>
                                                        <img src={this.state.fileLink} alt=""/>
                                                        <Row>
                                                            <Col>
                                                                <br/>
                                                                <label>{this.props.t("File name")}:</label>
                                                                <br/>
                                                                <label>{this.state.fileName}</label>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                                <label>{this.props.t("Image taken on")}:</label>
                                                                <br/>
                                                                <label>{moment(this.props.reportDate).format("DD MMM YYYY")}</label>
                                                                <br/>
                                                            </Col>
                                                        </Row>
                                                        {/* <Row>
                                    <Col>
                                        <label>Description</label>
                                        <Input placeholder='Write a description of the image to upload' type="text" id="imageComment" name='imageComment' onChange={this.handleImageCommentText} />
                                    </Col>
                                </Row> */}
                                                        <Row>
                                                            <Col>
                                                                {/* <Button onClick={(e) => this.handleSubmit(e)}>Upload</Button> */}

                                                                <Button onClick={() => {
                                                                    this.setState({
                                                                        chooseFileVisible: true,
                                                                        fileLink: null,
                                                                        uploadAndCancelVisible: false,
                                                                        fileName: null,
                                                                        file: null,
                                                                        imageList: []
                                                                    })
                                                                }
                                                                }>{this.props.t("Remove Image")}</Button>
                                                            </Col>
                                                        </Row>

                                                    </div>
                                                    : null
                                                }
                                                {/* </Form> */}
                                            </Col>
                                            <Col></Col>
                                        </Row>

                                        <Row>
                                            <Col md={9}>
                                                <img id="output"/>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                {/* <ImageGalleryUpload images={images} limit={12} getImages={this.getImages} type="image" /> */}
                                            </Col>
                                        </Row>
                                    </div>
                                    {/* addImageToForm={this.addImageToForm} */}

                                </Col>
                            </Row>

                            <hr/>

                            {this.state.errors?.recordAlreadyCreated && <Row>
                                <Col md={12}>
                                    <p className={'text-danger'}>
                                        {this.state.errors.recordAlreadyCreated}
                                    </p>
                                </Col>
                            </Row>
                            }
                            <Row>
                                <Col md={6}>
                                    <Button
                                        className="btn btn-info btn-block "
                                        disabled={this.state.disabledSubmit}
                                        color="info" type="submit">
                                        {this.props.t("Submit")}
                                    </Button>

                                </Col>


                            </Row>
                        </Form>

                    </CardBody>
                </Col>
            </Row>
        );
    }
}

const SimpleWeatherForm = withTranslation()(connect(mapStateToProps, null)(ConnectedSimpleWeatherForm));
export default SimpleWeatherForm;
