import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
import axios from "axios";
import moment from "moment"
import InputMask from 'react-input-mask';
import { connect } from "react-redux";
import { setProject, setReportDate, getProjects, getProjectsByUser } from "../../actions/index";
import { withTranslation } from 'react-i18next';

// reactstrap components
import {
  Button,

  Input,
  CardBody,
  Row,
  Col,
} from "reactstrap";
import { Dock } from "react-dock"
import classnames from "classnames";
import {userCan, userCant} from "../../services/Authorization/authService";

class AddMaterialsTable extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      data: [{ "_id": "zx3", "material_name": "LED Detector" }, { "_id": "zx4", "material_name": "Hand Torch" }],
      projectId: "ettest",
      materialToAddValue: '',
      editContent: false,
      editingRow: -1,
      isDirty: false,
      showForm:false,
      errors:{}
    }
    this.createNewMaterial = this.createNewMaterial.bind(this)
    this.saveRowData = this.saveRowData.bind(this)
    this.toggleEditable = this.toggleEditable.bind(this)
    this.renderActiveButtons = this.renderActiveButtons.bind(this)
    this.renderEditable = this.renderEditable.bind(this)
    this.deleteMaterialByMaterialId = this.deleteMaterialByMaterialId.bind(this)


  }



  componentDidMount(props) {

  }

  deleteMaterialByMaterialId(materialId) {
    if (userCant(this.props.user, 'can_manage_tasks_and_materials')) {
      return
    }
    axios.delete(process.env.REACT_APP_API_URL + '/api/material/' + materialId, {
      headers: {
        'Authorization': localStorage.jwtToken
      }
    }).then(res => {
      this.props.getSomeMaterials()
      return res
    })
      .catch((error) => {
        ;
      })
  }

  createNewMaterial(element) {

    this.setState({
      errors: {}
    })
    if (this.state.editMaterial) {
      return this.saveRowData()
    }

    axios.post(process.env.REACT_APP_API_URL + "/api/material/", {
      createdDate: moment(new Date()).format(),
      materialName: element,
      projectId: this.props.projectId
    }, {
      headers: {
        'Authorization': localStorage.jwtToken
      }
    })
      .then(res => {
        axios.get(process.env.REACT_APP_API_URL + "/api/material/project/" + this.props.projectId, {
          headers: {
            'Authorization': localStorage.jwtToken
          }
        })
          .then(res => {
            this.setState({
              data: res.data,
              errors: {},
              showForm: false
            }, () => this.state.data);
            this.props.getSomeMaterials()
            return res.data
          })
      })
      .catch((error) => {
        this.setState({
          errors: error.response.data
        })
      })
  }


  handleMaterialToAddText = (e) => {

    this.setState({ materialToAddValue: e.target.value }, () => this.state.materialToAddValue);
  }


  componentWillReceiveProps({ timesheetLogs, dateSelected, materialTableData }) {
    this.setState({ ...this.state, timesheetLogs, dateSelected, materialTableData }, () => this.state)
  }

  handleInputChange = (cellInfo, event) => {
    let data = [...this.props.materialTableData];
    data[cellInfo.index][cellInfo.column.id] = event.target.value;

    this.setState({ data });
  };
  renderEditable(cellInfo) {
    return (
        <div >
          {cellInfo.value}
        </div>
    );
  }


  saveRowData() {

    axios.put(process.env.REACT_APP_API_URL + '/api/material/', {
      _id: this.state.editMaterial,
      materialName: this.state.materialToAddValue,
    }, {
      headers: {
        'Authorization': localStorage.jwtToken
      }
    }).then(res => {

      this.setState({
        editMaterial: null,
        showForm: false,
        errors:{}
      },()=>{
        this.props.refreshMaterial(res.data)
      })
      return res.data
    }).catch((error) => {
      this.setState({
        errors: error.response.data
      })
    })
  }


  toggleEditable(material) {
    this.setState({
      showForm: true,
      editMaterial: material,
      errors: {},
      materialToAddValue: material.materialName
    })
  }

  renderActiveButtons(row) {
    if (userCant(this.props.user, 'can_manage_tasks_and_materials')) {
      return
    }

    return (<div>
      <Button
        onClick={() => this.toggleEditable(row.original)}
        size="xs"
        className="btn-icon btn-neutral btn btn-info"
      ><i className="fa fa-edit"
        /></Button>
      <Button
        color="danger"
        size="sm"
        className="btn-icon btn-neutral btn btn-info"
        onClick={() => {
          this.deleteMaterialByMaterialId(row.original._id)
        }
        }
      ><i
          className="fa fa-remove"
        />
      </Button>
    </div>)


  }

  render() {
    const { errors } = this.state

    return (
      <>
        <div className="content">
          <Dock
              position='right'
              isVisible={this.state.showForm}
              size={window.innerWidth < 600?1:(window.innerWidth < 900?0.5:0.3)}
              duration={300}
          >
            {this.state.showForm && <Row>
              <Col>
                <CardBody>
                  <h3>
                    <Button color="info"
                            className="mr-2"
                            onClick={() => this.setState({errors:{},showForm:false,editMaterial:null,materialToAddValue:''})}>{this.props.t("Close")}</Button>

                    {this.props.t(`${this.state.editMaterial ? 'Edit' : 'Add'} Material`)}
                  </h3>
                <Row>
                  <Col>
                    <Input type="text"
                           className={classnames('form-control', {
                             'is-invalid': errors.materialName
                           })}
                           value={this.state.materialToAddValue}
                           onChange={this.handleMaterialToAddText} placeholder={this.props.t('Add material name')}/>

                    {errors.materialName && (
                        <div className="invalid-feedback d-block">{errors.materialName}</div>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button color="info"
                            onClick={() => this.createNewMaterial(this.state.materialToAddValue)}>
                      {this.props.t(`${this.state.editMaterial ? 'Edit ' : 'Add '} Material`)}
                      Material
                    </Button>
                  </Col>
                </Row>
                </CardBody>
              </Col>
            </Row>

            }
          </Dock>

          {userCan(this.props.user, 'can_manage_tasks_and_materials') &&
          <Button color="info" onClick={() => this.setState({showForm: !this.state.showForm})}>
            {this.props.t("Add Material")}
          </Button>}

          <ReactTable
            data={this.props.materialTableData}
            columns={[
              {
                Header: this.props.t("Materials Id"),
                accessor: "_id",
                show: false
              }, {
                Header: this.props.t("Material"),
                accessor: "materialName",
                Cell: this.renderEditable
              },
              {
                Header: this.props.t("Actions"),
                id: "activateToggle",
                width: 150,
                Cell: ((value) => (this.renderActiveButtons(value)))
              },

            ]}
            defaultPageSize={1}
            pageSize={this.props.materialTableData.length}
            showPaginationTop={false}
            showPaginationBottom={false}
            // style={{
            //   height: "200px" // This will force the table body to overflow and scroll, since there is not enough room
            // }}
            // getTrProps={this.onRowClick}
            // getTrProps={onRowClick}
            /*
              You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
            */
            className="-striped -highlight primary-pagination"
          />
        </div>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setProject: project => dispatch(setProject(project)),
    getProjects: () => dispatch(getProjects()),
    setReportDate: date => dispatch(setReportDate(date)),
    getProjectsByUser:(userid)=>dispatch(getProjectsByUser(userid))

  };
}

const mapStateToProps = state => ({
  auth: state.auth,
  user: state.auth.user,
  project: state.allOtherReducers.project,
  projectId: state.allOtherReducers.projectId,
  projectData: state.allOtherReducers.projectData,
  reportDate: state.allOtherReducers.reportDate,
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps )(AddMaterialsTable));


