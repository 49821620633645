import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
import axios from "axios";
import store from '../../store/index'
import { connect } from "react-redux";
import _, { isNull } from "lodash";
import helper from '../../utils/general_helper.js'
import { withTranslation } from 'react-i18next';

// reactstrap components
import {
  Input,
  InputGroup,
  Alert
} from "reactstrap";

function mapStateToProps(state) {
  return {
    project: state.allOtherReducers.project,
    projectId: state.allOtherReducers.projectId,
    projectData: state.allOtherReducers.projectData,
    reportDate: state.allOtherReducers.reportDate
  };
}

class ConnectedUpdateTimesheetLogTable extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      editContent: false,
      editingRow: -1,
      isDirty: false,
      selectedEmployeeList: [],
      selected_ids: [],
      keyGen: [],
      checked: '',
      isAlerted: false,
      testCellData: 0,
    }
    this.renderEditableMinutes = this.renderEditableMinutes.bind(this);
    this.renderEditableHours = this.renderEditableHours.bind(this);
    this.renderActiveButtons = this.renderActiveButtons.bind(this)
    this.hhMmToMinutes = this.hhMmToMinutes.bind(this)
    this.minutesToHhMm = this.minutesToHhMm.bind(this)
    this.hoursMinutesFormatted = this.hoursMinutesFormatted.bind(this)
    this.onDismiss = this.onDismiss.bind(this)
    this.shouldCheckBoxBeTicked = this.shouldCheckBoxBeTicked.bind(this)

  }

  componentDidUpdate(prevProps) {
    if (this.props.contractorSummaryTableData !== prevProps.contractorSummaryTableData) {
      this.populateDropDownCompany(this.props.contractorSummaryTableData);

    }
    if (this.props.timesheetLogsAllData !== prevProps.timesheetLogsAllData) {
      if (this.props.timesheetLogsAllData.length > 0) { }

    }

    if (this.props.timesheetLogDetail !== prevProps.timesheetLogDetail) {
      if (this.props.timesheetLogsAllData.length > 0) {
      }
    }
  }

  deleteTimesheetLogByTimesheetLogId(timesheetLogId) {
    axios.delete(process.env.REACT_APP_API_URL + '/api/timesheetlog/' + timesheetLogId, {
      headers: {
        'Authorization': localStorage.jwtToken
      }
    }).then(res => {
      this.props.getTimesheetLogByDate(this.props.reportDate)
      return res
    }).then(res => {
      this.props.getEmployeesCompressedListByDate(this.props.reportDate).catch((error) => {
        ;
      })
    })
      .catch((error) => {
        ;
      })
  }


  componentWillReceiveProps({ timesheetLogsAllData, dateSelected, clearChecks, timesheetLogDetail }) {
    this.setState({ ...this.state, timesheetLogsAllData, dateSelected, clearChecks, timesheetLogDetail }, () => {
    })
  }

  handleInputChange = (cellInfo, event) => {
    let data = [...this.props.timesheetLogsAllData];
    data[cellInfo.index][cellInfo.column.id] = event.target.value;

    this.setState({ data });
  };

  renderActiveButtons(row) {

  }

  hhMmToMinutes(x) {
    const hhmm = Math.round(100 * x)  // convert hh.mm -> hhmm
    return 60 * Math.trunc(hhmm / 100) + (hhmm % 100)
  }

  // convert minutes back to hh.mm pseudo-float format
  // use minutesToHhMm(minutes).toFixed(2) if you want trailing zeros
  minutesToHhMm(minutes) {
    return (Math.trunc(minutes / 60) + (minutes % 60) / 100).toFixed(2)
  }

  hoursMinutesFormatted(cellData) {
    var splitData = cellData.original.regularTime.toString()
    var stringToSplit = splitData.split('.')
    return stringToSplit[0] + ' Hrs' + ' ' + stringToSplit[1] + ' Mins'
  }

  filterCaseInsensitive = (filter, row) => {
    const id = filter.pivotId || filter.id;
    const content = row[id];
    if (typeof content !== 'undefined') {
      // filter by text in the table or if it's a object, filter by key
      if (typeof content === 'object' && content !== null && content.key) {
        return String(content.key).toLowerCase().includes(filter.value.toLowerCase());
      } else {
        return String(content).toLowerCase().includes(filter.value.toLowerCase());
      }
    }

    return true;
  };

  onDismiss() {
    if (this.state.isAlerted) {
      this.setState({ isAlerted: false })
    }

  }

  renderEditableHours(cellInfo) {

    let cellValue = this.props.timesheetLogsAllData[cellInfo.index][cellInfo.column.id];
    return (
      <div>

        <Input
          type='number'
          placeholder='0'
          min="0"
          max="24"
          onChange={this.handleInputChange.bind(null, cellInfo)}
          value={cellValue}

        />


      </div>
    );

  }

  renderEditableMinutes(cellInfo) {
    const cellValue = this.props.timesheetLogsAllData[cellInfo.index][cellInfo.column.id];
    return (
      <div>

        <Input
          type='number'
          placeholder='0'
          min="0"
          max="59"
          onChange={this.handleInputChange.bind(null, cellInfo)}
          value={cellValue}
        />


      </div>
    );
  }

  shouldCheckBoxBeTicked(checkboxArray, valueToCheck) {
    // if (checkboxArray.some(e => e.timesheetLogId === valueToCheck)) {
    //   this.setState({
    //     checked: {
    //       valueToCheck: true
    //     }
    //   });
    // }
    // return false
  }

  render() {

    var sumRegularTime = 0
    var regularTimeSummary = ([_.sum((_.map(this.props.timesheetLogsAllData, d => Number(Number(d.regularTime).toFixed(2)))))].map(this.hhMmToMinutes).map(x => sumRegularTime += x).map(this.minutesToHhMm)).toString().split('.')
    var totalRegularTime = regularTimeSummary[0] + ' Hrs' + ' ' + regularTimeSummary[1] + ' Mins'
    var sumOvertime = 0
    var overTimeSummary = _.sum((_.map(this.props.timesheetLogsAllData, d => d.overtime)))
    var extraTimeSummary = _.sum((_.map(this.props.timesheetLogsAllData, d => d.extraTime)))
    var totalOverTime

    //Get table cell content for allocated hours and minutes by combining timesheetlogs with timesheetlogdetail array of objects

    return (
      <>
        <div className="content">
          <div>
            <Alert color="primary" isOpen={this.state.isAlerted} toggle={this.onDismiss} fade={true}>
              {this.props.t("Add hours before selecting employee")}.
            </Alert>
          </div>

          <ReactTable
            NoDataComponent={() => null}
            data={this.props.timesheetLogsAllData}
            columns={[
              {
                Header: this.props.t("TimesheetLogs Id"),
                accessor: "_id",
                show: false
              },
              {
                Header: this.props.t("Name"),
                accessor: "username",
                width: 500
              },
              {
                Header: this.props.t("Allocation Hours"),
                headerStyle: { 'whiteSpace': 'unset' },
                accessor: "allocatedHours",
                Cell: this.renderEditableHours,
                width: 120
                // Footer: <span>{overTimeSummary}</span>
              },
              {
                Header: this.props.t("Allocation Mins"),
                headerStyle: { 'whiteSpace': 'unset' },
                accessor: "allocatedMinutes",
                Cell: this.renderEditableMinutes,
                width: 120
                // Footer: <span>{overTimeSummary}</span>
              },
              {
                Header: this.props.t("Current Allocation"),
                headerStyle: { 'whiteSpace': 'unset' },
                accessor: "allocatedTime",
                className: "frozen",
                Cell: (props) => {

                  return helper.hoursMinutesAllocateTimeFormatted(props.value);

                },
              },
              {
                Header: this.props.t("Hours Worked"),
                accessor: "regularTime",
                Cell: ((value) => helper.hoursMinutesFormatted(value)),
              },




              // {
              //   Header: "Select People",
              //   filterable: false,
              //   accessor: "checked",
              //   Cell: (rowInfo, cellInfo) => {
              //     return (
              //       <InputGroup>
              //         <Input
              //           addon type="checkbox" aria-label="Checkbox for following text input"
              //           onChange={(e) => this.updateStateList(e, rowInfo.original._id, rowInfo.original.hoursWorked, rowInfo)}
              //           checked={this.state.checked[rowInfo.original._id]}
              //           defaultChecked={rowInfo.original.checked}
              //         />

              //       </InputGroup>
              //     );
              //   },
              // },
            ]}
            defaultPageSize={1}
            minRows={0}
            pageSize={this.props.timesheetLogsAllData.length}
            showPaginationTop={false}
            showPaginationBottom={false}
            className="-striped -highlight primary-pagination"
            filterable
            defaultFilterMethod={this.filterCaseInsensitive}
          />
        </div>

      </>
    );
  }
}

const UpdateTimesheetLogTable = withTranslation()(connect(mapStateToProps, null)(ConnectedUpdateTimesheetLogTable));

export default UpdateTimesheetLogTable;
