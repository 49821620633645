import React from "react";
import axios from "axios"
import image_helper from "../../../utils/image_helper.js"
import ImageGalleryUpload from "./ImageGalleryUpload"
import EXIF from 'exif-js'
import moment from 'moment'
import _ from "lodash"




// reactstrap components
import {
    Card,
    CardBody,
    Row,
    Col,
    Alert,
    Form,
    Input,
    Button
} from "reactstrap";

import { connect } from "react-redux";
import { ADD_GENERAL_COMMENT } from "constants/action-types.js";
// import FontProvider from "../../../../pdfmake/src/fontProvider.js";

// import ImageGallery from "views/components/imageDisplay/ImageGallery";

function mapStateToProps(state) {
    return {
        project: state.allOtherReducers.project,
        projectId: state.allOtherReducers.projectId,
        projectData: state.allOtherReducers.projectData,
        reportDate: state.allOtherReducers.reportDate
    };
}

class ConnectedWeatherImage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            file: null,
            gotFile: null,
            retreivedImages: [],
            imageDate: moment().format("DD MMM YYYY"),
            imageReportDate: this.props.reportDate,
            visible: false,
            visibleNoImage: false,
            imageComment: null,
            chooseFileVisible: true,
            uploadAndCancelVisible: false,
            signedImages: [],
            fileName: ''
        };

        this.getImages = this.getImages.bind(this)
        this.removeImageFromPage = this.removeImageFromPage.bind(this)
        this.onDismiss = this.onDismiss.bind(this)
        this.uploadSuccessfulAlert = this.uploadSuccessfulAlert.bind(this)
        this.noImageAlert = this.noImageAlert.bind(this)
        this.noImageAlertDismiss = this.noImageAlertDismiss.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleUploadChange = this.handleUploadChange.bind(this)
        this.getS3SignUrlGetObject = this.getS3SignUrlGetObject.bind(this)
    }
    //Lifecycle
    componentDidUpdate(prevProps) {
        var projectId = this.props.projectId
        var date = this.props.reportDate

        if (this.props.reportDate !== prevProps.reportDate) {

            // this.getImages()
            // this.props.getGeneralCommentsByDateAndByProject({ date, projectId });
        }
    }
    componentDidMount() {
        // this.getImages()
    }

    //Alerts
    onDismiss() {
        this.setState({ visible: !this.state.visible })
    }

    uploadSuccessfulAlert() {
        this.setState({ visible: !this.state.visible })
    }

    noImageAlert() {
        this.setState({ visibleNoImage: !this.state.visibleNoImage })
    }

    noImageAlertDismiss() {
        this.setState({ visibleNoImage: !this.state.visibleNoImage })
    }

    removeImageFromPage() {
        var output = document.getElementById('output');
        output.removeAttribute('src')
        var imageFileUploadElement = document.getElementById('image')
        imageFileUploadElement.value = ""
        document.getElementById("imageUpload").reset();
        this.setState({
            file: null,
            imageDate: null
        })
    }

    handleImageCommentText = (e) => {

        this.setState({ imageComment: e.target.value }, () => this.state.imageComment);
    }
    async handleUploadChange(e) {
       
        let img = new Image()

        if (e.target.files) {

            const file = e.target.files[0];
            const fileLink = URL.createObjectURL(file);
            img.src = window.URL.createObjectURL(file)
            img.onload = () => {
                if (img.width) {
                    try {
                        this.setState({
                            imageHeight: img.naturalHeight,
                            imageWidth: img.naturalWidth
                        }, () => {
                            this.setState({
                                file: file,
                                fileLink: fileLink,
                                fileName: file.name,
                                chooseFileVisible: false,
                                uploadAndCancelVisible: true
                            }, () => { })
                        })
                    }
                    catch (err) {
                        console.log(err)
                    }
                }
            }
            img.onerror = () => {
                document.getElementById('uploadImage').reset();
            }
        }
    }

    async handleSubmit(e) {
        e.preventDefault()
       
        try {
            const data = await this.getS3SignUrl(this.state.file.name,
                this.state.file.type).then(async (x) => {
                    await this.pushPhotoToS3(x, this.state.file).then(() => {

                        this.uploadSuccessfulAlert()
                        // this.removeImageFromPage()

                        this.setState({
                            imageComment: null,
                            chooseFileVisible: true,
                            uploadAndCancelVisible: false,
                            // fileName: '',
                            fileLink: null,
                            imageHeight: null,
                            imageWidth: null,
                            imageList:[],
                            retreivedImages:null
                        }, () => this.getImages())
                    });
                });
        }
        catch (err) {
            console.log(err)
        }
        // if(data.url){
        // // const photoConvertedToBlob = dataURIToBlob(profilePhotoResized);

        // }
    }

    getS3SignUrl(filename, filetype) {
alert(filetype)
        return axios.post(process.env.REACT_APP_API_URL + "/api/imagemanagement/weather/presigned/", {
            filename: filename,
            imageComment: this.state.imageComment,
            projectid: this.props.projectId,
            date: this.props.reportDate,
            imageHeight: this.state.imageHeight,
            imageWidth: this.state.imageWidth,
            tags: ['weatherreport']
        }, {
            headers: {
                'Authorization': localStorage.jwtToken,
            }

        })
            .then(res => {
                console.log(res)
                return res.data
            }).catch((error) => {
                console.log(error)
                if (axios.isCancel(error)) {
                    console.log(error)
                }
                ;
            })



        // const presignedUrl = await response.json();

    }

    async getS3SignUrlGetObject(filename, filetype) {
        // {
        //   filename: filename,
        // },
        return axios.get(process.env.REACT_APP_API_URL + "/api/imagemanagement/presigned/" + filename, {
            headers: {
                'Authorization': localStorage.jwtToken,
            }
        })
            .then(res => {
                console.log(res)
                return res.data
            }).catch((error) => {
                console.log(error)
                if (axios.isCancel(error)) {
                    console.log(error)
                }
                ;
            })

        // const presignedUrl = await response.json();

    }

    async pushPhotoToS3(presignedUploadUrl, uploadPhoto) {
        const myHeaders = new Headers({ 'Content-Type': 'image/*' });
        const response = await fetch(presignedUploadUrl.url, {
            method: 'PUT',
            headers: myHeaders,
            body: uploadPhoto
        });
    }

    async getImages() {
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                'Authorization': localStorage.jwtToken
            },
            params: {
                tags: "weatherreport"
            }

        };

        this.setState({
            retreivedImages: [],
        }, () => { })

        const image = await axios.get(process.env.REACT_APP_API_URL + "/api/images/project/" + this.props.projectId + "/date/" + this.props.reportDate, config).then(
            (response) => {

                console.log(response)
                if (Array.isArray(response.data)) {
                    console.log(response)
                    response.data.map(async (imageItem) => {
                        imageItem.imageLocation = await this.getS3SignUrlGetObject(imageItem.imageKey)
                        this.setState(prevState => ({
                            retreivedImages: [...prevState.retreivedImages, imageItem]
                        }), () => {
                            console.log(this.state.retreivedImages)
                            this.props.addImageToForm(imageItem._id)
                        })

                    })

                }

            }
        )
        console.log(this.state.retreivedImages)
    }

    render() {
        var images = []

        images = this.state.retreivedImages

        console.log(images)
        const { chooseFileVisible, uploadAndCancelVisible } = this.state
        return (
            <div className="content">
                <Alert
                    color="success"

                    isOpen={this.state.visible}
                    toggle={this.onDismiss}
                >
                    <span>
                        <b> Info - </b> Image uploaded successfully.
                    </span>
                </Alert>
                <Alert
                    color="warning"
                    isOpen={this.state.visibleNoImage}
                    toggle={this.noImageAlertDismiss}
                >
                    <span>
                        <b> Warning - </b> No image selected.
                    </span>
                </Alert>
                <Row>

                    <Col>
                        {/* <Form id='uploadImage' onSubmit={(e) => this.handleSubmit(e)}> */}

                        {chooseFileVisible ?
                            <Input type="file" onChange={(e) => this.handleUploadChange(e)} accept="image/*" />
                            : null

                        }
                        <img src={this.state.fileLink} alt="" />
                        <div>Image: {this.state.fileName}</div>
                        {uploadAndCancelVisible ?
                            <div>
                                <Row>
                                    <Col>
                                        <br />
                                        <label>File name:</label>
                                        <br />
                                        <label>{this.state.fileName}</label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <label>Image taken on:</label>
                                        <br />
                                        <label>{moment(this.props.reportDate).format("DD MMM YYYY")}</label>
                                        <br />
                                    </Col>
                                </Row>
                                {/* <Row>
                                    <Col>
                                        <label>Description</label>
                                        <Input placeholder='Write a description of the image to upload' type="text" id="imageComment" name='imageComment' onChange={this.handleImageCommentText} />
                                    </Col>
                                </Row> */}
                                <Row>
                                    <Col>
                                        <Button onClick={(e) => this.handleSubmit(e)}>Upload</Button>

                                        <Button onClick={() => {
                                            this.setState({
                                                chooseFileVisible: true,
                                                fileLink: null,
                                                uploadAndCancelVisible: false,
                                                fileName: null,
                                                file: null
                                            })
                                        }
                                        }>Remove Image</Button>
                                    </Col>
                                </Row>

                            </div>
                            : null
                        }
                        {/* </Form> */}
                    </Col>
                    <Col></Col>
                </Row>

                <Row>
                    <Col md={9}>
                        <img id="output" />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {/* <ImageGalleryUpload images={images} limit={12} getImages={this.getImages} type="image" /> */}
                    </Col>
                </Row>
            </div>

        )
    }
}


const WeatherImage = connect(
    mapStateToProps,
    null
)(ConnectedWeatherImage);
export default WeatherImage;
