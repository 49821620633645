import isEmpty from '../validation/is-empty';

import { SET_CURRENT_USER,SET_CLIENT_OBJECT,UPDATE_CURRENT_USER } from '../actions/types';

const initialState = {
  isAuthenticated: false,
  user: {},
  client:{}
};

export default  function(state = initialState, action) {
  let client = {};
  switch (action.type) {
    case SET_CURRENT_USER:
         client = action.payload?.client || {};
        delete action.payload['client'];
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        user: action.payload,
          client: client
      };
    case UPDATE_CURRENT_USER:
      return {
        ...state,
        user: action.payload
      };
    case SET_CLIENT_OBJECT:
      client = action.payload || {};
      return {
        ...state,
        client: client
      }
    default:
      return state;
  }
}
