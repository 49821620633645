import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";

// reactstrap components

import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';



function mapStateToProps(state) {
  return {
    project: state.allOtherReducers.project,
    projectId: state.allOtherReducers.projectId,
    projectData: state.allOtherReducers.projectData,
    reportDate: state.allOtherReducers.reportDate
    };
}


class ConnectedPeopleList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      selectedRowList: [],
      selected: -1,
    }
    this.onSelectRowWithClick=this.onSelectRowWithClick.bind(this)
  }
  filterWorkerData(timesheetUsers, dataWorker) {

  }

  componentDidMount(props) {
//     this.onSelectRowWithClick = (state, rowInfo, column, instance) => {
//         return {
//           onClick: e => {
//             var selectedRowListTemp = []
//             if (this.props.resetState === true) {
//               selectedRowListTemp = []
//             } else {
//               selectedRowListTemp = this.state.selectedRowList
//             }
//             if (!selectedRowListTemp.includes(rowInfo.row._id)) {
// //LETS EXPERIMENT WITH FILTER TO GET
//               selectedRowListTemp.push(rowInfo.row._id)
//               this.setState({
//                 selectedRowList: selectedRowListTemp
//               }, () => this.props.rowsToCopyFromPeopleToTimesheet(this.state.selectedRowList), this.props.selectedRowState(false))
//             } else {
//               selectedRowListTemp.splice(selectedRowListTemp.indexOf(rowInfo.row._id), 1)
//               this.setState({
//                 selectedRowList: selectedRowListTemp
//               })
//             }
//           },
//           style: {

//             background: this.state.selectedRowList.includes(rowInfo.row._id) ? '#00afec' : 'white',
//             color: this.state.selectedRowList.includes(rowInfo.row._id) ? 'white' : 'black'
//           }
//         }
//     }



  }

  componentWillUnmount() {

  }


  componentDidUpdate(prevProps) {
   if (this.props.projectId !== prevProps.projectId) {
      this.props.getEmployeesCompressedListByDate(this.props.reportDate)
    }

    if (this.props.reportDate !== prevProps.reportDate) {
      this.props.getEmployeesCompressedListByDate(this.props.reportDate)
    }


  }

  componentWillReceiveProps(prevProps, { employees, resetState }) {
    this.setState({ ...this.state, employees, resetState }, () => console.log(this.state))
    if(prevProps.resetState!==this.props.resetState){
      this.setState({selectedRowList:[]}, ()=>this.state.selectedRowList)
    }

  }

  onSelectRowWithClick = (state, rowInfo, column, instance) => {
    return {
      onClick: e => {
        var selectedRowListTemp = []
        if (this.props.resetState === true) {
          selectedRowListTemp = []
        } else {
          selectedRowListTemp = this.state.selectedRowList
        }
        if (!selectedRowListTemp.includes(rowInfo.row._id)) {
//LETS EXPERIMENT WITH FILTER TO GET
          selectedRowListTemp.push(rowInfo.row._id)
          this.setState({
            selectedRowList: selectedRowListTemp
          }, () => this.props.rowsToCopyFromPeopleToTimesheet(this.state.selectedRowList), this.props.selectedRowState(false))
        } else {
          selectedRowListTemp.splice(selectedRowListTemp.indexOf(rowInfo.row._id), 1)
          this.setState({
            selectedRowList: selectedRowListTemp
          })
        }
      },
      style: {

        background: this.state.selectedRowList.includes(rowInfo.row._id) ? '#00afec' : 'white',
        color: this.state.selectedRowList.includes(rowInfo.row._id) ? 'white' : 'black'
      }
    }
}


filterCaseInsensitive = (filter, row) => {
  const id = filter.pivotId || filter.id;
  const content = row[id];
  if (typeof content !== 'undefined') {
      // filter by text in the table or if it's a object, filter by key
      if (typeof content === 'object' && content !== null && content.key) {
          return String(content.key).toLowerCase().includes(filter.value.toLowerCase());
      } else {
          return String(content).toLowerCase().includes(filter.value.toLowerCase());
      }
  }

  return true;
};


  render() {

    console.log("Render People List - the right one")
    console.log(this.props.employees)

    return (
      <>
        <div className="content">
          <ReactTable
            data={this.props.employees}
            columns={[
              {
                Header: this.props.t("userInSystemId"),
                accessor: "_id",
                show: false,
              },
              {
                Header: this.props.t("User ID"),
                accessor: "user_id",
                show: false,
              },
              {
                Header: this.props.t("Name"),
                id: 'name',
                accessor: d => `${d.name}`,
                style: {
                  fontSize: 10,
                },

              },
              {
                Header: this.props.t("Surname"),
                id: 'lastName',
                accessor: d => `${d.last_name || ''}`,
                style: {
                  fontSize: 10,
                },

              },
              {
                Header: this.props.t("Company"),
                accessor: "companies[0].name",
                style: {
                  fontSize: 10,
                },
              },
              // {
              //   Header: "Skill",
              //   accessor: "positions[0].name",
              //   style: {
              //     fontSize: 12,
              //   },
              // }
            ]}

            defaultPageSize={1}
          pageSize={this.props.employees.length}
            minRows={0}
            showPaginationTop={false}
            showPaginationBottom={false}
            getTrProps={this.onSelectRowWithClick}
            filterable
            defaultFilterMethod={this.filterCaseInsensitive}
            style={{
              // height: "400px" // This will force the table body to overflow and scroll, since there is not enough room
            }}

          />


        </div>
      </>
    );
  }
}

const PeopleList = withTranslation()(connect(mapStateToProps, null)(ConnectedPeopleList));

export default PeopleList;
