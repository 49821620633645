import React from "react";
// react component for creating dynamic tables
import ReactTable, {ReactTableDefaults}from "react-table";
import axios from "axios";
import store from '../../../store/index'
import { connect } from "react-redux";
import InputMask from 'react-input-mask';
import _, { isNull } from "lodash";
import helper from '../../../utils/general_helper.js'


// reactstrap components
import {
  Button,
  Input,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem
} from "reactstrap";

function mapStateToProps(state) {
  return {
    project: state.allOtherReducers.project,
    projectId: state.allOtherReducers.projectId,
    projectData: state.allOtherReducers.projectData,
    reportDate: state.allOtherReducers.reportDate
  };
}

class ConnectedTimesheetLogTable extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      editContent: false,
      editingRow: -1,
      isDirty: false

    }

    this.renderEditable = this.renderEditable.bind(this);
    this.renderEditableTime = this.renderEditableTime.bind(this);
    this.saveRowData = this.saveRowData.bind(this);
    this.toggleEditable = this.toggleEditable.bind(this);
    this.renderActiveButtons = this.renderActiveButtons.bind(this)
    this.hhMmToMinutes = this.hhMmToMinutes.bind(this)
    this.minutesToHhMm = this.minutesToHhMm.bind(this)
    // this.toMinutes=this.toMinutes.bind(this)
    // this.sum=this.sum.bind(this)
  }



  componentDidMount(props) {

  }

  deleteTimesheetLogByTimesheetLogId(timesheetLogId) {
    axios.delete(process.env.REACT_APP_API_URL + '/api/timesheetlog/' + timesheetLogId, {
      headers: {
        'Authorization': localStorage.jwtToken
      }
    }).then(res => {
      this.props.getTimesheetLogByDate(this.props.reportDate)
      return res
    }).then(res => {
      this.props.getEmployeesCompressedListByDate(this.props.reportDate).catch((error) => {
        ;
      })
    })
      .catch((error) => {
        ;
      })
  }


  componentWillReceiveProps({ timesheetLogs, dateSelected }) {
    this.setState({ ...this.state, timesheetLogs, dateSelected }, () => this.state)
    console.log("this.props.timesheetLogs")
    console.log(this.props.timesheetLogs)
    console.log(this.props.dateSelected)
  }

  handleInputChange = (cellInfo, event) => {
    let data = [...this.props.timesheetLogs];
    data[cellInfo.index][cellInfo.column.id] = event.target.value;

    this.setState({ data });
  };
  renderEditable(cellInfo) {

    const cellValue = this.props.timesheetLogs[cellInfo.index][cellInfo.column.id];



    const { editingRow } = this.state;
    return (
      <div>
        {editingRow === cellInfo.index ? (
          <Input
            maskChar=""
            mask="999"
            placeholder=''
            onChange={this.handleInputChange.bind(null, cellInfo)}
            value={cellValue}
            tag={InputMask}
          />
        ) : (<div >
          {(cellInfo.value == undefined) ? 0 : cellInfo.value}
        </div>)}
      </div>
    );
  }
  renderEditableTime(cellInfo) {

    const cellValue = this.props.timesheetLogs[cellInfo.index][cellInfo.column.id];
    const { editingRow } = this.state;
    return (
      <div>
        {editingRow === cellInfo.index ? (
          <Input
            placeholder="type here"
            name="time"
            type="time"
            onChange={this.handleInputChange.bind(null, cellInfo)}
            value={cellValue}
          />) : (
          (<div >
            {cellInfo.value}
          </div>)
        )}
      </div>

    );
  }

  saveRowData(cellInfo) {
    console.log("cellInfo")
    console.log(cellInfo)
    this.setState({ editContent: false }, () => console.log(cellInfo.original.timeIn))

    axios.put(process.env.REACT_APP_API_URL + '/api/timesheetlog/', {
      _id: cellInfo.original._id,
      timeIn: cellInfo.original.timeIn,
      timeOut: cellInfo.original.timeOut,
      breaks: cellInfo.original.breaks,
      regularTime: cellInfo.original.regularTime,
      overtime: cellInfo.original.overtime,
      extraTime: cellInfo.original.extraTime


    }, {
      headers: {
        'Authorization': localStorage.jwtToken
      }
    }).then(res => {
      this.props.getTimesheetLogByDate(this.props.reportDate)
      console.log(res)
      this.setState({
        editingRow: -1,
        isDirty: false
      })
      return res.data
    })
      .catch((error) => {
        ;
      })
  }


  toggleEditable(index) {
    console.log(index);
    const { isDirty } = this.state;
    if (!isDirty) {
      this.setState({
        editingRow: index,
        isDirty: true
      });
    }
    else {
      this.setState({
        editingRow: -1,
        isDirty: false
      })
    }
  }

  renderActiveButtons(row) {

    return (<div>
      <Button
        onClick={() => this.toggleEditable(row.index)}
        size="xs"
        className="btn-icon btn-neutral btn btn-info btn-sm"
      ><i className="fa fa-edit"
        /></Button>
      <Button
        onClick={() => this.saveRowData(row)}
        size="xs"
        className="btn-icon btn-neutral btn btn-info btn-sm"
      ><i className="fa fa-save"
        /></Button><br />
      <Button
        color="danger"
        size="sm"
        className="btn-icon btn-neutral btn btn-info btn-sm"
        onClick={() => {
          this.deleteTimesheetLogByTimesheetLogId(row.original._id)
        }
        }
      ><i
          className="fa fa-remove"
        />
      </Button>

    </div>)


  }

  hhMmToMinutes(x) {
    console.log(x)
    const hhmm = Math.round(100 * x)  // convert hh.mm -> hhmm
    return 60 * Math.trunc(hhmm / 100) + (hhmm % 100)
  }


  convertMinutesToHoursMinutes(n) {

    var num = n;
    var hours = (num / 60);
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);
    return rhours + ' Hrs ' + rminutes + ' Mins';
  }

  // convert minutes back to hh.mm pseudo-float format
  // use minutesToHhMm(minutes).toFixed(2) if you want trailing zeros
  minutesToHhMm(minutes) {
    return (Math.trunc(minutes / 60) + (minutes % 60) / 100).toFixed(2)
  }



  toMinutes(duration) {


    var ary = duration.split(':');
    return +ary[0] * 60 + ary[1];

  }

  sum(a, b) {
    return a + b;
  }
  render() {

    var sumRegularTime = 0

    function toMinutes(duration) {
      console.log(duration)

      var ary = duration.split('.');
      console.log(ary)
      return +Number(ary[0]) * 60 + Number(ary[1]);

    }

    function sum(a, b) {
      return a + b;
    }

    function toMinutesAndHours() {

    }

    var regularTimeSummary = this.convertMinutesToHoursMinutes(this.props.timesheetLogs.map((d) => toMinutes(d.regularTime)).reduce(sum, 0))

    console.log("regularTimeSummary")
    console.log(regularTimeSummary)

    // var regularTimeSummary = ([_.sum((_.map(this.props.timesheetLogs, d => Number(Number(d.regularTime).toFixed(2)))))].map(this.hhMmToMinutes).map(x => sumRegularTime += x).map(this.minutesToHhMm)).toString().split('.')


    var totalRegularTime = regularTimeSummary

    // var totalRegularTime = regularTimeSummary[0] + ' Hrs' + ' ' + regularTimeSummary[1] + ' Mins'

    var sumOvertime = 0
    var overTimeSummary = _.sum((_.map(this.props.timesheetLogs, d => d.overtime)))
    var extraTimeSummary = _.sum((_.map(this.props.timesheetLogs, d => d.extraTime)))
    // .map(this.hhMmToMinutes).map(x => sumOvertime += x).map(this.minutesToHhMm)).toString().split('.')
    // var totalOverTime = overTimeSummary[0]+ ' Hrs' + ' ' + overTimeSummary[1]+' Mins'
    var totalOverTime

    const columnDefaults = {
      ...ReactTableDefaults.column,

    }


    return (
      <>
        <div className="content">


          <ReactTable
            data={this.props.timesheetLogs}
            columns={[
              {
                Header: "TimesheetLogs Id",
                accessor: "_id",
                show: false
              },
              {
                Header: "Name",
                accessor: "username",
                style: {
                  fontSize: 10,
                },
                Footer: <span>{this.props.timesheetLogs.length} Selected</span>,
              },
              {
                Header: "Time In",
                accessor: "timeIn",
                Cell: this.renderEditableTime,
                style: {
                  fontSize: 10,
                },
              },
              {
                Header: "Time out",
                accessor: "timeOut",
                Cell: this.renderEditableTime,
                style: {
                  fontSize: 10,
                },
              },
              {
                Header:"Breaks (Mins)",
                accessor: "breaks",
                Cell: this.renderEditable,
                style: {
                  fontSize: 10,
                },
              },
              {
                Header: "Regular Time",
                accessor: "regularTime",
                Cell: ((value) => helper.hoursMinutesFormatted(value)),
                style: {
                  fontSize: 10,
                },
                Footer: <span>{totalRegularTime}</span>,
              },
              {
                Header: "Overtime (Mins)",
                accessor: "overtime",
                Cell: this.renderEditable,
                style: {
                  fontSize: 10,
                },
                Footer: <span>{overTimeSummary}</span>
              },
              {
                Header: "Extra Time (Mins)",
                accessor: "extraTime",
                Cell: this.renderEditable,
                style: {
                  fontSize: 10,
                },
                Footer: <span>{extraTimeSummary}</span>
              },
              {
                Header: "Actions",
                id: "activateToggle",
                width: 150,
                style: {
                  fontSize: 10,
                },
                Cell: ((value) => (this.renderActiveButtons(value)))
              }
            ]
            }

            columnDefaults
            defaultPageSize={1}
            minRows={1}
            pageSize={this.props.timesheetLogs.length}
            showPaginationTop={false}
            showPaginationBottom={false}
            // getTrProps={this.onRowClick}
            // getTrProps={onRowClick}
            /*
              You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
            */
            className="-striped -highlight primary-pagination"
          />
        </div>
      </>
    );
  }
}

const TimesheetLogTable = connect(
  mapStateToProps,
  null
)(ConnectedTimesheetLogTable);

export default TimesheetLogTable;
