var helper = {
  convertMinutesToHoursMinutes(n) {

    var num = n;
    var hours = (num / 60);
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);
    return rhours + 'h ' + rminutes + 'm';
  },
  hoursMinutesAllocateTimeFormatted(value) {
    if (value != undefined) {
      var num = parseInt(value, 10);

      var hours = (num / 60);
      var rhours = Math.floor(hours);
      var minutes = (hours - rhours) * 60;
      var rminutes = Math.round(minutes);
      return rhours + "h " + rminutes + "m";
    }
    else {
      return 0 + "h " + 0 + "m";
    }

  },
  minutesToHhMm(minutes) {
    return (Math.trunc(minutes / 60) + (minutes % 60) / 100).toFixed(2)
  },
  hhMmToMinutes(x) {
    const hhmm = Math.round(100 * x)  // convert hh.mm -> hhmm
    return 60 * Math.trunc(hhmm / 100) + (hhmm % 100)
  },

  hoursMinutesFormatted(cellData) {
    if (cellData.original.regularTime!=null){
    var splitData = cellData.original.regularTime.toString()
    var stringToSplit = splitData.split('.')
    return stringToSplit[0] + 'h' + ' ' + stringToSplit[1] + 'm'}
    else{
      return ''
    }
  }

}

module.exports = helper;