import {
  CURRENT_SCHEDULE_ITEM
} from '../actions/types';

const initialState = {
  scheduleItem: null,
  scheduleItemId: null
};

function scheduleReducer(state = initialState, action) {
  if (action.payload === null) {
    return Object.assign({}, state, {
      scheduleItem: '',
      scheduleItemId: ''
    })
  }
  else {
    switch (action.type) {

      case CURRENT_SCHEDULE_ITEM:
        return Object.assign({}, state, {
          scheduleItem: [{ ...action.payload }][0].full_path,
          scheduleItemId: [{ ...action.payload }][0]._id
        })
      default:
        return state;
    }
  }

}
export default scheduleReducer
