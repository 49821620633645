import React from "react";
import axios from "axios"
import moment from "moment"
import AddDelayTypeTable from "./AddDelayTypeTable"
import AddDelayForm from "./AddDelayForm"
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';


// reactstrap components
import {
  Card,
  CardBody,
  Row,
  Col
} from "reactstrap";
import {userCant} from "../../../services/Authorization/authService";



function mapStateToProps(state) {
  return {
    project: state.allOtherReducers.project,
    projectId: state.allOtherReducers.projectId,
    projectData: state.allOtherReducers.projectData,
    reportDate: state.allOtherReducers.reportDate,
    user: state.auth.user
  };
}

class ConnectedDelays extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dynamicTreeData: [],
      datafetch: [],
      rowsToCopy: [],
      selected: -1,
      employees: [],
      dateList: [],
      today: moment(new Date()),
      dateSelected: '',
      selectedRowList: [],
      resetState: false,
      scheduleClickedItem: '',
      contractorSummaryTableData: [],
      materialData: [],
      taskData: [],
      siteLocationData: [],
      hoursWorked: [],
      contractorWorkTableData: [],
      delayTypeData: [],
      delayData: []

    };
    this.updateDelayTypeTableData = this.updateDelayTypeTableData.bind(this)
    this.getSomeDelayTypes = this.getSomeDelayTypes.bind(this)
    this.getSomeDelays = this.getSomeDelays.bind(this)
    this.getSomeDelayTypes();
    this.getSomeDelays();
    this.refreshDelay = this.refreshDelay.bind(this)
    this.refreshDelayType = this.refreshDelayType.bind(this)
  }

  componentWillMount() {
    if (userCant(this.props.user, 'delaysandevents.index') &&
        userCant(this.props.user, 'delaysandevents.manage')) {
      return this.props.history.push('/admin/instructions')
    }
  }

  componentDidMount() {
    if (userCant(this.props.user, 'delaysandevents.index') &&
        userCant(this.props.user, 'delaysandevents.manage')) {
      return this.props.history.push('/admin/instructions')
    }
    var startDate = new Date();
    startDate.setDate(startDate.getDate() - 7);

    var endDate = new Date();
    endDate.setDate(endDate.getDate());
    var getDateArray = function (start, end) {
      var arr = new Array();
      var dt = new Date(start);
      while (dt <= end) {
        arr.push(new Date(dt));
        dt.setDate(dt.getDate() + 1);
      }
      return arr;
    }


    axios.get(process.env.REACT_APP_API_URL + '/api/projectschedule/project/' + this.props.projectId, {
      headers: {
        'Authorization': localStorage.jwtToken
      }
    })
      .then(res => {
        this.setState({ dynamicTreeData: res.data }, () => this.state.dynamicTreeData)
        // window.location.reload(true)
      })
      .catch((error) => {
        console.log(error);
      })


    var dateArr = getDateArray(startDate, endDate);
    this.setState({ dateList: dateArr }, () => this.state.dateList)

  }

  componentDidUpdate(prevProps) {
    if (this.props.projectId !== prevProps.projectId) {
      this.getSomeDelayTypes();
      this.getSomeDelays();
      this.getProjectSchedule();
    }
    if (this.props.reportDate !== prevProps.reportDate) {
      this.getSomeDelayTypes();
      this.getSomeDelays();
    }

  }

  getSomeDelayTypes() {
    if (userCant(this.props.user, 'delaysandevents.manage')) {
      return
    }

    axios.get(process.env.REACT_APP_API_URL + "/api/delaytype/project/" + this.props.projectId, {
      headers: {
        'Authorization': localStorage.jwtToken
      }
    })
      .then(res => {
        this.setState({
          delayTypeData: res.data
        }, () => { });

        return res.data
      }).catch((error) => {
        console.log(error)
          ;
      })
  }

  getProjectSchedule() {
    if (userCant(this.props.user, 'delaysandevents.manage')) {
      return
    }

    axios.get(process.env.REACT_APP_API_URL + '/api/projectschedule/project/' + this.props.projectId, {
      headers: {
        'Authorization': localStorage.jwtToken
      }
    })
      .then(res => {
        this.setState({ dynamicTreeData: res.data }, () => this.state.dynamicTreeData)
        // window.location.reload(true)
      })
      .catch((error) => {
        console.log(error);
      })
  }
  getSomeDelays() {
    if (userCant(this.props.user, 'delaysandevents.index') &&
        userCant(this.props.user, 'delaysandevents.manage')) {
      return this.props.history.push('/admin/instructions')
    }

    axios.get(process.env.REACT_APP_API_URL + "/api/delay/project/" + this.props.projectId, {
      headers: {
        'Authorization': localStorage.jwtToken
      }
    })
      .then(res => {
        this.setState({
          delayData: res.data
        }, () => console.log(this.state.delayData));

        return res.data
      }).catch((error) => {
        ;
      })
  }




  dateSelectedFromDropdownIntervals(dateIn) {
    this.setState({ dateSelected: dateIn }, () => this.state.dateSelected)
  }

  getScheduleClickedOnText = (gotText) => {
    this.setState({ scheduleClickedItem: gotText }, () => this.state.scheduleClickedItem)
  }

  updateDelayTypeTableData(data) {
    this.setState({ delayTypeData: data }, () => this.state.delayTypeData)

  }

  updateDelayData = (data) => {
    this.setState({ delayData: data }, () => this.state.delayData)

  }

  updateSiteLocationTableData = (data) => {
    this.setState({ siteLocationData: data }, () => this.state.siteLocationData)

  }


  thisTestFunction = () => {
    alert("im in the parent")
  }

  refreshDelay(delay){
    let delayData = this.state.delayData.map(row => {
      return row._id === delay._id ? delay : row
    })

    this.setState({
      delayData:delayData
    })
  }

  refreshDelayType(delayType){
    let delayTypeData = this.state.delayTypeData.map(row => {
      return row._id === delayType._id ? delayType : row
    })

    this.setState({
      delayTypeData:delayTypeData
    })
  }

  render() {
    if (userCant(this.props.user, 'delaysandevents.index') &&
        userCant(this.props.user, 'delaysandevents.manage')) {
      return this.props.history.push('/admin/instructions')
    }
    return (
      <>
        <div className="content">
          <Row>
            <Col tag="h3">
              {this.props.t("Delays and Events")}
            </Col>
          </Row>
          <Row>
            <Col>
              <Card>
                <CardBody>
                   <AddDelayForm
                    delayTypeData={this.state.delayTypeData}
                    delayData={this.state.delayData}
                    getSomeDelays={this.getSomeDelays}
                    refreshDelay={this.refreshDelay}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>

              <AddDelayTypeTable
                delayTypeData={this.state.delayTypeData}
                getSomeDelayTypes={this.getSomeDelayTypes}
                scheduleClickedItem={this.state.scheduleClickedItem}
                delayTableData={this.state.delayTableData}
                refreshDelayType={this.refreshDelayType}
              />

            </Col>

          </Row>
        </div>
      </>
    );
  }
}

const Delays = withTranslation()(connect(mapStateToProps, null)(ConnectedDelays));

export default Delays;
