import React from "react";
import ReactTable from "react-table";
import axios from "axios";
import Datetime from 'react-datetime';
import moment from "moment"
import InputMask from 'react-input-mask';
import ReactBSAlert from "react-bootstrap-sweetalert";
import { StyleSheet, css } from 'aphrodite';

// reactstrap components
import {
  Button,
  Input,
  Row,
  Col,
  Form,
  FormGroup,
  Alert
} from "reactstrap";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import {userCant} from "../../../services/Authorization/authService";




class ConnectedRentalTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      company: '',
      companyFromAPI: [],
      rentalCompanyFromAPI: [],
      rentalEquipmentFromAPI: [],
      rentalEquipment: '',
      rentalDate: '',
      comment: 'test',
      editContent: false,
      editingRow: -1,
      isDirty: false,
      alert: null,
      rentalFinishedDate: null,
      saveDateErrorVisible: false,
      deleteRentalError: false,
      errors: ''
    }

    this.renderEditableText = this.renderEditableText.bind(this);
    this.renderEditableNumber = this.renderEditableNumber.bind(this);
    this.renderEditableDate = this.renderEditableDate.bind(this);
    this.saveRowData = this.saveRowData.bind(this);
    this.toggleEditable = this.toggleEditable.bind(this);
    this.renderActiveButtons = this.renderActiveButtons.bind(this);
    this.deleteRentalById = this.deleteRentalById.bind(this)
    this.populateDropDownRentalCompany = this.populateDropDownRentalCompany.bind(this)
    this.stopRental = this.stopRental.bind(this)
    this.warningWithConfirmAndCancelMessage = this.warningWithConfirmAndCancelMessage.bind(this)
    this.renderEditableFinishDate = this.renderEditableFinishDate.bind(this)
    this.handleInputChangeFinishDate = this.handleInputChangeFinishDate.bind(this)
    this.saveDateErrorOnDismiss = this.saveDateErrorOnDismiss.bind(this)
    this.deleteRentalErrorOnDismiss = this.deleteRentalErrorOnDismiss.bind(this)

  }


  populateDropDownCompany(data) {


    var testdata = data
    const options = testdata.map(company => {
      return { value: company.name, label: company.name }
    })
    this.setState({ companyFromAPI: options }, () => console.log(this.state.companyFromAPI))
  }

  populateDropDownRentalCompany(data) {


    var testdata = data
    const options = testdata.map(company => {
      return { value: company.name, label: company.name }
    })
    this.setState({ rentalCompanyFromAPI: options }, () => console.log(this.state.rentalCompanyFromAPI))
  }

  populateDropDownRentalEquipment(data) {

    var testdata = data
    console.log("testdata")
    console.log(testdata)
    const options = testdata.map(rental => {
      var htmlInsert = <Row><Col>{rental.rentalCompany?.name}</Col><Col><div>{rental.description}</div></Col><Col><div>{rental.typeModel}</div></Col></Row>
      var rentalDescriptionValue = String(rental.description)
      return { value: rental._id, label: htmlInsert }
    })

    this.setState({ rentalEquipmentFromAPI: options }, () => console.log(this.state.rentalEquipmentFromAPI))
  }




  getRentalEquipmentById(rentalEquipmentId) {

    return axios.get(process.env.REACT_APP_API_URL + "/api/rentalequipment/" + rentalEquipmentId, {
      headers: {
        'Authorization': localStorage.jwtToken
      }
    })
      .then(res => (res.data[0]))
      .catch((error) => {
        ;
      })
  }

  stopRental(rentalId) {


  }

  warningWithConfirmAndCancelMessage = (rentalId) => {
    this.setState({
      alert: (
        <ReactBSAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title={this.props.t("Are you sure?")}
          // onConfirm={() => this.stopRental(rentalId)}
          onConfirm={() => this.deleteRentalById(rentalId)}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          confirmBtnText={this.props.t("OK")}
          cancelBtnText={this.props.t("Cancel")}
          showCancel
        >
          {this.props.t("To record an increase of hire length you can choose to edit the end date in the rentals table or add new rental.")}
        </ReactBSAlert>
      )
    });
  }

  hideAlert = () => {
    this.setState({
      alert: null
    }, () => { });
  };
  formSubmitSuccess(e) {
    e.preventDefault();

    const formData = new FormData(e.target);
    var gotRentalEquipmentId = formData.get('equipment')
    var test = formData.get("comment")

    this.getRentalEquipmentById(gotRentalEquipmentId).then(


      (response) => {
        var data =
        {
          companyRenting: formData.get('company'),
          rentalEquipmentId: gotRentalEquipmentId,
          description: response.description,
          typeModel: response.typeModel,
          rentalCompany: response.rentalCompany,
          rentalDate: this.props.reportDate,
          rentalFinishedDate: this.state.rentalFinishedDate,
          numberOfDays: formData.get("numberOfDays"),
          comment: formData.get("comment"),
          projectId: this.props.projectId
        }

        this.props.addRental(data)

      }).finally(()=>{
        if(!this.state.errors){
          axios.get(process.env.REACT_APP_API_URL + "/api/rental/", {
            headers: {
              'Authorization': localStorage.jwtToken
            }
          })
            .then(res => {
              this.setState({
                data: res.data,
                company: '',
                description: '',
                typeModel: '',
                rentalEquipment: '',
                rentalDate: '',
                rentalFinishedDate: ''
              }, () => this.state.data);

              this.props.getRentalsByProjectAndReportDate()
              this.clearForm();

              return res.data
            })

        .catch((error) => {
        })
      }
      else{

      }

      })
      .catch((error) => {
      })

  }

  componentWillReceiveProps({ companyTableData, rentalEquipmentTableData, rentalsTableData, errors }) {
    this.setState({ ...this.state, companyTableData, rentalEquipmentTableData, rentalsTableData, errors }, () => this.state)
    if (errors) {
      this.setState({ errors: errors }, () => {console.log(this.state.errors) });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.companyTableData !== prevProps.companyTableData) {
      this.populateDropDownCompany(this.props.companyTableData);
    }

    if (this.props.rentalCompanyTableData !== prevProps.rentalCompanyTableData) {
      this.populateDropDownRentalCompany(this.props.rentalCompanyTableData);
    }

    if (this.props.rentalEquipmentTableData !== prevProps.rentalEquipmentTableData) {
      this.populateDropDownRentalEquipment(this.props.rentalEquipmentTableData);
    }

  }

  clearForm() {
    this.setState({
      company: "",
      description: "",
      typeModel: "",
      rentalEquipment: "",
      rentalDate: "",
      rentalFinishedDate: ''
    }, () => this.state.description)
  }

  handleInputChange = (cellInfo, event) => {
    let data = [...this.props.rentalsTableData];
    data[cellInfo.index][cellInfo.column.id] = event.target.value;

    this.setState({ data });
  };



  renderEditableText(cellInfo) {



    const cellValue = this.props.rentalsTableData[cellInfo.index][cellInfo.column.id];
    const { editingRow } = this.state;
    return (
      <div>
        {editingRow === cellInfo.index ? (
          <Input
            maskChar=""
            mask=""
            placeholder=""
            onChange={this.handleInputChange.bind(null, cellInfo)}
            value={cellValue}
            tag={InputMask}
          />
        ) : (<div >
          {cellInfo.value}
        </div>)}
      </div>
    );
  }

  renderEditableNumber(cellInfo) {
    const { editingRow } = this.state;

    const cellValue = this.props.rentalsTableData[cellInfo.index][cellInfo.column.id];

    return (
      <div>
        {editingRow === cellInfo.index ? (
          <Input
            maskChar=""
            mask="999"
            placeholder=""
            onChange={this.handleInputChange.bind(null, cellInfo)}
            value={cellValue}
            tag={InputMask}
          />
        ) : ((<div >
          {cellInfo.value}
        </div>))
        }
      </div>

    );
  }

  handleInputChangeFinishDate = (e, cellInfo) => {

    let data = [...this.props.rentalsTableData];
    data[cellInfo.index][cellInfo.column.id] = e.target.value;
    // this.setState({ data });
  }
  renderEditableDate(cellInfo) {

    const { editingRow } = this.state;
    const cellValue = this.props.rentalsTableData[cellInfo.index][cellInfo.column.id];
    return (
      <div>
        {editingRow === cellInfo.index ? (
          <Input
            maskChar=""
            mask="99/99/9999"
            placeholder="dd/mm/yyyy"
            onChange={(e) => this.handleInputChangeFinishDate(e, cellInfo)}
            tag={InputMask}
            permanents={[2, 5]}
          />

        )
          : (
            (<div >
              {moment.utc(cellInfo.value).format('DD MMM YYYY')}
            </div>
            )
          )}
      </div>

    );
  }






  renderEditableFinishDate(cellInfo) {
    const { editingRow } = this.state;
    const cellValue = this.props.rentalsTableData[cellInfo.index][cellInfo.column.id];

    return (



      <Datetime
        onChange={value =>
          this.setState({ rentalFinishDate: value })
        }
        closeOnSelect={true}
        timeFormat={false}
        utc={true}
        dateFormat={'DD MMM YYYY'}
        inputProps={{ placeholder: this.props.t("Datetime Picker Here") }}
      />

    )
  }

  validateEndDate(startDate, finishDate) {

    //Check is a date
    if (!moment(finishDate, "DD/MM/YYYY", true).isValid()) {
      return false
    }

    //format for compare
    var rentalFinishDate = moment(finishDate, "DD/MM/YYYY").format("DD/MM/YYYY")
    if (!moment(rentalFinishDate, "DD/MM/YYYY", true).isValid()) {
      return false
    }



    //Format rental Start Date for compare
    var rentalStart = moment(startDate).format("DD/MM/YYYY")
    //Check its still a valid date
    if (!moment(rentalStart, "DD/MM/YYYY", true).isValid()) {
      return false
    }

    //Compare
    if (moment(finishDate, "DD/MM/YYYY", true) >= moment(rentalStart, "DD/MM/YYYY", true)) {
      if (moment(finishDate, "DD/MM/YYYY", true) < moment(this.props.reportDate, "YYYY-MM-DD").format("DD/MM/YYYY")) {
        return false
      }
      else {
        return true
      }
    }
    else {
      return false
    }


  }
  saveRowData(cellInfo) {

    this.setState({ editContent: false }, () => console.log(cellInfo.original.numberEmployeesWorking))

    //validation
    if (!this.validateEndDate(cellInfo.original.rentalDate, cellInfo.original.rentalFinishedDate)) {
      this.setState({
        saveDateErrorVisible: true
      })


    }
    else {

      axios.put(process.env.REACT_APP_API_URL + '/api/rental/', {
        _id: cellInfo.original._id,
        // rentalDate: cellInfo.original.rentalDate,
        // numberOfDays: cellInfo.original.numberOfDays,
        // comment: cellInfo.original.comment,
        rentalFinishedDate: cellInfo.original.rentalFinishedDate
      }, {
        headers: {
          'Authorization': localStorage.jwtToken
        }
      }).then(res => {
        this.props.getRentalsByProjectAndReportDate()
        this.setState({
          editingRow: -1,
          isDirty: false
        })
        return res.data
      })
        .catch((error) => {
          ;
        })
    }
  }

  renderActiveButtons(row) {

    if (userCant(this.props.user, 'equipment.manage')) {
      return ''
    }

    return (<div>
      <Button
        onClick={() => this.toggleEditable(row.index)}
        size="xs"
        className="btn-icon btn-neutral btn btn-info"
      ><i className="fa fa-edit"
        /></Button>
      <Button
        onClick={() => this.saveRowData(row)}
        size="xs"
        className="btn-icon btn-neutral btn btn-info"
      ><i className="fa fa-save"
        /></Button>
      {/* <Button
        color="danger"
        size="sm"
        className="btn-icon btn-neutral btn btn-info"
        onClick={() => {
          this.deleteRentalById(row.original._id)
        }
        }
      ><i
          className="fa fa-remove"
        />
      </Button> */}
      <Button
        color="danger"
        size="xs"
        className="btn-icon btn-neutral btn btn-info"
        onClick={() => {
          this.warningWithConfirmAndCancelMessage(row.original._id)
        }
        }
      ><i
          className="fa fa-remove"
        />
      </Button>
    </div>)


  }
  deleteRentalById(rentalId) {
    axios.delete(process.env.REACT_APP_API_URL + '/api/rental/' + rentalId + '/date/' + this.props.reportDate, {
      headers: {
        'Authorization': localStorage.jwtToken
      }
    }).then(res => {

      this.setState({
        alert: null
      }, () => { });
      this.props.getRentalsByProjectAndReportDate()


    })
      .catch((error) => {
        this.setState({
          alert: null,
          deleteRentalError: true
        }, () => { });

      })
  }



  toggleEditable(index) {

    const { isDirty } = this.state;
    if (!isDirty) {
      this.setState({
        editingRow: index,
        isDirty: true
      });
    }
    else {
      this.setState({
        editingRow: -1,
        isDirty: false
      })
    }
  }

  saveDateErrorOnDismiss() {
    this.setState({ saveDateErrorVisible: !this.state.saveDateErrorVisible })
  }

  deleteRentalErrorOnDismiss() {
    this.setState({ deleteRentalError: !this.state.deleteRentalError })
  }

  render() {
    console.log(this.props.rentalsTableData)
    const s = StyleSheet.create({
      rdtPicker: { // this generate a new css class e.g rdtPicker_1wtftbl rather than target the original class
        'z-Index': '99999 !important'
      },
    });

    //define other properties here, use camel case(remember we are using Javascript)


    var yesterday = moment(this.props.reportDate);
    function valid(current) {
      return current.isAfter(yesterday);
    }

    console.log(this.props.rentalsTableData)
    const { errors } = this.state;
    return (



      <>
        <div className="content" >
          {this.state.alert}
          <h4>All Rented Equipment</h4>
          <Alert
            color="danger"

            isOpen={this.state.saveDateErrorVisible}
            toggle={this.saveDateErrorOnDismiss}
          >
            <span>{this.props.t("Incorrect rental end date. Date cant be before the start date or before the current report date")} </span>
            <span>{this.props.t("If you want to change the end date to an earlier day, please change the report date to the date you want the rental to end")}</span>
          </Alert>
          <Alert
            color="danger"

            isOpen={this.state.deleteRentalError}
            toggle={this.deleteRentalErrorOnDismiss}
          >
            <span>{this.props.t("Unable to delete rental")}.</span>
            <span>{this.props.t("If the start of the rental is in the past, it is not possible to delete the rental without contacting your administrator")}</span>
          </Alert>
          <ReactTable
            // style={{overflow:'visble'}}
            data={this.props.rentalsTableData}
            columns={[
              {
                Header: this.props.t("Rental"),
                accessor: "_id",
                show: false
              },
              {
                Header: this.props.t("Rental Equipment Id"),
                accessor: "rentalEquipmentId",
                show: false
              }, {
                Header:this.props.t("Company Renting"),
                accessor: "companyRenting",
              },
              {
                Header: this.props.t("Equipment Description"),
                accessor: "rentalEquipmentId.description",
              }, {
                Header: this.props.t("Type / Model"),
                accessor: "rentalEquipmentId.typeModel",
              },
              {
                Header: this.props.t("Rental Company"),
                accessor: "rentalCompany.name",
              },
              {
                Header: this.props.t( "Rental Start"),
                accessor: "rentalDate",
                Cell: (props) => {
                  //props.value will contain your date
                  //you can convert your date here
                  this.renderEditableDate(props)
                  const custom_date = moment.utc(props.value).format('DD MMM YYYY')
                  return <span>{custom_date}</span>
                }
              },
              // {
              //   Header: () => (
              //     <div
              //       style={{
              //         textAlign: "left"
              //       }}
              //     >Comments</div>),
              //   accessor: "comment",
              //   Cell: this.renderEditableText
              // },
              {
                Header: this.props.t("Rental End"),
                accessor: "rentalFinishedDate",
                // Cell: (props) => {
                //   this.renderEditableDate()

                // }
                getProps: (state, rowInfo, column) => {
                  return {
                    style: {
                      // background: 'red',
                      // overflow: "unset"
                    },
                  }
                },
                // // Cell: this.renderEditableDate
                Cell: (props) => {
                  //props.value will contain your date
                  //you can convert your date here
                  return this.renderEditableDate(props)
                  // if (props.value != undefined) {
                  //   const custom_dater = moment(props.value).format('DD MMM YYYY')
                  //   return <span>{custom_dater}</span>
                  // }
                  // else {
                  //   return <span></span>
                  // }

                }
              },
              {
                Header: this.props.t("Actions"),
                id: "activateToggle",
                width: 150,
                Cell: ((value) => (this.renderActiveButtons(value)))
              }
            ]}
            showPaginationTop={false}
            getTdProps={() => { return { style: { position: "inherit", overflow: "inherit" } } }}
            pageSize={this.props.rentalsTableData.length}
            defaultPageSize={1}
            showPaginationBottom={false}
            // getTrProps={this.onRowClick}
            // getTrProps={onRowClick}
            /*
              You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
            */
            className="-striped -highlight primary-pagination"
          // getProps={(state, rowInfo, column) => {
          //   return {
          //     style: {
          //       background: "red",
          //     }
          //   };
          // }}
          />
          {/* </Col>
          </Row> */}
        </div>
      </>
    );
  }
}

ConnectedRentalTable.propTypes = {
  // errors: PropTypes.object.isRequired,
  // addRental: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    project: state.allOtherReducers.project,
    projectId: state.allOtherReducers.projectId,
    projectData: state.allOtherReducers.projectData,
    reportDate: state.allOtherReducers.reportDate,
    user: state.auth.user,
    errors: state.errors
  };
}

const RentalTable = withTranslation()(connect(mapStateToProps, {RentalTable})(ConnectedRentalTable));

export default RentalTable;


