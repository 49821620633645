import React from "react";

// reactstrap components
import {Card, CardBody, CardHeader, CardTitle, Col, Form, FormGroup, Row} from "reactstrap";
import classnames from "classnames";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import store from "../../../store";
import {GET_ERRORS} from "../../../actions/types";
import {updateProfile} from '../../../actions/authActions';

class UserProfile extends React.Component {
    constructor() {
        super();
        this.state = {
            name: store.getState().auth.user.name,
            last_name: store.getState().auth.user.last_name,
            email: store.getState().auth.user.email,
            current_password: '',
            password: '',
            errors: '',
        };

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }

    onSubmit(e) {
        e.preventDefault();
        const userData = {
            name: this.state.name,
            last_name: this.state.last_name,
            email: this.state.email,
            password: this.state.password,
            current_password: this.state.current_password
        };

        this.props.updateProfile(userData, this.props.history);
    }

    componentDidMount() {
        store.dispatch({
            type: GET_ERRORS,
            payload: {}
        })
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let stateProps = {};

        stateProps.errors = nextProps.errors || {};

        return stateProps;
    }

    render() {
        const {errors} = this.state;

        return (
            <>
                <div className="content">
                    <Row>
                        <Col md="3">
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h4">Profile</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <Form onSubmit={this.onSubmit}>
                                        <Row>
                                            <Col md="12">
                                                <FormGroup>
                                                    <label htmlFor="exampleInputEmail1">
                                                        Email address
                                                    </label>
                                                    <div className="form-group">
                                                        <input
                                                            type="email"
                                                            className={classnames('form-control form-control-lg', {
                                                                'is-invalid': errors.email
                                                            })}
                                                            placeholder="Email Address"
                                                            name="email"
                                                            value={this.state.email}
                                                            onChange={this.onChange}
                                                        />
                                                        {errors.email && (
                                                            <div className="invalid-feedback">{errors.email}</div>
                                                        )}
                                                    </div>
                                                </FormGroup>
                                                <FormGroup>
                                                    <label>Name</label>
                                                    <input
                                                        type="text"
                                                        className={classnames('form-control form-control-lg', {
                                                            'is-invalid': errors.name
                                                        })}
                                                        placeholder="Name"
                                                        name="name"
                                                        value={this.state.name}
                                                        onChange={this.onChange}
                                                    />
                                                    {errors.name && (
                                                        <div className="invalid-feedback">{errors.name}</div>
                                                    )}
                                                </FormGroup>

                                                <FormGroup>
                                                    <label>Lastname</label>
                                                    <input
                                                        type="text"
                                                        className={classnames('form-control form-control-lg', {
                                                            'is-invalid': errors.last_name
                                                        })}
                                                        placeholder="Lastname"
                                                        name="last_name"
                                                        value={this.state.last_name}
                                                        onChange={this.onChange}
                                                    />
                                                    {errors.last_name && (
                                                        <div className="invalid-feedback">{errors.last_name}</div>
                                                    )}
                                                </FormGroup>

                                                <FormGroup>
                                                    <label>Current password</label>
                                                    <input
                                                        type="password"
                                                        className={classnames('form-control form-control-lg', {
                                                            'is-invalid': errors.current_password
                                                        })}
                                                        placeholder="Current password"
                                                        name="current_password"
                                                        value={this.state.current_password}
                                                        onChange={this.onChange}
                                                    />
                                                    {errors.current_password && (
                                                        <div className="invalid-feedback">{errors.current_password}</div>
                                                    )}
                                                </FormGroup>

                                                <FormGroup>
                                                    <label>New password</label>
                                                    <input
                                                        type="password"
                                                        className={classnames('form-control form-control-lg', {
                                                            'is-invalid': errors.password
                                                        })}
                                                        placeholder="New password"
                                                        name="password"
                                                        value={this.state.password}
                                                        onChange={this.onChange}
                                                    />
                                                    {errors.password && (
                                                        <div className="invalid-feedback">{errors.password}</div>
                                                    )}
                                                </FormGroup>

                                                <input type="submit" className="btn btn-info btn-block mt-4"/>
                                            </Col>
                                        </Row>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

UserProfile.propTypes = {
    updateProfile: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors,
});

export default connect(mapStateToProps, {updateProfile})(withRouter(UserProfile));
