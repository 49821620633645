import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
import axios from "axios";
import moment from "moment"
import ProjectTable from "views/tables/ProjectTable"
import {emptyProjectIdsFromoPermissionsList, addProjectPermissionsToUser, getProjects} from 'actions/index'
import InputMask from 'react-input-mask';
import {onColumnFilter} from '../../filters/ColumnFilter'
import { Dock } from "react-dock"

// reactstrap components
import {
  Button,
  Row,
  Col,
  Modal,
  CardBody,
  Card,
  Input
} from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import RenderCustomFields from "../CustomFields/Render/RenderCustomFields";
import {userCan, userCant} from "../../../services/Authorization/authService";

function mapDispatchToProps(dispatch) {
  return {

    emptyProjectIdsFromoPermissionsList: x => dispatch(emptyProjectIdsFromoPermissionsList(x)),
    addProjectPermissionsToUser: userDetails=>dispatch(addProjectPermissionsToUser(userDetails)),
    getProjects: () => dispatch(getProjects())
  }
}

function mapStateToProps(state) {
  return {
    project: state.allOtherReducers.project,
    projectId: state.allOtherReducers.projectId,
    projectData: state.allOtherReducers.projectData,
    reportDate: state.allOtherReducers.reportDate,
    projectPermissionsList: state.allOtherReducers.projectPermissionList,
    loggedInUser: state.auth.user,
    client: state.auth.client,
  };
}

class UserTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      editContent: false,
      modalClassic: false,
      userId:'',
      isDirty: false,
      rowData:"",
      openCustomFields:false
    }
    this.renderEditable = this.renderEditable.bind(this);
    this.saveRowData = this.saveRowData.bind(this);
    this.renderActiveButtons = this.renderActiveButtons.bind(this);
    this.deactivateUser = this.deactivateUser.bind(this);
    this.activateUser = this.activateUser.bind(this);
    this.openCustomFieldsDock = this.openCustomFieldsDock.bind(this);
    this.onCloseCustomFieldsModal = this.onCloseCustomFieldsModal.bind(this);
  }

  componentDidMount(props) {
    //always get fresh projects
    this.props.getProjects()
  }

  componentWillReceiveProps({ userTableData }) {
    this.setState({ ...this.state, userTableData }, () => this.state)
  }


  handleInputChange = (cellInfo, event) => {
    let data = [...this.props.userTableData];
    data[cellInfo.index][cellInfo.column.id] = event.target.value;

    this.setState({ data });
  };
  renderEditable(cellInfo) {

    let row = cellInfo.original,
        fullName = `${row.name} ${row.last_name || ''}`

      return (
          <div>{fullName}</div>
      );
  }

  saveRowData(cellInfo) {
    this.setState({ editContent: false }, () => console.log(cellInfo.original.name))

    axios.put(process.env.REACT_APP_API_URL + '/api/user/', {
      _id: cellInfo.original._id,
      name: cellInfo.original.name,
      email: cellInfo.original.email

    }, {
      headers: {
        'Authorization': localStorage.jwtToken
      }
    }).then(res => {
      console.log(res)
      this.setState({
        isDirty: false
      })
      return res.data
    })
      .catch((error) => {
        ;
      })
  }
  deactivateUser(cellInfo) {
    if (userCant(this.props.loggedInUser, 'users.update')) {
      return;
    }

    axios.put(process.env.REACT_APP_API_URL + '/api/user/deactivate', {
      _id: cellInfo.original._id,

    }, {
      headers: {
        'Authorization': localStorage.jwtToken
      }
    }).then(res => {
      console.log(res)
      this.props.getSomeUsers();
      return res.data
    })
      .catch((error) => {
        ;
      })
  }

  activateUser(cellInfo) {
    axios.put(process.env.REACT_APP_API_URL + '/api/user/activate', {
      _id: cellInfo.original._id,

    }, {
      headers: {
        'Authorization': localStorage.jwtToken
      }
    }).then(res => {
      console.log(res)
      this.props.getSomeUsers();
      return res.data
    })
      .catch((error) => {
        ;
      })
  }

  showUserForm(index) {
    if (userCant(this.props.loggedInUser, 'users.update')) {
      return;
    }
    this.props.setEditingUser(this.props.userTableData[index]);
  }

  toggleModalClassic = (row) => {
    this.setState({
      modalClassic: !this.state.modalClassic,
      rowData: row
    });

  };

  openCustomFieldsDock(row) {
    this.setState({
      openCustomFields: !this.state.openCustomFields,
      rowData: row.original
    })
  }

  saveProjectPermissions(userId)
  {

    var userDataToUpdate = {
      _id:userId.original._id,
      projects:this.props.projectPermissionsList
    }
    this.props.addProjectPermissionsToUser(userDataToUpdate).then(() => {
      this.props.getSomeUsers();

      this.setState({
        modalClassic: !this.state.modalClassic,
        rowData: []
      });
    })
  }
  // permissionsModal() {
  //   return (<Modal
  //     isOpen={this.state.modalClassic}
  //     toggle={this.toggleModalClassic}
  //   > <Button
  //     className="btn-link"
  //     color="default"
  //     data-dismiss="modal"
  //     type="button"
  //     onClick={this.toggleModalClassic}
  //   >
  //       Never mind
  // </Button></Modal>)
  // }

  renderActiveButtons(row) {


    if (row.original.active) {
      return (<div>
        {userCan(this.props.loggedInUser, 'users.update') && <Button
            onClick={() => this.showUserForm(row.index)}
            size="xs"
            className="btn-icon btn-neutral btn btn-info"
        ><i className="fa fa-edit"
        /></Button>}
        {userCan(this.props.loggedInUser, 'users.update') && <Button
            onClick={() => this.deactivateUser(row)}
            size="xs"
            className="btn-icon btn-neutral btn btn-info"
        ><i className="fa fa-power-off"
        /></Button>}
        {userCan(this.props.loggedInUser, 'users.assign_to_projects') && <Button
            onClick={() => this.toggleModalClassic(row)}
            size="xs"
            className="btn-icon btn-neutral btn btn-info"
        ><i className="fa fa-list"
        /></Button>
        }
        { userCan(this.props.loggedInUser, 'users.update') && this.props.client?.config?.enableCustomFields && <Button
            onClick={() => this.openCustomFieldsDock(row)}
            size="xs"
            className="btn-icon btn-neutral btn btn-info mt-2"
        ><i className="fa fa-exclamation"
        /></Button> }
      </div>)
    }
    else {
      if(userCant(this.props.loggedInUser, 'users.update')){
        return ;
      }
      return (<div>
        <Button
          onClick={() => this.activateUser(row)}
          size="xs"
          className={"m-0"}
        >Activate</Button>
      </div>)
    }
  }

  renderRoles(user){
    return user.roles?.map(role => <label key={`role_${role._id}`} className="p-1 mr-1 rounded bg-success text-white">{role.label}</label> ) || ''
  }
  renderProjects(user) {
    const projects = user.projects || []
    return projects.map(project =>
        project.projectId &&
        <label key={`project_${project.projectId._id}`}
               className="p-1 mr-1 rounded bg-info text-white">
          {project.projectId.name}
        </label>
    )
  }
  renderLocations(user){
    return  user.locations.map(location => <label key={`location_${location._id}`} className="p-1 mr-1 rounded bg-success text-white">{location.name}</label> )
  }

  renderCompanies(project) {
    if (!project.companies) {
      return ''
    }

    let companies = [];

    project.companies.forEach(company => {
      company.locations = project.locations.filter(l => l.companyId == company._id)
      companies.push(company);
    })


    return companies.map(company => <label
        key={`company_${company._id}`}
        className="p-1 mr-1 rounded bg-success text-white">
      {company.name} <span
        style={{'fontSize': '10px'}}>{company.locations.length ? `( ${company.locations.map(l => l.name).join(', ')}   )` : ''}</span>
    </label>)
  }

  renderPosition(user) {
    return user.positions?.map((p, index) => <label className={'p-1 rounded bg-success text-white'} key={`post_${index}`}>{p.name}</label>)
  }

    onCloseCustomFieldsModal() {
        this.setState({
            openCustomFields: false,
            rowData: ''
        })
    }

  render() {

    if (userCant(this.props.loggedInUser, 'users.index')) {
      return;
    }

    return (
      <>
        <div className="content">
          <ReactTable
            data={this.props.userTableData}
            columns={[
              {
                Header: this.props.t("User Id"),
                accessor: "_id",
                show: false,
                filterable:false
              }, {
                Header: this.props.t("Name"),
                accessor: "name",
                filterBy:["name","last_name"],
                Cell: this.renderEditable
              },
              {
                Header: this.props.t("Email"),
                accessor: "email"
              },
              {
                Header: this.props.t("Roles"),
                accessor: "roles",
                filterBy:"label",
                Cell:row => this.renderRoles(row.original),
              },
              {
                Header: this.props.t("Companies"),
                accessor: "companies",
                filterBy:"name",
                Cell: row => this.renderCompanies(row.original),
                sortable:false,
              }, {
                Header: this.props.t("Position"),
                accessor: "positions",
                filterBy:"name",
                Cell: row => this.renderPosition(row.original),
                sortable:false,
              },
              {
                Header: this.props.t("Projects"),
                accessor: "projects",
                filterBy:"name",
                Cell: row => this.renderProjects(row.original),
                sortable:false,
              },
              {
                Header: this.props.t("Actions"),
                id: "activateToggle",
                width: 150,
                Cell: ((value) => (this.renderActiveButtons(value))),
                filterable:false
              }

            ]}
            defaultPageSize={1}
            pageSize={this.props.userTableData.length}
            showPaginationTop={false}
            showPaginationBottom={false}
            className="-striped -highlight primary-pagination"
            filterable
            defaultFilterMethod={(filter, row, column) => {
                return onColumnFilter(filter, row, column);}}
          />

          <Dock
              position='right'
              size={window.innerWidth < 600?1:(window.innerWidth < 900?0.5:0.35)}
              isVisible={this.state.openCustomFields}
              duration={300}
          >

              {this.state.openCustomFields &&  this.props.client?.config?.enableCustomFields && <RenderCustomFields onClose={this.onCloseCustomFieldsModal}
                                                                  forModel={'user'}
                                                                  model={this.state.rowData}/>}
          </Dock>


          <Modal
            isOpen={this.state.modalClassic}
            toggle={this.toggleModalClassic}
            // onOpened={this.props.emptyProjectIdsFromoPermissionsList}
            onClosed={this.props.emptyProjectIdsFromoPermissionsList}
            size='lg'
            // onClosed={this.setState({userId:''})}
          >
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    {/* <div>{this.state.userId}</div> */}
                    <ProjectTable
                    rowData={this.state.rowData}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col md="3"/>
              <Col md="3">
                <Button
                  className="btn-link"
                  color="default"
                  data-dismiss="modal"
                  type="button"
                  onClick={()=>this.toggleModalClassic('')}
                >
                  {this.props.t('Never mind')}
                </Button>
                </Col>

                <Col md="3">
                <Button
                  className="btn-link"
                  color="default"
                  data-dismiss="modal"
                  type="button"
                  onClick={()=>this.saveProjectPermissions(this.state.rowData)}
                >
                  {this.props.t('Save')}
                </Button>
              </Col>
              <Col md="3"/>
            </Row>
          </Modal>
        </div>
      </>
    );
  }
}


export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(UserTable)));
