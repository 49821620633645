import React, {useEffect, useCallback, useState} from "react"
import {Button, Col, Input, Row, Form, FormGroup} from "reactstrap"
import {debounce, sortBy} from "lodash"
import {useSelector} from "react-redux"
import {Dock} from "react-dock"
import Datetime from 'react-datetime';

import fetchTools from '../../../services/fetchTools.js';
import date from '../../../assets/img/date.svg'
import tagIcon from '../../../assets/img/tag.svg'
import close from '../../../assets/img/close.svg'

import closeTag from '../../../assets/img/close-tag.svg'
import closeGreen from '../../../assets/img/close-green.svg'
import placeholder from '../../../assets/img/image-placeholder.svg'
import emptyPhoto from '../../../assets/img/photo-placeholder.svg'
import './PhotoGallery.scss'
import moment from "moment"
import {getPhotoGallery} from "services/photo-gallery.js"
import {useDispatch} from "react-redux"
import {
    refreshPhotoList,
    refreshPhotosWithKeepSelectionMood as refreshPhotosWithKeepSelectionMoodAction
} from "actions/index.js"
import {PhotoOptions} from "views/pages/PhotoGallery/PhotoOptions";
import {TagList} from "./TagList.jsx"
import {EditPhotosModal} from "./EditPhotosModal.jsx"
import {ExportPDFModal} from "./ExportPDFModal.jsx"
import Switch from "react-bootstrap-switch";
import {userCant} from "../../../services/Authorization/authService";
import Lightbox from "yet-another-react-lightbox";
import Counter from "yet-another-react-lightbox/dist/plugins/counter";
import Thumbnails from "yet-another-react-lightbox/dist/plugins/thumbnails";
import "yet-another-react-lightbox/dist/styles.css";
import "yet-another-react-lightbox/dist/plugins/counter/counter.css";
import "yet-another-react-lightbox/dist/plugins/thumbnails/thumbnails.css";
import {useTranslation} from 'react-i18next';
import classnames from "classnames";
import Select from "react-select";
import axios from "axios";
import {Video} from "yet-another-react-lightbox/dist/plugins";

export const PhotoGallery = (props) => {
    const user = useSelector((state) => state.auth.user)
    const projectId = useSelector((state) => state.allOtherReducers.projectId)
    const refreshed = useSelector((state) => state.allOtherReducers.refreshPhotoList)
    const refreshPhotosWithKeepSelectionMood = useSelector((state) => state.allOtherReducers.refreshPhotosWithKeepSelectionMood)
    const dispatch = useDispatch()
    const {t, i18n} = useTranslation();
    const [searchText, setSearchText] = useState('')
    const [photos, setPhotos] = useState([])
    const [isOpen, setIsOpen] = useState(false)
    const [index, setIndex] = useState(-1)
    const [loadingPagination, setLoadingPagination] = useState(false);
    const showTagsStorage = localStorage.getItem('show_tags');
    const [showTags, setShowTags] = useState(showTagsStorage === null ? false : showTagsStorage === 'true')

    const [fromDate, setFromDate] = useState()
    const [showPhotosWithoutTagsOnly, setShowPhotosWithoutTagsOnly] = useState(false)
    const [nameImageFilter, setNameImageFilter] = useState('')
    const [descriptionFilter, setDescriptionFilter] = useState('')
    const [userFilter, setUserFilter] = useState('')
    const [toDate, setToDate] = useState()
    const [useOrTags, setUseOrTags] = useState(false)

    const [tags, setTags] = useState([])
    const [selectedTags, setSelectedTags] = useState([])
    const [isSelected, setIsSelected] = useState(0)
    const [nextPage, setNextPage] = useState(1);

    const [lightBoxList, setLightBoxList] = useState([]);

    const [selectMode, setSelectMode] = useState(false)
    const [selectedPhotos, setSelectedPhotos] = useState([])

    const [showEditPhotosModal, setShowEditPhotosModal] = useState(false)
    const [showModalTagsStep, setShowModalTagsStep] = useState(false)

    const [isExportModalOpen, setShowExportModal] = useState(false)
    const [filtersCleared, setFiltersCleared] = useState(false)
    const [usersList, setUsersList] = useState([])

    const getPhotosCallback = useCallback(debounce(async () => {
        if (userCant(user, 'images.manage')) {
            return
        }
        const response = await getPhotoGallery(projectId, selectedTags, searchText, fromDate, toDate)
        setPhotos(response.images)
        setNextPage(response.nextPage)
    }, 1000))

    function isVideo(filename) {
        const videoExtensions = [
            'mp4', 'mkv', 'flv', 'avi', 'mov',
            'wmv', 'mpg', 'mpeg', 'm4v', 'rm',
            'rmvb', 'vob', 'asf', '3gp', '3g2'
        ];

        const fileExt = filename.split('.')[1]

        return videoExtensions.includes(fileExt)
    }

    const lightboxImagesList = (photosList) => {
        let slides = [];
        photosList.forEach(group => {
            group.value.forEach(photo => {
                let {width, height} = photo.metadata || {};

                if (isVideo(photo.imageKey)) {
                    slides.push({
                        id: photo._id,
                        type: "video",
                        width: 1280,
                        height: 720,
                        poster: "/video-poster.png",
                        sources: [
                            {
                                src: photo.imageUrl,
                                type: "video/mp4"
                            },
                        ],
                    })
                } else {
                    width *= 4;
                    height *= 4;
                    slides.push({
                        src: photo.imageUrl,
                        width,
                        height,
                        id: photo._id
                    })
                }
            })
        })

        setLightBoxList(slides)
    }

    const resetDateSelector = (e) => {
        e.stopPropagation()
        setFromDate()
        setToDate()
    }

    const resetNameDescriptionFilter = (e) => {
        e.stopPropagation()
        setNameImageFilter('');
        setDescriptionFilter('')
    }

    const resetUserFilter = (e) => {
        e.stopPropagation()
        setUserFilter('');
    }

    const resetTagSelector = (e) => {
        e.stopPropagation()
        setSelectedTags([])
    }

    const refreshPhotos = (_photos) => {
        let refreshPhotos = photos.map(group => {
            group.value = group.value.map(p => {
                let targetPhoto = _photos.find(_p => `${p._id}` == `${_p._id}`)
                if (targetPhoto) {
                    targetPhoto.imageUrl = p.imageUrl
                    targetPhoto.thumbnailUrl = p.thumbnailUrl
                    return targetPhoto
                }

                return p;
            })

            return group
        })

        setPhotos(refreshPhotos)
        setSelectedPhotos(_photos)
    }

    useEffect(() => {
        if (!refreshed) {
            return
        }
        handleApplyFilter()
        dispatch(refreshPhotoList(false))
        setSelectMode(false)
    }, [refreshed])

    useEffect(() => {
        if (!refreshPhotosWithKeepSelectionMood) {
            return
        }
        handleApplyFilter()
        dispatch(refreshPhotosWithKeepSelectionMoodAction(false))

    }, [refreshPhotosWithKeepSelectionMood])

    useEffect(() => {
        getTagSystems(projectId);
        getPhotosCallback.cancel()
        handleApplyFilter()
        getSomeUsers()
        return () => setSelectedPhotos([])
    }, [projectId]);

    useEffect(() => {
        lightboxImagesList(photos);
    }, [photos])

    const getTagSystems = async (projectId) => {
        if (userCant(user, 'images.manage')) {
            return
        }
        try {
            const response = await fetchTools.get(`api/tag-systems/project/${projectId}`);
            if (response.data) {
                getTags(response.data?.[0]?._id);
            }
        } catch (error) {
            console.log('error', error);
        }
    }

    const getTags = async (rootTagId) => {
        if (userCant(user, 'images.manage')) {
            return
        }
        try {
            const response = await fetchTools.get(`api/tags/${rootTagId}`);
            setTags(sortBy(response.data, 'order', 'asc'))
        } catch (error) {
            console.log('error', error);
        }
    }

    const handleUpdateFilterTags = (tag) => {
        const tagIndex = selectedTags.findIndex(t => t._id === tag._id)
        if (tagIndex !== -1) {
            setSelectedTags([...selectedTags.slice(0, tagIndex), ...selectedTags.slice(tagIndex + 1, selectedTags.length)])
        } else {
            setSelectedTags([...selectedTags, tag])
        }
    }

    function getSomeUsers() {

        let url = `${process.env.REACT_APP_API_URL}/api/images/users-have-images?projectId=${projectId}`;

        axios.get(url, {
            headers: {
                'Authorization': localStorage.jwtToken
            }
        }).then(res => {
            setUsersList(res.data.map(user => {
                return {
                    value: user._id, label: `${user.name} ${user.last_name || ''}`
                }
            }))
        })
    }

    const handleApplyFilter = async () => {
        if (userCant(user, 'images.manage')) {
            return
        }

        const response = await getPhotoGallery(
            projectId,
            selectedTags,
            nameImageFilter,
            fromDate,
            toDate,
            descriptionFilter,
            showPhotosWithoutTagsOnly,
            userFilter,
            useOrTags
        )

        setPhotos(response.images)
        setNextPage(response.nextPage)
    }


    useEffect(() => {
        if (filtersCleared) {
            handleApplyFilter();
            setFiltersCleared(false);
        }
    }, [filtersCleared])

    const resetFilters = e => {
        resetDateSelector(e)
        resetTagSelector(e)
        resetNameDescriptionFilter(e)
        resetUserFilter(e)
        setShowPhotosWithoutTagsOnly(false);
        setUseOrTags(false);

        setFiltersCleared(true);
    }

    useEffect(() => {
        if (userCant(user, 'images.manage')) {
            props.history.push('/admin/instructions')
        }
        addRemoveScrollEventToPanel('add');

        return () => {
            // Anything in here is fired on component unmount.
            addRemoveScrollEventToPanel('remove');
        }
    });

    /**
     *
     * @param action
     */
    const addRemoveScrollEventToPanel = action => {
        const mainPanel = document.getElementsByClassName('main-panel')[0];

        switch (action) {
            case 'add':
                if (mainPanel) {
                    mainPanel.addEventListener('scroll', handleScrolling);
                }
                break;
            case 'remove':
                mainPanel.removeEventListener('scroll', handleScrolling);
        }
    }

    const handleScrolling = async () => {

        if (loadingPagination) {
            return;
        }

        const mainPanel = document.getElementsByClassName('main-panel')[0];
        let reachedBottom = Math.round(mainPanel.scrollTop + mainPanel.clientHeight) >= (mainPanel.scrollHeight - 10);

        if (reachedBottom && nextPage) {

            setLoadingPagination(true);

            const response = await getPhotoGallery(
                projectId,
                selectedTags,
                nameImageFilter,
                fromDate,
                toDate,
                descriptionFilter,
                showPhotosWithoutTagsOnly,
                userFilter,
                useOrTags,
                nextPage
            )

            setNextPage(response.nextPage)

            let _photos = photos;

            response.images.forEach(object => {
                if (_photos.find(o => o.key === object.key)) {
                    _photos = photos.map(o => {
                        if (o.key === object.key) {
                            o.value.push(...object.value)
                        }
                        return o;
                    })
                } else {
                    _photos.push(object)
                }
            })

            setPhotos(_photos);

            setTimeout(() => {
                setLoadingPagination(false);
            })
        }
    }

    const handleUpdateSelectedPhotos = (tag) => {
        if (!selectMode) {
            return
        }
        const photoIndex = selectedPhotos.findIndex(t => t._id === tag._id)
        if (photoIndex !== -1) {
            setSelectedPhotos([...selectedPhotos.slice(0, photoIndex), ...selectedPhotos.slice(photoIndex + 1, selectedPhotos.length)])
        } else {
            setSelectedPhotos([...selectedPhotos, tag])
        }
    }

    return (
        <div className="content">
            {showEditPhotosModal &&
            <EditPhotosModal tags={tags} selectedPhotos={selectedPhotos} isOpen={showEditPhotosModal}
                             refreshPhotos={refreshPhotos}
                             showModalTagsStep={showModalTagsStep}
                             toggleOpen={() => setShowEditPhotosModal(!showEditPhotosModal)} toggleSelectMode={() => {
                setSelectMode(!selectMode)
                setSelectedPhotos([])
            }}/>}

            <ExportPDFModal selectedPhotos={selectedPhotos} isOpen={isExportModalOpen}
                            toggleOpen={() => setShowExportModal(!isExportModalOpen)} toggleSelectMode={() => {
                setSelectMode(!selectMode)
                setSelectedPhotos([])
            }}/>
            <Dock
                position='right'
                isVisible={isOpen}
                duration={300}
                size={window.innerWidth < 600 ? 1 : (window.innerWidth < 900 ? 0.5 : 0.3)}
            >
                <Form className="photo__form" onSubmit={(e) => this.formSubmitSuccess(e)}>
                    <Row className="photo__form__header">
                        <span>{t('Filter selection')}</span>
                        <img className="pointer" src={close} onClick={() => setIsOpen(false)}/>
                    </Row>
                    <div className="overflowY">
                        <div className="photo__filter__date">
                            <div className="photo__datepicker__title">
                                <span>{t('Filter by date')}</span>
                                <Button color="link" type="button" onClick={resetDateSelector}>{t('Reset')}</Button>
                            </div>
                            <div className="photo__datepicker__label">
                                <span>{t('From')}</span>
                            </div>
                            <div className="photo__datepicker">
                                <Datetime
                                    value={fromDate}
                                    onChange={date => setFromDate(date)}
                                    closeOnSelect={true}
                                    timeFormat={false}
                                    utc={true}
                                    dateFormat={'DD MMM YYYY'}
                                    inputProps={{placeholder: t("Choose start date")}}
                                />
                                <img src={date}/>
                            </div>
                            <div className="photo__datepicker__label">
                                <span>{t('To')}</span>
                            </div>
                            <div className="photo__datepicker">
                                <Datetime
                                    value={toDate}
                                    onChange={date => setToDate(date)}
                                    closeOnSelect={true}
                                    timeFormat={false}
                                    utc={true}
                                    dateFormat={'DD MMM YYYY'}
                                    inputProps={{placeholder: t("Choose end date")}}
                                />
                                <img src={date}/>
                            </div>
                        </div>
                        <div className="photo__filter__title_description">
                            <div className="photo__datepicker__title">
                                <span>{t('Filter by')} {t('User')}</span>
                                <Button color="link" type="button"
                                        onClick={resetUserFilter}>{t('Reset')}</Button>
                            </div>
                            <FormGroup>
                                <Select
                                    className="react-select primary"
                                    classNamePrefix="react  -select"
                                    name="building"
                                    value={userFilter}
                                    onChange={option => {
                                        setUserFilter(option)
                                    }}

                                    options={usersList}
                                    placeholder={t("Choose User")}
                                />
                            </FormGroup>
                        </div>

                        <div className="photo__filter__title_description">
                            <div className="photo__datepicker__title">
                                <span>{t('Filter by')} {t('name')}/{t('description')}</span>
                                <Button color="link" type="button"
                                        onClick={resetNameDescriptionFilter}>{t('Reset')}</Button>
                            </div>
                            <FormGroup>
                                <label>{t('Name')}</label>
                                <Input
                                    placeholder={'Photo name'}
                                    name="name"
                                    type="text"
                                    className={classnames('form-control form-control-lg')}
                                    onChange={(e) => setNameImageFilter(e.target.value)}
                                    value={nameImageFilter}
                                />
                            </FormGroup>

                            <FormGroup>
                                <label>{t('Description')}</label>
                                <Input
                                    placeholder={t('Photo description')}
                                    name="name"
                                    type="text"
                                    className={classnames('form-control form-control-lg')}
                                    onChange={(e) => setDescriptionFilter(e.target.value)
                                    }
                                    value={descriptionFilter}
                                />
                            </FormGroup>
                        </div>
                        <div className="photo__filter__include_without_tags">
                            <div className="photo__tag__child pointer" onClick={e => {
                                setShowPhotosWithoutTagsOnly(!showPhotosWithoutTagsOnly)
                            }}>
                                <input
                                    type="checkbox" checked={!!showPhotosWithoutTagsOnly}
                                    onChange={(e) => {
                                        e.stopPropagation()
                                        setShowPhotosWithoutTagsOnly(e.target.checked)
                                    }}/>
                                <span>{t('Show photos without tags only')}</span>
                            </div>
                        </div>
                        <div className="photo__filter__tag">
                            <div className="photo__datepicker__title">
                                <span>{t('Filter by tag')}</span>
                                <Button color="link" type="button" onClick={resetTagSelector}>{t('Reset')}</Button>
                            </div>
                            {selectedTags.length ? (
                                <>
                                    <div className="photo__tag__child pointer" onClick={e => {
                                        setUseOrTags(!useOrTags)
                                    }}>
                                        <input
                                            type="checkbox" checked={!!useOrTags}
                                            onChange={(e) => {
                                                e.stopPropagation()
                                                setUseOrTags(e.target.checked)
                                            }}/>
                                        <span>{t('Use OR filter function')}</span>
                                    </div>
                                    <div className="photo__tag__container">
                                        <span>{t('Selected')}</span>
                                        <div className="photo__tag__list">
                                            {selectedTags.map(t => <div className="photo__tag--selected" key={t._id}>
                                                <span>{t.name}</span>
                                                <img className="pointer" src={closeTag}
                                                     onClick={() => handleUpdateFilterTags(t)}/>
                                            </div>)}
                                        </div>
                                    </div>
                                </>
                            ) : null}


                            <TagList tags={tags} selectedTags={selectedTags}
                                     handleUpdateSelectedTags={handleUpdateFilterTags}/>
                        </div>
                    </div>
                    <div className="photo__filter__actions">
                        <Button color="info" onClick={handleApplyFilter}>{t('Apply')}</Button>
                        <Button color="info" onClick={(e) => {
                            resetFilters(e)
                        }}>{t('Reset')}</Button>
                    </div>
                </Form>
            </Dock>

            <div className="photo__filter__container">
                <Row className={'m-2 d-flex justify-content-between'}>

                    <div className="photo__filter">
                        <span>{t('Filter')}</span>
                        <button className={`photo__btn ${(fromDate || toDate) ? 'photo__btn--active' : ''}`}
                                onClick={() => setIsOpen(true)}>
                            {(fromDate || toDate) ? null : <i className={'fa fa-filter'}></i>}
                            {/*<span>Date</span>*/}
                            {(fromDate || toDate) ?
                                <img className="photo__btn__close" src={closeGreen}
                                     onClick={resetDateSelector}/> : null}
                        </button>
                        {/* <button className={`photo__btn ${selectedTags.length ? 'photo__btn--active' : ''}`} onClick={() => setIsOpen(true)}>
						{selectedTags.length ? null : <img src={tag} />}
						<span>Tag</span>
						{selectedTags.length ? <img className="photo__btn__close" src={closeGreen} onClick={resetTagSelector} /> : null}
					</button> */}
                    </div>

                    {selectedPhotos.length ? (
                        <div className="photo__options__container order-last order-md-0">

                            <PhotoOptions selectedPhotos={selectedPhotos}
                                          showEditPhotosModal={(showTagsStep) => {
                                              setShowModalTagsStep(showTagsStep);
                                              setShowEditPhotosModal(true)
                                          }}
                                          showExportModal={() => setShowExportModal(true)}/>
                        </div>
                    ) : null}

                    <div className="photo__col--last">
                        <div style={{'marginRight': '20px'}}>
                            <p className="category">{t('Hide/Show Tags')}</p>
                            <Switch
                                offColor="success"
                                value={showTags}
                                onChange={(el, state) => {
                                    setShowTags(state);
                                    localStorage.setItem('show_tags', state ? 'true' : 'false')
                                }
                                }
                                offText={<i className="nc-icon nc-simple-remove"> </i>}
                                onColor="success"
                                onText={<i className="nc-icon nc-check-2"/>}
                            />
                        </div>

                        {!selectMode ? (
                            <Button color="info" onClick={() => setSelectMode(true)}>{t('Select photos')}</Button>
                        ) : (
                            <Button
                                color="info" onClick={() => {
                                setSelectMode(false)
                                setSelectedPhotos([])
                            }}>{t('Unselect')}</Button>
                        )}
                    </div>
                </Row>
                <Row className={'m-2'}>
                    <div>
                        <span>{t('Selected')} {selectedPhotos.length} {t('photos')}</span>
                    </div>
                    <Col md={{size: 12}}>
                        {searchText && (
                            photos.length > 0 ? (
                                <span
                                    className="mt-6">{photos.reduce((acc, cur) => acc += cur.value.length, 0)} {t('results are found')}</span>
                            ) : (
                                <span
                                    className="mt-6">{t('No result were found')}. {t('Please use another keyword')}.</span>
                            )
                        )}

                    </Col>
                </Row>
            </div>

            <Row>
                <Col>
                    <Lightbox
                        index={index}
                        slides={lightBoxList}
                        open={index >= 0}

                        plugins={[Counter, Thumbnails, Video]}
                        counter={{container: {style: {top: "unset", bottom: 0}}}}
                        close={() => setIndex(-1)}
                    />

                    {photos.length ? photos.map(({key, value}) => (
                        <div className="photo__list__group" key={key}>
                            <div className="photo__list__title">
                                <span className="photo__list__date">{moment(key).format('DD/MM/YYYY')}</span>
                                <span className="photo__list__stats">{value.length} {t('photos')}</span>
                            </div>
                            <div className="photo__list">
                                {value.map(v => (
                                    <div className="relative" onClick={() => handleUpdateSelectedPhotos(v)}
                                         key={v._id}>
                                        <img loading="lazy"
                                             onClick={() => {
                                                 if (selectMode) {
                                                     return;
                                                 }
                                                 let photoIndex = lightBoxList.findIndex(p => p.id == v._id);
                                                 setIndex(photoIndex)
                                             }}
                                             className={`photo__item pointer ${selectedPhotos.findIndex(p => p._id === v._id) >= 0 ? 'photo__item--active' : ''} `}
                                             src={isVideo(v.imageKey) ? '/video-poster.png' : v.thumbnailUrl}
                                             onError={({currentTarget}) => {
                                                 currentTarget.onerror = null // prevents looping
                                                 currentTarget.src = placeholder
                                             }}
                                        />
                                        {v.showInReport && <span className="text-info"
                                                                 style={{
                                                                     fontSize: "10px",
                                                                     fontWeight: "bold"
                                                                 }}>{t('Included in reports')}</span>}
                                        {selectMode &&
                                        <Input addon type="checkbox" className="photo__item__checkbox"
                                               checked={selectedPhotos.findIndex(p => p._id === v._id) >= 0}
                                               onChange={(e) => {
                                                   e.stopPropagation()
                                                   handleUpdateSelectedPhotos(v)
                                               }}/>}

                                        {showTags && <div className="d-flex flex-wrap mt-1">
                                            {v.tags && v.tags.map(t => <div className="badge badge-primary ml-1 p-1"
                                                                            key={t._id}>
                                                <img src={tagIcon} alt={"tagIcon"} className={"mr-1"}/>
                                                <small className={"font-weight-bold"}>{t.name}</small>
                                            </div>)}
                                        </div>
                                        }
                                    </div>
                                ))}
                            </div>

                        </div>
                    )) : (
                        <div className="photo__empty">
                            <img src={emptyPhoto}/>
                            <span>{t('There are no photos in the gallery')}</span>
                            <span>{t('Please add more')}</span>
                        </div>
                    )}

                </Col>
            </Row>
        </div>
    )
}
