import { createStore, applyMiddleware, compose } from "redux"
import rootReducer from "../reducers/index";
import thunk from "redux-thunk";
import filemanager from './FileManager/filemanager';
import dashboard from './FileManager/dashboard';
import fileManagerAPI from '../middleware/FileManager/api';

function saveToLocalStorage(state) {
    try {
        const serializedState = JSON.stringify(state)
        localStorage.setItem('state', serializedState)
    } catch (e) {
        console.log(e)
    }
}

function LoadFromLocalStorage()
{
    let _state = {
        filemanager,
        dashboard
    }

    try{
        const serializedState=localStorage.getItem('state') 
        if(serializedState===null) return _state
        return {
            ...JSON.parse(serializedState),
            ..._state
        }

    }catch(e){
        return _state
    }
}
const storeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const persistedState=LoadFromLocalStorage()
const store = createStore(
    rootReducer,
    persistedState,
    storeEnhancers(applyMiddleware(thunk, fileManagerAPI()))
);

store.subscribe(() => saveToLocalStorage(store.getState()))

export default store;