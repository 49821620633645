import React from "react";
import axios from "axios"
import AddProjectStructure from "./AddProjectStructure"
import { connect } from "react-redux";
import { setProject, setReportDate, getProjects, getProjectsByUser } from "../../../actions/index";
import store from "store";


// reactstrap components
import {
  Card,
  CardBody,
  Row,
  Col,
} from "reactstrap";
import {userCant} from "../../../services/Authorization/authService";
import { withTranslation } from 'react-i18next';


class ProjectStructure extends React.Component {
  constructor(props) {
    super(props);
this.getSomeProjectstructures=this.getSomeProjectstructures.bind(this)
    this.state = {

      projectData: [],
      currentProject: store.getState().projectName,
      projectStructureTableData: [],
      levelsTableData:[]
    };

    this.getSomeProjects();
    this.getSomeProjectstructures();  
    // this.getSomeLevels();

  }

  componentWillMount() {
    if (userCant(this.props.user, 'projectStructure.manage')) {
      return this.props.history.push('/admin/instructions')
    }
  }


  componentDidMount() {

    var startDate = new Date();
    startDate.setDate(startDate.getDate() - 7);

    var endDate = new Date();
    endDate.setDate(endDate.getDate());
    var getDateArray = function (start, end) {
      var arr = new Array();
      var dt = new Date(start);
      while (dt <= end) {
        arr.push(new Date(dt));
        dt.setDate(dt.getDate() + 1);
      }
      return arr;
    }

  }

  componentDidUpdate(prevProps, e) {

    if (this.props.projectData !== prevProps.projectData) {
      this.populateDropDownSelectProject(this.props.projectData)

    }

    if (this.props.projectId !== prevProps.projectId) {

      this.getSomeProjectstructures();
      this.setState({
        currentProject: store.getState().project
      })
    }

  }


  getSomeProjects() {
    axios.get(process.env.REACT_APP_API_URL + "/api/project/", 
    {
      headers: {
        'Authorization': localStorage.jwtToken
      }
    })
      .then(res => {
        this.setState({
          projectData: res.data
        }, () => console.log(this.state.projectData));

        return res.data
      }).catch((error) => {
        ;
      })
  }

  dateSelectedFromDropdownIntervals(dateIn) {
    this.setState({ dateSelected: dateIn }, () => this.state.dateSelected)
  }

  getScheduleClickedOnText = (gotText) => {
    this.setState({ scheduleClickedItem: gotText }, () => this.state.scheduleClickedItem)
  }

  updateProjectData = (data) => {
    this.setState({ projectData: data }, () => this.state.projectData)

  }

  populateDropDownSelectProject(data) {
    var testdata = data
    if (testdata && testdata.length > 0) {
      const options = testdata.map(project => {
        // console.log(rental)
        return { value: project._id, label: project.name }
      })
      this.props.setProject(options[0])
      this.setState({ projectDataFromAPI: options }, () => { console.log(this.state.projectDataFromAPI[0].value) })

    }

  }


  getSomeProjectstructures() {
    if (userCant(this.props.user, 'projectStructure.manage')) {
      return;
    }

    axios.get(process.env.REACT_APP_API_URL + "/api/projectstructure/project/" + this.props.projectId, {
      headers: {
        'Authorization': localStorage.jwtToken
      }
    })
      .then(res => {
        if(Array.isArray(res.data))
        {
        this.setState({

          projectStructureTableData: res.data
        }, () => { console.log(res.data)});
      }
        return []
      }).catch((error) => {
        ;
      })
  }

  // getSomeLevels() {
  //   axios.get(process.env.REACT_APP_API_URL + "/api/projectstructure/level", {
  //     headers: {
  //       'Authorization': localStorage.jwtToken
  //     }
  //   })
  //     .then(res => {
  //       if(Array.isArray(res.data))
  //       {
  //       this.setState({

  //         levelsTableData: res.data
  //       }, () => { });
  //     }
  //       return []
  //     }).catch((error) => {
  //       ;
  //     })
  // }

  render() {
    if (userCant(this.props.user, 'projectStructure.manage')) {
      return
    }
    return (
      <>
        <div className="content">
          <Row>
            <Col tag="h3">{this.props.t('Project Structure')}</Col>
          </Row>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <AddProjectStructure
                    updateProjectData={this.updateProjectData}
                    getSomeBuildings={this.getSomeBuildings}
                    getSomeProjectstructures={this.getSomeProjectstructures }
                    projectStructureTableData={this.state.projectStructureTableData}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setProject: project => dispatch(setProject(project)),
    getProjects: () => dispatch(getProjects()),
    setReportDate: date => dispatch(setReportDate(date)),
    getProjectsByUser: (userid) => dispatch(getProjectsByUser(userid))

  };
}


const mapStateToProps = state => ({
  auth: state.auth,
  project: state.allOtherReducers.project,
  projectId: state.allOtherReducers.projectId,
  projectData: state.allOtherReducers.projectData,
  reportDate: state.allOtherReducers.reportDate,
  user: state.auth.user
});


export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ProjectStructure));


