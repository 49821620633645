
import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col
} from "reactstrap";
import { connect } from "react-redux";
import ReactTable from "react-table";
import moment from "moment"
import { withTranslation } from 'react-i18next';

function mapStateToProps(state) {
  return {
    project: state.allOtherReducers.project,
    projectId: state.allOtherReducers.projectId,
    projectData: state.allOtherReducers.projectData,
    reportDate: state.allOtherReducers.reportDate
  };
}

class ConnectedSiteReportRentals extends React.Component {

  constructor(props) {
    super(props);
    this.state = {

    }
  }




  componentWillReceiveProps({ reportRentals }) {
    this.setState({ ...this.state, reportRentals }, () => this.state)
  }

  render() {
    const NullComponent = () => null;
    return (
      <div>
        <>
        <h4 className="pl-3 card-title">{this.props.t('Rentals')}</h4>
          <ReactTable
            NoDataComponent={NullComponent}
            data={this.props.reportRentals}
            columns={[
              {
                Header: this.props.t("Rental Equipment Id"),
                accessor: "rentalEquipmentId",
                show: false
              }, {
                Header: this.props.t("Company Renting"),
                accessor: "companyRenting",
              },
              {
                Header: this.props.t("Equipment Description"),
                accessor: "rentalEquipmentId.description",
              }, {
                Header: this.props.t("Type / Model"),
                accessor: "rentalEquipmentId.typeModel",
              },
              {
                Header: this.props.t("Rental Company"),
                accessor: "rentalCompany.name",
              },
              {
                Header: this.props.t("Rental Date"),
                accessor: "rentalDate",
                Cell: (props) => {
                  //props.value will contain your date
                  //you can convert your date here
                  // this.renderEditableDate(props)
                  const custom_date = moment.utc(props.value).format('DD MMM YYYY')
                  return <span>{custom_date}</span>
                }
              },
              // {
              //   Header: () => (
              //     <div
              //       style={{
              //         textAlign: "left"
              //       }}
              //     >No. Days</div>),
              //   accessor: "numberOfDays",
              //   // Cell: this.renderEditableNumber
              // },
              {
                Header: `${this.props.t("Rental")} <br />${this.props.t('Finished Date')}`,
                accessor: "rentalFinishedDate",
                Cell: (props) => {
                  //props.value will contain your date
                  //you can convert your date here
                  // this.renderEditableDate(props)
                  if (props.value != undefined) {
                    const custom_dater = moment.utc(props.value).format('DD MMM YYYY')
                    return <span>{custom_dater}</span>
                  }
                  else {
                    return <span></span>
                  }

                }
              },
              // {
              //   Header: () => (
              //     <div
              //       style={{
              //         textAlign: "left"
              //       }}
              //     >Comments</div>),
              //   accessor: "comment",
              //   // Cell: this.renderEditableText
              // },
            ]}
            loadingText=""
            defaultPageSize={1}
            pageSize={this.props.reportRentals.length}
            showPaginationTop={false}
            showPaginationBottom={false}
            // style={{
            //   height: "200px" // This will force the table body to overflow and scroll, since there is not enough room
            // }}
            // getTrProps={this.onRowClick}
            // getTrProps={onRowClick}
            /*
              You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
            */
            className="-striped -highlight primary-pagination"
          />

        </>
      </div>
    );
  }
}

const SiteReportRentals = withTranslation()(connect(mapStateToProps, null)(ConnectedSiteReportRentals));

export default SiteReportRentals;
