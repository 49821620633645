import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
import axios from "axios";
import moment from "moment"
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';

import InputMask from 'react-input-mask';
// reactstrap components
import {
  Button,

  Input,
  CardBody,
  Row,
  Col,
} from "reactstrap";
import { Dock } from "react-dock"
import classnames from "classnames";
import {userCan, userCant} from "../../services/Authorization/authService";

function mapStateToProps(state) {
  return {
    project: state.allOtherReducers.project,
    projectId: state.allOtherReducers.projectId,
    projectData: state.allOtherReducers.projectData,
    reportDate: state.allOtherReducers.reportDate,
    user: state.auth.user
  };
}

class ConnectedAddTaskTable extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      taskToAddValue: '',
      editContent: false,
      editingRow: -1,
      isDirty: false,
      errors: {},
      showForm:false

    }
    this.createNewTask = this.createNewTask.bind(this)
    this.saveRowData = this.saveRowData.bind(this)
    this.toggleEditable = this.toggleEditable.bind(this)
    this.renderActiveButtons = this.renderActiveButtons.bind(this)
    this.renderEditable = this.renderEditable.bind(this)
    this.deleteTaskByTaskId = this.deleteTaskByTaskId.bind(this)

  }



  componentDidMount(props) {

  }

  deleteTaskByTaskId(taskId) {
      if (userCant(this.props.user, 'can_manage_tasks_and_materials')) {
          return
      }

    axios.delete(process.env.REACT_APP_API_URL + '/api/task/' + taskId, {
      headers: {
        'Authorization': localStorage.jwtToken
      }
    }).then(res => {
      this.props.getSomeTasks()
      return res
    })
      .catch((error) => {
        ;
      })
  }

  createNewTask(element) {
    this.setState({
      errors:{}
    })

    if(this.state.editTask){
      return this.saveRowData()
    }

    axios.post(process.env.REACT_APP_API_URL + "/api/task/", {
      createdDate: moment(new Date()).format(),
      taskName: element,
      projectId: this.props.projectId
    }, {
      headers: {
        'Authorization': localStorage.jwtToken
      }
    })
      .then(res => {
        axios.get(process.env.REACT_APP_API_URL + "/api/task/", {
          headers: {
            'Authorization': localStorage.jwtToken
          }
        })
          .then(res => {
            this.setState({
              data: res.data,
              showForm:false,
              errors:{},
            }, () => this.state.data);
            this.props.getSomeTasks(this.state.data)
            return res.data
          })
      })
      .catch((error) => {
        this.setState({
          errors: error.response.data
        })
      })
  }


  handleTaskToAddText = (e) => {

    this.setState({ taskToAddValue: e.target.value }, () => this.state.taskToAddValue);
  }


  componentWillReceiveProps({ timesheetLogs, dateSelected, taskData }) {
    this.setState({ ...this.state, timesheetLogs, dateSelected, taskData }, () => this.state)
  }


  handleInputChange = (cellInfo, event) => {
    let data = [...this.props.taskData];
    data[cellInfo.index][cellInfo.column.id] = event.target.value;

    this.setState({ data });
  };
  renderEditable(cellInfo) {
    return (
        <div >
          {cellInfo.value}
        </div>
    );
  }

  saveRowData() {

    axios.put(process.env.REACT_APP_API_URL + '/api/task/', {
      _id: this.state.editTask._id,
      taskName: this.state.taskToAddValue,
    }, {
      headers: {
        'Authorization': localStorage.jwtToken
      }
    }).then(res => {
      this.setState({
        editTask: null,
        showForm: false,
        errors: {},
        taskToAddValue:''
      }, () => this.props.refreshTask(res.data))

      return res.data
    }).catch((error) => {
        this.setState({
          errors: error.response.data
        })
      })
  }


  toggleEditable(task) {
    this.setState({
      showForm: true,
      editTask: task,
      errors: {},
      taskToAddValue: task.taskName
    })
  }

    renderActiveButtons(row) {
        if (userCant(this.props.user, 'can_manage_tasks_and_materials')) {
            return
        }
        return (<div>
            <Button
                onClick={() => this.toggleEditable(row.original)}
                size="xs"
                className="btn-icon btn-neutral btn btn-info"
            ><i className="fa fa-edit"
            /></Button>
            <Button
                color="danger"
                size="sm"
                className="btn-icon btn-neutral btn btn-info"
                onClick={() => {
                    this.deleteTaskByTaskId(row.original._id)
                }
                }
            ><i
                className="fa fa-remove"
            />
            </Button>
        </div>)


    }

  render() {
    const {errors} = this.state
    return (
      <>
        <div className="content">
          <Dock
              position='right'
              isVisible={this.state.showForm}
              size={window.innerWidth < 600?1:(window.innerWidth < 900?0.5:0.3)}
              duration={300}
          >
            {this.state.showForm && <Row>
              <Col>
                <CardBody>
                  <h3>
                      <Button color="info"
                              className="mr-2"
                              onClick={() => this.setState({errors:{},showForm:false,taskToAddValue:'',editTask:null})}>{this.props.t("Close")}</Button>
                    {this.props.t(`${this.state.editTask ? 'Edit' : 'Add'} Task`)}
                  </h3>
                <Row>
                  <Col>
                    <Input type="text"
                           className={classnames('form-control', {
                             'is-invalid': errors.taskName
                           })}
                           value={this.state.taskToAddValue}
                           onChange={this.handleTaskToAddText} placeholder={this.props.t('Add task name')}/>

                    {errors.taskName && (
                        <div className="invalid-feedback d-block">{errors.taskName}</div>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button color="info"
                            onClick={() => this.createNewTask(this.state.taskToAddValue)}>
                      {this.props.t(`${this.state.editTask ? 'Edit ' : 'Add '} Task`)}
                    </Button>
                  </Col>
                </Row>
                </CardBody>
              </Col>
            </Row>
            }
          </Dock>

            {userCan(this.props.user, 'can_manage_tasks_and_materials') &&
            <Button color="info" onClick={() => this.setState({showForm: !this.state.showForm})}>
                {this.props.t("Add Task")}
            </Button>}

          <ReactTable
            data={this.props.taskData}
            columns={[
              {
                Header: this.props.t("Task Id"),
                accessor: "_id",
                show: false
              }, {
                Header: this.props.t("Task"),
                accessor: "taskName",
                Cell: this.renderEditable
              },
              {
                Header: this.props.t("Actions"),
                id: "activateToggle",
                width: 150,
                Cell: ((value) => (this.renderActiveButtons(value)))
              },

            ]}
            defaultPageSize={1}
            pageSize={this.props.taskData.length}
            showPaginationTop={false}
            showPaginationBottom={false}
            // getTrProps={this.onRowClick}
            // getTrProps={onRowClick}
            /*
              You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
            */
            className="-striped -highlight primary-pagination"
          />
        </div>
      </>
    );
  }
}

const AddTaskTable = withTranslation()(connect(mapStateToProps, null)(ConnectedAddTaskTable));

export default AddTaskTable;
