import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
import axios from "axios";
import {connect} from "react-redux";

import moment from "moment"
import InputMask from 'react-input-mask';
import WeatherReportPDF from "./WeatherReportPDF"
// reactstrap components
import {
    Button,
    Input,
    Row,
    Col
} from "reactstrap";

import {withTranslation} from 'react-i18next';
import {userCan} from "../../../services/Authorization/authService";

class SimpleWeatherConditionsTable extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            editingRow: -1,
            isDirty: false

        }

        this.toggleEditable = this.toggleEditable.bind(this)
        this.renderActiveButtons = this.renderActiveButtons.bind(this)
        this.renderEditable = this.renderEditable.bind(this)
        this.deleteWeatherByWeatherId = this.deleteWeatherByWeatherId.bind(this)
    }


    deleteWeatherByWeatherId(weatherId) {

        axios.delete(process.env.REACT_APP_API_URL + '/api/weather/simple/' + weatherId, {
            headers: {
                'Authorization': localStorage.jwtToken
            }
        }).then(res => {
            this.props.getSimpleWeatherTableData()
            return res
        })
    }

    componentWillReceiveProps({simpleWeatherTableData}) {
        this.setState({...this.state, simpleWeatherTableData}, () => this.state)
    }

    handleInputChange = (cellInfo, event) => {
        let data = [...this.props.simpleWeatherTableData];
        data[cellInfo.index][cellInfo.column.id] = event.target.value;

        this.setState({data});
    };

    renderEditable(cellInfo) {
        return <div>
            {cellInfo.value}
        </div>
    }

    toggleEditable(row) {

        const {isDirty} = this.state;
        if (!isDirty) {
            this.props.setEditingSimpleWeatherRecord(row.original);
        } else {
            this.props.setEditingSimpleWeatherRecord(null);

        }
    }

    renderActiveButtons(row) {

        return (<div>
            {userCan(this.props.user, 'weather.manage') && <Button
                onClick={() => this.toggleEditable(row)}
                size="xs"
                className="btn-icon btn-neutral btn btn-info"
            ><i className="fa fa-edit"
            /></Button>}
            {userCan(this.props.user, 'weather.manage') && <Button
                color="danger"
                size="sm"
                className="btn-icon btn-neutral btn btn-info"
                onClick={() => {
                    this.deleteWeatherByWeatherId(row.original._id)
                }}
            ><i
                className="fa fa-remove"
            />
            </Button>}
            <WeatherReportPDF/>
        </div>)
    }

    render() {
        return (
            <>
                <div className="content">
                    <ReactTable
                        data={this.props.simpleWeatherTableData}
                        columns={[
                            {
                                Header: "Weather Id",
                                accessor: "_id",
                                show: false
                            },
                            // {
                            //   Header: "Date",
                            //   accessor: "weatherDate",
                            //   Cell: (props) => {
                            //     //props.value will contain your date
                            //     //you can convert your date here
                            //     const custom_date = moment(props.value).format('DD MMM YYYY')
                            //     return <span>{custom_date}</span>
                            //   }
                            // },
                            {
                                Header: this.props.t("Weather Type"),
                                accessor: "weatherType",
                            },
                            {
                                Header: this.props.t("Temperature"),
                                accessor: "temperature",
                                Cell: this.renderEditable
                            },
                            {
                                Header: this.props.t("Wind"),
                                accessor: "wind",
                                Cell: this.renderEditable
                            },
                            {
                                Header: this.props.t("Comment"),
                                accessor: "comment",
                                Cell: this.renderEditable
                            },
                            {
                                Header: this.props.t("Images"),
                                accessor: "images",
                                Cell: (props) => {

                                    if (Array.isArray(props.value)) {
                                        return <Row striped={"true"} style={{'padding-left':'20px'}} className={'d-flex justify-content-start'}>
                                            {props.value.map((image) => <div>
                                                    <img width={120} height={120} src={image.url} alt={image.name}></img>
                                                </div>
                                            )}
                                        </Row>
                                    } else {
                                        return []
                                    }
                                },

                            },
                            {
                                Header: this.props.t("Actions"),
                                id: "activateToggle",
                                width: 150,
                                Cell: ((value) => (this.renderActiveButtons(value)))
                            },

                        ]}
                        loadingText=""
                        defaultPageSize={1}
                        pageSize={this.props.simpleWeatherTableData.length}
                        showPaginationTop={false}
                        showPaginationBottom={false}
                        // style={{
                        //   height: "200px" // This will force the table body to overflow and scroll, since there is not enough room
                        // }}
                        // getTrProps={this.onRowClick}
                        // getTrProps={onRowClick}
                        /*
                          You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
                        */
                        className="-striped -highlight primary-pagination"
                    />
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.auth.user
    };
}

export default withTranslation()(connect(mapStateToProps, null)(SimpleWeatherConditionsTable));
