import React from "react";
import axios from "axios"
import AddProjectForm from "views/forms/AddProjectForm"
import Select from "react-select"
import { connect } from "react-redux";
import { setProject, setReportDate, getProjects, getProjectsByUser } from "../../actions/index";
import store from "store";
import { withTranslation } from 'react-i18next';


// reactstrap components
import {
  Card,
  CardBody,
  Row,
  Col,
} from "reactstrap";
import {userCant} from "../../services/Authorization/authService";

class Project extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      projectData:[],
      currentProject: store.getState().projectName
    };

     this.getSomeProjects();
  }


  componentWillMount() {
    if (userCant(this.props.user, 'projects.manage')) {
      return this.props.history.push('/admin/instructions')
    }
  }

  componentDidMount() {
    this.populateDropDownSelectProject(this.props.projectData)
    var startDate = new Date();
    startDate.setDate(startDate.getDate() - 7);

    var endDate = new Date();
    endDate.setDate(endDate.getDate());
    var getDateArray = function (start, end) {
      var arr = new Array();
      var dt = new Date(start);
      while (dt <= end) {
        arr.push(new Date(dt));
        dt.setDate(dt.getDate() + 1);
      }
      return arr;
    }

  }

  componentDidUpdate(prevProps, e){

    if (this.props.projectData !== prevProps.projectData) {
      this.populateDropDownSelectProject(this.props.projectData)

      this.setState({
        projectData: this.props.projectData
      })
    }

    if (this.props.projectId !== prevProps.projectId) {
      this.populateDropDownSelectProject(this.props.projectData)
      this.getSomeProjects()
      this.setState({
        currentProject: store.getState().project
      })
    }

  }


  getSomeProjects() {
    if (userCant(this.props.user, 'projects.manage')) {
      return
    }
    axios.get(process.env.REACT_APP_API_URL + "/api/project/", {headers: {
      'Authorization': localStorage.jwtToken}})
      .then(res => {
        this.setState({
          projectData: res.data
        }, () => console.log(this.state.projectData));

        return res.data
      }).catch((error) => {
        ;
      })
  }

  dateSelectedFromDropdownIntervals(dateIn) {
    this.setState({ dateSelected: dateIn }, () => this.state.dateSelected)
  }

  getScheduleClickedOnText = (gotText) => {
    this.setState({ scheduleClickedItem: gotText }, () => this.state.scheduleClickedItem)
  }

  updateProjectData = (data) => {
    this.setState({ projectData: data }, () => this.state.projectData)

  }

  thisTestFunction = () => {
    alert("im in the parent")
  }

  populateDropDownSelectProject(data) {
    var testdata = data
    if (testdata && testdata.length>0){
      const options = testdata.map(project => {
        return { value: project._id, label: project.name }
      })

      this.setState({ projectDataFromAPI: options }, ()=>{console.log(this.state.projectDataFromAPI[0].value)})

    }

  }

  render() {
    if (userCant(this.props.user, 'projects.manage')) {
      return
    }

    return (
      <>
        <div className="content">
          <Row>
            <Col tag="h3">{this.props.t('Project Administration')}</Col>
          </Row>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <AddProjectForm
                    updateProjectData={this.updateProjectData}
                    projectData={this.state.projectData}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setProject: project => dispatch(setProject(project)),
    getProjects: () => dispatch(getProjects()),
    setReportDate: date => dispatch(setReportDate(date)),
    getProjectsByUser:(userid)=>dispatch(getProjectsByUser(userid))

  };
}


const mapStateToProps = state => ({
  auth: state.auth,
  project: state.allOtherReducers.project,
  projectId: state.allOtherReducers.projectId,
  projectData: state.allOtherReducers.projectData,
  reportDate: state.allOtherReducers.reportDate,
  user: state.auth.user
});


export default withTranslation()(connect(mapStateToProps, mapDispatchToProps )(Project));


