import React from "react";
import classnames from "classnames";
import {
  Button,
  Collapse,
  NavbarBrand,
  Navbar,
  Container,
  Row,
} from "reactstrap";
import { connect } from "react-redux";
import { setProject, setReportDate, getProjects, getProjectsByUser,clearSelectedProject } from "../../actions/index";
import store from "store";
import Datetime from 'react-datetime';
import moment from "moment"
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { logoutUser } from '../../actions/authActions';
import { clearCurrentProfile } from '../../actions/profileActions';
import {

  PROJECT_DATA_LOADED,

} from "../../constants/action-types";
import Col from "reactstrap/lib/Col";
import Select from "react-select";
import LanguageSelector from "../LanguageSelector";


class AdminNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      project: "",
      projectId: "",
      collapseOpen: false,
      color: "navbar-transparent",
      projectData: [{ "test": "me" }],
      projectDataFromAPI: [],
      dateList: [],
      currentProject: store.getState().projectName,
      reportingDate: '',
      defaultProjectSelectValue: {},
      defaultSelectValue: '',
      defaultSelectLabel: '',

    };
  }


  onLogoutClick(e) {
    e.preventDefault();
    this.props.clearCurrentProfile();
    this.props.clearSelectedProject()
    this.props.logoutUser(this.props.history);

  }
  componentDidMount(prevProps) {
     window.addEventListener("resize", this.updateColor);
    this.populateDropDownSelectProject(this.props.projectData)

    var getDateArray = function (start, end) {
      var arr = new Array();
      var dt = new Date(start);
      while (dt <= end) {
        arr.push(new Date(dt));
        dt.setDate(dt.getDate() + 1);
      }

      return arr;


    }

    var startDate = new Date();
    startDate.setDate(startDate.getDate() - 7);

    var endDate = new Date();
    endDate.setDate(endDate.getDate());

    var dateArr = getDateArray(startDate, endDate);
    this.setState({ dateList: dateArr }, () => console.log(this.state.dateList))


    if (!this.props.auth.isAuthenticated) {
      this.props.history.push('/auth/login');
    }

  }


  componentDidUpdate(prevProps, e) {
    // if (
    //   window.outerWidth < 993 &&
    //   // e.history.location.pathname !== e.location.pathname &&
    //   document.documentElement.className.indexOf("nav-open") !== -1
    // ) {
    //   document.documentElement.classList.toggle("nav-open");
    // }



    if (this.props.projectData !== prevProps.projectData) {
      this.populateDropDownSelectProject(this.props.projectData)
      // this.populateDropDownSelectProject(this.props.projectData)

    }

    if (this.props.projectId !== prevProps.projectId) {
      this.setState({
        currentProject: store.getState().project
      })
    }

  }


  populateDropDownSelectProject(data) {
    const options = data.map(project => {
      return {value: project._id, label: project.name}
    })
    this.setState({projectDataFromAPI: options});

  }
  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  updateColor = () => {
    if (window.innerWidth < 993 && this.state.collapseOpen) {
      this.setState({
        color: "bg-white"
      });
    } else {
      this.setState({
        color: "navbar-transparent"
      });
    }
  };
  // this function opens and closes the sidebar on small devices
  toggleSidebar = () => {
    document.documentElement.classList.toggle("nav-open");
  };
  // this function opens and closes the collapse on small devices
  // it also adds navbar-transparent class to the navbar when closed
  // ad bg-white when opened
  toggleCollapse = () => {
    let newState = {
      collapseOpen: !this.state.collapseOpen
    };
    if (!this.state.collapseOpen) {
      newState["color"] = "bg-white";
    } else {
      newState["color"] = "navbar-transparent";
    }
    this.setState(newState);
  };

  getLangOption(code){
    return this.state.languages.find(language => language.value === code) || this.state.languages[0]
  }
  render() {
    const { isAuthenticated, user } = this.props.auth;
    const authLinks = (
      <ul className="navbar-nav ml-auto">
        <li className="nav-item z-100">
            <a
            href=""
            onClick={this.onLogoutClick.bind(this)}
            className="nav-link"
          >
            <img
              className="rounded-circle"
              src={user.avatar}
              alt={user.name}
              style={{ width: '25px', marginRight: '5px' }}
            />{' '}
            Logout
          </a>
        </li>
      </ul>
    );

    const guestLinks = (
      <ul className="navbar-nav ml-auto">
        <li className="nav-item">
          <Link className="nav-link" to="/register">
            Sign Up
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/login">
            Login
          </Link>
        </li>
      </ul>
    );

    return (
      <>
        <nav className="navbar sticky-top bg-white border-bottom my-2">
          <div className={"d-flex justify-content-between align-content-center w-100"}>
            <div className={"d-flex flex-wrap align-content-center col-md-9 col-12 mb-2"}>
              <div
                  style={{position: "absolute", left:"-15px", zIndex:"10000"}}
                  className={classnames("navbar-toggle", {
                    toggled: this.state.sidebarOpen
                  })}
              >
                <button
                    className="navbar-toggler"
                    type="button"
                    onClick={this.toggleSidebar}
                >
                  <span className="navbar-toggler-bar bar1"/>
                  <span className="navbar-toggler-bar bar2"/>
                  <span className="navbar-toggler-bar bar3"/>
                </button>
              </div>
              <div className={"col-md-1 d-none d-md-block"}>
                <div className="navbar-minimize">
                  <Button
                      className="btn-icon btn-round m-0"
                      color="default"
                      id="minimizeSidebar"
                      onClick={this.props.handleMiniClick}
                  >
                    <i className="nc-icon nc-minimal-right text-center visible-on-sidebar-mini"/>
                    <i className="nc-icon nc-minimal-left text-center visible-on-sidebar-regular"/>
                  </Button>
                </div>
              </div>

              <div className={'col-md-5 col-12 align-content-center pl-4 pl-md-0'}>
                <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    name="projectList"
                    value={this.props.projectId ? {
                      value: this.props.projectId,
                      label: this.props.project
                    } : null}
                    defaultValue={this.props.projectId ? {
                      value: this.props.projectId,
                      label: this.props.project
                    } : null}
                    onChange={selectedProject => this.props.setProject(selectedProject)}
                    options={this.state.projectDataFromAPI}
                />

              </div>

              <div className={"col-md-5 col-12"}>
                <div className={"d-md-flex d-block align-content-center pl-md-0"}>
                  <div className={"d-md-flex d-block"}>
                <label className={"m-2"}>Reporting Date</label>
                <Datetime
                    defaultValue={moment(this.props.reportDate).format('DD MMM YYYY')}
                    onChange={(value) =>
                        this.props.setReportDate(moment(value).format('YYYY-MM-DD'))
                    }
                    closeOnSelect={true}
                    timeFormat={false}
                    utc={true}
                    dateFormat={'DD MMM YYYY'}
                    inputProps={{placeholder: "Datetime Picker Here", className:'customDatetimeClass form-control'}}
                />
                </div>
                </div>
              </div>


            </div>
            <div className={'d-none d-md-block'}>
              <div className={"col-md-3 col-12 d-inline"}>
                <LanguageSelector/>
              </div>

              <div className={'d-md-inline-block'}>
                {isAuthenticated ? authLinks : guestLinks}
              </div>
            </div>
          </div>
        </nav>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setProject: project => dispatch(setProject(project)),
    getProjects: () => dispatch(getProjects()),
    setReportDate: date => dispatch(setReportDate(date)),
    logoutUser: history => dispatch(logoutUser(history)),
    clearCurrentProfile: () => dispatch(clearCurrentProfile()),
    clearSelectedProject: () => dispatch(clearSelectedProject()),
    getProjectsByUser: (userid) => dispatch(getProjectsByUser(userid))

  };
}

AdminNavbar.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  clearCurrentProfile: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  project: state.allOtherReducers.project,
  projectId: state.allOtherReducers.projectId,
  projectData: state.allOtherReducers.projectData,
  reportDate: state.allOtherReducers.reportDate,
});


export default connect(mapStateToProps, mapDispatchToProps)(AdminNavbar);

