import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {resetPassword} from '../../actions/authActions';
import {Card, CardBody, CardHeader, Col, Container, Row,} from "reactstrap";
import classnames from "classnames";
import store from "../../store";
import {SET_RESPONSE} from "../../constants/action-types";
import {Link} from "react-router-dom";

class ResetPassword extends Component {
    constructor() {
        super();
        this.state = {
            email: '',
            token: '',
            password: '',
            password2: '',
            errors: {},
            message: '',
        };

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() {
        if (this.props.auth.isAuthenticated) {
            this.props.history.push('/app/initialisation')
        }
        store.dispatch({
            type: SET_RESPONSE,
            payload: {}
        })
        document.body.classList.toggle("login-page");
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let params = new URLSearchParams(location.search);

        let stateProps = {};

        stateProps.email = params.get('email')
        stateProps.token = params.get('token')

        stateProps.errors = nextProps.errors || {};
        stateProps.message = nextProps.message || '';

        if (stateProps.message) {
            stateProps.email = ''
            stateProps.token = ''
            stateProps.password = ''
            stateProps.password2 = ''
        }

        return stateProps;
    }

    componentWillUnmount() {
        document.body.classList.toggle("login-page");
    }

    onSubmit(e) {
        e.preventDefault();

        const userData = {
            email: this.state.email,
            token: this.state.token,
            password: this.state.password,
            password2: this.state.password2,
        };

        this.props.resetPassword(userData);
    }

    onChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }

    render() {
        const {errors} = this.state;
        let {message} = this.state;
        return (
            <div>
                <Container>
                    <Row>
                        <Col className="ml-auto mr-auto" lg="4" md="6">
                            {!message &&
                            <form onSubmit={this.onSubmit}>
                                <Card className="card-login">
                                    <CardHeader>
                                        Enter your new password
                                    </CardHeader>
                                    <CardBody>
                                        <div className="form-group">
                                            <input
                                                type="password"
                                                className={classnames('form-control form-control-lg', {
                                                    'is-invalid': errors.password
                                                })}
                                                autoComplete="new-password"
                                                placeholder="Password"
                                                name="password"
                                                value={this.state.password}
                                                onChange={this.onChange}
                                            />
                                            {errors.password && (
                                                <div className="invalid-feedback">{errors.password}</div>
                                            )}
                                        </div>
                                        <div className="form-group">
                                            <input
                                                type="password"
                                                className={classnames('form-control form-control-lg', {
                                                    'is-invalid': errors.password2
                                                })}
                                                autoComplete="new-password"
                                                placeholder="Confirm Password"
                                                name="password2"
                                                value={this.state.password2}
                                                onChange={this.onChange}
                                            />
                                            {errors.password2 && (
                                                <div className="invalid-feedback">{errors.password2}</div>
                                            )}
                                        </div>
                                        <input type="submit" className="btn btn-info btn-block mt-4"
                                               value="Reset Password"/>

                                        <Link to={`/auth/login`}>Already have account, login here?</Link>
                                    </CardBody>
                                </Card>
                            </form>
                            }
                            {
                                message && message.length &&
                                <div className="alert alert-success">
                                    {message}
                                    <br/>
                                    <b>
                                        <Link to={`/auth/login`} className="text-light">Click her to login</Link>
                                    </b>
                                </div>
                            }
                        </Col>
                    </Row>
                </Container>
                <div
                    className="full-page-background"
                    style={{
                        backgroundImage: `url(${require("assets/img/bg/crane-sky.jpg")?.default})`
                    }}
                />
            </div>
        );
    }
}

ResetPassword.propTypes = {
    resetPassword: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        errors: state.errors,
        message: state.allOtherReducers.response ? state.allOtherReducers.response.message : ""
    }
};

export default connect(mapStateToProps, {resetPassword})(ResetPassword);
