import React from 'react';
import { node } from 'prop-types';

class TreeNode extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      visible: true,
      thisTestProp: '',
      prevTitle:'',
      hover: false,
    };
    
   this.handleMouseEnter=this.handleMouseEnter.bind(this)
   this.handleMouseLeave=this.handleMouseLeave.bind(this)
  }

  handleMouseEnter = () => {
    this.setState({ hover: true });
  }
  handleMouseLeave = () => {
    this.setState({ hover: false });
  }

  componentDidMount(){
    this.setState({prevTitle:this.props.node.title}, ()=>{})
  }

  toggle = e => {
    this.setState({ visible: !this.state.visible });
   this.setState({scheduleClickedOnText: e.target.getAttribute('title'),
   scheduleClickedOnTextId: e.target.getAttribute('id')
  }, ()=>{this.props.getScheduleClickedOnText(this.state.scheduleClickedOnText, this.state.scheduleClickedOnTextId)
    console.log(this.state.scheduleClickedOnText)})
  };


  componentWillReceiveProps(prevProps, {node, prevName }) {
    this.setState({ ...this.state, node, prevName }, () => {})

  }
  

  render() {
    const { hover } = this.state;
    var childNodes;
    var className;

    if (this.props.node.childNodes != null) {
        childNodes = this.props.node.childNodes.map((node, index) => {       
        return <li key={index} ><TreeNode {...this.props} node={node} prevName={this.props.prevName}/></li>
      });

      className = 'togglable  togglable-down';
      // if (this.state.visible) {
      //   className += ' togglable-down';
      // } else {
      //   className += ' togglable-up';
      // }
      var test = this.state.prevTitle + node.title
      
    }
    else{
      this.setState({prevTitle:''}, ()=>{})
    }

    var style;
    if (!this.state.visible) {
      style = { display: "none" };
    }
    return (
     
      <div>
        <div 
        onClick={this.toggle} 
        className={className} 
        class="text-muted" 
        key={this.props.node._id} 
        id={this.props.node._id} 
        title={this.props.prevName+" > "+this.props.node.title}
        style={{
          backgroundColor: "white",
          ...(hover && { boxShadow: `0px 0px 1px 1px`}),
        }}
        onMouseOver={this.handleMouseEnter} // Or onMouseOver
        onMouseLeave={this.handleMouseLeave}
        >
          {this.props.node.title} 
        </div>
        <ul style={style}>
          {childNodes}
        </ul>
      </div>
    )
  }
}
export default TreeNode
