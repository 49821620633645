import React from "react";

import axios from "axios"

import Schedule from "views/pages/Schedule"
import AddAbsenceModal from "views/components/AddAbsenceModal"
import { connect } from "react-redux"
import { getEmployees } from "../../actions/index"

// reactstrap components
import {
  Card,
  CardBody,
  Row,
  Col,
} from "reactstrap";


function mapStateToProps(state) {
  return {
    employees: state.apiEmployees.slice(0, 100)
  };
}
function mapDispatchToProps(dispatch) {
  return {
    getEmployees: employees => dispatch(getEmployees(employees)),

  };
}

class ConnectedAbsence extends React.Component {
  calendarComponentRef = React.createRef();
  constructor(props) {
    super(props);

    this.state = {
      employeeData: []
    };

    // this.getSomeEmployees();
  }




  componentDidMount() {

    var startDate = new Date();
    startDate.setDate(startDate.getDate() - 7);

    var endDate = new Date();
    endDate.setDate(endDate.getDate());
    var getDateArray = function (start, end) {
      var arr = new Array();
      var dt = new Date(start);
      while (dt <= end) {
        arr.push(new Date(dt));
        dt.setDate(dt.getDate() + 1);
      }
      return arr;
    }

    this.props.getEmployees().then(response => (
      this.mapEmployeesToFullCalendar(this.props.employees)
    
    ))
  }

  getSomeEmployees() {
      axios.get(process.env.REACT_APP_API_URL + '/api' + "/employee/")
        .then(res => {
          this.setState({
            employeeData: res.data
          }, () => this.mapEmployeesToFullCalendar(this.state.employeeData));

          return res.data
        }).catch((error) => {
          ;
        })
    }


  mapEmployeesToFullCalendar(employeeData) {
      for(var i = 0; i<employeeData.length; i++) {
      Object.assign(
        employeeData[i],
        { 'title': employeeData[i].name + ' ' + employeeData[i].last_name, 'id': employeeData[i]._id }
      );
    }
    this.setState({
      fullCalendarEmployeeList: employeeData
    }, () => console.log(this.state.fullCalendarEmployeeList))
    return employeeData
  }


  render() {
    return (
      <>
        <div className="content">
          <Row>
            <Col tag="h3">Project Administration</Col>
          </Row>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      <AddAbsenceModal />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Schedule
                        fullCalendarEmployeeList={this.state.fullCalendarEmployeeList}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

const Absence = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedAbsence);

export default Absence;
