
import React from "react";
import Select from "react-select";
import axios from "axios"
import Datetime from 'react-datetime';
import ReactTable from "react-table";
import moment from 'moment'
import InputMask from 'react-input-mask';

// reactstrap components
import {
  Button,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  CardBody
} from "reactstrap";
import { connect } from "react-redux";
import { Dock } from "react-dock"
import classnames from "classnames";
import {withTranslation} from "react-i18next";
import {userCant, userCan} from "../../../services/Authorization/authService";

function mapStateToProps(state) {
  return {
    project: state.allOtherReducers.project,
    projectId: state.allOtherReducers.projectId,
    projectData: state.allOtherReducers.projectData,
    reportDate: state.allOtherReducers.reportDate,
    user: state.auth.user
  };
}

class ConnectedAddDelayForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      singleDelayValueSelect: '',
      selectedValueDelayType: '',
      delayDate: "",
      commentInput: "",
      editContent: false,
      editingRow: -1,
      isDirty: false,
      showForm:false,
      errors:{},
      editDelay: null
    };
    this.formSubmitSuccess = this.formSubmitSuccess.bind(this)
    this.renderEditable = this.renderEditable.bind(this);
    this.saveRowData = this.saveRowData.bind(this);
    this.toggleEditable = this.toggleEditable.bind(this);
    this.renderActiveButtons = this.renderActiveButtons.bind(this);
    this.deleteDelayById = this.deleteDelayById.bind(this)

  }

  componentDidUpdate(prevProps) {
    if (this.props.delayTypeData !== prevProps.delayTypeData) {
      this.populateDropDownDelayType(this.props.delayTypeData);
    }


  }
  handleTagsinput = tagsinput => {
    this.setState({ tagsinput });

  };

  populateDropDownDelayType(data) {
    var testdata = data
    const options = testdata.map(delayType => {
      var htmlInsert = <Row><Col>{delayType.delayTypeNumber}</Col><Col><div>{delayType.delayTypeActivity}</div></Col><Col><div>{delayType.delayTypeDescription}</div></Col></Row>
      return { value: delayType._id, label: htmlInsert }
    })

    this.setState({ delayTypeFromAPI: options }, () => console.log(this.state.delayTypeFromAPI))
  }

  getDelayTypeById(delayTypeId) {

    return axios.get(process.env.REACT_APP_API_URL + "/api/delayType/" + delayTypeId, {
      headers: {
        'Authorization': localStorage.jwtToken
      }
    })
      .then(res => (res.data[0]))
      .catch((error) => {
        ;
      })
  }

  formSubmitSuccess(e) {
    e.preventDefault();

    this.setState({
      errors: {}
    })


    if (this.state.editDelay) {
      return this.saveRowData(e)
    }

    const data = new FormData(e.target);
    var gotDelayTypeId = data.get('delayType')

    this.getDelayTypeById(gotDelayTypeId).then(


      (response) =>

        axios.post(process.env.REACT_APP_API_URL + "/api/delay/", {
          delayTypeId: gotDelayTypeId,
          delayTypeDescription: response.delayTypeDescription,
          scheduledItemId: response.scheduledItemId,
          delayTypeActivity: response.delayTypeActivity,
          delayTypeNumber: response.delayTypeNumber,
          delayDate: this.props.reportDate,
          comment: data.get("comment"),
          projectId: this.props.projectId
        }, {
          headers: {
            'Authorization': localStorage.jwtToken
          }
        })
          .then(res => {
            axios.get(process.env.REACT_APP_API_URL + "/api/delay/project/" + this.props.projectId, {
              headers: {
                'Authorization': localStorage.jwtToken
              }
            })
              .then(res => {
                this.setState({
                  data: res.data
                }, () => this.state.data);

                this.props.getSomeDelays()
                this.clearForm();

                return res.data
              })
          })
          .catch((error) => {
            this.setState({
              errors: error.response.data
            })
          })
    )
  }

  clearForm() {
    this.setState({
      delayDate:null,
      singleDelayValueSelect: null,
      commentInput: '',
      showForm:false,
      errors:{},
      editDelay:null
    }, () => this.state.description)
  }




  componentWillReceiveProps(prevProps, { delayTypeData, delayData }) {
    this.setState({
      ...this.state, delayTypeData, delayData
    }, () => console.log(this.props))

  }

  deleteDelayById(delayId) {
    axios.delete(process.env.REACT_APP_API_URL + '/api/delay/' + delayId, {
      headers: {
        'Authorization': localStorage.jwtToken
      }
    }).then(res => {
      this.props.getSomeDelays()
      return res
    })
      .catch((error) => {
        ;
      })
  }

  handleInputChange = (cellInfo, event) => {
    let data = [...this.props.delayData];
    data[cellInfo.index][cellInfo.column.id] = event.target.value;

    this.setState({ data });
  };

  renderEditable(cellInfo) {

   const cellValue = this.props.delayData[cellInfo.index][cellInfo.column.id];

  const { editingRow } = this.state;
  return (
    <div>
      {editingRow === cellInfo.index ? (
        <Input
          maskChar=""
          mask=""
          placeholder=""
          onChange={this.handleInputChange.bind(null, cellInfo)}
          value={cellValue}
          tag={InputMask}
        />
      ) : (<div >
        {cellInfo.value}
      </div>)}
    </div>
  );
  }
  saveRowData(e) {

    const data = new FormData(e.target);
    var gotDelayTypeId = data.get('delayType')

    this.getDelayTypeById(gotDelayTypeId).then(response => {
      axios.put(process.env.REACT_APP_API_URL + '/api/delay/', {
        _id: this.state.editDelay._id,

        delayTypeId: gotDelayTypeId,
        delayTypeDescription: response.delayTypeDescription,
        scheduledItemId: response.scheduledItemId,
        delayTypeActivity: response.delayTypeActivity,
        delayTypeNumber: response.delayTypeNumber,
        comment: data.get("comment"),

      }, {
        headers: {
          'Authorization': localStorage.jwtToken
        }
      }).then(res => {
        this.setState({
          editMaterial: null,
          showForm: false,
          errors: {},
          editDelay:null,
          singleDelayValueSelect:'',
        }, () => this.props.refreshDelay(res.data))
        return res.data
      })
          .catch((error) => {
            this.setState({
              errors: error.response.data
            })
          })
    })


  }
  toggleEditable(delay) {
    this.setState({
      showForm: true,
      editDelay: delay,
      errors: {},
      commentInput: delay.comment,
      singleDelayValueSelect: this.state.delayTypeFromAPI.find(d => delay.delayTypeId == d.value)
    })
  }

  renderActiveButtons(row) {


    if (userCant(this.props.user, 'delaysandevents.manage')) {
      return '';
    }

    return (<div>
      <Button
        onClick={() => this.toggleEditable(row.original)}
        size="xs"
        className="btn-icon btn-neutral btn btn-info"
      ><i className="fa fa-edit"
        /></Button>
      <Button
        onClick={() => this.deleteDelayById(row.original._id)}
        color="danger"
        size="sm"
        className="btn-icon btn-neutral btn btn-info"
        ><i
        className="fa fa-remove"
        /></Button>
    </div>)


  }



  render() {
    const {errors} = this.state

    return (
      <>

        <Dock
            position='right'
            isVisible={this.state.showForm}
            duration={300}
            size={window.innerWidth < 600?1:(window.innerWidth < 900?0.5:0.3)}
        >
       <Row>
          <Col>
            <CardBody>
              <h4>
                <Button color="info"
                        className={'mr-2'}
                        onClick={() =>this.clearForm()}>{this.props.t("Cancel")}</Button>
                  {this.props.t(`${this.state.editDelay ? 'Edit' : 'Add'} Delay`)}
              </h4>
            <Form className="form-horizontal" onSubmit={(e) => this.formSubmitSuccess(e)}>
              <Row>
                <Col>
                  <label><b>{this.props.t("Delay")}</b></label>
                  <Select
                      classNamePrefix="react-select"
                      name="delayType"
                      onChange={value =>
                          this.setState({singleDelayValueSelect: value})
                      }
                      className={classnames('react-select primary form-control', {
                        'is-invalid': errors.delayTypeActivity
                      })}
                      value={this.state.singleDelayValueSelect}
                      options={this.state.delayTypeFromAPI}
                      placeholder={this.state.selectedValueDelayType}
                  />

                  {errors.delayTypeActivity && (
                      <div className="invalid-feedback d-block">{errors.delayTypeActivity}</div>
                  )}
                </Col>
              </Row>

              <Row><Col>
                <FormGroup>
                  <label>{this.props.t(`Comment`)}</label>
                  <Input
                    name="comment"
                    type="textarea"
                    className={classnames('form-control', {
                      'is-invalid': errors.comment
                    })}
                    value={this.state.commentInput} onChange={(e) => { this.setState({ commentInput: e.target.value }) }}
                  />

                  {errors.comment && (
                      <div className="invalid-feedback d-block">{errors.comment}</div>
                  )}
                </FormGroup>
              </Col>
                {/* <Col md="3">
                  <FormGroup>
                    <label>Delay Date</label>
                    <Datetime
                      onChange={value =>
                        this.setState({ delayDate: value })
                      }
                      closeOnSelect={true}
                      timeFormat={false}
                      utc={true}
                      dateFormat={'DD MMM YYYY'}
                      inputProps={{ placeholder: "Datetime Picker Here" }}
                    />
                  </FormGroup>
                </Col> */}
              </Row>
              <Row>
                <Col> <Button color="info" type="submit">
                  {this.props.t(`${this.state.editDelay ? 'Edit' : 'Add'} Delay`)}
                </Button></Col>

              </Row>


            </Form>
            </CardBody>
          </Col>
        </Row>
        </Dock>
        <Row>
          <Col>

            {userCan(this.props.user, 'delaysandevents.manage') && <Button color="info"
                                                                            onClick={() => this.setState({
                                                                              errors: {},
                                                                              showForm: true
                                                                            })}>{"Add Delay"}</Button>}
          <h4>{this.props.t("All Delays")}</h4>
            <ReactTable
              data={this.props.delayData}
              columns={[
                {
                  Header: this.props.t("Delay"),
                  accessor: "_id",
                  show: false
                },
                {
                  Header: this.props.t("Delay Date"),
                  accessor: "delayDate",
                  Cell: (props) => {
                    //props.value will contain your date
                    //you can convert your date here
                    const custom_date = moment.utc(props.value).format('DD MMM YYYY')
                    return <span>{custom_date}</span>
                  }
                },
                {
                    Header: this.props.t("Delay Type"),
                  accessor: "delayTypeDescription",
                }, {
                  Header: this.props.t("Delay Type Activity"),
                  accessor: "delayTypeActivity",
                },

                {
                  Header: this.props.t("Comments"),
                  accessor: "comment",
                  Cell: this.renderEditable
                },
                {
                  Header: this.props.t("Actions"),
                  accessor: "actions",
                  sortable: false,
                  filterable: false,
                  Cell: ((value) => (this.renderActiveButtons(value)))
                },

              ]}
              showPaginationTop={false}
              showPaginationBottom={false}
              minRows={1}
              pageSize={this.props.delayData.length}

              // style={{
              //   height: "200px" // This will force the table body to overflow and scroll, since there is not enough room
              // }}
              // getTrProps={this.onRowClick}
              // getTrProps={onRowClick}
              /*
                You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
              */
              className="-striped -highlight primary-pagination"
            />
          </Col>
        </Row>
        {/* onSubmit={this.formSubmitSuccess} */}

      </>
    );
  }
}

const AddDelayForm = withTranslation()(connect(mapStateToProps, null)(ConnectedAddDelayForm));

export default AddDelayForm;
