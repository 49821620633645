
import React from "react";
import axios from "axios"
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "../../../vfs_fonts";
import imageHelper from '../../../utils/image_helper.js'
import { connect } from "react-redux";
import { getGeneralCommentsByDateAndByProject } from "../../../actions/index";
import moment from "moment"
import * as imageConversion from 'image-conversion';
import {
  Button
} from "reactstrap";
import ReactBSAlert from "react-bootstrap-sweetalert";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

function mapDispatchToProps(dispatch) {
  return {
    getGeneralCommentsByDateAndByProject: (payload) => dispatch(getGeneralCommentsByDateAndByProject(payload))
  };
}

function mapStateToProps(state) {
  return {
    client: state.allOtherReducers.client,
    clientId: state.allOtherReducers.clientId,
    project: state.allOtherReducers.project,
    projectId: state.allOtherReducers.projectId,
    projectData: state.allOtherReducers.projectData,
    reportDate: state.allOtherReducers.reportDate,
    generalCommentData: state.generalCommentData
  };
}

class ConnectedWeatherReportPDF extends React.Component {
  constructor() {
    super();

    this.state = {

      reportWeather: [],
      customerLogo: null,
      retreivedImages: [],
      previous: 0,
      newWeatherData: [],
      imagesToAdd: [],
      reportWeather1: [],
      weatherDataTypes: '',
      formattedTableStructure: [],
      retreivedLogo: [],
      logoToAdd: [],
      pdfWaitAlert: false,
      alert: null
    };

    this.getImages = this.getImages.bind(this)
    this.getLogo = this.getLogo.bind(this)
    this.getS3SignUrlGetObject = this.getS3SignUrlGetObject.bind(this)
    this.warningWithConfirmAndCancelMessage = this.warningWithConfirmAndCancelMessage.bind(this)
    this.getPDF = this.getPDF.bind(this)
    this.getReportWeather1=this.getReportWeather1.bind(this)

    this.promisedSetState = this.promisedSetState.bind(this)

  }
  promisedSetState = (newState) => new Promise(resolve => this.setState(newState, resolve));
  componentDidMount() {
    var projectId = this.props.projectId
    var date = this.props.reportDate

    if (this.props.projectId === undefined) {
       console.log('no project Id')
    } else {
      this.getReportWeather1(this.props.weatherRecordId)
      this.getLogo()
    }


  }

  componentDidUpdate(prevProps) {
    if (this.props.projectId !== prevProps.projectId) {

      if (this.props.projectId === undefined) {
        console.log('no project Id')
      } else {
        this.getReportWeather1(this.props.weatherRecordId)
      }
    }

    if (this.props.reportDate !== prevProps.reportDate) {

      this.getReportWeather1(this.props.weatherRecordId)
      this.getLogo()

    }

  }

  warningWithConfirmAndCancelMessage = () => {
    this.setState({
      alert: (
        <ReactBSAlert
          style={{ display: "block", marginTop: "-100px" }}
          title="Report Generation"
          onConfirm={() => this.hideAlert()}
          showConfirm={false}
        >
          Your report is being created and will download automatically in a moment.
        </ReactBSAlert>
      )
    });
  }

  hideAlert = () => {
    this.setState({
      alert: null
    }, () => { });
  };
  async getS3SignUrlGetObject(filename, filetype) {
    return axios.get(process.env.REACT_APP_API_URL + "/api/imagemanagement/presigned/" + filename, {
      headers: {
        'Authorization': localStorage.jwtToken,
      }
    })
      .then(res => {
        return res.data
      }).catch((error) => {

        if (axios.isCancel(error)) {

        }
        ;
      })
  }

  getReportWeather1() {
    axios.get(process.env.REACT_APP_API_URL + "/api/weather/simple/" + this.props.reportDate + "/project/" + this.props.projectId, {
      headers: {
        'Authorization': localStorage.jwtToken
      }
    })
      .then(res => {
        console.log(res)
        this.setState({
          reportWeather1: res.data
        }, () => { })

          this.state.reportWeather1.map(weather => {
          let weatherTypes = weather.weatherType.map(e => e).join(", ")
          this.setState({
            weatherDataTypes: weatherTypes
          }, ()=>{})
        }
        )
      }).catch((error) => {
        console.log(error)
        ;
      })
  }

  async getImages() {

    const config = {
      headers: {
        'content-type': 'multipart/form-data',
        'Authorization': localStorage.jwtToken
      },
      params: {
        tags: "weatherreport"
      }

    };
    if (this.state.reportWeather1.length > 0) {
      if (this.state.reportWeather1[0].images.length > 0) {
        const image = await axios.get(process.env.REACT_APP_API_URL + "/api/image/" + this.state.reportWeather1[0].images[0]._id, config)

        if (Array.isArray(image.data)) {
          console.log("no images")
          console.log(response.data)
        }
        else {
          var res = image.data
          res.imageLocation = await this.getS3SignUrlGetObject(res.imageKey)
          await this.promisedSetState({ retreivedImages: res });
        }
      }
    }
  }
  async getLogo() {
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
        'Authorization': localStorage.jwtToken
      }
    };

    this.setState({ retreivedLogo: [] })

    const image = await axios.get(process.env.REACT_APP_API_URL + "/api/logo/company/" + this.props.clientId, config)

    if (Array.isArray(image.data)) {
      image.data.map(async logoItem => {
        try {
          logoItem.imageLocation = await this.getS3SignUrlGetObject(logoItem.imageKey)
          this.setState(prevState => ({
            retreivedLogo: [...prevState.retreivedLogo, logoItem]
          }), () => { })
        }
        catch (err) {

        }

      })
    }
  }


  buildReportHeader() {
    var reportHeaderContent = ''

    const headers = [[{ bold: false, text: "Project" }, { bold: false, text: "Report Date" }]];

    const data = [[this.props.project, moment(this.props.reportDate).format('DD MMMM YYYY')]]
    let content = {
      head: headers,
      body: data,
      headerStyles: {
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        lineWidth: 0.5

      },
      startY: 140,
      theme: "grid",

    };

    reportHeaderContent = content
    return reportHeaderContent
  }

  buildWeatherSummary() {
    var weatherSummaryContent = ''
    const headers = [{ bold: false, text: "Weather" }, { bold: false, text: "Temperature" }, { bold: false, text: "Wind" }];
    const weatherData = this.state.newWeatherData
    weatherData.splice(0, 0, headers)
    weatherSummaryContent = weatherData
    return weatherSummaryContent
  }

  buildWeatherSummary1() {
    var weatherSummaryContent = ''
    if (this.state.reportWeather1.length) {
      const weatherData = this.state.reportWeather1
      const test = [{ text: 'Weather: ' + this.state.weatherDataTypes, style: 'subheader' },
      { text: 'Temperature: ' + weatherData[0].temperature, style: 'subheader' },
      { text: 'Wind: ' + weatherData[0].wind, style: 'subheader' }
      ]
      weatherSummaryContent = test
      return weatherSummaryContent
    }
  }

  buildWeather() {
    var weatherContent = ''
    const headers = [["Weather", "Temperature", "Wind"]];
    const data = this.state.reportWeather.map(elt =>
      [elt.weatherType, elt.temperature, elt.wind
      ]);
    let content = {
      head: headers,
      body: data,
      headerStyles: {
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        lineWidth: 0.5
      },
      theme: "grid"
    };
    weatherContent = content
    return weatherContent
  }

  buildTitles(title) {
    const headers = [[title]];
    const data = []
    let content = {
      head: headers,
      body: data,
      headerStyles: {
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        lineWidth: 0
      },
      theme: "grid"
    };
    return content
  }

  getPDF = async () => {
    this.getReportWeather1()
    this.warningWithConfirmAndCancelMessage()
    this.setState({ pdfWaitAlert: true })
    try {
      let weatherData = []
      let weatherData1 = []
      weatherData = this.buildWeatherSummary()
      weatherData1 = this.buildWeatherSummary1()
      await this.getImages();
      let projectName = this.props.project
      let clientName = this.props.client
      let reportDate = moment(this.props.reportDate).format('DD MMM YYYY')
      var temp = ''
      if (this.state.reportWeather1.length > 0) {
        temp = this.state.reportWeather1[0].temperature
      }
      var windSpeed = ''
      if (this.state.reportWeather1.length > 0) {
        windSpeed = this.state.reportWeather1[0].wind
      }

      var comments = ''
      if (this.state.reportWeather1.length > 0) {
        comments = this.state.reportWeather1[0].comment
      }

      let docDefinitions = {
        compress: true,
        pageMargins: [40, 40, 40, 60],
        content: [],
        pageBreakBefore: function (currentNode, followingNodesOnPage, nodesOnNextPage, previousNodesOnPage) {
          return currentNode.headlineLevel === 1 && followingNodesOnPage.length === 0;
        },

        defaultStyle: {
          font: 'calibre'
        },

        styles: {
          header: {
            fontSize: 18,
            bold: true,
            margin: [0, 0, 0, 10]
          },
          subheader1: {
            fontSize: 16,
            bold: true,
            margin: [0, 10, 0, 5]
          },
          subheader2: {
            fontSize: 12,
            bold: true,
            margin: [0, 0, 0, 0]
          },
          tableExample: {
            margin: [0, 5, 0, 15],
          },
          tableHeader: {
            bold: true,
            fontSize: 13,
            color: 'black'
          }
        },
      }

      pdfMake.fonts = {
        calibre: {
          normal: 'Calibre-Regular.ttf',
          bold: 'Calibre-Semibold.ttf',
          italics: 'Calibre-RegularItalic.ttf',
          bolditalics: 'Calibre-SemiboldItalic.ttf'
        },
      }

      pdfMake.tableLayouts = {
        standardTable: {
          hLineWidth: function (i, node) {
            if (i === 0 || i === node.table.body.length) {
              return 0.25;
            }
            return (i === node.table.headerRows) ? 2 : 1;
          },
          vLineWidth: function (i) {

            return 0.25;
          },
          fillColor: function (i, node) {
            if (i === 0) {
              return '#CCCCCC'
            }
          },
          hLineColor: function (i) {
            return i === 1 ? '#878787' : '#aaa';
          },

          // vLineColor: function(i, node) {
          //   return (i === 0 || i === node.table.widths.length) ? '#ccc' : '#ccc';},
          paddingTop: function (i) {
            return i === 0 ? 10 : 5;
          },
          paddingBottom: function (i, node) {
            return i === 0 ? 5 : 3;
          },
          margin: [0, 0, 0, 0]
        },

        verticalHeaders: {
          hLineWidth: function (i, node) {

            return 0.25;

          },
          vLineWidth: function (rowIndex, node, columnIndex) {
            if (columnIndex === 0) {
              return 1;
            }
            return 0.25;
          },
          vLineColor: function (rowIndex, node, columnIndex) {
            if (columnIndex === 0) {
              return '#878787';
            }
            return '#aaa'

          },

          fillColor: function (rowIndex, node, columnIndex) {
            return (columnIndex === 0) ? '#CCCCCC' : null;
          },
        }
      };


      var filteredLogos = []
      this.state.retreivedLogo.map(logo => {
        filteredLogos.push(logo)
      })


      var logoContainer = []
      for (let filterLogo of filteredLogos) {
        try {
          const logoData = await this.toDataURL1(filterLogo.imageLocation)

          var logoToAdd = {
            image: logoData,
            width: 200,
            margin: 20,
            // alignment: 'center',
          }

          logoContainer.push(logoToAdd)
        }
        catch (err) {
          console.log(err)
        }

      }

      if (logoContainer.length > 0) {
        const logoContent = {
          image: logoContainer[0].image,
          margin: [0, 15, 0, 0],
          width: 140,
          alignment: 'right'
        }
        docDefinitions.content.push(logoContent)
      }

      docDefinitions.content.push(basicShell)
      const basicShell = [
        {
          columns: [
            {
              text: 'Weather Report', style: {
                fontSize: 24,
                bold: false,
                color: "gray",
                marginBottom: 1
              }, lineHeight: 1
            },

          ],
          columnGap: 40,
        },
        // { text: "\n" },
        // { text: "\n" },
        {
          canvas: [
            {
              type: 'line',
              x1: 0, y1: 0,
              x2: 510, y2: 0,
              lineWidth: 0.25
            }
          ]
        },
        { text: "\n" }, {
          columns: [
            {
              style: 'tableExample',
              layout: 'verticalHeaders',
              table: {
                // headerRows: 1,

                widths: [70, 170],
                body:
                  [
                    [{ text: 'Company' }, { text: clientName, style: 'subheader' }],
                    [{ text: 'Project', style: 'subheader' },
                    { text: this.props.project, style: 'subheader' }
                    ],

                    [{ text: 'Date' }, { text: reportDate, style: 'subheader' }]

                  ]
              },
              margin: [0, 0, 0, 20],
              dontBreakRows: false
            },
            {
              style: 'tableExample',
              layout: 'verticalHeaders',
              table: {
                // headerRows: 1,

                widths: [70, 170],
                body:
                  [
                    [{ text: 'Weather' }, { text: this.state.weatherDataTypes, style: 'subheader' }],
                    [{ text: 'Temperature', style: 'subheader' },
                    { text: temp, style: 'subheader' }
                    ],

                    [{ text: 'Wind' },
                    { text: windSpeed + ' mph', style: 'subheader' }],

                  ],


              },
              margin: [0, 0, 0, 20],
              dontBreakRows: false
            },



          ]
        },

        {
          columns:
            [{
              style: 'tableExample',
              layout: 'verticalHeaders',
              table: {
                // headerRows: 1,

                widths: [70, 170],
                body:

                  [[{ text: 'Comments' },
                  { text: comments, style: 'subheader' }]]

                ,


              },
              margin: [0, 0, 0, 0],
              dontBreakRows: false
            },],

        },
        { text: "\n" },
      ]

      docDefinitions.content.push(basicShell)


      // var photoTitle = { text: 'Photographs', style: 'header', lineHeight: 2 }
      // { text: "\n" },
      var photoTitle = [{
        alignment: "left",
        text: 'Images', style: {
          fontSize: 18,
          bold: false,
          color: "gray",
          marginBottom: 1,
          marginTop: 1,
        }, lineHeight: 1
      }]
      docDefinitions.content.push(photoTitle)



      var line = {
        canvas: [
          {
            type: 'line',
            x1: 0, y1: 0,
            x2: 510, y2: 0,
            lineWidth: 0.25
          }
        ]
      }

      docDefinitions.content.push(line)
      if (this.state.retreivedImages) {
        //   //second attempt
        // var filteredImages = []

        // filteredImages.push(this.state.retreivedImages)
        var self = this
        var container = []
        try {


          const imageData = await this.toDataURL1(this.state.retreivedImages.imageLocation)
          var metaDataAvailable = ((this.state.retreivedImages || {}).metadata || {}).width;
          let imageWidth = 0
          let imageHeight = 0
          if (metaDataAvailable) {
            let imageSizing = imageHelper.calculateAspectRatioFit(this.state.retreivedImages.metadata.width, this.state.retreivedImages.metadata.height, 400, 400)
            imageWidth = imageSizing.width
            imageHeight = imageSizing.height
          }
          else {
            imageWidth = 200
            imageHeight = 300
          }


          var imageToAdd = {
            stack: [
              // second column consists of paragraphs
              {
                image: imageData,
                width: imageWidth,
                height: imageHeight,
                margin: [20, 20, 10, 10],

              },
              // { text: filterImage.imageComment, margin: [20, 0, 0, 0], },
            ],
          }
          container.push(imageToAdd)
        }
        catch (err) {
          console.log(err)
        }


        var a = container, chunk
        while (a.length > 0) {

          chunk = a.splice(0, 2)

          docDefinitions.content.push({
            columns: [
              chunk[0],
              chunk[1]
            ],
            columnGap: 40,
          })

        }

      }
      docDefinitions.footer = (function (page, pages) {
        return {
          margin: [15, 10, 15, 0],
          columns: [{
            alignment: "left",
            text: 'Weather Report - ' + moment(self.props.reportDate).format('DD MMM YYYY') + '\n' + self.props.project, color: "gray", fontSize: 10
          }, {
            alignment: "center",
            text: [
              { text: "Page " + page.toString() },
              " of ",
              { text: pages.toString() }
            ], color: "gray", fontSize: 10
          }, {
            alignment: "right",
            text: [
              { text: "Prepared by Worksite Manager\non " + moment().format('DD MMM YYYY'), color: "gray", fontSize: 10 },
            ]
          }
          ]
        }
      })

      try {
        self.setState({
          pdfWaitAlert: false
        }, () => { this.hideAlert() })
        pdfMake.createPdf(docDefinitions).download('Weather Report - ' + this.props.project + ' ' + this.props.reportDate + '.pdf');
        this.setState({
          // retreivedImages: null
        })
      } catch (error) {

        console.log(error)
      }

    } catch (error) {
      console.log(error)
    }
    // setTimeout(() => {  pdfMake.createPdf(docDefinitions).open() }, 10000);

  }

  async getBase64(url) {
    return fetch
      (url, {
        crossDomain: true,
        method: 'get',
        mode: 'cors' // 'cors' by default
      })
      .then(response => response.blob())
      .then(blob => new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onloadend = () => resolve(reader.result)
        reader.onerror = reject
        reader.readAsDataURL(blob)
      }))

  }


  async toDataURL1(src, outputFormat) {
    try {

      return new Promise((resolve, reject) => {
        var img = new Image();
        img.crossOrigin = 'Anonymous';
        img.onload = function () {
          var canvas = document.createElement('CANVAS');
          var ctx = canvas.getContext('2d');
          var dataURL;
          canvas.height = this.naturalHeight;
          canvas.width = this.naturalWidth;
          ctx.drawImage(this, 0, 0);
          try {
            dataURL = canvas.toDataURL();
          } catch (error) {
            console.error(error);
          }
          try {
            resolve(dataURL);
          } catch (error) {
            console.error(error);
          }
        };
        img.src = src;
        if (img.complete || img.complete === undefined) {
          img.src = "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==";
          img.src = src;
        }
      })
    } catch (error) {
      console.error(error);
    }
  }



  render() {
    const images = this.state.retreivedImages

    return (
      <>
        {this.state.alert}
        <div>

          <Button onClick={this.getPDF} variant="contained" color="primary">
            Generate Pdf
          </Button>
        </div>
      </>
    );
  }
}

const WeatherReportPDF = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedWeatherReportPDF);

export default WeatherReportPDF;
