import React from "react";
import { NavLink } from "react-router-dom";
import { Nav, Collapse, Row, Col } from "reactstrap";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";

import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import {userCan} from "../../services/Authorization/authService";
import { logoutUser } from '../../actions/authActions';
import { clearCurrentProfile } from '../../actions/profileActions';
import PropTypes from "prop-types";
import { withTranslation } from 'react-i18next';
import LanguageSelector from "../LanguageSelector";


var ps;


function mapDispatchToProps(dispatch) {
  return {
    logoutUser: history => dispatch(logoutUser(history)),
    clearCurrentProfile: () => dispatch(clearCurrentProfile()),
  };
}

function mapStateToProps(state) {
  return {
    project: state.allOtherReducers.project,
    projectId: state.allOtherReducers.projectId,
    projectData: state.allOtherReducers.projectData,
    reportDate: state.allOtherReducers.reportDate,
    loggedInUser: state.auth.user,
    logoutUser: PropTypes.func.isRequired,
    clearCurrentProfile: PropTypes.func.isRequired,
  };
}

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getCollapseStates(props.routes);
    this.checkRoutes = this.checkRoutes.bind(this);
    this.isRouteAccessible = this.isRouteAccessible.bind(this);
    this.createLinks = this.createLinks.bind(this)
    this.getCollapseInitialState = this.getCollapseInitialState.bind(this)
  }
  // this creates the intial state of this component based on the collapse routes
  // that it gets through this.props.routes
  getCollapseStates = routes => {
    let initialState = {};
    this.checkRoutes(routes).map((prop, key) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: this.getCollapseInitialState(prop.views),
          ...this.getCollapseStates(prop.views),
          ...initialState
        };
      }
      return null;
    });
    return initialState;
  };
  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms
  getCollapseInitialState(routes) {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (window.location.pathname.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  }

    isRouteAccessible(route) {
        if (route.permission === undefined) {
            return true;
        }

        let permissions = Array.isArray(route.permission) ? route.permission : [route.permission];

        for (let p of permissions) {
            if (userCan(this.props.loggedInUser, p)) {
                return true;
            }
        }

        return false;
    }

  onLogoutClick(e) {
    e.preventDefault();
    this.props.clearCurrentProfile();
    this.props.logoutUser(this.props.history);

  }

  checkRoutes(routes) {
    let filteredRoutes = [];

    for (let route of routes) {
      if (this.isRouteAccessible(route)) {
        filteredRoutes.push(route);
      }
    }

    return filteredRoutes
  }
  toggleSidebar = () => {
    document.documentElement.classList.toggle("nav-open");
  }
  // this function creates the links and collapses that appear in the sidebar (left menu)
  createLinks = routes => {
    return routes.map((prop, key) => {
      if (prop.redirect) {
        return null;
      }
      if(prop.show==="false")
      {
        return null;
      }
      if (prop.collapse) {

        let subViews = this.checkRoutes(prop.views);

        if (subViews.every(v => v.show === 'false')) {
          return null;
        }

        var st = {};
        st[prop["state"]] = !this.state[prop.state];
        return (
          <li
            className={this.getCollapseInitialState(prop.views) ? "active" : ""}
            key={key}
          >
            <a
              href="#pablo"
              data-toggle="collapse"
              aria-expanded={this.state[prop.state]}
              onClick={e => {
                e.preventDefault();
                this.setState(st);
              }}
            >
              {prop.icon !== undefined ? (
                <>
                  <i className={prop.icon} />
                  <p>
                    {this.props.t(prop.name)}
                    <b className="caret" />
                  </p>
                </>
              ) : (
                <>
                  <span className="sidebar-mini-icon">{prop.mini}</span>
                  <span className="sidebar-normal">
                    {this.props.t(prop.name)}
                    <b className="caret" />
                  </span>
                </>
              )}
            </a>
            <Collapse isOpen={this.state[prop.state]}>
              <ul className="nav">{this.createLinks(subViews)}</ul>
            </Collapse>
          </li>
        );
      }
      return (
        <li className={this.activeRoute(prop.layout + prop.path)} key={key}>
          <NavLink to={prop.layout + prop.path} activeClassName="" onClick={()=>this.toggleSidebar()}>
            {prop.icon !== undefined ? (
              <>
                <i className={prop.icon} />
                <p>{this.props.t(prop.name)}</p>
              </>
            ) : (
              <>
                <span className="sidebar-mini-icon">{prop.mini}</span>
                <span className="sidebar-normal">{this.props.t(prop.name)}</span>
              </>
            )}
          </NavLink>
        </li>
      );
    });
  };
  // verifies if routeName is the one active (in browser input)
  activeRoute = routeName => {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };
  componentDidMount() {
    // if you are using a Windows Machine, the scrollbars will have a Mac look
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.sidebar, {
        suppressScrollX: true,
        suppressScrollY: false
      });
    }
  }
  componentWillUnmount() {
    // we need to destroy the false scrollbar when we navigate
    // to a page that doesn't have this component rendered
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
  }
  render() {
    return (
      <div
        className="sidebar"
        data-color={this.props.bgColor}
        data-active-color={this.props.activeColor}
      >
        <div className="logo">
          {/* <a
            href="https://www.brightermindz.com"
            className="simple-text logo-mini"
          >
            <div className="logo-img">
              <img src={logo} alt="react-logo" />
            </div>
          </a> */}
          <a

            className="simple-text logo-normal"
          >
            WORKSITE MANAGER
          </a>
        </div>

        <div className="sidebar-wrapper" ref="sidebar">
          <div className="user">
            <Row>
            <Col>
            {/* <div className="photo">
              <img src={avatar} alt="Avatar" />
            </div> */}

            <div className="info">

              <a
                href="#pablo"
                data-toggle="collapse"
                aria-expanded={this.state.openAvatar}
                onClick={() =>
                  this.setState({ openAvatar: !this.state.openAvatar })
                }

              >
                <span >

            {this.props.loggedInUser.email}
                  <b className="caret" />
                </span>
              </a>

              <Collapse isOpen={this.state.openAvatar}>
                <ul className="nav">
                  <li>
                    <NavLink to="/admin/user-profile" activeClassName="">
                      <span className="sidebar-mini-icon">MP</span>
                      <span className="sidebar-normal">My Profile</span>
                    </NavLink>
                    <a href="" onClick={this.onLogoutClick.bind(this)}>
                      <span className="sidebar-mini-icon">LO</span>
                      <span className="sidebar-normal">Logout</span>
                    </a>

                    <a href={'#'} style={{'padding':0,'margin':'0px 0px 0 8px'}}>
                      <LanguageSelector customClass={'custom-mobile-react-selector-class'}/>
                    </a>
                  </li>
                  {/*<li>*/}
                  {/*  <NavLink to="/admin/user-profile" activeClassName="">*/}
                  {/*    <span className="sidebar-mini-icon">EP</span>*/}
                  {/*    <span className="sidebar-normal">Edit Profile</span>*/}
                  {/*  </NavLink>*/}
                  {/*</li>*/}
                  {/*<li>*/}
                  {/*  <NavLink to="/admin/user-profile" activeClassName="">*/}
                  {/*    <span className="sidebar-mini-icon">S</span>*/}
                  {/*    <span className="sidebar-normal">Settings</span>*/}
                  {/*  </NavLink>*/}
                  {/*</li>*/}
                </ul>
              </Collapse>

            </div>
            </Col>
            </Row>
          </div>
          <Nav>{this.createLinks(this.checkRoutes(this.props.routes))}</Nav>
        </div>
      </div>
    );
  }
}


export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(Sidebar)));
