import React from "react";
// import jsPDF from "jspdf";
// import "jspdf-autotable";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  Button
} from "reactstrap";
import { connect } from "react-redux";
import ReactTable from "react-table";
import helper from '../../../utils/general_helper.js'
import { withTranslation } from 'react-i18next';

function mapStateToProps(state) {
  return {
    project: state.allOtherReducers.project,
    projectId: state.allOtherReducers.projectId,
    projectData: state.allOtherReducers.projectData,
    reportDate: state.allOtherReducers.reportDate
  };
}

class ConnectedSiteReportContractors extends React.Component {

  constructor(props) {
    super(props);
    this.state = {

    }
  }








  componentWillReceiveProps({ reportContractedWork, formattedTableStructure }) {
    this.setState({ ...this.state, reportContractedWork, formattedTableStructure }, () => this.state)
  }

  render() {
    const NullComponent = () => null;
    return (
      <div>
        <>
          <h4 className="pl-3 card-title">{this.props.t('Contracted Work')}</h4>
          <ReactTable
            NoDataComponent={NullComponent}
            data={this.props.formattedTableStructure}
            columns={[
              {
                Header: "Id",
                accessor: "_id",
                show: false
              },
              {
                Header:this.props.t( "Project Id"),
                accessor: "projectId",
                show: false
              },
              {
                Header: this.props.t("Scheduled Item"),
                accessor: "scheduledItem",
                style: { 'whiteSpace': 'unset' },
                width: 300,
              },
              {
                Header:this.props.t( "Detail"),
                accessor: "contractedWorkDetail",
                className: "frozen",
                Cell: (props) => {
                  let multiLineString = props.value
                  let lines = multiLineString.split('\n')
                  return lines.map((line) => <div>{line}</div>);
                },
              },
              {
                Header: this.props.t("Employee Detail"),
                accessor: "timesheetLogDetail",
                // className: "frozen",
                Cell: (props) => {


                  return props.value.map((timesheetLogDetail) =>
                      <Row striped>
                        <Col>{timesheetLogDetail.user_id.name + ' ' + timesheetLogDetail.user_id.last_name}</Col><Col>{helper.hoursMinutesAllocateTimeFormatted(timesheetLogDetail.allocated)}</Col>
                      </Row>

                  )
                },

              },
              {
                Header: this.props.t("Total employee duration"),
                accessor: "timesheetLogDetail",
                // className: "frozen",
                Cell: (props) => {

                  return helper.hoursMinutesAllocateTimeFormatted(props.value.reduce((r, d) => r + d.allocated, 0));

                },

              },
              // {
              //   Header: "Work Duration",
              //   accessor: "duration",
              //   width: 150,
              // },
            ]}
            loadingText=""
            defaultPageSize={1}
            pageSize={this.props.formattedTableStructure.length}
            showPaginationTop={false}
            showPaginationBottom={false}
            className="-striped -highlight primary-pagination"
          />
        </>
      </div>
    );
  }
}

const SiteReportContractors = withTranslation()(connect(mapStateToProps, null)(ConnectedSiteReportContractors));

export default SiteReportContractors;

