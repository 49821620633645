
import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col
} from "reactstrap";
import { connect } from "react-redux";
import ReactTable from "react-table";
import { withTranslation } from 'react-i18next';

function mapStateToProps(state) {
  return {
    project: state.allOtherReducers.project,
    projectId: state.allOtherReducers.projectId,
    projectData: state.allOtherReducers.projectData,
    reportDate: state.allOtherReducers.reportDate
  };
}

class ConnectedSiteReportPositions extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      tableLength:[]
    }
  }

 


  componentWillReceiveProps({ reportPositions }) {
    this.setState({ ...this.state, reportPositions }, () => this.state)
  }

  render() {
    const NullComponent = () => null;
    return (
      <div>
        <>
        <h4 className="pl-3 card-title">{this.props.t('Positions on site')}</h4>
          <ReactTable
            NoDataComponent={NullComponent}
            data={this.props.reportPositions}
            columns={[
              {
                Header: this.props.t("Positions"),
                accessor: "position"
              },{
                Header: this.props.t("Company"),
                accessor: "company"
              },
              {
                Header: this.props.t("Count"),
                accessor: "count"
              }
            ]}
            defaultPageSize={2}
            pageSize={this.props.reportPositions.length} 
            showPaginationTop={false}
            showPaginationBottom={false}
            // style={{
            //   height: "200px" // This will force the table body to overflow and scroll, since there is not enough room
            // }}
            // getTrProps={this.onRowClick}
            // getTrProps={onRowClick}
            /*
              You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
            */
            className="-striped -highlight primary-pagination"
          />

        </>
      </div>
    );
  }
}

const SiteReportPositions = withTranslation()(connect(mapStateToProps, null)(ConnectedSiteReportPositions));

export default SiteReportPositions;
