export const GET_ERRORS = 'GET_ERRORS';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const GET_PROFILE = 'GET_PROFILE';
export const PROFILE_LOADING = 'PROFILE_LOADING';
export const PROFILE_NOT_FOUND = 'PROFILE_NOT_FOUND';
export const CLEAR_CURRENT_PROFILE = 'CLEAR_CURRENT_PROFILE';
export const GET_PROFILES = 'GET_PROFILES';
export const CURRENT_SCHEDULE_ITEM = 'CURRENT_SCHEDULE_ITEM';
export const SET_CLIENT_OBJECT = 'SET_CLIENT_OBJECT';
export const UPDATE_CURRENT_USER = 'UPDATE_CURRENT_USER';
