import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
import axios from "axios";
import moment from "moment"
import InputMask from 'react-input-mask';
import TreeNode from "views/components/TreeNode";
import { withTranslation } from 'react-i18next';

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Input,
  Form,
  Row,
  Col,
  FormGroup,
  Modal
} from "reactstrap";
import { connect } from "react-redux";

function mapStateToProps(state) {
  return {
    project: state.allOtherReducers.project,
    projectId: state.allOtherReducers.projectId,
    projectData: state.allOtherReducers.projectData,
    reportDate: state.allOtherReducers.reportDate
  };
}

class ConnectedInstructionFormAndTable extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      data: [{ "_id": "zx3", "material_name": "LED Detector" }, { "_id": "zx4", "material_name": "Hand Torch" }],
      instructionData: '',
      instructionTitle: '',
      userDefinedInstructionNumber: '',
      instructionDescriptionToAddValue: '',
      instructionDescription: ''
    }
    this.formSubmitSuccess = this.formSubmitSuccess.bind(this)

  }



  componentDidMount(props) {

  }


  deleteInstructionById(instructionId) {
    axios.delete(process.env.REACT_APP_API_URL + '/api/instruction/' + instructionId, {
      headers: {
        'Authorization': localStorage.jwtToken
      }
    }).then(res => {
      this.props.getSomeInstructions()
      return res
    })
      .catch((error) => {
        ;
      })
  }


  formSubmitSuccess(e) {
    e.preventDefault();
    const data = new FormData(e.target);
    axios.post(process.env.REACT_APP_API_URL + "/api/instruction/", {
      userDefinedInstructionNumber: data.get("userDefinedInstructionNumber"),
      instructionTitle: data.get("instructionTitle"),
      instructionDescription: data.get("instructionDescription"),
      projectId: this.props.projectId
    }, {
      headers: {
        'Authorization': localStorage.jwtToken
      }
    }).then(
      res => {
        axios.get(process.env.REACT_APP_API_URL + "/api/instruction/project/" + this.props.projectId, {
          headers: {
            'Authorization': localStorage.jwtToken
          }
        })
          .then(res => {
            this.setState({
              data: res.data
            }, () => this.props.getSomeInstructions());
            this.clearForm()
            return res.data
          })
      })
      .catch((error) => {
        ;
      })

  }

  clearForm() {
    this.setState({
      userDefinedInstructionNumber: '',
      instructionTitle: '',
      instructionDescription: '',
    }, () => this.state.description)
    this.props.isInstructionDockVisibleControl()
  }



  handleInstructionToAddDescription = (e) => {

    this.setState({ instructionDescriptionToAddValue: e.target.value }, () => this.state.instructionDescriptionToAddValue);
  }



  componentDidUpdate(prevProps) {
    if (this.props.projectId !== prevProps.projectId) {
      this.props.getSomeInstructions()
    }

  }

  componentWillReceiveProps({ timesheetLogs, dateSelected, instructionData }) {
    this.setState({ ...this.state, timesheetLogs, dateSelected, instructionData }, () => this.state)
  }

  handleInputChange = (cellInfo, event) => {
    let data = [...this.props.instructionData];
    data[cellInfo.index][cellInfo.column.id] = event.target.value;

    this.setState({ data });
  };



  render() {
    return (
      <>

        <Card>
          <CardHeader>
            <CardTitle tag="h4">
              <Button color="info" className={'mr-2'}
                      onClick={() => {
                        this.props.isInstructionDockVisibleControl()
                      }}>
                {this.props.t("Close")}
              </Button>
              {this.props.t("Add Instruction")}
            </CardTitle>
          </CardHeader>
          <CardBody>

            <Row>
              <Col>
                <Form className="form-horizontal" onSubmit={(e) => this.formSubmitSuccess(e)}>

                  <Row>
                    <Col>
                      <FormGroup>
                        <label>{this.props.t("Instruction Number")}</label>
                        <Input
                          type="text"
                          name="userDefinedInstructionNumber"
                          value={this.state.userDefinedInstructionNumber}
                          // defaultValue={this.state.description}
                          onChange={(e) => this.setState({ userDefinedInstructionNumber: `${e.target.value}` })}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <label>{this.props.t("Title")}</label>
                        <Input
                          type="text"
                          name="instructionTitle"
                          value={this.state.instructionTitle}
                          // defaultValue={this.state.description}
                          onChange={(e) => this.setState({ instructionTitle: `${e.target.value}` })}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <label>{this.props.t("Description")}</label>
                        <Input
                          type="text"
                          name="instructionDescription"
                          value={this.state.instructionDescription}
                          // defaultValue={this.state.description}
                          onChange={(e) => this.setState({ instructionDescription: `${e.target.value}` })}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Button color="info" type="submit">
                        {this.props.t("Submit")}
                      </Button>

                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>


          </CardBody>
        </Card>
      </>
    );
  }
}

const AddInstructionFormAndTable = withTranslation()(connect(mapStateToProps, null)(ConnectedInstructionFormAndTable));

export default AddInstructionFormAndTable;
