const helper = {

   formatAdditionalWorkDataForTables(dataToFormat) {
    if (dataToFormat) {
      var compositeScheduledItemId;
      const formattedData = dataToFormat.map(elt => {
        console.log(elt)
        if (typeof elt.instruction != "undefined" ) { var compositeInstruction = elt.instruction.userDefinedInstructionNumber===undefined?"-" + ' | ' + elt.instruction.instructionTitle:elt.instruction.userDefinedInstructionNumber + ' | ' + elt.instruction.instructionTitle}  else { var compositeInstruction = '' }
        if (elt.elevation) { var compositeElevation = elt.elevation.name } else { var compositeElevation = '' }
        if (elt.level) { var compositeLevel = elt.level.name } else { var compositeLevel = '' }
        if (elt.system) { var compositeSystem = elt.system.name } else { var compositeSystem = '' }
        if (elt.comment.length > 0) { var compositeComment = elt.comment } else { var compositeComment = '' }
        if (elt.hasOwnProperty('task')) {
          if (elt.task != null) {
            var compositeTaskName = elt.task.taskName
          }
          else
          {
            var compositeTaskName = ''
          }
        }
        else {
          var compositeTaskName = ''
        }
        if (elt.hasOwnProperty('material')) {
          if (elt.material != null) {
            var compositeMaterialName = elt.material.materialName
          }
          else
          {
            var compositeMaterialName = ''
          }
        }
        else {
          var compositeMaterialName = ''
        }
        if (typeof elt.scheduledItemId === null) {
          compositeScheduledItemId = elt.scheduledItem
        } else {
          console.log(elt.scheduledItemId)
          compositeScheduledItemId = elt.scheduledItemId?.full_path }
        var nameComma = ''

        return [
          // format output
          'Instruction: '+compositeInstruction
          + '\n'
          + elt.building?.building
          + ' '
          + compositeSystem
          + ': '
          + compositeTaskName
          + ' '
          + compositeMaterialName
          + ' '
          + compositeLevel
          + ' '
          + compositeElevation
          + '\n'
          + 'Comments: ' + compositeComment,
          //The order here is reflected in the table structure below by x[n]
          elt.duration,
          elt._id,
          elt.projectId,
          elt.scheduledItem,
          compositeScheduledItemId,
          elt.timesheetLogDetail,
          // compositeInstruction,
        ]
      }
      )
      const tableStructure = formattedData.map(x => {
        return {
          '_id': x[2],
          'projectId': x[3],
          'scheduledItem': x[5],
          'instruction': x[7],
          'additionalWorkDetail': x[0],
          'timesheetLogDetail': x[6],
          'duration': x[1]
        }
      }
      )
      console.log(tableStructure)
      return formattedData
    }
    else
    {
      var str = 'ERR:No contracted work data provide'
      console.log(str)
      return []
    }
  }
}

module.exports = helper;
